import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../public/buildconfiguration.css";
import { Download } from "react-bootstrap-icons";
import { addToCart, fetchUserById } from "../../cart/view/cartSlice";
import { useDispatch } from "react-redux";
import BuildConfigurationModel from "./buildConfigurationModel";
import * as cartService from "../../../services/cartService";
import { axiosClient, imageBaseUrl } from "../../../../config";
import Modal from "@mui/material/Modal";
import MetaDecorator from "../../../../util/metaDecorator";

// import { Alert, Snackbar } from "@mui/material";
import toast from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";

import { BiSolidErrorAlt } from "react-icons/bi";
import BreadcrumbNew from "../../../client_component/breadcrumbNew";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BuildConfiguration = () => {
  const dispatch = useDispatch();
  const [configOption, setConfigOption] = useState(0);
  const [openProdcess, setOpenProdcess] = useState(false);
  const [open, setOpen] = useState(false);
  const [buildPcData, setBuildPcData] = useState([]);
  const [buildPcDataTemp, setBuildPcDataTemp] = useState([]);
  const [buildPcCategoryId, setBuildPcCategoryId] = useState(null);
  const [buildPcCategoryIndex, setBuildPcCategoryIndex] = useState(null);
  const [configurations, setConfigurations] = useState([
    { products: [] },
    { products: [] },
    { products: [] },
  ]);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSnackbarOpen = () => {
    setOpenSnackbar(true);
  };

  const fetchBuildPcData = async () => {
    try {
      const res = await axiosClient.get("/member/build-pc");
      const data = res.data.data;
      setBuildPcDataTemp(data);
      const newArray = data
        .flatMap((e) => (e.catId == 360 ? Array(2).fill(e) : Array(1).fill(e)))
        .map((item, index) => ({ ...item, index: index }));
      setBuildPcData(newArray);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    fetchBuildPcData();
    const savedConfigurations =
      JSON.parse(localStorage.getItem("configurations")) || [];
    if (savedConfigurations.length > 0) {
      setConfigurations(savedConfigurations);
    }
  }, []);

  const handleClickOpen = (cateId, index) => {
    setBuildPcCategoryId(cateId);
    setBuildPcCategoryIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setConfigOption(newValue);
  };

  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };

  const user = useAuth();

  const handleChooseProduct = (product, configOption) => {
    const updatedConfigurations = [...configurations];
    const selectedProductIndex = updatedConfigurations[
      configOption
    ].products.findIndex(
      (productItem) =>
        productItem.catId === product.catId &&
        productItem.index === product.index
    );

    if (selectedProductIndex !== -1) {
      const selectedProduct =
        updatedConfigurations[configOption].products[selectedProductIndex];

      if (selectedProduct.productName !== product.productName) {
        updatedConfigurations[configOption].products[selectedProductIndex] = {
          ...product,
          quantity: 1,
        };
      } else {
        updatedConfigurations[configOption].products[selectedProductIndex] = {
          ...selectedProduct,
          quantity: selectedProduct.quantity + 1,
        };
      }
    } else {
      updatedConfigurations[configOption].products.push({
        ...product,
        quantity: 1,
      });
    }

    setConfigurations(updatedConfigurations);
    localStorage.setItem(
      "configurations",
      JSON.stringify(updatedConfigurations)
    );
    setOpen(false);
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    if (user) {
      if (
        configurations[configOption] &&
        configurations[configOption].products
      ) {
        for (const product of configurations[configOption].products) {
          totalPrice += product.priceOld * product.quantity;
        }
      }
    } else {
      if (
        configurations[configOption] &&
        configurations[configOption].products
      ) {
        for (const product of configurations[configOption].products) {
          totalPrice += product.price * product.quantity;
        }
      }
    }
    return totalPrice;
  };

  const fomatNumer = (number) => {
    return new Intl.NumberFormat("vi", {
      style: "currency",
      currency: "VND",
    }).format(number);
  };

  const handleAddToCart = async () => {
    if (user) {
      if (
        configurations[configOption] &&
        configurations[configOption].products.length > 0
      ) {
        setOpenProdcess(true);
        const postRequests = configurations[configOption].products.map(
          async (product) => {
            try {
              const res = await cartService.postCart({
                product_id: product.product_id,
                quality: product.quantity,
                price: product.priceOld,
                title: product.productName,
                picture: product.picture,
                MaKhoSPApdung: product.macn,
                catName: product.cat_name.category_desc.cat_name,
                catNameParent:
                  product.cat_name_parrent.category_desc.friendly_url,
                brandName: product.brand_name,
                stock: product.stock,
              });

              if (res.data.status === true) {
                return true;
              } else {
                return false;
              }
            } catch (error) {
              console.error("post data error", error);
              return false;
            }
          }
        );

        const results = await Promise.all(postRequests);
        const success = results.every((result) => result);

        if (success) {
          dispatch(fetchUserById());
          setOpenProdcess(false);
        } else {
          toast.custom(
            (t) => (
              <CustomToast
                icon={<BiSolidErrorAlt size={25} color="red" />}
                content={"Có lỗi xảy ra khi thêm vào giỏ hàng!"}
              />
            ),
            {
              position: "top-right",
            }
          );
        }
      }
    } else {
      if (
        configurations[configOption] &&
        configurations[configOption].products.length > 0
      ) {
        configurations[configOption].products.forEach((product) => {
          dispatch(
            addToCart({
              productId: product.product_id,
              quality: product.quantity,
              price: product.price,
              productName: product.productName,
              picture: product.picture,
              MaKhoSPApdung: product.macn,
              catName: product.cat_name.category_desc.cat_name,
              catNameParent:
                product.cat_name_parrent.category_desc.friendly_url,
              brandName: product.brand_name,
              stock: product.stock,
            })
          );
        });
      }
    }
  };

  const handleIncrement = (product) => {
    const updatedConfigurations = [...configurations];
    const productIndex = updatedConfigurations[configOption].products.findIndex(
      (item) =>
        item.catId === product.catId && item.productName === product.productName
    );

    if (productIndex !== -1) {
      const currentQuantity =
        updatedConfigurations[configOption].products[productIndex].quantity;

      if (currentQuantity < 10) {
        updatedConfigurations[configOption].products[
          productIndex
        ].quantity += 1;
        setConfigurations(updatedConfigurations);
        localStorage.setItem(
          "configurations",
          JSON.stringify(updatedConfigurations)
        );
      } else {
        toast.custom((t) => (
          <CustomToast
            icon={<BiSolidErrorAlt size={25} color="red" />}
            content={"Số lượng sản phẩm tối đa là 10."}
          />
        ));
      }
    }
  };

  const handleDecrement = (product) => {
    const updatedConfigurations = [...configurations];
    const productIndex = updatedConfigurations[configOption].products.findIndex(
      (item) =>
        item.catId === product.catId && item.productName === product.productName
    );

    if (productIndex !== -1) {
      if (
        updatedConfigurations[configOption].products[productIndex].quantity ===
        1
      ) {
        updatedConfigurations[configOption].products.splice(productIndex, 1);
      } else {
        updatedConfigurations[configOption].products[
          productIndex
        ].quantity -= 1;
      }
      setConfigurations(updatedConfigurations);
      localStorage.setItem(
        "configurations",
        JSON.stringify(updatedConfigurations)
      );
    }
  };

  const handleQuantityChange = (event, product) => {
    const { value } = event.target;
    if (!isNaN(value) && value >= 1 && value <= 10) {
      const updatedConfigurations = [...configurations];
      const productIndex = updatedConfigurations[
        configOption
      ].products.findIndex(
        (item) =>
          item.catId === product.catId &&
          item.productName === product.productName
      );

      if (productIndex !== -1) {
        updatedConfigurations[configOption].products[productIndex].quantity =
          parseInt(value, 10);
        setConfigurations(updatedConfigurations);
        localStorage.setItem(
          "configurations",
          JSON.stringify(updatedConfigurations)
        );
      }
    } else {
      toast.custom((t) => (
        <CustomToast
          icon={<BiSolidErrorAlt size={25} color="red" />}
          content={
            "Số lượng sản phẩm trong xây dựng cấu hình phải từ 1 đến 10 sản phẩm."
          }
        />
      ));
    }
  };

  const handleRemove = (product) => {
    const updatedConfigurations = [...configurations];
    const productIndex = updatedConfigurations[configOption].products.findIndex(
      (item) =>
        item.catId === product.catId && item.productName === product.productName
    );

    if (productIndex !== -1) {
      updatedConfigurations[configOption].products.splice(productIndex, 1);
      setConfigurations(updatedConfigurations);
      localStorage.setItem(
        "configurations",
        JSON.stringify(updatedConfigurations)
      );
    }
  };

  const handleSaveConfigurationss = async () => {
    const currentConfiguration = configurations[configOption].products;
    if (currentConfiguration.length > 0) {
      setOpenProdcess(true);
    } else {
      toast.custom(
        (t) => (
          <CustomToast
            icon={<BiSolidErrorAlt size={25} color="red" />}
            content={"Vui lòng chọn ít nhất một cấu hình trước khi lưu!"}
          />
        ),
        {
          position: "top-right",
        }
      );
      return;
    }

    const dataCombine = [];
    for (const item of currentConfiguration) {
      dataCombine.push({
        productName: item.productName,
        price: user ? item.priceOld : item.price,
        quantity: item.quantity,
      });
    }

    await axiosClient({
      url: `/member/export-excel-pc?key=${JSON.stringify(dataCombine)}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "build-pc.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setOpenProdcess(false);
      });
  };

  const breadcrumbLink = [
    { id: 1, name: "Xây dựng cấu hình", link: `/xay-dung-cau-hinh` },
  ];
  return (
    <div>
      {/* <Toaster /> */}
      <MetaDecorator
        description="Nơi tự tay xây dụng cấu hình máy của riêng bạn"
        title="Xây dựng cấu hình"
      />
      <Container>
        {/* <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
        
          <Alert
            variant="filled"
            severity="warning"
            onClose={() => handleSnackbarClose()}
          >
            Vui lòng chọn ít nhất một cấu hình trước khi lưu!
          </Alert>
        </Snackbar> */}
        <div className="mt-3">
          <BreadcrumbNew arrayLink={breadcrumbLink} />
        </div>
        <div className="build-configuration-container">
          <div className="build-configuration-left">
            <Box sx={{ width: "100%" }}>
              {/* Mobile */}
              <div className="choose-option-save-mobile">
                {/* <div  className="d-flex ps-4">
                  <button onClick={handleSaveConfigurationss} className="save-config-btn">
                    <Download size={15} className="me-2" />
                    Lưu cấu hình
                  </button>
                </div> */}
                <div className="save-config-btn">
                  <Link
                    rel="noopener noreferrer"
                    title="liên hệ tư vấn từ chuyên gia"
                    to={`https://zalo.me/${`0933880711`}`}
                    // className="provisional-costs"
                    style={{ color: "#fff" }}
                    target="_blank"
                  >
                    Liên hệ tư vấn từ chuyên gia
                  </Link>
                </div>

                <div>
                  {configurations[configOption]?.products &&
                  configurations[configOption].products.length > 0 ? (
                    <div className="provisional-costs-container">
                      <h3 className="provisional-costs">
                        Chi phí dự tính: {fomatNumer(calculateTotalPrice())}
                      </h3>
                      <div
                        onClick={handleAddToCart}
                        className="add-to-cart-button"
                      >
                        Thêm vào giỏ hàng
                      </div>
                    </div>
                  ) : (
                    <div className="provisional-costs-container">
                      <h3 className="provisional-costs">
                        Chi phí dự tính: {fomatNumer(0)}
                      </h3>
                    </div>
                  )}
                </div>
              </div>

              {/* Desktop */}
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <div className="build-configuration-tab">
                  <div>
                    <Tabs
                      value={configOption}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Cấu hình 1" {...a11yProps(0)} />
                      <Tab label="Cấu hình 2" {...a11yProps(1)} />
                      <Tab label="Cấu hình 3" {...a11yProps(2)} />
                    </Tabs>
                  </div>

                  <div className="choose-option-save ">
                    <div className="save-config-btn">
                      <Link
                        to="https://zalo.me/0933880711"
                        // className="provisional-costs"
                        style={{ color: "#fff" }}
                        target="_blank"
                      >
                        Liên hệ tư vấn từ chuyên gia
                      </Link>
                    </div>
                    <button
                      className="save-config-btn"
                      onClick={handleSaveConfigurationss}
                    >
                      <Download size={15} className="me-2" />
                      Lưu cấu hình
                    </button>
                    <div>
                      {configurations[configOption]?.products &&
                      configurations[configOption].products.length > 0 ? (
                        <div className="provisional-costs-container">
                          <h3 className="provisional-costs">
                            Chi phí dự tính: {fomatNumer(calculateTotalPrice())}
                          </h3>
                          <div
                            onClick={handleAddToCart}
                            className="add-to-cart-button"
                          >
                            Thêm vào giỏ hàng
                          </div>
                        </div>
                      ) : (
                        <div className="provisional-costs-container">
                          <h3 className="provisional-costs">
                            Chi phí dự tính: {fomatNumer(0)}
                          </h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Box>

              <CustomTabPanel value={configOption} index={0}>
                <div className="Configurations-list">
                  {buildPcData.map((item) => {
                    const selectedProduct = configurations[
                      configOption
                    ].products.find(
                      (product) =>
                        product.catId === item.catId &&
                        product.index === item.index
                    );
                    const isSelected = selectedProduct !== undefined;
                    return (
                      <div className="Configurations-item">
                        <div className="Configurations-left">
                          <div className="Configurations-name">
                            {item.catName}
                          </div>
                          {isSelected && selectedProduct ? (
                            <div className="Configurations-desc">
                              <div className="d-flex">
                                <div className="d-block">
                                  <img
                                    className="accessory-img"
                                    src={`${imageBaseUrl}/uploads/${selectedProduct.picture}`}
                                    alt={`${selectedProduct.productName}`}
                                    loading="lazy"
                                  />
                                </div>
                                <div className="Configurations-name-product">
                                  {selectedProduct.productName}
                                  <div className="Configurations-quality-mobile">
                                    <div className="quantity-product Configurations-quantity">
                                      <button
                                        className="decrement-btn"
                                        onClick={() =>
                                          handleDecrement(selectedProduct)
                                        }
                                      >
                                        -
                                      </button>
                                      <input
                                        className="input-quantity"
                                        type="number"
                                        onChange={(e) =>
                                          handleQuantityChange(
                                            e,
                                            selectedProduct
                                          )
                                        }
                                        value={selectedProduct.quantity}
                                        min={1}
                                      />
                                      <button
                                        className="increment-btn"
                                        onClick={() =>
                                          handleIncrement(selectedProduct)
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                    <p className="total-price Configurations-total-price">
                                      {user
                                        ? fomatNumer(
                                            selectedProduct.priceOld *
                                              selectedProduct.quantity
                                          )
                                        : fomatNumer(
                                            selectedProduct.price *
                                              selectedProduct.quantity
                                          )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="Configurations-quality-tablet">
                                <div className="quantity-product Configurations-quantity">
                                  <button
                                    className="decrement-btn"
                                    onClick={() =>
                                      handleDecrement(selectedProduct)
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    className="input-quantity"
                                    type="number"
                                    onChange={(e) =>
                                      handleQuantityChange(e, selectedProduct)
                                    }
                                    value={selectedProduct.quantity}
                                    min={1}
                                  />
                                  <button
                                    className="increment-btn"
                                    onClick={() =>
                                      handleIncrement(selectedProduct)
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                                <p className="total-price Configurations-total-price">
                                  {user
                                    ? fomatNumer(
                                        selectedProduct.priceOld *
                                          selectedProduct.quantity
                                      )
                                    : fomatNumer(
                                        selectedProduct.price *
                                          selectedProduct.quantity
                                      )}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <div>
                                <img
                                  onClick={() =>
                                    handleClickOpen(item.catId, item.index)
                                  }
                                  className="accessory-img"
                                  src="https://firebasestorage.googleapis.com/v0/b/mongcaifood.appspot.com/o/cpu.svg?alt=media&token=77cbc8d7-f570-45a4-8bee-8d87265e39fd"
                                  alt="cpu"
                                  loading="lazy"
                                />
                              </div>
                              <div className="Configurations-title">
                                Vui lòng chọn linh kiện
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="Configurations-choose-action">
                          {isSelected && (
                            <div
                              className="Configurations-choose-option-btn"
                              onClick={() => handleRemove(selectedProduct)}
                            >
                              Xóa
                            </div>
                          )}
                          <div
                            onClick={() =>
                              handleClickOpen(item.catId, item.index)
                            }
                            className="Configurations-choose-option-btn"
                          >
                            {isSelected ? "Sửa" : "Chọn"}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={configOption} index={1}>
                <div className="Configurations-list">
                  {buildPcData.map((item) => {
                    const selectedProduct = configurations[
                      configOption
                    ].products.find(
                      (product) =>
                        product.catId === item.catId &&
                        product.index === item.index
                    );
                    const isSelected = selectedProduct !== undefined;
                    return (
                      <div className="Configurations-item">
                        <div className="Configurations-left">
                          <div className="Configurations-name">
                            {item.catName}
                          </div>
                          {isSelected && selectedProduct ? (
                            <div className="Configurations-desc">
                              <div className="d-flex">
                                <div className="d-block">
                                  <img
                                    className="accessory-img"
                                    src={`${imageBaseUrl}/uploads/${selectedProduct.picture}`}
                                    alt={`${selectedProduct.productName}`}
                                    loading="lazy"
                                  />
                                </div>
                                <div className="Configurations-name-product">
                                  {selectedProduct.productName}
                                  <div className="Configurations-quality-mobile">
                                    <div className="quantity-product Configurations-quantity">
                                      <button
                                        className="decrement-btn"
                                        onClick={() =>
                                          handleDecrement(selectedProduct)
                                        }
                                      >
                                        -
                                      </button>
                                      <input
                                        className="input-quantity"
                                        type="number"
                                        onChange={(e) =>
                                          handleQuantityChange(
                                            e,
                                            selectedProduct
                                          )
                                        }
                                        value={selectedProduct.quantity}
                                        min={1}
                                      />
                                      <button
                                        className="increment-btn"
                                        onClick={() =>
                                          handleIncrement(selectedProduct)
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                    <p className="total-price Configurations-total-price">
                                      {user
                                        ? fomatNumer(
                                            selectedProduct.priceOld *
                                              selectedProduct.quantity
                                          )
                                        : fomatNumer(
                                            selectedProduct.price *
                                              selectedProduct.quantity
                                          )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="Configurations-quality-tablet">
                                <div className="quantity-product Configurations-quantity">
                                  <button
                                    className="decrement-btn"
                                    onClick={() =>
                                      handleDecrement(selectedProduct)
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    className="input-quantity"
                                    type="number"
                                    onChange={(e) =>
                                      handleQuantityChange(e, selectedProduct)
                                    }
                                    value={selectedProduct.quantity}
                                    min={1}
                                  />
                                  <button
                                    className="increment-btn"
                                    onClick={() =>
                                      handleIncrement(selectedProduct)
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                                <p className="total-price Configurations-total-price">
                                  {user
                                    ? fomatNumer(
                                        selectedProduct.priceOld *
                                          selectedProduct.quantity
                                      )
                                    : fomatNumer(
                                        selectedProduct.price *
                                          selectedProduct.quantity
                                      )}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <div>
                                <img
                                  onClick={() =>
                                    handleClickOpen(item.catId, item.index)
                                  }
                                  className="accessory-img"
                                  src="https://firebasestorage.googleapis.com/v0/b/mongcaifood.appspot.com/o/cpu.svg?alt=media&token=77cbc8d7-f570-45a4-8bee-8d87265e39fd"
                                  alt="cpu"
                                  loading="lazy"
                                />
                              </div>
                              <div className="Configurations-title">
                                Vui lòng chọn linh kiện
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="Configurations-choose-action">
                          {isSelected && (
                            <div
                              className="Configurations-choose-option-btn"
                              onClick={() => handleRemove(selectedProduct)}
                            >
                              Xóa
                            </div>
                          )}
                          <div
                            onClick={() =>
                              handleClickOpen(item.catId, item.index)
                            }
                            className="Configurations-choose-option-btn"
                          >
                            {isSelected ? "Sửa" : "Chọn"}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={configOption} index={2}>
                <div className="Configurations-list">
                  {buildPcData.map((item) => {
                    const selectedProduct = configurations[
                      configOption
                    ].products.find(
                      (product) =>
                        product.catId === item.catId &&
                        product.index === item.index
                    );
                    const isSelected = selectedProduct !== undefined;
                    return (
                      <div className="Configurations-item">
                        <div className="Configurations-left">
                          <div className="Configurations-name">
                            {item.catName}
                          </div>
                          {isSelected && selectedProduct ? (
                            <div className="Configurations-desc">
                              <div className="d-flex">
                                <div className="d-block">
                                  <img
                                    className="accessory-img"
                                    src={`${imageBaseUrl}/uploads/${selectedProduct.picture}`}
                                    alt={`${selectedProduct.productName}`}
                                    loading="lazy"
                                  />
                                </div>
                                <div className="Configurations-name-product">
                                  {selectedProduct.productName}
                                  <div className="Configurations-quality-mobile">
                                    <div className="quantity-product Configurations-quantity">
                                      <button
                                        className="decrement-btn"
                                        onClick={() =>
                                          handleDecrement(selectedProduct)
                                        }
                                      >
                                        -
                                      </button>
                                      <input
                                        className="input-quantity"
                                        type="number"
                                        onChange={(e) =>
                                          handleQuantityChange(
                                            e,
                                            selectedProduct
                                          )
                                        }
                                        value={selectedProduct.quantity}
                                        min={1}
                                      />
                                      <button
                                        className="increment-btn"
                                        onClick={() =>
                                          handleIncrement(selectedProduct)
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                    <p className="total-price Configurations-total-price">
                                      {user
                                        ? fomatNumer(
                                            selectedProduct.priceOld *
                                              selectedProduct.quantity
                                          )
                                        : fomatNumer(
                                            selectedProduct.price *
                                              selectedProduct.quantity
                                          )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="Configurations-quality-tablet">
                                <div className="quantity-product Configurations-quantity">
                                  <button
                                    className="decrement-btn"
                                    onClick={() =>
                                      handleDecrement(selectedProduct)
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    className="input-quantity"
                                    type="number"
                                    onChange={(e) =>
                                      handleQuantityChange(e, selectedProduct)
                                    }
                                    value={selectedProduct.quantity}
                                    min={1}
                                  />
                                  <button
                                    className="increment-btn"
                                    onClick={() =>
                                      handleIncrement(selectedProduct)
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                                <p className="total-price Configurations-total-price">
                                  {user
                                    ? fomatNumer(
                                        selectedProduct.priceOld *
                                          selectedProduct.quantity
                                      )
                                    : fomatNumer(
                                        selectedProduct.price *
                                          selectedProduct.quantity
                                      )}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <div>
                                <img
                                  onClick={() =>
                                    handleClickOpen(item.catId, item.index)
                                  }
                                  className="accessory-img"
                                  src="https://firebasestorage.googleapis.com/v0/b/mongcaifood.appspot.com/o/cpu.svg?alt=media&token=77cbc8d7-f570-45a4-8bee-8d87265e39fd"
                                  alt="cpu"
                                />
                              </div>
                              <div className="Configurations-title">
                                Vui lòng chọn linh kiện
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="Configurations-choose-action">
                          {isSelected && (
                            <div
                              className="Configurations-choose-option-btn"
                              onClick={() => handleRemove(selectedProduct)}
                            >
                              Xóa
                            </div>
                          )}
                          <div
                            onClick={() =>
                              handleClickOpen(item.catId, item.index)
                            }
                            className="Configurations-choose-option-btn"
                          >
                            {isSelected ? "Sửa" : "Chọn"}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </CustomTabPanel>
            </Box>
          </div>
        </div>
        {buildPcCategoryId != null ? (
          <BuildConfigurationModel
            open={open}
            close={handleClose}
            buildPcCategoryId={buildPcCategoryId}
            buildPcCategoryIndex={buildPcCategoryIndex}
            configOption={configOption}
            onChooseProduct={handleChooseProduct}
          />
        ) : null}
      </Container>
      {openProdcess && (
        <Modal
          open={openProdcess}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="paypalProcessing">
              <div className="animation">
                <div className="container">
                  <div className="balls">
                    <div className="ball ball-one" />
                    <div className="ball ball-two" />
                    <div className="ball ball-three" />
                    <div className="ball ball-four" />
                    <div className="shadow shadow-one" />
                    <div className="shadow shadow-two" />
                    <div className="shadow shadow-three" />
                    <div className="shadow shadow-four" />
                  </div>
                </div>
              </div>
              <div className="text">
                <h1>Đang xử lý</h1>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      <h1 style={{ color: "transparent" }}>Xây dựng cấu hình</h1>
      <h2 style={{ color: "transparent" }}>Xây dựng cấu hình</h2>
    </div>
  );
};

export default BuildConfiguration;
