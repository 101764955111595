import { Container, Image, Form, Col, Row } from "react-bootstrap";
import CardSimpleParameter from "./cardSimpleParameter";
import CardContentDetailHire from "./cardContentDetailHire";
import CardInfoCompany from "./cardInfoCompany";
import BoxJobRelated from "./boxJobRelated";
import ModalApplyJob from "./modalApplyJob";
import { useState } from "react";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert, Snackbar } from "@mui/material";
import { axiosClient } from "../../../../config";
import { useParams } from "react-router-dom";

function DetailHire() {
  const [show, setShow] = useState(false);
  const [showSnackbar, setShowSnackBar] = useState({
    open: false,
    status: null,
  });

  const [dataJob, setDataJob] = useState("");
  const [loading, setLoading] = useState(false);
  const { nameJob } = useParams();
  const fetchDataJob = async () => {
    try {
      const res = await axiosClient.get(`/member/hire-post/${nameJob}`);
      setDataJob(res.data.data);
      setLoading(false);
    } catch (error) {
      console.error("fetch data error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchDataJob();
  }, [nameJob]);

  return (
    <div className="hire-wrapper">
      <Container fluid>
        {!loading ? (
          <>
            <Row className="container_detail_hire">
              <Col md="8">
                <Row>
                  <Col md="12" className="container_simple_parameters p-3">
                    <div className="box_simple_parameters">
                      <CardSimpleParameter
                        dataJob={dataJob}
                        show={show}
                        setShow={setShow}
                      />
                    </div>
                  </Col>
                  <Col md="12" className="container_detail_parameters p-3">
                    <div className="box_detail_parameters">
                      <CardContentDetailHire
                        dataJob={dataJob}
                        show={show}
                        setShow={setShow}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md="4" className="box_content_right">
                <Row>
                  <Col md="12" className="container_info_company p-3">
                    <div className="box_info_company">
                      <CardInfoCompany dataJob={dataJob} />
                    </div>
                  </Col>
                  <Col md="12" className="container_job_related p-3">
                    <div className="box_job_related">
                      <BoxJobRelated dataJob={dataJob} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            {show && (
              <ModalApplyJob
                setShowSnackBar={setShowSnackBar}
                idJob={dataJob.id}
                show={show}
                setShow={setShow}
              />
            )}

            <Snackbar
              TransitionComponent="SlideTransition"
              open={showSnackbar.open}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={3000}
              onClose={() =>
                setShowSnackBar((prev) => ({
                  ...prev,
                  open: false,
                }))
              }
            >
              <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
                {showSnackbar.status
                  ? `Ứng tuyển thành công`
                  : "Qúa trình ứng tuyển chưa hoàn thành"}
              </Alert>
            </Snackbar>
          </>
        ) : (
          <>
            <div className="text-center pt-5">
              {" "}
              <CircularProgress />
            </div>
          </>
        )}
      </Container>
    </div>
  );
}

export default DetailHire;
