import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ReactPaginate from "react-paginate";
import { IconButton } from "@mui/material";
import { PlusCircle } from "react-bootstrap-icons";
import CircularProgress from "@mui/material/CircularProgress";
import "../public/buildConfigurationModel.css";
import { axiosClient, imageBaseUrl } from "../../../../config";

const BuildConfigurationModel = ({
  open,
  close,
  buildPcCategoryId,
  configOption,
  onChooseProduct,
  buildPcCategoryIndex,
}) => {
  const [buildPcDataProducts, setBuildPcDataProducts] = useState([]);
  const [buildPcDataProductsTemp, setBuildPcDataProductsTemp] = useState([]);
  const [filterBrand, setFilterBrand] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [chooseBrand, setChooseBrand] = useState("");
  const [filterOption, setFilterOption] = useState([]);
  const [chooseOption, setChooseOption] = useState("");
  const [priceFilter, setPriceFilter] = useState("");
  const [valueSearch, setValueSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 12;
  const startIndex = pageNumber * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const handlePageChange = ({ selected }) => {
    window.scrollTo(0, 0);
    setPageNumber(selected);
  };
  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();
  const fethFilterBuildPc = async () => {
    try {
      const res = await axiosClient.get(
        "/member/filter-build-pc?key=" + buildPcCategoryId
      );

      setFilterBrand(res.data.brand);
      setFilterOption(res.data.opSearch);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };
  const fetchBuildPcProducts = async () => {
    setIsLoaded(false);
    try {
      const res = await axiosClient.get(
        "/member/build-pc?key=" +
          buildPcCategoryId +
          "&price=" +
          priceFilter +
          "&brandId=" +
          chooseBrand +
          "&opSearch=" +
          chooseOption
      );
      setBuildPcDataProducts(res.data.productResult);
      setBuildPcDataProductsTemp(res.data.productResult);
      setIsLoaded(true);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };
  useEffect(() => {
    fetchBuildPcProducts();
  }, [buildPcCategoryId, priceFilter, chooseBrand, chooseOption]);
  useEffect(() => {
    fethFilterBuildPc();
  }, [buildPcCategoryId]);

  const currencyFormat = (num) => {
    return (
      Number(num)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " đ"
    );
  };
  const handleChooseProduct = (product) => {
    onChooseProduct({ ...product, index: buildPcCategoryIndex }, configOption);
  };

  const handleSearchProduct = (event) => {
    const value = event.target.value;
    setValueSearch(value);
    if (!value) return setBuildPcDataProducts(buildPcDataProductsTemp);
    const updateDataSearch = buildPcDataProducts.filter((item) => {
      const title = item.productName && item.productName.toLowerCase();
      return title && value && title.includes(value);
    });
    setBuildPcDataProducts(updateDataSearch);
  };

  const handleChangeBrand = (selectedOption) => {
    setChooseBrand(selectedOption);
  };

  const handleChangeOption = (selectedOption) => {
    setChooseOption(selectedOption);
  };
  return (
    <div className="box_modal_config">
      <Dialog className="build-configuration-model" open={open} onClose={close}>
        <div cljassName="filter-from">
          <DialogTitle className="py-2">
            <p className="text-center mb-0">Bộ lọc</p>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{ position: "absolute", right: 10, top: 10 }}
          >
            X
          </IconButton>
          <DialogContent className="py-2">
            <div>
              <div className="select-filter-product-box">
                <div className="select-box">
                  <select
                    id="brand"
                    className="down-filter"
                    onChange={(e) => handleChangeBrand(e.target.value)}
                  >
                    <option className="down-config-option" value="">
                      Chọn thương hiệu
                    </option>
                    {filterBrand.map((item) => (
                      <option
                        className="brand-option"
                        key={item.brandId}
                        value={item.brandId}
                      >
                        {item.brandName}
                      </option>
                    ))}
                  </select>
                  <select
                    id="brand-cpu"
                    className="down-filter"
                    onChange={(e) => handleChangeOption(e.target.value)}
                  >
                    <option className="down-config-option" value="">
                      Chọn cấu hình
                    </option>
                    {filterOption.map((item) => (
                      <option
                        className="brand-option"
                        key={item.Op_id}
                        value={item.Op_id}
                      >
                        {item.OpSearchName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="filter-price-and-search">
                  <div className="filter-price-box">
                    <div className="filter-title-name">Sắp xếp theo</div>
                    <div className="filter-price-box-item">
                      <div
                        onClick={() => setPriceFilter("asc")}
                        className="filter-btn"
                      >
                        Giá tăng dần
                      </div>
                      <div
                        onClick={() => setPriceFilter("desc")}
                        className="filter-btn"
                      >
                        Giá giảm dần
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      className="search-accessory-input"
                      placeholder="Tìm kiếm linh kiện"
                      value={valueSearch}
                      onChange={handleSearchProduct}
                    />
                  </div>
                </div>
                <div className="filter-price-and-search-mobile">
                  <div className="filter-price-box">
                    <div className="filter-title-name">Sắp xếp theo</div>
                    <div className="d-flex">
                      <div
                        onClick={() => setPriceFilter("ASC")}
                        className="filter-btn"
                      >
                        Giá tăng dần
                      </div>
                      <div
                        onClick={() => setPriceFilter("DESC")}
                        className="filter-btn"
                      >
                        Giá giảm dần
                      </div>
                    </div>
                  </div>
                  <div className="filter-price-box-item">
                    <input
                      className="search-accessory-input"
                      placeholder="Tìm kiếm linh kiện"
                      value={valueSearch}
                      onChange={handleSearchProduct}
                    />
                  </div>
                </div>
              </div>

              <>
                <div className="filter-product-box">
                  {!isLoaded ? (
                    <div
                      style={{ height: "75%" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div>
                        <CircularProgress />
                      </div>
                    </div>
                  ) : (
                    <>
                      {buildPcDataProducts != null &&
                      buildPcDataProducts.length > 0 ? (
                        buildPcDataProducts
                          ?.filter(
                            (item) =>
                              item.picture != "" &&
                              item.price != 0 &&
                              item.productName != null
                          )
                          .slice(startIndex, endIndex)
                          .map((item) => {
                            return (
                              <div className="filter-product-list">
                                <div className="filter-product-left">
                                  <div>
                                    <img
                                      className="filter-product-img"
                                      src={`${imageBaseUrl}/uploads/${item.picture}`}
                                      alt={`${item.productName}`}
                                      loading="lazy"
                                    />
                                  </div>
                                  <div className="filter-product-desc">
                                    <div className="filter-product-desc-name">
                                      {item.productName}
                                    </div>
                                    <div className="filter-product-desc-price">
                                      <div>
                                        {user
                                          ? currencyFormat(item.priceOld)
                                          : currencyFormat(item.price)}
                                      </div>

                                      <div
                                        className="choose-product-btn-mobile"
                                        onClick={() =>
                                          handleChooseProduct(item)
                                        }
                                      >
                                        <PlusCircle
                                          className="me-2"
                                          size={15}
                                        />
                                        Chọn
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div
                                    className="choose-product-btn"
                                    onClick={() => handleChooseProduct(item)}
                                  >
                                    Chọn
                                  </div>
                                </div>
                              </div>
                            );
                          })
                      ) : (
                        <h2 className="no-product-nofication mt-4">
                          Không có sản phẩm phù hợp
                        </h2>
                      )}
                    </>
                  )}
                </div>
                <div className="build-configuration-pagination">
                  <ReactPaginate
                    pageCount={Math.ceil(
                      buildPcDataProducts?.filter(
                        (item) =>
                          item.picture != "" &&
                          item.price != 0 &&
                          item.productName != null
                      ).length / itemsPerPage
                    )}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    previousLabel={"<"}
                    nextLabel={">"}
                  />
                </div>
              </>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default BuildConfigurationModel;
