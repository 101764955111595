import { Container, Spinner } from "react-bootstrap";
import MetaDecorator from "../../../../util/metaDecorator";
import { FaHandshake } from "react-icons/fa6";

import "../public/partnerMain.css";
import { useEffect, useState } from "react";
import { axiosClient, imageBaseUrl } from "../../../../config";
import PartnerModal from "./PartnerModal";
import BreadcrumbNew from "../../../client_component/breadcrumbNew";

function PartnerMain() {
  const [logoData, setLogoData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [namePartner, setNamePartner] = useState("");
  const [contentData, setContentData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchDataLogo();
    fetchDataContent();
  }, []);

  const fetchDataContent = async () => {
    try {
      const res = await axiosClient.get(`/member/get-partnerIntro`);

      if (res.data.status === true) {
        setContentData(res.data.data);
      }
    } catch (error) {
      console.error("Lỗi không thể fetch data logo hãng", error);
    }
  };

  const fetchDataLogo = async () => {
    setIsLoading(true);
    try {
      const res = await axiosClient.get(`/member/partner`);

      if (res.data.status == "success") {
        setLogoData(res.data.partner.reverse());
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Lỗi không thể fetch data logo hãng", error);
    } finally {
      setIsLoading(false);
    }
  };

  const breadcrumbLink = [
    { id: 1, name: "Đối tác của chúng tôi", link: `/doi-tac` },
  ];
  const handleOpenModalPartNer = (namePartner) => {
    setNamePartner(namePartner);
    setModalShow(true);
  };

  return (
    <div className="box_partner_company">
      <MetaDecorator
        title="Đối tác của Nguyên Kim"
        description="Đối tác của Công ty Vi tính Nguyên Kim"
      />

      <Container>
        {isLoading ? (
          <div className="w-100 h-100 d-flex justify-content-center">
            {" "}
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="partner-container">
            <div className="mt-1">
              <BreadcrumbNew arrayLink={breadcrumbLink} />
            </div>
            <h4 className="partner-title">Đối tác của Nguyên Kim</h4>

            <div
              className="partner-introduce"
              dangerouslySetInnerHTML={{ __html: contentData.introduce }}
            ></div>

            <div className="partner-logo">
              <div className="partner-logo__title">
                <FaHandshake size={20} />
                <h2 className="partner-logo__item">Đối tác của chúng tôi</h2>
                <FaHandshake size={20} />
              </div>
              <div className="logo-grid-container">
                {logoData &&
                  logoData.length > 0 &&
                  logoData.map((item) => (
                    <div
                      onClick={() => handleOpenModalPartNer(item.url)}
                      key={item.id}
                      to={`/doi-tac/${item.url}`}
                      rel="nofollow"
                    >
                      <div className="logo-grid-item">
                        <img
                          className="logo-img"
                          src={`${imageBaseUrl}/uploads/${item.logo}`}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div
              className="partner-thanks"
              dangerouslySetInnerHTML={{ __html: contentData.thanks }}
            ></div>
          </div>
        )}
      </Container>
      {modalShow && (
        <PartnerModal
          namePartner={namePartner}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
    </div>
  );
}

export default PartnerMain;
