import { Col, Row } from "react-bootstrap";
import { TbDatabaseOff } from "react-icons/tb";

import CardJob from "./cardJob";
import { memo } from "react";
import { Pagination } from "@mui/material";
import { imageBaseUrl } from "../../../../config";

function HotJobs({ listJobs, page, setPage, itemBanner }) {
  const handleChangePage = (e, p) => {
    setPage(p);
  };

  return (
    <Row
      style={{
        background: `url(${
          imageBaseUrl + "/uploads/" + itemBanner?.picture
        })  no-repeat center/cover`,
      }}
      className="container_hot_jobs d-flex flex-column "
    >
      <Col md="12" className="list_hot_jobs">
        <h3 className="common_title d-inline">VỊ TRÍ TUYỂN DỤNG NỔI BẬT</h3>
      </Col>

      <Col md="12" className="container_list_jobs">
        <div className="box_list_jobs p-3 ">
          {listJobs.data && listJobs.data.length > 0 ? (
            <>
              <Row className="mx-0">
                {listJobs.data &&
                  listJobs.data.length > 0 &&
                  listJobs.data?.map((job, index) => (
                    <CardJob key={index} job={job} />
                  ))}
                <Col className="col-12">
                  <Pagination
                    className="d-inline-block float-end"
                    page={page}
                    onChange={handleChangePage}
                    count={Math.ceil(listJobs.total / listJobs.per_page)}
                    variant="outlined"
                    shape="rounded"
                  />{" "}
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col className="  col-12 box_icon_nodata">
                  <TbDatabaseOff /> <span>Chưa có vị trí tuyển dụng nào!</span>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default memo(HotJobs);
