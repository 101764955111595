import { configureStore  } from '@reduxjs/toolkit';
import productReducer from '../modules/product/view/productSlice';
import cartReducer from '../modules/cart/view/cartSlice'
import searchReducer from '../client_component/header/view/searchSlice'

const store = configureStore({
    reducer: {
        product: productReducer,
        cart: cartReducer,
        historySearch: searchReducer,
    },
});

export default store;
