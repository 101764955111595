import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import { getBannerPopup } from "../../../services/advertiseService";
import { imageBaseUrl } from "../../../../config";
const SpecialPromotionBanner = ({ auth }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [dataBannerPopup, setDataBannerPopup] = useState(null);
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchBannerPopup = async () => {
    const data = await getBannerPopup();
    if (data && data.length > 0) {
      setDataBannerPopup(data[0]);
      sessionStorage.setItem("popup-banner", true);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("popup-banner") !== "true" && auth) {
      fetchBannerPopup();
    }
  }, []); // Empty dependency array ensures this runs only once

  return (
    dataBannerPopup &&
    dataBannerPopup?.picture && (
      <Modal
        title={null}
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        className="special-promotion-banner"
        width={{
          xs: "90%",
          sm: "80%",
          md: "70%",
          lg: "60%",
          xl: "50%",
          xxl: "40%",
        }}
        centered
      >
        <Link to={dataBannerPopup?.link}>
          <img
            loading="preload"
            target={dataBannerPopup?.target}
            alt={dataBannerPopup?.title}
            src={`${imageBaseUrl}/uploads/${dataBannerPopup?.picture}`}
            className="w-100 h-100"
          />
        </Link>
      </Modal>
    )
  );
};
export default SpecialPromotionBanner;
