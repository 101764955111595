import { TypeAnimation } from 'react-type-animation';

const TextAmination = () => {
  return (
    <div className='box_text_amination'>
         <TypeAnimation
    //   sequence={[
    //      'Chào mừng bạn đến với VI TÍNH NGUYÊN KIM!',
    //     1000,  
    //     'Tham gia CÔNG TY VI TÍNH NGUYÊN KIM ngay!',
    //     1000,
    //     "VI TÍNH NGUYÊN KIM đang tìm kiếm bạn!",
     
       
       
    //   ]}


    sequence={[
        'Chào mừng bạn đến với VI TÍNH NGUYÊN KIM!',
        1000, // wait 1s before replacing "Mice" with "Hamsters"
        'Tham gia CÔNG TY VI TÍNH NGUYÊN KIM ngay!',
        1000,
        "VI TÍNH NGUYÊN KIM đang tìm kiếm bạn!",
        1000 
      ]}

 
      wrapper="span"
      speed={50}
      style={{ fontSize: '2em', display: 'inline-block' }}
      repeat={Infinity}
    />
    </div>
  );
};

export default TextAmination