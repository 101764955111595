import React from "react";
import Header from "./user/client_component/header/view/header";
import Footer from "./user/client_component/footer/view/footer";
import Router from "./user/router";
import { useState, useEffect } from "react";
import "./App.css";
import { Link, useLocation } from "react-router-dom";
import ViewedProduct from "./user/modules/main/view/viewed_product";
import * as authService from "./user/services/authService";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserById } from "./user/modules/cart/view/cartSlice";
import Zalo from "./component/image/zalo.png";
import Phone from "./component/image/phone.png";
import { useNavigate } from "react-router-dom";
import { XCircle } from "react-bootstrap-icons";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ScrollToTop from "./user/scrollToTop";
import { io } from "socket.io-client";

import toast, { Toaster } from "react-hot-toast";
import CustomToast from "./user/client_component/alertCustom";

import { BiSolidErrorAlt } from "react-icons/bi";
import axios from "axios";
import { axiosClient } from "./config";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
};

function App() {
  const product = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();
  const [supportMember, setSupportMember] = useState([]);
  const [userName, setUserName] = useState("");
  const [member, setMember] = useState();
  const fetchSupportData = async () => {
    try {
      const data = await authService.getUser();
      if (data.data != "") {
        setSupportMember(data.data.adminSupport[0]);
        setUserName(data.data.member.username);
        setMember(data.data.member);
        sessionStorage.setItem("vtnkus", data.data.member.username);
      } else {
        toast.custom((t) => (
          <CustomToast
            icon={<BiSolidErrorAlt size={25} color="red" />}
            content={"Tài khoản không tồn tại !!!"}
          />
        ));
        localStorage.removeItem("user_token");
        sessionStorage.removeItem("vtnkus");
        navigate("/login");
      }
    } catch (error) {
      console.error("fetch data error", error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchSupportData();
    }
    dispatch(fetchUserById());
  }, [userName, user]);

  const timeoutRef = React.useRef();
  const clearTimer = React.useCallback(
    () => clearTimeout(timeoutRef.current),
    []
  );

  React.useEffect(() => {
    if (timeoutRef.current) clearTimer();

    timeoutRef.current = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);

    return () => {
      clearTimer();
    };
  }, [clearTimer]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [notificationState, setNotificationState] = useState({
    notificationList: JSON.parse(localStorage.getItem("notify")) || [],
  });

  const handleNotificationClick = (index) => {
    const updatedNotifications = [...notificationState.notificationList];
    updatedNotifications[index].viewed = true;
    localStorage.setItem("notify", JSON.stringify(updatedNotifications));

    setNotificationState({
      notificationList: updatedNotifications,
    });
  };

  const checkUserStatus = async () => {
    try {
      const response = await axiosClient("/member/check-member");

      if (response.data && response.data.status === true) {
        const data = response.data;
        return data.m_status;
      }
    } catch (error) {
      console.error("Fetch user status error: ", error);
      return 0;
    }
  };

  useEffect(() => {
    const verifyUser = async () => {
      const token = localStorage.getItem("user_token");
      if (!token) return;

      const status = await checkUserStatus();

      if (status === 0) {
        handleLogout();
      } else {
        localStorage.setItem("m_status", status);
      }
    };

    const handleLogout = () => {
      localStorage.removeItem("user_token");
      localStorage.removeItem("m_status");
      navigate("/login");
    };

    verifyUser();
  }, [navigate]);

  return (
    <div>
      <Toaster />
      <Header
        member={member}
        noficationData={notificationState}
        handleNotificationClick={handleNotificationClick}
      />
      <div className="body">
        <ScrollToTop />
        <Router />
      </div>
      {product.viewedProductItems.length !== 0 ? (
        <div className="product-viewed">
          <ViewedProduct />
        </div>
      ) : null}
      <div onClick={handleOpen} className="member-support-container">
        Hỗ trợ khách hàng
      </div>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box borderRadius={5} sx={style}>
          <XCircle onClick={handleClose} className="close-modal-btn" />
          <div className="member-support-name">
            Người hỗ trợ:{" "}
            {user && supportMember ? supportMember.display_name : "Mr. Tuấn An"}
          </div>
          <div className="member-support-item">
            <Link
              to={`https://zalo.me/${
                user && supportMember ? supportMember.phone : "0938808447"
              }`}
              target="_blank"
            >
              <img src={Zalo} className="zalo-img" />
            </Link>
            <Link
              to={`https://zalo.me/${
                user && supportMember ? supportMember.phone : "0938808447"
              }`}
              target="_blank"
              className="member-support-item-desc"
            >
              Liên hệ qua Zalo
            </Link>
          </div>
          <div className="member-support-item">
            <Link
              to={`tel:${
                user && supportMember ? supportMember.phone : "0938808447"
              }`}
            >
              <img src={Phone} className="zalo-img" />
            </Link>
            <Link
              to={`tel:${
                user && supportMember ? supportMember.phone : "0938808447"
              }`}
              className="member-support-item-desc"
            >
              Liên hệ qua số điện thoại
            </Link>
          </div>
        </Box>
      </Modal>

      <Footer
        userName={userName}
        supportMember={supportMember}
        noficationData={notificationState}
        handleNotificationClick={handleNotificationClick}
      />
    </div>
  );
}

export default App;
