import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";

const BreadcrumbNew = ({ arrayLink }) => {
  return (
    <Breadcrumb
      className="container_breadcrumb_new"
      items={[
        {
          title: (
            <div className="item_breadcrumb">
              <HomeOutlined /> <Link to={"/"}></Link>
            </div>
          ),
        },
        ...(arrayLink && arrayLink.length > 0
          ? arrayLink.map((item) => ({
              title: (
                <div className="item_breadcrumb">
                  <Link
                    state={{ type: item?.type || null }}
                    to={item.link || "#"}
                    className="item"
                  >
                    {item?.name}
                  </Link>
                </div>
              ),
            }))
          : []),
      ]}
    />
  );
};

export default BreadcrumbNew;
