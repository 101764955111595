import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { addToCart, fetchUserById } from "../../cart/view/cartSlice";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { axiosClient } from "../../../../config";
import "../public/reviewProduct.css";

import toast, { Toaster } from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";

import { BiSolidErrorAlt } from "react-icons/bi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ReviewProduct = ({ open, close, friendlyUrl, productData }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [technology, setTechnology] = useState([]);
  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  const handleAddCart = async (product) => {
    if (user) {
      try {
        const res = await axiosClient.post("/member/list-cart", {
          product_id: product.productId,
          quality: 1,
          price: product.price,
          title: product.productName,
          picture: product.picture,
          MaKhoSPApdung: product.macn,
          catName: product.catName,
          catNameParent: product.catNameParent,
          brandName: product.brandName,
          stock: product.status,
        });
        if (res.data.status == true) {
          dispatch(fetchUserById());
          // setOpenAlert(true);
          toast.custom(
            (t) => (
              <CustomToast
                icon={<IoIosCheckmarkCircle size={25} color="green" />}
                content={"Đã thêm sản phẩm vào giỏ hàng"}
              />
            ),
            {
              position: "top-right",
              duration: 1000,
            }
          );
        } else {
          toast.custom((t) => (
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={"Đã xảy ra lỗi. Xin vui lòng thử lại!"}
            />
          ));
        }
      } catch (error) {
        console.error("post data error", error);
      }
    } else {
      dispatch(addToCart(product));
      // setOpenAlert(true);
      toast.custom(
        (t) => (
          <CustomToast
            icon={<IoIosCheckmarkCircle size={25} color="green" />}
            content={"Đã thêm sản phẩm vào giỏ hàng"}
          />
        ),
        {
          position: "top-right",
          duration: 1000,
        }
      );
    }
  };

  const fetchSpecifications = async () => {
    try {
      const response = await axiosClient.get(
        `/member/get-product-technology/${friendlyUrl}`
      );
      const data = response?.data?.technology;

      if (data) {
        setTechnology(data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Fetch category menu error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchSpecifications();
  }, []);

  const [openAlert, setOpenAlert] = useState(false);
  return (
    <div className="model">
      <Dialog open={open} onClose={close}>
        <div className="detailed-specificate-model-container">
          <DialogTitle
            className="productComparisonTitle"
            id="alert-dialog-title"
          >
            Thông số chi tiết
          </DialogTitle>
          <DialogContent className="detailed-specificate-model-content-review">
            <TableContainer
              className="detailed-specificate-model-table h-100"
              component={Paper}
            >
              {loading ? (
                <div className="text-center m-5 ">
                  <CircularProgress color="success" />
                </div>
              ) : (
                <Table sx={{ width: "100%" }} aria-label="spanning table">
                  <TableBody>
                    {technology &&
                      technology.length > 0 &&
                      technology?.map((item, index) => {
                        // old technology 1
                        if (item.catOption && item.nameCatOption) {
                          return (
                            <TableRow key={index}>
                              <TableCell className="detailed-specificate-col-title-main">
                                {item.catOption}
                              </TableCell>
                              <TableCell
                                className="detailed-specificate-col-title"
                                align="left"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item.nameCatOption !== null
                                        ? item.nameCatOption
                                        : "",
                                  }}
                                ></div>
                              </TableCell>
                            </TableRow>
                          );
                        }
                        // old technology 2
                        else if (
                          item.title &&
                          item.keylistTechnology?.technology
                        ) {
                          return (
                            <TableRow key={index}>
                              <TableCell className="detailed-specificate-col-title-main">
                                {item.title}
                              </TableCell>
                              <TableCell
                                className="detailed-specificate-col-title"
                                align="left"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item.keylistTechnology.technology !== ""
                                        ? item.keylistTechnology.technology
                                        : "Không",
                                  }}
                                ></div>
                              </TableCell>
                            </TableRow>
                          );
                        }
                        // new technology
                        else if (item.description && item.properties.title) {
                          return (
                            <TableRow key={index}>
                              <TableCell className="detailed-specificate-col-title-main">
                                {item.properties.title}
                              </TableCell>
                              <TableCell
                                className="detailed-specificate-col-title"
                                align="left"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item.description !== ""
                                        ? item.description
                                        : "Không",
                                  }}
                                ></div>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    {!loading && technology.length == 0 && (
                      <TableRow className="no-tech-title">
                        Không có thông số kỹ thuật
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </DialogContent>
          <DialogContent className="detailed-specificate-model-content-moblie">
            <TableContainer
              className="detailed-specificate-model-table h-100"
              component={Paper}
            >
              {loading ? (
                <div className="text-center m-5 ">
                  <CircularProgress color="success" />
                </div>
              ) : (
                <Table sx={{ width: "100%" }} aria-label="spanning table">
                  <TableBody>
                    {technology &&
                      technology.length > 0 &&
                      technology?.map((item, index) => {
                        // old technology 1 in mobile
                        if (item.catOption && item.nameCatOption) {
                          return (
                            <TableRow key={index}>
                              <TableCell className="detailed-specificate-col-title-main">
                                {item.catOption}
                              </TableCell>
                              <TableCell
                                className="detailed-specificate-col-title"
                                align="left"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item.nameCatOption !== null
                                        ? item.nameCatOption
                                        : "",
                                  }}
                                ></div>
                              </TableCell>
                            </TableRow>
                          );
                        }
                        // old technology 2 in mobile
                        else if (
                          item.title &&
                          item.keylistTechnology?.technology
                        ) {
                          return (
                            <TableRow key={index}>
                              <TableCell className="detailed-specificate-col-title-main">
                                {item.title}
                              </TableCell>
                              <TableCell
                                className="detailed-specificate-col-title"
                                align="left"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item.keylistTechnology.technology !== ""
                                        ? item.keylistTechnology.technology
                                        : "Không",
                                  }}
                                ></div>
                              </TableCell>
                            </TableRow>
                          );
                        }
                        // new technology in mobile
                        else if (item.description && item.properties.title) {
                          return (
                            <TableRow key={index}>
                              <TableCell className="detailed-specificate-col-title-main">
                                {item.properties.title}
                              </TableCell>
                              <TableCell
                                className="detailed-specificate-col-title"
                                align="left"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item.description !== ""
                                        ? item.description
                                        : "Không",
                                  }}
                                ></div>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    {!loading && technology.length == 0 && (
                      <TableRow className="no-tech-title">
                        Không có thông số kỹ thuật
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </DialogContent>
          <div className="d-flex justify-content-around mt-2 mb-3">
            <div className="btn-see-detail">
              <Link
                className="btn-see-detail-title"
                to={`/${friendlyUrl}`}
                state={{ type: "product" }}
              >
                Xem chi tiết
              </Link>
            </div>
            {(productData.status === 1 || productData.status === 4) &&
            productData.price != 0 ? (
              <div
                rel="nofollow"
                onClick={() => handleAddCart(productData)}
                className="btn-add-to-cart"
              >
                <div className="btn-add-to-cart-title">Thêm Vào giỏ</div>
                <Snackbar
                  open={openAlert}
                  autoHideDuration={1000}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right", // Adjust this as needed
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right", // Adjust this as needed
                  }}
                  onClose={handleCloseAlert}
                >
                  <Alert
                    onClose={handleCloseAlert}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Thêm vào giỏ hàng thành công
                  </Alert>
                </Snackbar>
              </div>
            ) : null}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ReviewProduct;
