import { Input } from "antd";

const ComponentNumberPhoneForm = ({
  size,
  value = "",
  onChange,
  placeholder,
  readOnly,
}) => {
  const validatePhoneNumber = (number) => {
    // Định dạng số điện thoại chuẩn Việt Nam (bắt đầu bằng 0 và có 10 chữ số)
    const phoneRegex = /^0\d{9}$/;
    return phoneRegex.test(number);
  };

  const onNumberChange = (e) => {
    const newNumber = e.target.value;

    // Kiểm tra nếu chỉ chứa ký tự số và độ dài không quá 10 ký tự
    if (/^\d{0,10}$/.test(newNumber)) {
      onChange?.(newNumber);

      // Kiểm tra định dạng số điện thoại khi độ dài bằng 10
      if (newNumber.length === 10 && validatePhoneNumber(newNumber)) {
      }
    }
  };

  return (
    <>
      <Input
        readOnly={readOnly}
        size={size}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onNumberChange}
      />
    </>
  );
};

export default ComponentNumberPhoneForm;
