import moment from "moment";
import "../public/promotionCard.css";
import { Link } from "react-router-dom";
import { imageBaseUrl } from "../../../../config";
const PromotionCard = (props) => {
  const startDate = moment(props.dateStart, "DD/MM/YYYY");
  const endDate = moment(props.dateEnd, "DD/MM/YYYY");
  const dateNow = moment();
  return (
    <div className="promotion-ticket-container">
      <Link
        title={props.title}
        to={
          props.dateStart != "service"
            ? `/khuyen-mai/${props.link}`
            : `/dich-vu/${props.link}`
        }
      >
        <img
          className="promotion-ticket-container-img"
          src={`${imageBaseUrl}/uploads/${props.picture}`}
          alt={`${props.title}`}
          loading="lazy"
        />
      </Link>
      <div className="promotion-ticket-container-content">
        {props.dateStart != "service" ? (
          <div className="time-deadline">
            từ {props.dateStart} đến {props.dateEnd}
          </div>
        ) : null}
        <Link
          title={props.title}
          to={
            props.dateStart != "service"
              ? `/khuyen-mai/${props.link}`
              : `/dich-vu/${props.link}`
          }
          className="promotion-ticket-container-title"
        >
          {props.title}
        </Link>
      </div>
      {props.dateStart != "service" && props.dateStart != "service" ? (
        <div className="promotion-ticket-ribbon">
          {dateNow.isBetween(startDate, endDate, null, "[]")
            ? "Mới"
            : "Hết hạn"}
        </div>
      ) : null}
    </div>
  );
};

export default PromotionCard;
