import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import "../public/comboProduct.css";
import { fetchUserById } from "../../cart/view/cartSlice";
import { addToCart } from "../../cart/view/cartSlice";
import { useDispatch } from "react-redux";
import { axiosClient, imageBaseUrl } from "../../../../config";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "react-bootstrap";

import toast, { Toaster } from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";

import { BiSolidErrorAlt } from "react-icons/bi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const ComboProduct = (props) => {
  const dispatch = useDispatch();
  const [openProdcess, setOpenProdcess] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [groupData, setGroupData] = useState(props.groupProduct);

  const { productDetail, groupProduct, stock } = props;

  const useAuths = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const checkLogin = useAuths();
  const currencyFormat = (num) => {
    return (
      Number(num)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " đ"
    );
  };

  const toggleProductSelection = (item, isChecked) => {
    const updatedCartData = groupData.map((cartItem) =>
      cartItem.productName === item.productName
        ? { ...cartItem, isChecked }
        : cartItem
    );

    setGroupData(updatedCartData);
    const updatedSelectedProducts = isChecked
      ? [...selectedProducts, item]
      : selectedProducts.filter(
          (product) => product.productName !== item.productName
        );
    setSelectedProducts(updatedSelectedProducts);
  };
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    let price1 = 0;

    if (checkLogin) {
      totalPrice += props.productDetail.listPrice
        .filter((item) => item.main === 1)
        .map((item) => (price1 = item.price_old))
        .reduce((acc, price) => acc + price, 0);
    } else {
      totalPrice += props.productDetail.listPrice
        .filter((item) => item.main === 1)
        .map((item) => item.price)
        .reduce((acc, price) => acc + price, 0);
    }

    selectedProducts.forEach((productName) => {
      totalPrice += checkLogin
        ? productName.price_list?.length > 0
          ? productName.price_list[0].price_old
          : productName.price_old
        : productName.price_list?.length > 0
        ? productName.price_list[0].price
        : productName.price;
    });

    // checkLogin ?  productName.data_group.price_old : productName.data_group.price;

    return totalPrice;
  };

  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();

  const addToCartComboProduct = async () => {
    if (user) {
      setOpenProdcess(true);
      const productsToAddToCart = [props.productDetail, ...selectedProducts];
      const postRequests = productsToAddToCart.map((product) => {
        const catName = product.catName
          ? product.catName
          : product.catAll
          ? product.catAll[1].cat_name
          : "";
        const catNameParent = product.catNameParent
          ? product.catNameParent
          : product.catAll
          ? product.catAll[0].friendly_url
          : "";
        return axiosClient.post("/member/list-cart", {
          product_id: product.productId ? product.productId : product.encryId,
          quality: 1,
          price:
            product.listPrice?.length > 0
              ? product.listPrice
                  .filter((item) => item.main == 1)
                  .map((item) => item.price_old)
                  .join("")
              : product.price_list?.length > 0
              ? product.price_list?.[0].price_old
              : product.price_old,
          title: product.productName
            ? product.productName
            : product.product_desc.title,
          picture:
            product.price_list?.length > 0 &&
            product.price_list[0].picture !== null
              ? product.price_list[0].picture
              : product.pictureForDetailProduct
              ? product.pictureForDetailProduct
              : product.picture,
          MaKhoSPApdung: product.maso,
          catName: catName,
          catNameParent: "",
          brandName: product.brandName,
          stock: product.stock,
        });
      });

      let successCount = 0;

      const responses = await Promise.all(postRequests);

      responses.forEach((response) => {
        if (response.data.status === true) {
          successCount++;
        }
      });

      if (successCount === productsToAddToCart.length) {
        dispatch(fetchUserById());
        setOpenProdcess(false);
      } else {
        toast.custom((t) => (
          <CustomToast
            icon={<BiSolidErrorAlt size={25} color="red" />}
            content={"Có lỗi xảy ra khi thêm vào giỏ hàng!"}
          />
        ));
      }
    } else {
      const productsToAddToCart = [props.productDetail, ...selectedProducts];

      productsToAddToCart.forEach((product) => {
        const catName = product.catName
          ? product.catName
          : product.catAll
          ? product.catAll[1].cat_name
          : "";
        const catNameParent = product.catNameParent
          ? product.catNameParent
          : product.catAll
          ? product.catAll[0].friendly_url
          : "";
        dispatch(
          addToCart({
            productId: product.productId ? product.productId : product.encryId,
            quality: 1,
            price:
              product.listPrice?.length > 0
                ? product.listPrice[0].price
                : product.price_list?.length > 0
                ? product.price_list[0].price
                : product.price,
            productName: product.productName
              ? product.productName
              : product.product_desc.title,
            picture:
              product.listPrice?.length > 0 &&
              product.listPrice[0].picture !== null
                ? product.listPrice[0].picture
                : product.pictureForDetailProduct
                ? product.pictureForDetailProduct
                : product.price_list?.length > 0
                ? product.price_list?.[0].picture
                : product.picture,
            MaKhoSPApdung: product.maso,
            catName: catName,
            catNameParent: catNameParent,
            brandName: product.brandName,
            stock: product.stock,
          })
        );
      });
    }
  };

  useEffect(() => {
    const updatedCartData = groupData.map((item) => ({
      ...item,
      isChecked: false,
    }));
    setGroupData(updatedCartData);
    setSelectedProducts([]);
  }, []);

  return (
    <div className="combo-product-container">
      <h3 className="combo-product-title">Mua kèm nhận ưu đãi</h3>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className="combo-product-box">
            <Link
              title={props.productDetail.productName}
              to={`/${props.productDetail.friendlyUrl}`}
            >
              <div className="combo-product-img-box">
                <img
                  className="combo-product-img"
                  src={`${imageBaseUrl}/uploads/${
                    productDetail.listPrice &&
                    productDetail.listPrice.length > 0
                      ? productDetail.listPrice
                          .filter(
                            (item) => item.main === 1 && item.picture !== null
                          )
                          .map((item) => item.picture)
                          .join("")
                      : productDetail.pictureForDetailProduct
                  }`}
                  loading="lazy"
                  alt={`${props.productDetail.productName}`}
                />
              </div>
              <div>
                <div className="combo-product-box-title">
                  {props.productDetail.productName}
                </div>
                {props.stock === 0 ? (
                  <div rel="nofollow" className="combo-product-box-price">
                    Liên hệ
                  </div>
                ) : props.stock === 1 &&
                  (props.productDetail.listPrice
                    .filter((item) => item.main == 1)
                    .map((item) => item.price_old) === 0 ||
                    props.productDetail.listPrice
                      .filter((item) => item.main == 1)
                      .map((item) => item.price) === 0) ? (
                  <div rel="nofollow" className="combo-product-box-price">
                    Liên hệ
                  </div>
                ) : props.stock === 1 &&
                  (props.productDetail.listPrice
                    .filter((item) => item.main == 1)
                    .map((item) => item.price_old) !== 0 ||
                    props.productDetail.listPrice
                      .filter((item) => item.main == 1)
                      .map((item) => item.price) !== 0) ? (
                  <div rel="nofollow" className="combo-product-box-price">
                    {checkLogin
                      ? currencyFormat(
                          props.productDetail.listPrice
                            .filter((item) => item.main == 1)
                            .map((item) => item.price_old)
                        )
                      : currencyFormat(
                          props.productDetail.listPrice
                            .filter((item) => item.main == 1)
                            .map((item) => item.price)
                        )}
                  </div>
                ) : props.stock === 2 ? (
                  <div rel="nofollow" className="combo-product-box-price">
                    Ngừng kinh doanh
                  </div>
                ) : null}
              </div>
            </Link>
          </div>
          <div
            className={
              props.groupProduct.length > 3
                ? `product-combo-list`
                : `d-flex align-items-center`
            }
          >
            {props.groupProduct.map((item) =>
              item.data_groups.map((itemx) => {
                if (itemx.stock === 1) {
                  return (
                    <div className="d-flex align-items-center" key={item.id}>
                      <div className="mx-1 fs-1">+</div>
                      <input
                        type="checkbox"
                        checked={itemx.isChecked}
                        onChange={(e) =>
                          toggleProductSelection(itemx, e.target.checked)
                        }
                      />
                      <div className="combo-product-box">
                        <Link
                          rel="nofollow"
                          title={itemx.product_desc.title}
                          to={`/${itemx.product_desc.friendly_url}`}
                        >
                          <div className="combo-product-img-box">
                            <img
                              className="combo-product-img"
                              src={`${imageBaseUrl}/uploads/${
                                itemx.price_list?.length > 0 &&
                                itemx.price_list[0]?.picture !== null
                                  ? itemx.price_list[0].picture
                                  : itemx.picture
                              }`}
                              alt={itemx.product_desc.productName}
                              loading="lazy"
                            />
                          </div>
                          <div>
                            <div className="combo-product-box-title">
                              {itemx.product_desc.title}
                            </div>
                            <div className="combo-product-box-price">
                              {currencyFormat(
                                checkLogin
                                  ? itemx.price_list?.length > 0
                                    ? itemx.price_list[0].price_old
                                    : itemx.price_old
                                  : itemx.price_list?.length > 0
                                  ? itemx.price_list[0].price
                                  : itemx.price
                              )}
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                }
                return null; // return null if itemx.stock !== 1
              })
            )}
          </div>
        </div>
        {props.groupProduct.length < 3 &&
          props.stock === 1 &&
          (props.productDetail.listPrice
            .filter((item) => item.main == 1)
            .map((item) => item.price_old) !== 0 ||
            props.productDetail.listPrice
              .filter((item) => item.main == 1)
              .map((item) => item.price) !== 0) && (
            <div className="combo-product-add-cart-desktop">
              <div className="combo-price">
                {currencyFormat(calculateTotalPrice())}
              </div>
              <Button
                rel="nofollow"
                className="combo-add-cart"
                onClick={addToCartComboProduct}
              >
                Thêm vào giỏ
              </Button>
            </div>
          )}
      </div>
      {props.groupProduct.length > 3 &&
        props.stock === 1 &&
        (props.productDetail.listPrice
          .filter((item) => item.main == 1)
          .map((item) => item.price_old) !== 0 ||
          props.productDetail.listPrice
            .filter((item) => item.main == 1)
            .map((item) => item.price) !== 0) && (
          <div className="combo-product-add-cart-desktop mb-3">
            <div className="combo-price d-flex justify-content-end">
              {currencyFormat(calculateTotalPrice())}
            </div>
            <div
              rel="nofollow"
              className="combo-add-cart"
              onClick={addToCartComboProduct}
            >
              Thêm vào giỏ
            </div>
          </div>
        )}
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert variant="outlined" severity="warning">
          Không áp dụng cùng 1 số khuyến mãi khác
        </Alert>
      </Stack>
      <div className="combo-product-add-cart-moblie">
        <div className="combo-price">
          {currencyFormat(calculateTotalPrice())}
        </div>
        <div className="combo-add-cart" onClick={addToCartComboProduct}>
          Thêm vào giỏ
        </div>
      </div>
      {openProdcess && (
        <Modal
          open={openProdcess}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="paypalProcessing">
              <div className="animation">
                <div className="container">
                  <div className="balls">
                    <div className="ball ball-one" />
                    <div className="ball ball-two" />
                    <div className="ball ball-three" />
                    <div className="ball ball-four" />
                    <div className="shadow shadow-one" />
                    <div className="shadow shadow-two" />
                    <div className="shadow shadow-three" />
                    <div className="shadow shadow-four" />
                  </div>
                </div>
              </div>
              <div className="text">
                <h1>Đang xử lý</h1>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default ComboProduct;
