import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import ReactPaginate from "react-paginate";
import "../public/promotion.css";
import PromotionCard from "./promotionCard";
import { axiosClient } from "../../../../config";
import MetaDecorator from "../../../../util/metaDecorator";
import BreadcrumbNew from "../../../client_component/breadcrumbNew";
import { useLocation, useNavigate } from "react-router-dom";

const Promotion = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentLocation = `${location.pathname}`;
  const [isLoaded, setIsLoaded] = useState(false);
  const [promotionList, setPromotionList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const fetchData = async () => {
    try {
      setIsLoaded(true);
      const res = await axiosClient.get(`/member/promotion?page=${pageNumber}`);
      const data = res.data.list;
      if (res.data.status == "success" && data) {
        setPromotionList(data);
      }
    } catch (error) {
      console.error("Fetch data promotion is error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  const initialPage =
    parseInt(new URLSearchParams(location.search).get("page")) || 1;

  useEffect(() => {
    setPageNumber(1);
  }, [location.pathname]);

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;

    if (newPage < 2) {
      setPageNumber(newPage);
      window.scrollTo(0, 0);
      return;
    }

    setPageNumber(newPage);
    const searchWithoutPage = location.search.replace(/(\?|&)page=\d+/g, "");
    const pagePart = `page=${newPage}`;
    const updatedURL = searchWithoutPage
      ? `${searchWithoutPage}&${pagePart}`
      : `?${pagePart}`;
    window.scrollTo(0, 0);
    navigate(`${currentLocation}${updatedURL}`);
  };

  const breadcrumbLink = [
    { id: 1, name: "Tổng hợp khuyến mãi", link: `/khuyen-mai` },
  ];

  return (
    <div className="">
      <MetaDecorator
        title="Tổng hợp khuyến mãi"
        description="tổng hợp những khuyến mãi tại công ty Vi tính Nguyên Kim"
      />

      <Container className="promotion-container">
        {!isLoaded ? (
          <div
            style={{ height: "65vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <CircularProgress />
            </div>
          </div>
        ) : (
          <>
            <div className="ms-4">
              <BreadcrumbNew arrayLink={breadcrumbLink} />
            </div>
            <h2 className="promotion-container-title">Tổng hợp khuyến mãi</h2>
            <div className="promotion-container-content">
              {promotionList?.data &&
                promotionList?.data.length > 0 &&
                promotionList?.data
                  ?.filter((item) => item.picture !== "" && item.display === 1)
                  .map((item) => (
                    <PromotionCard
                      key={item.promotion_id}
                      picture={item.picture}
                      title={item.promotion_desc.title}
                      link={item.promotion_desc.friendly_url}
                      dateStart={item.date_start_promotion}
                      dateEnd={item.date_end_promotion}
                    />
                  ))}
            </div>

            {promotionList?.data && promotionList?.data.length > 0 && (
              <div className="d-flex justify-content-end py-1">
                <ReactPaginate
                  pageCount={Math.ceil(
                    promotionList?.total / promotionList?.per_page
                  )}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  initialPage={initialPage - 1}
                />
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default Promotion;
