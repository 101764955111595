import { useState } from "react";
import BoxNews from "./boxNews";
import ReactPaginate from "react-paginate";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import { axiosClient } from "../../../../config";
import { useParams, useLocation } from "react-router-dom";
const NewsContent = () => {
  const { NameNews } = useParams();
  const location = useLocation();
  const [techNewsList, setTechNewsList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage < 2) {
      setPageNumber(newPage);
      window.scrollTo(0, 0);
      return;
    }
    window.scrollTo(0, 0);
    setPageNumber(newPage);
  };

  const fetchData = async () => {
    setIsLoaded(false);
    try {
      const res = await axiosClient.get(
        "/member/news/" + NameNews + "?page=" + pageNumber
      );
      const data = res.data.data.data;
      const last_page = res.data.data.last_page;
      setTotalPage(last_page);
      setTechNewsList(data);
      setIsLoaded(true);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };
  useEffect(() => {
    setPageNumber(1);
  }, [location]);
  useEffect(() => {
    fetchData();
  }, [NameNews, pageNumber]);

  return (
    <div>
      {isLoaded ? (
        <>
          {techNewsList.length > 0 ? (
            techNewsList.map((techNewsItem) => (
              <div key={techNewsItem.news_id}>
                <BoxNews
                  image={techNewsItem.picture}
                  title={techNewsItem.title}
                  desc={techNewsItem.short}
                  link={techNewsItem.friendly_url}
                  datePost={techNewsItem.date_post}
                />
              </div>
            ))
          ) : (
            <div className="no-news-title">Không có bài viết nào</div>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <CircularProgress />
          </div>
        </div>
      )}
      {techNewsList.length > 0 && (
        <div className="d-flex justify-content-end">
          <ReactPaginate
            pageCount={totalPage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLabel={"<"}
            nextLabel={">"}
          />
        </div>
      )}
    </div>
  );
};

export default NewsContent;
