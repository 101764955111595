import React, { useState, useEffect } from "react";
// import Sidebar from "./usersidebar";
import { Col, Container, Row } from "react-bootstrap";

import * as authService from "../../../../services/authService";
import "../../public/account.css";
import { useNavigate } from "react-router-dom";

import toast, { Toaster } from "react-hot-toast";
import CustomToast from "../../../../client_component/alertCustom";

import { BiSolidErrorAlt } from "react-icons/bi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Form, Input, Button, Space, Spin } from "antd";
import ComponentNumberPhoneForm from "../../../../client_component/componentNumberPhoneForm";
import SelectAddress from "../../../../client_component/selectAddress";
import MetaDecorator from "../../../../../util/metaDecorator";
import MenuAccount from "../../../../client_component/menuAccount";
import BreadcrumbNew from "../../../../client_component/breadcrumbNew";
const Account = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    authService
      .getUser()
      .then((res) => {
        if (res.data && res.data !== "") {
          const member = res?.data?.member || {};

          form.setFieldsValue({
            fullName: member.full_name ?? null,
            email: member.email ?? null,
            numberPhone: member.phone ?? null,
            nameCompany: member.Tencongty ?? null,
            tax: member.Masothue ?? null,
            emailCompany: member.emailcty ?? null,
            numberPhoneCompany: member.Sdtcongty ?? null,
            cityAddress: member.city_province ?? null,
            districtAddress: member.district ?? null,
            wardAddress: member.ward ?? null,
            streetAddress: member.address ?? null,
            addressCompany: member.Diachicongty ?? null,
            nameReceiver: member.nameReceiver ?? null,
          });
        } else {
          toast.custom(
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={"Tài khoản không tồn tại!"}
            />,
            { position: "top-right" }
          );
          localStorage.removeItem("user_token");
          navigate("/login");
        }
      })
      .catch((error) => console.error("Fetch data error", error))
      .finally(() => setLoading(false));
  }, []);

  const handleSubmit = async (values) => {
    try {
      const res = await authService.postUser(values);
      if (res.data.status === true) {
        toast.custom(
          (t) => (
            <CustomToast
              icon={<IoIosCheckmarkCircle size={25} color="green" />}
              content={"Lưu thay đổi thành công"}
            />
          ),
          {
            position: "top-right",
          }
        );
      } else {
        toast.custom(
          (t) => (
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={"Đã xảy ra lỗi. Xin vui lòng thử lại!"}
            />
          ),
          {
            position: "top-right",
          }
        );
      }
    } catch (error) {
      console.error("post data error");
    }
  };

  if (loading) {
    return <Spin spinning={loading} fullscreen />;
  }

  const breadcrumbLink = [
    { id: 1, name: "Thông tin tài khoản", link: `/account` },
  ];

  return (
    <>
      <Toaster />
      <MetaDecorator
        title="Thông tin tài khoản"
        description="Thông tin tài khoản"
      />
      <Container>
        <div className="container_account">
          <div className="mt-1">
            <BreadcrumbNew arrayLink={breadcrumbLink} />
          </div>
          <Row>
            <MenuAccount />
            <Col md={8} lg={9} className="mt-2 mt-md-0">
              {!loading && (
                <div className="box_form_info_user">
                  <div className="box_avatar_account text-center">
                    <img src={"/image/man.png"} alt="avatar" />
                  </div>

                  <div className=" box_form_account d-flex justify-content-center">
                    <Form
                      onFinish={handleSubmit}
                      form={form}
                      autoComplete="off"
                      className="w-100"
                    >
                      <div className="box_form_item_address">
                        <div className="title_address">
                          <span>Thông tin tài khoản</span>
                        </div>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Hãy nhập họ và tên!",
                            },
                          ]}
                          className="mb-2"
                          name={"fullName"}
                        >
                          <Input placeholder="Họ và tên" />
                        </Form.Item>
                      </div>

                      <div className="box_form_item_address">
                        <div className="title_address">
                          <span>Thông tin xuất hóa đơn</span>
                        </div>

                        <Space className="w-100 space_info_client_form">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Hãy nhập tên công ty!",
                              },
                            ]}
                            className="mb-2"
                            name={"nameCompany"}
                          >
                            <Input readOnly placeholder="Tên công ty" />
                          </Form.Item>

                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Hãy nhập mã số thuế",
                              },
                            ]}
                            className="mb-2"
                            name={"tax"}
                          >
                            <Input readOnly placeholder="Mã số thuế" />
                          </Form.Item>
                        </Space>

                        <Space className="w-100 space_info_client_form  ">
                          <Form.Item
                            className="mb-2"
                            name={"emailCompany"}
                            rules={[
                              {
                                type: "email",
                                message: "E-mail không đúng định dạng!",
                              },
                              {
                                required: true,
                                message: "Hãy nhập E-mail công ty!",
                              },
                            ]}
                          >
                            <Input placeholder="Email công ty" />
                          </Form.Item>
                          {/* <Form.Item
                            className="mb-2"
                            rules={[
                              {
                                required: true,
                                message: "Hãy nhập số điện thoại công ty!",
                              },
                              {
                                pattern: /^0\d{9}$/,
                                message: "Số điện thoại không đúng định dạng!",
                              },
                            ]}
                            name={"numberPhoneCompany"}
                          >
                            <ComponentNumberPhoneForm
                              readOnly={true}
                              placeholder={"Số điện thoại công ty"}
                            />
                          </Form.Item> */}

                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Hãy nhập địa chỉ công ty",
                              },
                            ]}
                            className="mb-2"
                            name={"addressCompany"}
                          >
                            <Input readOnly placeholder="Địa chỉ công ty" />
                          </Form.Item>
                        </Space>
                      </div>

                      <div className="box_form_item_address">
                        <div className="title_address">
                          <span>Địa chỉ nhận hàng</span>
                        </div>
                        <SelectAddress
                          nameCity={"cityAddress"}
                          nameDistrict={"districtAddress"}
                          nameWard={"wardAddress"}
                          nameStreet={"streetAddress"}
                          form={form}
                        />
                        <Space className="w-100 space_info_client_form">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Hãy nhập họ và tên người nhận!",
                              },
                            ]}
                            className="mb-2"
                            name={"nameReceiver"}
                          >
                            <Input placeholder="Họ và tên người nhận" />
                          </Form.Item>
                          <Form.Item
                            className="mb-3"
                            rules={[
                              {
                                required: true,
                                message: "Hãy nhập số điện thoại!",
                              },
                              {
                                pattern: /^0\d{9}$/,
                                message: "Số điện thoại không đúng định dạng!",
                              },
                            ]}
                            name={"numberPhone"}
                          >
                            <ComponentNumberPhoneForm
                              placeholder={"Số điện thoại người nhận"}
                            />
                          </Form.Item>
                        </Space>
                      </div>

                      <Form.Item className="text-center mt-2" label={null}>
                        <Button danger htmlType="submit">
                          Cập nhật
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
export default Account;
