import React, { useState } from "react";
import { CaretRightFill } from "react-bootstrap-icons";
import "../public/productDescription.css";

const ProductDescription = ({ desc }) => {
  const [open, setOpen] = useState(false);
  const handleOpenFull = () => {
    setOpen(!open);
  };

  return (
    <div className={` product-desc-container`}>
      <h2 className={`product-desc-title `}>Mô tả sản phẩm</h2>
      <div
        className={`${
          open === true ? "h-auto" : "height-450"
        } product-desc-content`}
      >
        {desc ? (
          <div dangerouslySetInnerHTML={{ __html: desc }}></div>
        ) : (
          <div className="updated">Đang cập nhật</div>
        )}
      </div>
      <div
        rel="nofollow"
        onClick={handleOpenFull}
        className={`product-desc-see-more`}
      >
        <div className="d-flex justify-content-center">
          <p className="see-more-text">
            {open === true ? "Ẩn bớt" : "Xem thêm"}
            <CaretRightFill className="ms-2" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductDescription;
