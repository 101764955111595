import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import * as AdvertiseService from "../../../../services/advertiseService";
import { axiosClient, imageBaseUrl } from "../../../../../config";
import "../../public/menu/mini_banner.css";
import LazyImage from "../../../../../util/lazyImage";

const Minibanner = () => {
  const [miniBannerData, setMiniBannerData] = useState([]);

  useEffect(() => {
    const fetchMiniBannerData = async () => {
      try {
        const res = await axiosClient.get("/member/mini-banner-advertise");
        if (res.data.status === true) {
          setMiniBannerData(res.data);
        }
      } catch (error) {
        console.error("Lỗi không lấy được thông tin miniBanner.", error);
      }
    };
    fetchMiniBannerData();
  }, []);

  return (
    <div className="mini-banner">
      <Row>
        {miniBannerData &&
          miniBannerData.warranty &&
          miniBannerData.warranty.length > 0 && (
            <Col>
              <div className="card-img-banner">
                <div className="row">
                  {miniBannerData.warranty.map((item, index) => (
                    <div className="col-12" key={index}>
                      <Link title={item.title} to={item.link}>
                        <img
                          className="card-img-top"
                          src={`${imageBaseUrl}/uploads/${item.picture}`}
                          alt={`${item.title}`}
                          loading="lazy"
                        />{" "}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          )}

        {Array.isArray(miniBannerData.data) &&
          miniBannerData.data.length > 0 &&
          miniBannerData.data.map((item) => (
            <Col key={item.id}>
              <div className="card-img-banner">
                <Link title={item.title} to={item.link}>
                  <LazyImage
                    classCss={"card-img-top"}
                    alt={item.title}
                    src={item?.picture}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                  />
                </Link>
              </div>
            </Col>
          ))}
      </Row>
    </div>
  );
};
export default Minibanner;
