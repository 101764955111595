import { Form, Input, Select, Space } from "antd";
import { useState } from "react";
import { province as Address } from "../modules/member/public/province";

const SelectAddress = ({
  form,
  nameCity,
  nameDistrict,
  nameWard,
  nameStreet,
}) => {
  const [address, setAddress] = useState(() => ({
    cityAddress: Address.map((city) => ({
      value: city.name,
      label: city.name,
    })),
    districtAddress: [],
    wardAddress: [],
  }));

  const handleChangeAddress = (value, type) => {
    setAddress((prev) => {
      if (type === "SelectCityAddress") {
        const foundCity = Address.find((city) => city.name === value);
        if (!foundCity) return prev;

        form?.resetFields([nameWard, nameDistrict]);

        return {
          ...prev,
          citySelect: value,
          districtAddress: foundCity.districts.map((district) => ({
            value: district.name,
            label: district.name,
          })),
          wardAddress: [],
        };
      }

      if (type === "SelectDistrictAddress") {
        const foundDistrict = Address.find(
          (city) => city.name === address.citySelect
        )?.districts.find((district) => district.name === value);
        if (!foundDistrict) return prev;

        form?.resetFields([nameWard]);

        return {
          ...prev,
          districtSelect: value,
          wardAddress: foundDistrict.wards.map((ward) => ({
            value: ward.name,
            label: ward.name,
          })),
        };
      }

      return prev;
    });
  };

  return (
    <>
      <Space className="w-100 space_select_address">
        <Form.Item
          className="mb-2 city_Address"
          name={nameCity}
          rules={[
            {
              required: true,
              message: "Hãy chọn thành phố!",
            },
          ]}
        >
          <Select
            onChange={(e) => handleChangeAddress(e, "SelectCityAddress")}
            placeholder="Thành Phố"
            options={address.cityAddress}
          />
        </Form.Item>
        <Form.Item
          name={nameDistrict}
          className="mb-2 district_Address"
          rules={[
            {
              required: true,
              message: "Hãy chọn quận/huyện!",
            },
          ]}
        >
          <Select
            onChange={(e) => handleChangeAddress(e, "SelectDistrictAddress")}
            placeholder="Quận/huyện"
            options={address.districtAddress}
          />
        </Form.Item>
      </Space>
      <Space className="w-100 space_select_address">
        <Form.Item
          className="mb-2 ward_Address"
          name={nameWard}
          rules={[
            {
              required: true,
              message: "Hãy chọn phường/xã!",
            },
          ]}
        >
          <Select
            onChange={(e) => handleChangeAddress(e, "SelectWardAddress")}
            placeholder="Phường/xã"
            options={address.wardAddress}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Hãy chọn đường/số nhà!",
            },
          ]}
          className="mb-2 street_Address"
          name={nameStreet}
        >
          <Input className="bg-white" placeholder="Đường/số nhà" />
        </Form.Item>
      </Space>
    </>
  );
};

export default SelectAddress;
