import React, { memo, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CaretRightFill } from "react-bootstrap-icons";
import DetailedSpecificateModel from "./detailedSpecificateModel";
import "../public/detailedSpecificate.css";

const DetailedSpecificate = ({ list }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = (event) => {
    event.preventDefault();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return list ? (
    <div className="detailed-specificate-container">
      <h3 className="detailed-specificate-title">Thông số kỹ thuật</h3>
      <TableContainer
        className="detailed-specificate-table detailed-specificate-detail"
        component={Paper}
      >
        <Table sx={{ width: "100%" }} aria-label="spanning table">
          <TableBody>
            {list.length > 0 ? (
              list?.map((item, index) => {
                return (
                  index < 6 && (
                    <TableRow key={index}>
                      <TableCell className="detailed-specificate-col-title-main">
                        {item.catOption}
                      </TableCell>
                      <TableCell
                        className="detailed-specificate-col-title"
                        align="left"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              item.nameCatOption !== null
                                ? item.nameCatOption
                                : "",
                          }}
                        ></div>
                      </TableCell>
                    </TableRow>
                  )
                );
              })
            ) : (
              <div className="updated">Đang cập nhật</div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {list?.length > 6 ? (
        <div
          onClick={handleClickOpen}
          rel="nofollow"
          className="detailed-specificate-see-more"
        >
          <div className="d-flex justify-content-center">
            <p className="detailed-specificate-see-more-text">
              Xem thêm
              <CaretRightFill className="ms-2" />
            </p>
          </div>
        </div>
      ) : null}

      <DetailedSpecificateModel
        open={open}
        close={handleClose}
        subList={list}
      />
    </div>
  ) : null;
};

export default memo(DetailedSpecificate);
