import React, { useEffect, useState } from "react";
import {
  Camera,
  PersonCircle,
  HandThumbsUp,
  HandThumbsDown,
} from "react-bootstrap-icons";
import "../public/comment.css";
import { Col, Row } from "react-bootstrap";
import * as authService from "../../../services/authService";
import axios from "axios";
import { imageBaseUrl } from "../../../../config";

import toast, { Toaster } from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";

import { BiSolidErrorAlt } from "react-icons/bi";
import { IoIosCheckmarkCircle } from "react-icons/io";

const Comment = ({ comments, productId, macn, moduleComment }) => {
  const [file, setFile] = useState([]);
  const [filePost, setFilePost] = useState([]);
  const [content, setContent] = useState("");
  const [commentUser, setCommentUser] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [validateComment, setValidateComment] = useState({
    phone: "",
    commentUser: "",
    email: "",
  });
  function handleChange(e) {
    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];
    //save
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);

    fileReader.onload = (event) => {
      setFilePost([event.target.result]);
    };

    targetFilesObject.map((item) => {
      return selectedFIles.push(URL.createObjectURL(item));
    });
    setFile(selectedFIles);
  }
  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();
  const [memId, setMember] = useState("");

  useEffect(() => {
    if (user) {
      authService.getUser().then((data) => {
        const memberId = 99999 * data.data.member.mem_id;
        setMember(memberId);
      });
    }
  }, []);

  const validatePhoneNumber = (phone) => {
    var validatePhone = /(03|05|07|08|09|01[2|6|8|9]|028)+([0-9]{7,8})\b/;
    return validatePhone.test(phone);
  };

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  function validateName(name) {
    var pattern = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\/-]/;
    var number = /[0-9]/;
    if (number.test(name) || pattern.test(name)) {
      return true;
    } else {
      return false;
    }
  }
  const handleComment = async () => {
    if (!user) {
      const errorsComment = {};
      if (phone == "") {
        errorsComment.phone = "Số điện thoại không được để trống";
      } else if (!validatePhoneNumber(phone)) {
        errorsComment.phone = "Vui lòng nhập đúng số điện thoại";
      }
      if (commentUser.trim() == "") {
        errorsComment.commentUser = "Tên không được để trống";
      } else if (validateName(commentUser)) {
        errorsComment.commentUser = "Tên không được có ký tự đặc biệt";
      }
      if (email == "") {
        errorsComment.email = "Email không được để trống";
      } else if (!validateEmail(email)) {
        errorsComment.email = "Vui lòng nhập đúng email";
      }
      setValidateComment(errorsComment);
      if (Object.keys(errorsComment).length === 0) {
        try {
          const res = await axios.post(`${imageBaseUrl}/api/comment`, {
            post_id: productId,
            macn: macn,
            module: moduleComment,
            avatar: filePost,
            name: commentUser,
            email: email,
            phone: phone,
            content: content,
            mpydvtnk: memId,
          });
          if (res.data.status == true) {
            toast.custom(
              (t) => (
                <CustomToast
                  icon={<IoIosCheckmarkCircle size={25} color="green" />}
                  content={"Cám ơn bạn đã để lại thông tin bình luận"}
                />
              ),
              {
                position: "top-right",
              }
            );
            setPhone("");
            setCommentUser("");
            setEmail("");
            setFile([]);
            setContent("");
          } else {
            toast.custom(
              (t) => (
                <CustomToast
                  icon={<BiSolidErrorAlt size={25} color="red" />}
                  content={"Đã xảy ra lỗi. Xin vui lòng thử lại!"}
                />
              ),
              {
                position: "top-right",
              }
            );
          }
        } catch (error) {
          console.error("post data error");
        }
      }
    } else {
      try {
        const res = await axios.post(`${imageBaseUrl}/api/comment`, {
          post_id: productId,
          macn: macn,
          module: moduleComment,
          avatar: filePost,
          name: commentUser,
          // email: email,
          // phone: phone,
          content: content,
          mpydvtnk: memId,
        });
        if (res.data.status == true) {
          toast.custom(
            (t) => (
              <CustomToast
                icon={<IoIosCheckmarkCircle size={25} color="green" />}
                content={"Cám ơn bạn đã để lại thông tin bình luận"}
              />
            ),
            {
              position: "top-right",
            }
          );
          setFile([]);
          setContent("");
        } else {
          toast.custom(
            (t) => (
              <CustomToast
                icon={<BiSolidErrorAlt size={25} color="red" />}
                content={"Đã xảy ra lỗi. Xin vui lòng thử lại!"}
              />
            ),
            {
              position: "top-right",
            }
          );
        }
      } catch (error) {
        console.error("post data error");
      }
    }
  };
  const [replyingCommentId, setReplyingCommentId] = useState(null);
  const [replyContent, setReplyContent] = useState("");
  const handleReplyClick = (commentId) => {
    setReplyingCommentId((prevId) => (prevId === commentId ? null : commentId));
  };
  const handleReplySubmit = async () => {
    if (!user) {
      const errorsComment = {};
      if (phone == "") {
        errorsComment.phone = "Số điện thoại không được để trống";
      } else if (!validatePhoneNumber(phone)) {
        errorsComment.phone = "Vui lòng nhập đúng số điện thoại";
      }
      if (commentUser.trim() == "") {
        errorsComment.commentUser = "Tên không được để trống";
      } else if (validateName(commentUser)) {
        errorsComment.commentUser = "Tên không được có ký tự đặc biệt";
      }
      if (email == "") {
        errorsComment.email = "Email không được để trống";
      } else if (!validateEmail(email)) {
        errorsComment.email = "Vui lòng nhập đúng email";
      }
      setValidateComment(errorsComment);
      if (Object.keys(errorsComment).length === 0) {
        try {
          const res = await axios.post(`${imageBaseUrl}/api/comment`, {
            post_id: productId,
            macn: macn,
            module: moduleComment,
            avatar: filePost,
            name: commentUser,
            email: email,
            phone: phone,
            content: replyContent,
            mpydvtnk: memId,
          });
          if (res.data.status == true) {
            toast.custom(
              (t) => (
                <CustomToast
                  icon={<IoIosCheckmarkCircle size={25} color="green" />}
                  content={"Cám ơn bạn đã để lại thông tin bình luận"}
                />
              ),
              {
                position: "top-right",
              }
            );
            setPhone("");
            setCommentUser("");
            setEmail("");
            setFile([]);
            setContent("");
            setReplyingCommentId(null);
            setReplyContent("");
          } else {
            toast.custom(
              (t) => (
                <CustomToast
                  icon={<BiSolidErrorAlt size={25} color="red" />}
                  content={"Đã xảy ra lỗi. Xin vui lòng thử lại!"}
                />
              ),
              {
                position: "top-right",
              }
            );
          }
        } catch (error) {
          console.error("post data error");
        }
      }
    } else {
      try {
        const res = await axios.post(`${imageBaseUrl}/api/comment`, {
          post_id: productId,
          macn: macn,
          module: moduleComment,
          avatar: filePost,
          name: commentUser,
          // email: email,
          // phone: phone,
          content: replyContent,
          mpydvtnk: memId,
        });
        if (res.data.status == true) {
          toast.custom(
            (t) => (
              <CustomToast
                icon={<IoIosCheckmarkCircle size={25} color="green" />}
                content={"Cám ơn bạn đã để lại thông tin bình luận"}
              />
            ),
            {
              position: "top-right",
            }
          );
          setFile([]);
          setContent("");
          setReplyingCommentId(null);
          setReplyContent("");
        } else {
          toast.custom(
            (t) => (
              <CustomToast
                icon={<BiSolidErrorAlt size={25} color="red" />}
                content={"Đã xảy ra lỗi. Xin vui lòng thử lại!"}
              />
            ),
            {
              position: "top-right",
            }
          );
        }
      } catch (error) {
        console.error("post data error");
      }
    }
  };
  return (
    <div className="comment-container">
      <h3 className="comment-title">Bình luận</h3>
      <div>
        <div>
          {/* <form  class="" enctype="multipart/form-data"> */}
          <textarea
            className="comment-input"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Mời bạn bình luận hoặc đặt câu hỏi ..."
          ></textarea>
          <div className="btn_upload">
            <input
              onChange={handleChange}
              multiple
              type="file"
              id="upload_file"
              name=""
            />
            <div className="d-flex align-items-center pointer">
              <Camera size={20} className="me-2" />
              Gửi ảnh thật tế
            </div>
          </div>
          <div>
            {file.length == 0 ? (
              <div></div>
            ) : (
              file.map((item) => <img className="preview-image" src={item} />)
            )}
          </div>
          {!user && (
            <Row>
              <Col xs={12} sm={6} className="Personal mb-3">
                <input
                  className="info-input"
                  type="text"
                  placeholder="Mời nhập tên *"
                  value={commentUser}
                  onChange={(e) => setCommentUser(e.target.value)}
                />
                {validateComment.commentUser != "" ? (
                  <div>{validateComment.commentUser}</div>
                ) : null}
              </Col>
              <Col xs={12} sm={6} className="Personal mb-2">
                <input
                  className="info-input"
                  type="text"
                  placeholder="Số điện thoại"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                {validateComment.phone != "" ? (
                  <div>{validateComment.phone}</div>
                ) : null}
              </Col>
            </Row>
          )}
          <Row className="mt-2">
            {!user && (
              <Col xs={12} sm={6} className="Personal mb-3">
                <input
                  className="info-input"
                  type="Email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {validateComment.email != "" ? (
                  <div>{validateComment.email}</div>
                ) : null}
              </Col>
            )}
            {user ? (
              <Col xs={12} sm={12} className="Personal">
                <button onClick={handleComment} className="submit-btn">
                  Gửi
                </button>
              </Col>
            ) : (
              <Col xs={12} sm={6} className="Personal">
                <button onClick={handleComment} className="submit-btn">
                  Gửi
                </button>
              </Col>
            )}
          </Row>
          {/* </form> */}
        </div>
        {Array.isArray(comments) && comments != null ? (
          <>
            {comments.map((item) => {
              return (
                <div>
                  <div className="comment-user-container">
                    <div className="d-flex align-items-center comment-user-name">
                      <PersonCircle className="me-2" />
                      {item.name}
                    </div>
                    {item.avatar != "" && item.avatar != null ? (
                      <img
                        className="comment-img"
                        src={`${imageBaseUrl}/uploads/${item.avatar}`}
                        alt={`${item.name}`}
                        loading="lazy"
                      />
                    ) : null}
                    <p className="comment-user">{item.content}</p>
                    <div className="d-flex comment-option">
                      <div
                        className="me-3 pointer "
                        onClick={() => handleReplyClick(item.comment_id)}
                      >
                        Trả lời
                      </div>
                      <p className="me-4 pointer">
                        <HandThumbsUp />
                        Thích
                      </p>
                      <p className="time">21 giờ trước</p>
                    </div>
                  </div>
                  {replyingCommentId === item.comment_id && (
                    <div>
                      {/* <form  class="" enctype="multipart/form-data"> */}
                      <textarea
                        className="comment-input"
                        value={replyContent}
                        onChange={(e) => setReplyContent(e.target.value)}
                        placeholder="Mời bạn bình luận hoặc đặt câu hỏi ..."
                      ></textarea>
                      <div className="btn_upload">
                        <input
                          onChange={handleChange}
                          multiple
                          type="file"
                          id="upload_file"
                          name=""
                        />
                        <div className="d-flex align-items-center pointer">
                          <Camera size={20} className="me-2" />
                          Gửi ảnh thật tế
                        </div>
                      </div>
                      <div>
                        {file.length == 0 ? (
                          <div></div>
                        ) : (
                          file.map((item, index) => (
                            <img
                              className="preview-image"
                              src={item}
                              alt={index}
                            />
                          ))
                        )}
                      </div>
                      {!user && (
                        <Row>
                          <Col xs={12} sm={6} className="Personal mb-3">
                            <input
                              className="info-input"
                              type="text"
                              placeholder="Mời nhập tên *"
                              value={commentUser}
                              onChange={(e) => setCommentUser(e.target.value)}
                            />
                            {validateComment.commentUser != "" ? (
                              <div>{validateComment.commentUser}</div>
                            ) : null}
                          </Col>
                          <Col xs={12} sm={6} className="Personal mb-2">
                            <input
                              className="info-input"
                              type="text"
                              placeholder="Số điện thoại"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                            {validateComment.phone != "" ? (
                              <div>{validateComment.phone}</div>
                            ) : null}
                          </Col>
                        </Row>
                      )}
                      <Row className="mt-2">
                        {!user && (
                          <Col xs={12} sm={6} className="Personal mb-3">
                            <input
                              className="info-input"
                              type="Email"
                              placeholder="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            {validateComment.email != "" ? (
                              <div>{validateComment.email}</div>
                            ) : null}
                          </Col>
                        )}
                        {user ? (
                          <Col xs={12} sm={12} className="Personal">
                            <button
                              onClick={handleReplySubmit}
                              className="submit-btn"
                              rel="nofollow"
                            >
                              Gửi
                            </button>
                          </Col>
                        ) : (
                          <Col xs={12} sm={6} className="Personal">
                            <button
                              onClick={handleReplySubmit}
                              className="submit-btn"
                              rel="nofollow"
                            >
                              Gửi
                            </button>
                          </Col>
                        )}
                      </Row>
                      {/* </form> */}
                    </div>
                  )}
                  <div>
                    {item.subcomments ? (
                      <div className="reply-comment">
                        <div className="d-flex align-items-center comment-user-name">
                          <PersonCircle className="me-2" />
                          {item.subcomments.name} -
                          {item.subcomments.comment_id == item.parentid ? (
                            <div className="ms-2">QTV</div>
                          ) : null}
                        </div>
                        <div
                          className="comment-user"
                          dangerouslySetInnerHTML={{
                            __html: item.subcomments.content,
                          }}
                        ></div>
                        <div className="d-flex comment-option">
                          <div className="me-3 pointer">Trả lời</div>
                          <p className="me-4 pointer">
                            <HandThumbsUp />
                            Hài lòng
                          </p>
                          <p className="me-4 pointer">
                            <HandThumbsDown />
                            Không Hài lòng
                          </p>
                          <p className="time">21 giờ trước</p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Comment;
