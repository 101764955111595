import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { updateCartItemQuantity } from "../../cart/view/cartSlice";
import "../public/fixQuantity.css";
import { imageBaseUrl } from "../../../../config";

import toast, { Toaster } from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";

import { IoIosCheckmarkCircle } from "react-icons/io";
import { BiSolidErrorAlt } from "react-icons/bi";

const FixQuantity = ({ open, close }) => {
  const cart = useSelector((state) => state.cart);

  const dispatch = useDispatch();
  const [localQuantities, setLocalQuantities] = useState({});

  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();

  // const updateMemberCartItem = JSON.parse(
  //   localStorage.getItem("cartMemberItem")
  // );

  const increment = (productId) => {
    setLocalQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: (prevQuantities[productId] || 0) + 1,
    }));
  };

  const decrement = (productId) => {
    setLocalQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: Math.max((prevQuantities[productId] || 1) - 1, 1),
    }));
  };

  const handleChange = (e, productId) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 1 && value <= 500) {
      const updatedQuantities = {
        ...localQuantities,
        [productId]: value,
      };
      setLocalQuantities(updatedQuantities);
    } else {
      toast.custom((t) => (
        <CustomToast
          icon={<BiSolidErrorAlt size={25} color="red" />}
          content={"Số lượng sản phẩm phải từ 1 đến 500 sản phẩm."}
        />
      ));
    }
  };

  const handleUpdate = () => {
    Object.keys(localQuantities).forEach((productId) => {
      const quantity = localQuantities[productId];
      dispatch(updateCartItemQuantity({ productId, quantity }));
    });

    // Cập nhật lại toàn bộ cart theo quantity mới
    // const updatedCartItems = Object.keys(localQuantities).map((productId) => ({
    //   productId,
    //   quantity: localQuantities[productId],
    // }));
    // dispatch(updateCartItemQuantity(updatedCartItems));

    toast.custom(
      (t) => (
        <CustomToast
          icon={<IoIosCheckmarkCircle size={25} color="green" />}
          content={"Cập nhật giỏ hàng thành công"}
        />
      ),
      {
        position: "top-right",
      }
    );
    close();
  };

  const formatNumer = (number) => {
    return new Intl.NumberFormat("vi", {
      style: "currency",
      currency: "VND",
    }).format(number);
  };
  const CheckProductData = localStorage.getItem("selectedProducts");
  const combineCheckProductData = JSON.parse(CheckProductData);

  return (
    <>
      <Dialog open={open} onClose={close}>
        <div className="">
          <DialogTitle className="fix-quantity-title" id="alert-dialog-title">
            Chỉnh sửa số lượng
          </DialogTitle>
          <DialogContent>
            {/* có member */}
            {user ? (
              <div>
                {combineCheckProductData?.map((item) => (
                  <div className="fix-quantity-content">
                    <div className="d-flex">
                      <div>
                        <img
                          className="fix-quantity-image"
                          src={`${imageBaseUrl}/uploads/${item.picture}`}
                          alt={`${item.title}`}
                          loading="lazy"
                        />
                      </div>
                      <div className="fix-quantity-content-title">
                        <div className="fix-quantity-product-name">
                          {item.title}
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                          <div className="d-flex">
                            Số lượng:
                            <div className="fix-quantity-product">
                              <button
                                className="decrement-btn"
                                onClick={() => decrement(item.product_id)}
                              >
                                -
                              </button>
                              <input
                                className="input-quantity"
                                type="number"
                                onChange={(e) =>
                                  handleChange(e, item.product_id)
                                }
                                value={
                                  localQuantities[item.product_id] ||
                                  item.quality
                                }
                                min={1}
                              />
                              <button
                                className="increment-btn"
                                onClick={() => increment(item.product_id)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="fix-quantity-price">
                            {formatNumer(item.price * item.quality)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {cart.cartItem
                  .filter((item) => item.isChecked)
                  .map((item) => (
                    <div className="fix-quantity-content">
                      <div className="d-flex">
                        <div>
                          <img
                            className="fix-quantity-image"
                            src={`${imageBaseUrl}/uploads/${
                              item.listPrice && item.listPrice.length > 0
                                ? item.listPrice[0].picture
                                : item.picture
                            }`}
                            alt={`${item.productName}`}
                            loading="lazy"
                          />
                        </div>
                        <div className="fix-quantity-content-title">
                          <div className="fix-quantity-product-name">
                            {item.productName}
                          </div>
                          <div className="d-flex justify-content-between mt-3">
                            <div className="d-flex">
                              Số lượng:
                              <div className="fix-quantity-product">
                                <button
                                  className="decrement-btn"
                                  onClick={() => decrement(item.productId)}
                                >
                                  -
                                </button>
                                <input
                                  className="input-quantity"
                                  type="number"
                                  onChange={(e) =>
                                    handleChange(e, item.productId)
                                  }
                                  value={
                                    localQuantities[item.productId] ||
                                    item.quality
                                  }
                                  min={1}
                                />
                                <button
                                  className="increment-btn"
                                  onClick={() => increment(item.productId)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div className="fix-quantity-price">
                              {formatNumer(item.price * item.quality)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <div onClick={handleUpdate} className="btn-comparison">
              <div>
                <p className="text-white text-submit">Cập nhật</p>
              </div>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default FixQuantity;
