import { Container, Image, Form, Col, Row } from "react-bootstrap";
import "../public/detaiHire.css";
import { Fragment } from "react";
import { memo } from "react";
import moment from "moment/moment";

function CardContentDetailHire({ show, setShow, dataJob }) {
  const handleShow = () => setShow(true);

  return (
    <div className="box_card_content_detail_hire">
      <div className="text_title_content_detail my-3">
        <span>Chi tiết tin tuyển dụng</span>
      </div>

      <div
        className="box_infor_job_ckeditor"
        dangerouslySetInnerHTML={{ __html: dataJob.information }}
      ></div>

      <div className="box_apply_now mb-4">
        <span onClick={handleShow} className="apply_now">
          Ứng tuyển ngay
        </span>
      </div>
      <div>
        <p className="deadline_apply_text">
          Hạn nộp hồ sơ: {moment(dataJob.deadline).format("DD/MM/YYYY")}
        </p>
      </div>
    </div>
  );
}

export default memo(CardContentDetailHire);
