import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import CircularProgress from "@mui/material/CircularProgress";
import { axiosClient } from "../../../../config";
import PromotionCard from "../../promotion/view/promotionCard";
import MetaDecorator from "../../../../util/metaDecorator";
import BreadcrumbNew from "../../../client_component/breadcrumbNew";

const ServicePageMain = () => {
  const [serviceData, setServiceData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const fetchServiceData = async () => {
    try {
      const res = await axiosClient.get("/member/services?page=" + pageNumber);
      if (res.data.status == true) {
        setTotalPage(res.data.services.last_page);
        setServiceData(res.data.services.data);
        setIsLoaded(true);
      } else {
        fetchServiceData();
      }
    } catch (error) {
      console.error("fetch data error", error);
    }
  };
  useEffect(() => {
    fetchServiceData();
  }, [pageNumber]);
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage < 2) {
      setPageNumber(newPage);
      window.scrollTo(0, 0);
      return;
    }
    window.scrollTo(0, 0);
    setPageNumber(newPage);
  };

  const breadcrumbLink = [{ id: 1, name: "Dịch vụ", link: `/dich-vu` }];

  return (
    <>
      <MetaDecorator
        title="Dịch vụ"
        description="Những dịch vụ tại Công ty Vi tính Nguyên Kim"
      />
      <Container className="promotion-container">
        {!isLoaded ? (
          <div
            style={{ height: "65vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <CircularProgress />
            </div>
          </div>
        ) : (
          <>
            <div className=" ">
              <BreadcrumbNew arrayLink={breadcrumbLink} />
            </div>
            <h2 className="promotion-container-title">Dịch vụ</h2>
            <div className="promotion-container-content">
              {serviceData.map((item) => (
                <PromotionCard
                  key={item.service_id}
                  picture={item.picture}
                  title={item.service_desc.title}
                  link={item.service_desc.friendly_url}
                  dateStart={"service"}
                  dateEnd={"service"}
                />
              ))}
            </div>
          </>
        )}
        <div className="d-flex justify-content-end py-3">
          <ReactPaginate
            pageCount={totalPage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLabel={"<"}
            nextLabel={">"}
          />
        </div>
      </Container>
      <h1 style={{ color: "transparent" }}>dịch vụ</h1>
      <h3 style={{ color: "transparent" }}>dịch vụ</h3>
    </>
  );
};

export default ServicePageMain;
