import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import MetaDecorator from "../../../../../util/metaDecorator";
import { Col, Container, Row } from "react-bootstrap";
import MenuAccount from "../../../../client_component/menuAccount";

import moment from "moment";
import "moment/locale/vi";
import ComponentCardItemOrder from "../../../../client_component/componentCardItemOrder";
import { Spin, Tag } from "antd";
import ButtonRepurchase from "../../../../client_component/buttonRepurchase";
import { getOrder } from "../../../../services/authService";
import { useParams } from "react-router-dom";
import BreadcrumbNew from "../../../../client_component/breadcrumbNew";
moment.locale("vi");

const Order = () => {
  const [loading, setLoading] = useState(true);
  const [dataOrder, setDataOrder] = useState([]);
  let { id } = useParams();

  const fetchDataOrder = async (id) => {
    try {
      const res = await getOrder(id);

      setDataOrder(res?.data?.status ? res?.data?.dataOrder : []);
      setLoading(false);
    } catch (error) {
      console.error("fetch data error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchDataOrder(id);
  }, []);

  const breadcrumbLink = [
    { id: 1, name: "Quản lý đơn hàng", link: `/account/orders` },
    { id: 2, name: "Chi tiết đơn hàng", link: `/account/order/${id}` },
  ];

  return (
    <div>
      <>
        <Toaster />
        <MetaDecorator title="Chi tiết đơn hàng" />
        <Container>
          <div className="container_account">
            <BreadcrumbNew arrayLink={breadcrumbLink} />
            <Row>
              <MenuAccount />
              {loading ? (
                <div className="box_loading_orders">
                  <Spin />
                </div>
              ) : (
                <Col md={8} lg={9}>
                  <div className="top_content_detail_order ">
                    <div className="item_top_content_order w-100 ">
                      <span className="title_top_content_order">
                        Thông tin người nhận
                      </span>

                      <div className="box_item_info_top">
                        <div className="item_info_top_content">
                          <span className="title_top_content">Họ và tên:</span>
                          <span className="value_top_content">
                            {dataOrder?.d_name}
                          </span>
                        </div>
                        <div className="item_info_top_content">
                          <span className="title_top_content">
                            Hình thức nhận hàng:
                          </span>
                          <span className="value_top_content">
                            {/* {dataOrder?.shipping_method.title} */} Nhận tại
                            cửa hàng
                          </span>
                        </div>

                        <div className="item_info_top_content">
                          <span className="title_top_content">
                            Nhận tại cửa hàng:
                          </span>
                          <span className="value_top_content">
                            245B Trần Quang Khải, phường Tân Định, thành phố Hồ
                            Chí Minh
                          </span>
                        </div>

                        <div className="item_info_top_content">
                          <span className="title_top_content">
                            Điện thoại:{" "}
                          </span>
                          <span className="value_top_content">
                            {dataOrder?.d_phone}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="item_top_content_order w-100 ">
                      <span className="title_top_content_order">
                        Thông tin đơn hàng
                      </span>

                      <div className="box_item_info_top">
                        <div className="item_info_top_content">
                          <span className="title_top_content">
                            Trạng thái đơn hàng:
                          </span>
                          <span className="value_top_content">
                            {dataOrder?.order_status}
                          </span>
                        </div>
                        <div className="item_info_top_content">
                          <span className="title_top_content">
                            Thời gian đặt hàng:{" "}
                          </span>
                          <span className="value_top_content">
                            {moment
                              .unix(dataOrder?.date_order)
                              .format("dddd, HH:mm DD-MM-YYYY")
                              .replace(/^\w/, (c) => c.toUpperCase())}
                          </span>
                        </div>
                        {dataOrder?.comment ? (
                          <div className="item_info_top_content">
                            <span className="title_top_content">
                              Yêu cầu khác:
                            </span>
                            <span className="value_top_content">
                              {dataOrder?.comment}
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}

                        {dataOrder?.accumulatedPoints &&
                        dataOrder?.accumulatedPoints > 0 ? (
                          <div className="item_info_top_content">
                            <span className="title_top_content">
                              Điểm sử dụng:
                            </span>

                            <span className="value_top_content">
                              {dataOrder?.accumulatedPoints} điểm
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="middle_content_detail_order my-2">
                    <span className="text_title_common mb-2">Sản phẩm</span>
                    {dataOrder?.orderDetail &&
                      dataOrder?.orderDetail.length > 0 &&
                      dataOrder?.orderDetail.map((item, index) => (
                        <ComponentCardItemOrder item={item} key={index} />
                      ))}
                  </div>

                  <div className="bottom_content_detail_order mb-2">
                    <div className="d-flex flex-row justify-content-end">
                      <div>
                        <div className="item_info_top_content d-flex justify-content-between align-items-center">
                          <span className=" value_top_content">
                            Tổng tạm tính :
                          </span>
                          <span className="title_top_content ">
                            {Number(dataOrder?.total_cart) > 0
                              ? Number(dataOrder?.total_cart).toLocaleString(
                                  "vi",
                                  {
                                    style: "currency",
                                    currency: "VND",
                                  }
                                )
                              : "Liên hệ"}
                          </span>
                        </div>

                        {dataOrder?.shipping_method === "Nhận tại cửa hàng" ? (
                          <div className="item_info_top_content d-flex justify-content-between align-items-center">
                            <span className="value_top_content">
                              Phí vận chuyển :
                            </span>
                            <span className="title_top_content">Miễn phí</span>
                          </div>
                        ) : dataOrder?.address &&
                          dataOrder?.address?.province ===
                            "Thành phố Hồ Chí Minh" ? (
                          <div className="item_info_top_content d-flex justify-content-between align-items-center">
                            <span className="value_top_content">
                              Phí vận chuyển :
                            </span>
                            <span className="title_top_content">Miễn phí</span>
                          </div>
                        ) : (
                          <div className="item_info_top_content d-flex justify-content-between align-items-center">
                            <span className="value_top_content">
                              Phí vận chuyển :
                            </span>
                            <span className="title_top_content">Liên hệ</span>
                          </div>
                        )}

                        {dataOrder?.accumulatedPoints &&
                          dataOrder?.accumulatedPoints > 0 && (
                            <div className="item_info_top_content d-flex justify-content-between align-items-center">
                              <span className=" value_top_content">
                                Chiết khấu từ điểm:
                              </span>
                              <span className="title_top_content ">
                                <Tag color="geekblue">
                                  {dataOrder?.accumulatedPoints} điểm
                                </Tag>
                                -{" "}
                                {dataOrder?.totalValueOfPoint.toLocaleString(
                                  "vi",
                                  {
                                    style: "currency",
                                    currency: "VND",
                                  }
                                )}
                              </span>
                            </div>
                          )}

                        {dataOrder?.coupon && dataOrder?.coupon.MaCouponDes && (
                          <div className="item_info_top_content d-flex justify-content-between align-items-center">
                            <span className=" value_top_content">
                              Mã giảm giá :{" "}
                            </span>
                            <span className="title_top_content ">
                              <Tag color="geekblue">
                                {dataOrder?.coupon.MaCouponDes}
                              </Tag>
                              -{" "}
                              {dataOrder?.coupon.coupon.GiaTriCoupon.toLocaleString(
                                "vi",
                                {
                                  style: "currency",
                                  currency: "VND",
                                }
                              )}
                            </span>
                          </div>
                        )}

                        <div className="item_info_top_content d-flex justify-content-between align-items-center">
                          <span className=" value_top_content">
                            Thành tiền :
                          </span>
                          <d d-flex iv>
                            <span className="value_total_money ">
                              {" "}
                              {Number(dataOrder?.total_price).toLocaleString(
                                "vi",
                                {
                                  style: "currency",
                                  currency: "VND",
                                }
                              )}
                            </span>

                            <span className="text_vat">(Đã bao gồm VAT)</span>
                          </d>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="middle_content_detail_order mb-3 ">
                    <div className="box_method_payment">
                      <span className="text_title_common">
                        Thông tin thanh toán
                      </span>
                    </div>
                    <div className="box_method_payment_2">
                      <span className="">Phương thức thanh toán:</span>
                      <span className="title_top_content">
                        {dataOrder?.payment_method?.title}
                      </span>
                    </div>

                    <ButtonRepurchase arrProduct={dataOrder?.orderDetail} />
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </>
    </div>
  );
};
export default Order;
