import React, { useState, useEffect } from "react";
import Menu from "./menu/menu";
import "../public/main.css";
import * as productService from "../../../services/productService";

import MetaDecorator from "../../../../util/metaDecorator";
import AdvertisingBanner from "./menu/advertisingBanner";
import SpecialPromotionBanner from "./specialPromotionBanner";

const FlashSale = React.lazy(() => import("./flashSale"));
const ProductHot = React.lazy(() => import("./productHot"));
const Product = React.lazy(() => import("./product"));
const FeaturesBrand = React.lazy(() => import("./featuresbrand"));
const ProductPortfolio = React.lazy(() => import("./productPortfolio"));
const Video = React.lazy(() => import("./video"));

const Main = () => {
  const [category, setCategory] = useState([]);

  useEffect(() => {
    productService
      .getProduct()
      .then((data) => {
        setCategory(data);
      })
      .catch((error) => console.error("fetch data error", error));
  }, []);

  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };

  const user = useAuth();

  return (
    <div className="">
      <MetaDecorator
        title="Công ty TNHH Vi Tính Nguyên Kim"
        description="Vi Tính Nguyên Kim nhà phân phối laptop chính hãng, desktop, server, thiết bị mạng, máy in, mực in chính hãng, linh kiện hàng đầu."
      />

      <div className="slideshow">
        <Menu />
      </div>

      <AdvertisingBanner classCss={"container_banner_advertising_right"} />

      <div className="category-slide-mobile">
        <ProductPortfolio />
      </div>
      {user && <FlashSale />}
      <br />
      <ProductHot />
      <Product data={category} />

      {user && <SpecialPromotionBanner auth={user} />}

      <FeaturesBrand />
      <Video />
    </div>
  );
};
export default Main;
