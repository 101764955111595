import { useState } from "react";
import { Row } from "react-bootstrap";
import { imageBaseUrl } from "../../../../config";

function RewardsBenefit({ itemBanner }) {
  const [rewardsBenefit, setRewardsBenefit] = useState([
    {
      urlImage: "./image/reward_protect.png",
      title: "Bảo hiếm NGUYEMKIM",
    },
    {
      urlImage: "./image/reward_meditation.png",
      title: "Giải trí và sức khỏe",
    },
    {
      urlImage: "./image/reward_health-check.png",
      title: "Khám sức khỏe hằng năm",
    },
    {
      urlImage: "./image/reward_summer-holidays.png",
      title: "Kỳ nghỉ hằng năm",
    },
    {
      urlImage: "./image/reward_cash.png",
      title: "Lương tháng 13",
    },
    {
      urlImage: "./image/reward_open-box.png",
      title: "Các khoản phúc lợi khác",
    },
  ]);

  return (
    <Row
      style={{
        background: `url(${
          imageBaseUrl + "/uploads/" + itemBanner?.picture
        })  no-repeat center/cover`,
      }}
      className=" container_reward_benefit"
    >
      <div className="box_reward_benefit">
        <div className="content_reward_benefit">
          <div className="box_text_reward_benefit text-center">
            <h3 className="text_name_company text-light ">
              THƯỞNG VÀ PHÚC LỢI
            </h3>
            <span className="description_compay text-light">
              Chúng tôi luôn ưu tiên sức khỏe và tinh thần của nhân viên. Gói
              lợi ích của chúng tôi bao gồm nhiều phần thưởng hấp dẫn, chăm sóc
              sức khỏe toàn diện, hỗ trợ gia đình và bảo hiểm đầy đủ. Ngoài ra,
              các hoạt động văn hóa và thể thao được tổ chức thường xuyên, giúp
              tạo nên môi trường làm việc năng động và gắn kết.
            </span>
          </div>
          <div className="box_image_reward_benefit row">
            {rewardsBenefit &&
              rewardsBenefit.map((item, index) => (
                <div
                  className="col-md-4 col-6   p-md-4 item_image_reward_benefit"
                  key={index}
                >
                  <div className="box_item_reward  text-center">
                    <div className="circle_image_reward_item">
                      {" "}
                      <div className="parent_image_item">
                        <div className="box_image_reward_item is-5by3 image w-100">
                          <img
                            src={item.urlImage}
                            className="w-100 h-100 image_reward"
                            alt={item.title}
                          />
                        </div>
                      </div>{" "}
                    </div>

                    <div className="box_title_reward_item">
                      <span>{item.title}</span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Row>
  );
}

export default RewardsBenefit;
