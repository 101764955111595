import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";

export const Breadcrumb = ({ arrayLink }) => {
  //   const loaction = useLocation();
  //   let crumbLink = "";
  //   const crumbPath = loaction.pathname
  //     .split("/")
  //     .filter((item) => item !== "")
  //     .map((item) => {
  //       crumbLink = `/${item}`;
  //       return (
  //         <Link to={crumbLink} key={item}>
  //           {item}
  //         </Link>
  //       );
  //     });

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          rel="nofollow"
          sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}
          style={{ fontSize: "16px", textDecoration: "none" }}
          color="inherit"
          title="trang chủ"
          to="/"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Trang chủ
        </Link>
        {arrayLink.map((item, index) => (
          <Link
            key={index}
            underline="hover"
            sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}
            style={{ fontSize: "16px", textDecoration: "none" }}
            color="inherit"
            title={item.name}
            to={`/danh-muc${item.url}`}
          >
            {item.name}
          </Link>
        ))}
        {/* {crumbPath} */}
      </Breadcrumbs>
    </>
  );
};
