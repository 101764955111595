import { Form, Col, Row } from "react-bootstrap";
import "../public/hireHome.css";
import { memo, useEffect } from "react";
import { axiosClient } from "../../../../config";
import { useState } from "react";

function SearchJobs({ setVacancies, setNameJob, setCheckSearch, checkSearch }) {
  const [dataVacancies, setDataVacancies] = useState([]);
  const fetchDataVacancies = async () => {
    try {
      const res = await axiosClient.get(`/member/show-hire-category`);
      setDataVacancies(res.data.data);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };

  useEffect(() => {
    fetchDataVacancies();
  }, []);

  return (
    <Row className=" container_form_search_job">
      <Col md="auto" className="col-12  ">
        <Row className=" justify-content-center box_form_search_job  ">
          <Col md="auto" className="px-0 col-auto">
            <Form.Control
              onChange={(e) => {
                setNameJob(e?.target?.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") setCheckSearch(!checkSearch);
              }}
              className="custom_input_hire"
              size="lg"
              type="text"
              placeholder="Tên công việc"
            />
          </Col>

          <Col md="auto" className="px-0 col-auto">
            <Form.Select
              onChange={(e) => {
                setVacancies(e.target.value);
              }}
              placeholder="Large 1"
              className="custom_input_hire"
              size="lg"
            >
              <option value="">Vị trí tuyển dụng</option>
              {dataVacancies &&
                dataVacancies.length > 0 &&
                dataVacancies?.map((item, index) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </Form.Select>
          </Col>

          <Col md="auto" className="mt-2 box_btn_submit_hire col-auto">
            <button
              onClick={() => setCheckSearch(!checkSearch)}
              className="btn  btn_submit_hire px-0"
            >
              Tìm kiếm
            </button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default memo(SearchJobs);
