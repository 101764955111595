import { Link, Outlet, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import "../public/warrantyPolicyMain.css";
import React, { useState, useEffect } from "react";
import * as guideService from "../../../services/guideService";
import MetaDecorator from "../../../../util/metaDecorator";
import BreadcrumbNew from "../../../client_component/breadcrumbNew";
const WarrantyPolicyMain = () => {
  const location = useLocation();

  const breadcrumbLink = [
    {
      id: 1,
      name: "Các chính sách và quy định",
      link: `/cac-chinh-sach-bao-hanh-va-quy-dinh/quy-dinh-thanh-toan`,
    },
  ];
  const [guideData, setGuideData] = useState([]);
  const fetchGuideData = () => {
    guideService
      .getGuide()
      .then((data) => setGuideData(data.list))
      .catch((error) => console.error("fetch data error", error));
  };
  useEffect(() => {
    fetchGuideData();
  }, []);
  return (
    <div>
      <MetaDecorator
        title="Các chính sách và quy định"
        description="Các chính sách và quy định tại công ty Vi tính Nguyên Kim"
      />
      <Container>
        <div className="my-2">
          <BreadcrumbNew arrayLink={breadcrumbLink} />
        </div>
        <div className="warranty-policy-container">
          <h1 className="warranty-policy-title">Các chính sách và quy định</h1>
          <div className="warranty-policy-container-box">
            <div className="warranty-policy-list">
              {guideData &&
                guideData?.length > 0 &&
                guideData
                  .filter((item) => item.guide_desc)
                  .map((list) => (
                    <Link
                      key={list.guide_id}
                      rel="nofollow"
                      title={list.guide_desc.title}
                      className={`${
                        location.pathname ===
                        `/cac-chinh-sach-bao-hanh-va-quy-dinh/${list.guide_desc.friendly_url}`
                          ? "text-active"
                          : "text-black"
                      } warranty-policy-item`}
                      to={`/cac-chinh-sach-bao-hanh-va-quy-dinh/${list.guide_desc.friendly_url}`}
                    >
                      {list.guide_desc.title}
                    </Link>
                  ))}
            </div>
            <div className="warranty-policy-container-content">
              <Outlet />
            </div>
          </div>
        </div>
      </Container>
      <h3 style={{ color: "transparent" }}>Các chính sách và quy định</h3>
    </div>
  );
};

export default WarrantyPolicyMain;
