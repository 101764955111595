import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import "../public/shippingForm.css";
import { province } from "../../member/public/province";
import { Col, Row } from "react-bootstrap";
import { axiosClient } from "../../../../config";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Button,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { PlusLg, InfoCircle } from "react-bootstrap-icons";

const useAuth = () => {
  const checkLogin = localStorage.getItem("user_token");
  if (checkLogin) {
    return true;
  } else {
    return false;
  }
};

const CustomerInfo = ({ props, setOpen, onEdit }) => {
  const user = useAuth();
  const handleEditInfo = () => {
    setOpen((prev) => !prev);
    const storedData = JSON.parse(localStorage.getItem("customer_info")) || {};
    onEdit(storedData);
  };

  return !user ? (
    <div className="customer-info">
      <div className="customer-info__edit">
        <div className="customer-info__name">Tên: {props?.nameReceiver}</div>
        <button onClick={handleEditInfo} className="customer-info__edit-button">
          Thay đổi
        </button>
      </div>
      <div>Số điện thoại: {props.phone}</div>
      <div>Email: {props.email}</div>
      <div>
        Địa chỉ nhận hàng:{" "}
        {`${props.address}, ${props.ward.label}, ${props.district.label}, ${props.city.label}`}
      </div>
    </div>
  ) : (
    <div className="customer-info">
      <div className="customer-info__edit">
        <div className="customer-info__name">Tên: {props.nameReceiver}</div>
      </div>
      <div>Số điện thoại: {props.phone}</div>
      <div>Email: {props.emailcty}</div>
      <div>
        Địa chỉ nhận hàng:{" "}
        {`${props.address}, ${props.ward}, ${props.district}, ${props.city_province}`}
      </div>
    </div>
  );
};

const CustomInfoDelivery = ({ onDeliveryInfoChange }) => {
  const user = useAuth();
  const formikRef = useRef();
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [valueFormData, setValueFormData] = useState({
    email: "",
    name: "",
    phone: "",
    address: "",
    city: null,
    district: null,
    ward: null,
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEdit = (data) => {
    setTimeout(() => {
      if (formikRef.current) {
        formikRef.current.setValues({
          email: data.email || "",
          name: data.name || "",
          phone: data.phone || "",
          address: data.address || "",
          city: data.city || null,
          district: data.district || null,
          ward: data.ward || null,
        });
      }
    }, 200);
  };

  const getUserName = async () => {
    try {
      const res = await axiosClient.get("/member/member-information");
      setUserInfo(res.data.member);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };

  useEffect(() => {
    getUserName();
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "2px",
      borderColor: "#ddd",
    }),
  };

  const initialValues = {
    email: "",
    name: "",
    phone: "",
    address: "",
    city: null,
    district: null,
    ward: null,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email không hợp lệ")
      .required("Email là bắt buộc"),
    name: Yup.string().required("Họ và tên là bắt buộc"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Số điện thoại không hợp lệ")
      .min(10, "Số điện thoại phải ít nhất 10 số")
      .optional(),
    address: Yup.string().optional(),
    city: Yup.object().required("Vui lòng chọn tỉnh thành"),
    district: Yup.object().required("Vui lòng chọn quận huyện"),
    ward: Yup.object().required("Vui lòng chọn phường xã"),
  });

  const handleCityChange = (selectedCity, setFieldValue) => {
    setFieldValue("city", selectedCity);
    setFieldValue("district", null);
    setFieldValue("ward", null);
    const selectedDistricts =
      province.find((province) => province.code === selectedCity.value)
        ?.districts || [];

    setDistricts(
      selectedDistricts.map((district) => ({
        value: district.code,
        label: district.name,
        wards: district.wards,
      }))
    );
    setWards([]);
  };

  const handleDistrictChange = (selectedDistrict, setFieldValue) => {
    setFieldValue("district", selectedDistrict);
    setFieldValue("ward", null);
    const selectedWards = selectedDistrict?.wards || [];
    setWards(
      selectedWards.map((ward) => ({
        value: ward.code,
        label: ward.name,
      }))
    );
  };

  const handleSubmit = (values) => {
    setValueFormData(values);
    setIsSubmitted(true);
    setOpen(false);
    onDeliveryInfoChange(values);
    localStorage.setItem(
      "customer_info",
      JSON.stringify({
        ...values,
      })
    );
  };

  return (
    <div className="shipping-form-container">
      <p className="shipping-form__title">Thông tin nhận hàng</p>
      <div className="shipping-form__info-note">
        <InfoCircle style={{ color: "#007aff", marginRight: "8px" }} />
        <span>Miễn phí vận chuyển trong khu vực nội thành Hồ Chí Minh</span>
      </div>
      {!user ? (
        <div className="shipping-form__content">
          {isSubmitted ? (
            <CustomerInfo
              props={valueFormData}
              setOpen={setOpen}
              onEdit={handleEdit}
            />
          ) : (
            <div onClick={handleOpen} className="shipping-form__add">
              <PlusLg className="shipping-form__add-icon" />
              <p>Thêm địa chỉ</p>
            </div>
          )}
        </div>
      ) : (
        <CustomerInfo props={userInfo} />
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Thông tin người nhận hàng
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "gray",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="form-content">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <Row className="shipping-form__customer-info">
                  <Col md={12}>
                    {/* Họ và tên */}
                    <div className="shipping-form__group">
                      <label htmlFor="name">Họ tên</label>
                      <Field
                        name="name"
                        type="text"
                        className="shipping-form__control"
                        placeholder="Vui lòng nhập tên người nhận"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    {/* Email */}
                    <div className="shipping-form__group">
                      <label htmlFor="email">Email</label>
                      <Field
                        name="email"
                        type="email"
                        className="shipping-form__control"
                        placeholder="Nhập email của bạn"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    {/* Số điện thoại */}
                    <div className="shipping-form__group">
                      <label htmlFor="phone">Số điện thoại</label>
                      <Field
                        name="phone"
                        type="text"
                        className="shipping-form__control"
                        placeholder="Nhập số điện thoại"
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="shipping-form__customer-address">
                  <h5>Địa chỉ nhận hàng</h5>
                  <Col md={6}>
                    {/* Tỉnh thành */}
                    <div className="shipping-form__group">
                      <label htmlFor="city">Tỉnh thành</label>
                      <Select
                        options={province.map((city) => {
                          return {
                            value: city.code,
                            label: city.name,
                          };
                        })}
                        name="city"
                        value={values.city}
                        onChange={(option) =>
                          handleCityChange(option, setFieldValue)
                        }
                        placeholder="Chọn tỉnh/ thành phố"
                        styles={customStyles}
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="shipping-form__group">
                      <label htmlFor="district">Quận huyện</label>

                      <Select
                        options={districts}
                        name="district"
                        value={values.district}
                        onChange={(option) =>
                          handleDistrictChange(option, setFieldValue)
                        }
                        placeholder="Chọn quận/ huyện"
                        styles={customStyles}
                        isDisabled={!values.city}
                      />
                      <ErrorMessage
                        name="district"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="shipping-form__group">
                      <label htmlFor="ward">Phường xã</label>

                      <Select
                        options={wards}
                        name="ward"
                        value={values.ward}
                        onChange={(option) => setFieldValue("ward", option)}
                        placeholder="Chọn phường xã"
                        styles={customStyles}
                        isDisabled={!values.district}
                      />
                      <ErrorMessage
                        name="ward"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    {/* Địa chỉ */}
                    <div className="shipping-form__group">
                      <label htmlFor="address">Địa chỉ</label>
                      <Field
                        name="address"
                        type="text"
                        className="shipping-form__control"
                        placeholder="Địa chỉ (tùy chọn)"
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </DialogContent>

        {/* Nút Submit */}
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{ textTransform: "none" }}
          >
            Hủy bỏ
          </Button>
          <Button
            onClick={() => formikRef.current.submitForm()}
            type="submit"
            variant="contained"
            color="primary"
            sx={{ textTransform: "none" }}
          >
            Lưu địa chỉ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomInfoDelivery;
