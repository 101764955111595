import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../public/sidsebarProduct.css";
import { useNavigate } from "react-router-dom";
import { Funnel, XLg } from "react-bootstrap-icons";
import { axiosClient, imageBaseUrl } from "../../../../config";
import { useLocation } from "react-router-dom";

const SideBarProduct = ({
  catId,
  category,
  onChooseOption,
  cateChild,
  locationParams,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const popupRef = useRef(null);
  const [brandList, setBrandList] = useState([]);

  const [categoryOption, setCategoryOption] = useState([]);
  const [isOpenTotalFilter, setIsOpenTotalFilter] = useState(false);
  const [isOpenBrandFilter, setOpenBrandFilter] = useState(false);
  const [isOpenCateFilter, setIsOpenCateFilter] = useState(false);

  const [lastDeletedParams, setLastDeletedParams] = useState(false);

  const [chooseCateFilter, setChooseCateFilter] = useState("");

  const [selectedValues, setSelectedValues] = useState(
    state && state.params != undefined
      ? state.params
      : { brand: "", categories: [] }
  );

  const [selectedValuesTitle, setSelectedValuesTitle] = useState(
    state && state.title != undefined ? state.title : {}
  );

  const [selectedValuesTotal, setSelectedValuesTotal] = useState(
    state && state.params != undefined
      ? state.params
      : { brand: "", categories: [] }
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (brandItem, brandTitle) => {
    if (selectedValues.brand === brandItem) {
      const update = { ...selectedValues, brand: null };
      const updateBrandTitle = { ...selectedValuesTitle, brand: null };

      setSelectedValues(update);
      setSelectedValuesTitle(updateBrandTitle);
    } else {
      const update = { ...selectedValues, brand: brandItem };
      const updateBrandTitle = { ...selectedValuesTitle, brand: brandTitle };
      setSelectedValuesTitle(updateBrandTitle);
      setSelectedValues(update);
    }
    setOpenBrandFilter(false);
  };

  const [filterId, setFilterId] = useState(
    state && state.cateIdList != undefined ? state.cateIdList : []
  );

  const handleCategoryChange = (categoryName, categoryItem) => {
    const newItem = {
      id: categoryItem.id,
      properties_id: categoryItem.properties_id,
      name: categoryItem.name,
      slug: categoryItem.slug,
      categoryName: categoryName,
    };

    let filterList = [...filterId];

    const existingItemIndex1 = filterList.findIndex(
      (item) => item.properties_id === newItem.properties_id
    );

    const existingItemIndex = filterList.findIndex(
      (item) =>
        item.id === newItem.id && item.properties_id === newItem.properties_id
    );

    if (existingItemIndex1 >= 0) {
      filterList = filterList.map((item) => {
        if (item.properties_id === newItem.properties_id) {
          item.id = newItem.id;
          item.properties_id = newItem.properties_id;
          item.slug = newItem.slug;
          item.name = newItem.name;
          item.categoryName = newItem.categoryName;
        }
        return item;
      });

      if (existingItemIndex !== -1) {
        filterList.splice(existingItemIndex, 1);
      }
    } else {
      filterList.push(newItem);
    }

    setFilterId(filterList);
    let updatedCategoriesState = [...selectedValues.categories];

    const findIndexItem = selectedValues.categories.findIndex(
      (item) => item.categoryName === categoryName
    );

    const findIndexItem2 = selectedValues.categories.findIndex(
      (item) => item.categoryName === categoryName && item.name === newItem.name
    );
    if (findIndexItem >= 0) {
      updatedCategoriesState = updatedCategoriesState.map((item) => {
        if (
          item.categoryName === categoryName &&
          item.name !== newItem.name &&
          item.slug !== newItem.slug
        ) {
          item.id = newItem.id;
          item.properties_id = newItem.properties_id;
          item.slug = newItem.slug;
          item.name = newItem.name;
          item.categoryName = newItem.categoryName;
        }
        return item;
      });
      if (existingItemIndex !== -1) {
        updatedCategoriesState.splice(findIndexItem2, 1);
      }
    } else {
      updatedCategoriesState = [...selectedValues.categories, newItem];
    }

    // const updateURLCheck = updateURL(categoryName, categoryOption)
    setSelectedValues((pre) => ({
      ...pre,
      categories: updatedCategoriesState,
    }));

    setIsOpenCateFilter(false);
  };

  // useEffect for updateParams
  const updateParams = useCallback(() => {
    updateURL(selectedValues, filterId);
  }, [selectedValues, filterId]);

  useEffect(() => {
    updateParams();
  }, [updateParams]);

  useEffect(() => {
    onChooseOption(filterId);
  }, [filterId]);

  const [visibleChooseOption, setVisibleChooseOption] = useState([]);
  const ChooseOption = useCallback(() => {
    const filteredValues =
      selectedValues.categories &&
      selectedValues.categories.map((item) => item.name);
    const newArr = selectedValues.brand ? [selectedValues.brand] : [];
    if (filteredValues != undefined) {
      newArr.push(...filteredValues);
    }
    setVisibleChooseOption(newArr);
  }, [selectedValues]);

  useEffect(() => {
    ChooseOption();
  }, [selectedValues]);

  // Cập nhật url

  const updateURL = (url, cateIdList) => {
    const filteredValues = Object?.keys(url)
      ?.filter((key) => url[key] !== undefined && url[key] !== null)
      .reduce((acc, key) => {
        acc[key] = url[key];
        return acc;
      }, {});

    const queryParams = [];

    if (filteredValues.brand) {
      queryParams.push(`-${encodeURIComponent(filteredValues.brand)}`);
    }

    if (filteredValues.categories) {
      const categoryValues = filteredValues.categories.filter(
        (key) => filteredValues.categories
      );

      if (categoryValues.length > 0) {
        const categoryQuery = categoryValues
          .map((item) => `${item.categoryName}=${item.slug}`)
          .join("&");
        queryParams.push(categoryQuery);
      }
    }

    let constructedURL = `/${category}`;

    if (queryParams.length > 0) {
      constructedURL += `?${queryParams.join("&")}`;

      if (filteredValues.brand) {
        constructedURL = constructedURL.replace("&", "?");

        constructedURL = constructedURL.replace("?-", "-");
      }
    }

    if (filteredValues.brand !== "" || filteredValues.categories.length > 0) {
      navigate(constructedURL, {
        state: {
          params: url,
          cateIdList: cateIdList,
        },
      });
    } else if (!lastDeletedParams) {
      navigate(`${locationParams.pathname}` + `${locationParams.search}`, {
        state: {
          params: url,
          cateIdList: cateIdList,
        },
      });
    } else {
      navigate(`/${category}`, {
        state: {
          params: url,
          cateIdList: cateIdList,
        },
      });
    }
  };

  const fetchData = async () => {
    try {
      const res = await axiosClient.get("/member/category-option?key=" + catId);

      const BrandListItems = res.data.list;
      const catOptions = res.data.options;
      setBrandList(BrandListItems);
      setCategoryOption(catOptions);
      console.log("catOptions", catOptions);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };

  useEffect(() => {
    fetchData();
    setOpenBrandFilter(false);
    setChooseCateFilter("");
    setIsOpenCateFilter(false);
  }, [catId]);

  const handleOpenBrandFilter = () => {
    if (isOpenBrandFilter) {
      setOpenBrandFilter(false);
    } else {
      setChooseCateFilter("");
      setOpenBrandFilter(true);
      setIsOpenTotalFilter(false);
    }
  };

  const handleOpenCateFilter = (slug) => {
    if (isOpenCateFilter && slug == chooseCateFilter) {
      setChooseCateFilter("");
      setIsOpenCateFilter(false);
    } else {
      setChooseCateFilter(slug);
      setIsOpenCateFilter(true);
      setOpenBrandFilter(false);
      setIsOpenTotalFilter(false);
    }
  };

  const hanldeOpenTotalFilter = () => {
    if (isOpenTotalFilter) {
      setIsOpenTotalFilter(false);
    } else {
      setIsOpenTotalFilter(true);
      setChooseCateFilter("");
      setOpenBrandFilter(false);
    }
  };

  const [isOverflowLeft, setIsOverflowLeft] = useState(false);
  const [isOverflowRight, setIsOverflowRight] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const popupElement = popupRef.current;
      if (popupElement && isOpenCateFilter) {
        const rect = popupElement.getBoundingClientRect();
        setIsOverflowLeft(rect.left < 0);
        setIsOverflowRight(rect.right > window.innerWidth);
      }
    };

    window.addEventListener("resize", checkOverflow);
    checkOverflow();

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [isOpenCateFilter]);

  const handleCloseModal = () => {
    setIsOpenTotalFilter(false);
  };

  const filterShowRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterShowRef.current &&
        !filterShowRef.current.contains(event.target)
      ) {
        setOpenBrandFilter(false);
      }
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpenCateFilter(false);
        setChooseCateFilter("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterShowRef]);

  const hanldeRemoveFilter = () => {
    setSelectedValuesTotal({ brand: "", categories: [] });
    setSelectedValues({ brand: "", categories: [] });
    setIsOpenTotalFilter(false);
    updateURL({ brand: "", categories: [] }, []);
  };

  const hanldeSeeMore = () => {
    const filteredValues = Object?.keys(selectedValuesTotal)
      ?.filter(
        (key) =>
          selectedValuesTotal[key] !== undefined &&
          selectedValuesTotal[key] !== null
      )
      .reduce((acc, key) => {
        acc[key] = selectedValuesTotal[key];
        return acc;
      }, {});

    const queryParams = [];

    if (filteredValues.brand) {
      queryParams.push(`-${encodeURIComponent(filteredValues.brand)}`);
    }

    if (filteredValues.categories) {
      const categoryValues = filteredValues.categories.filter(
        (key) => filteredValues.categories
      );

      if (categoryValues.length > 0) {
        const categoryQuery = categoryValues
          .map((item) => `${item.categoryName}=${item.slug}`)
          .join("&");
        queryParams.push(categoryQuery);
      }
    }

    let constructedURL = `/${category}`;
    if (queryParams.length > 0) {
      constructedURL += `?${queryParams.join("&")}`;

      if (filteredValues.brand) {
        constructedURL = constructedURL.replace("&", "?");

        constructedURL = constructedURL.replace("?-", "-");
      }
    }
    navigate(constructedURL, {
      state: {
        params: selectedValuesTotal,
        title: filterId,
      },
    });
    setIsOpenTotalFilter(false);
  };

  // Xóa filter
  const handleDeleteFilter = (nameFilter) => {
    let deleteSelectedValue = { ...selectedValues };
    let deletefilterId = [...filterId];
    setLastDeletedParams(true);
    if (deleteSelectedValue.brand == nameFilter) {
      deleteSelectedValue.brand = "";
    }

    const findIndexItemForSelectedValue = deleteSelectedValue.categories.filter(
      (item) => {
        if (item.name !== nameFilter) {
          return item;
        }
      }
    );

    deleteSelectedValue.categories = findIndexItemForSelectedValue;

    const findIndexITemForFilterId = deletefilterId.filter((item) => {
      if (item.name !== nameFilter) {
        return item;
      }
    });

    deletefilterId = findIndexITemForFilterId;

    setSelectedValues(deleteSelectedValue);
    setFilterId(deletefilterId);
    // Sau khi thực hiện xóa cập nhật lại url
    updateURL(deleteSelectedValue, deletefilterId);
  };

  return (
    <div className="mt-2">
      {visibleChooseOption &&
        visibleChooseOption.filter((item) => item != null).length > 0 && (
          <div className="box-choose-title">
            <h3 className="has-choose">Đã chọn:</h3>
            {visibleChooseOption
              .filter((item) => item != null)
              .map((item, index) => {
                return (
                  <div key={index} className="box-choose-item">
                    {item}
                    <XLg
                      onClick={() => handleDeleteFilter(item)}
                      className="ms-2 delete-filter"
                      size={14}
                    />
                  </div>
                );
              })}
          </div>
        )}

      {!cateChild.status && (
        <div className="sidebar-product-container block-scroll filter">
          <div className="d-block d-md-none">
            <Button
              variant="primary"
              onClick={handleShow}
              style={{
                position: "relative",
                top: "-0.5px",
                padding: "6px 10px",
                width: "68px",
                fontSize: "14px",
                backgroundColor: "white",
                color: "black",
                border: "1px solid #e0e0e0",
              }}
            >
              Bộ lọc
            </Button>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Bộ lọc sản phẩm</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* brandlist options */}
                {brandList.map((item, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      handleChange(
                        item.brand_desc.friendly_url,
                        item.brand_desc.title
                      )
                    }
                    className={`c-btnbox ${
                      selectedValues.brand == item.brand_desc.friendly_url
                        ? `check`
                        : ``
                    }`}
                  >
                    <img
                      className="c-btnbox-filter-hang"
                      width={68}
                      height={30}
                      src={`${imageBaseUrl}/uploads/${item.picture}`}
                      alt={item.brand_desc.title}
                    />
                  </div>
                ))}
                {/* end brandlist options */}

                {/* category options */}
                {categoryOption.map((item, index) => {
                  return (
                    <div key={index} className="show-total-item count-item">
                      <p className="show-total-txt">{item.title}</p>
                      <div className="filter-list">
                        {item.propertiesValue
                          .filter((cate) => cate.slug != null)
                          .map((subCateOptions, subIndex) => {
                            const checkValue =
                              selectedValues.categories &&
                              selectedValues.categories.map(
                                (item) => item.name
                              );
                            return (
                              <div
                                key={subIndex}
                                onClick={() =>
                                  handleCategoryChange(
                                    item.slug,
                                    subCateOptions
                                  )
                                }
                                className={`c-btnbox ${
                                  selectedValues.categories
                                    ? checkValue.find(
                                        (items) => items == subCateOptions.name
                                      ) == subCateOptions.name
                                      ? `check`
                                      : ``
                                    : ``
                                }`}
                              >
                                {subCateOptions.name}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
                {/* end category options */}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  style={{ padding: "8px 12px", fontSize: "16px" }}
                  variant="secondary"
                  onClick={handleClose}
                >
                  Đóng
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div className="box-filter block-scroll-main scrolling">
            {brandList.length > 0 && (
              // check for bộ lọc
              <div className="filter-total">
                <div
                  className={`filter-item__title ${
                    isOpenTotalFilter && `active showing`
                  }`}
                  onClick={hanldeOpenTotalFilter}
                >
                  <div className="arrow-filter"></div>
                  <Funnel size={16} className="me-2" />
                  <span>Bộ lọc</span>
                </div>
                {isOpenTotalFilter && (
                  <div className="filter-show show-total active">
                    <div className="close-popup-box">
                      <div
                        onClick={handleCloseModal}
                        className="close-popup-total"
                      >
                        Đóng
                        <XLg className="ms-2" size={14} />
                      </div>
                    </div>
                    <div className="flt-fixbtn">
                      <div
                        onClick={handleCloseModal}
                        className="btn-closefilter"
                      >
                        Đóng
                      </div>
                    </div>
                    <div className="show-total-main">
                      <div className="show-total-item clearfix warpper-manu-inside arranged">
                        <p className="show-total-txt">Hãng</p>
                        <div className="filter-list filter-list--hang">
                          {brandList.map((item, index) => (
                            <div
                              key={index}
                              onClick={() =>
                                handleChange(
                                  item.brand_desc.friendly_url,
                                  item.brand_desc.title
                                )
                              }
                              className={`c-btnbox ${
                                selectedValues.brand ==
                                item.brand_desc.friendly_url
                                  ? `check`
                                  : ``
                              }`}
                            >
                              <img
                                className="c-btnbox-filter-hang"
                                width={68}
                                height={30}
                                src={`${imageBaseUrl}/uploads/${item.picture}`}
                                alt={item.brand_desc.title}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="filter-border"></div>
                      {categoryOption.map((item, index) => {
                        return (
                          <div className="show-total-item count-item">
                            <p className="show-total-txt">{item.title}</p>
                            <div className="filter-list">
                              {item.propertiesValue
                                .filter((cate) => cate.slug != null)
                                .map((subCateOptions, subIndex) => {
                                  const checkValue =
                                    selectedValues.categories &&
                                    selectedValues.categories.map(
                                      (item) => item.name
                                    );
                                  return (
                                    <div
                                      key={subIndex}
                                      onClick={() =>
                                        handleCategoryChange(
                                          item.slug,
                                          subCateOptions
                                        )
                                      }
                                      className={`c-btnbox ${
                                        selectedValues.categories
                                          ? checkValue.find(
                                              (items) =>
                                                items == subCateOptions.name
                                            ) == subCateOptions.name
                                            ? `check`
                                            : ``
                                          : ``
                                      }`}
                                    >
                                      {subCateOptions.name}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {Object.keys(selectedValues).length != 0 && (
                      <div className="filter-button filter-button--total">
                        <div
                          onClick={() => setIsOpenTotalFilter(false)}
                          className="btn-filter-close"
                        >
                          Xem kết quả
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {/* end check for bộ lọc */}

            {brandList.length > 0 && (
              <div className="filter-item">
                <div
                  onClick={handleOpenBrandFilter}
                  className={`filter-item__title ${
                    isOpenBrandFilter ? `active showing` : ``
                  }`}
                >
                  <div className="arrow-filter"></div>
                  <span>Hãng</span>
                </div>
                {isOpenBrandFilter && (
                  <div className="filter-show has-scroll" ref={filterShowRef}>
                    <div className="filter-list filter-list--hang">
                      {brandList.map((item, index) => (
                        <div
                          key={index}
                          onClick={() =>
                            handleChange(
                              item.brand_desc.friendly_url,
                              item.brand_desc.title
                            )
                          }
                          className={`c-btnbox ${
                            selectedValues.brand == item.brand_desc.friendly_url
                              ? `check`
                              : ``
                          }`}
                        >
                          <img
                            width={68}
                            height={30}
                            src={`${imageBaseUrl}/uploads/${item.picture}`}
                            alt={item.brand_desc.title}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {categoryOption.map((item, index) => {
              return (
                <div key={index} className="filter-item">
                  <div
                    onClick={() => handleOpenCateFilter(item.slug)}
                    className={`filter-item__title ${
                      isOpenCateFilter && chooseCateFilter == item.slug
                        ? `active showing`
                        : ``
                    }`}
                  >
                    <div className="arrow-filter"></div>
                    <span>{item.title}</span>
                  </div>
                  {isOpenCateFilter && chooseCateFilter == item.slug && (
                    <div
                      ref={popupRef}
                      className={`filter-show ${
                        isOverflowLeft ? "" : "filter-show--right"
                      } ${isOverflowRight ? "filter-show--right" : ""}`}
                    >
                      <div className="filter-list">
                        {item.propertiesValue
                          .filter((cate) => cate.slug != null)
                          .map((subCateOptions, subIndex) => {
                            const checkValue =
                              selectedValues.categories &&
                              selectedValues.categories.map(
                                (item) => item.slug
                              );
                            return (
                              <div
                                key={subIndex}
                                onClick={() =>
                                  handleCategoryChange(
                                    item.slug,
                                    subCateOptions
                                  )
                                }
                                className={`c-btnbox ${
                                  selectedValues.categories
                                    ? checkValue.find(
                                        (items) => items == subCateOptions.slug
                                      ) == subCateOptions.slug
                                      ? `check`
                                      : ``
                                    : ``
                                }`}
                              >
                                {subCateOptions.name}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {cateChild.status === true && (
        <ul className="box-filter__cate-child">
          {cateChild.data &&
            cateChild.data.length > 0 &&
            cateChild.data.map((item) => (
              <li key={item?.cat_id}>
                <a
                  href={`${window.location.protocol}//${window.location.hostname}/danh-muc/${item?.category_desc?.friendly_url}`}
                >
                  {item?.category_desc?.cat_name}
                </a>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default memo(SideBarProduct);
