import axios from "axios";

const getToken = () => localStorage.getItem("user_token");
const axiosClient = axios.create({
  baseURL: "https://api.vitinhnguyenkim.com.vn/api",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const imageBaseUrl = "https://media.vitinhnguyenkim.com.vn";
const mainUrl = "https://api.vitinhnguyenkim.com.vn";

const statusColors = {
  pending: "#FFA500",
  "customer-response": "#FF4500",
  paid: "#32CD32",
  delivered: "#1E90FF",
  finished: "#6A5ACD",
  fail: "#8B0000",
  "customer-cancels": "#A9A9A9",
};

export { axiosClient, imageBaseUrl, mainUrl, statusColors };

// export default config;
