import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import MetaDecorator from "../../../../../util/metaDecorator";
import { Col, Container, Row } from "react-bootstrap";
import MenuAccount from "../../../../client_component/menuAccount";
import StatusOrder from "./statusOrder";
import { getOrders } from "../../../../services/authService";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/vi";
import BreadcrumbNew from "../../../../client_component/breadcrumbNew";
import TableOrder from "../../../../client_component/tableOrder";
moment.locale("vi");

const Orders = () => {
  const [loading, setLoading] = useState(false);
  const [dataOrders, setDataOrders] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams(); // Khởi tạo useSearchParams
  const [page, setPage] = useState(1);

  let statusOrder = searchParams.get("statusOrder") || "pending";

  const fetchDataOrders = async (status, page) => {
    try {
      const res = await getOrders(status, page);

      setDataOrders(res?.data?.status ? res?.data?.data : []);
    } catch (error) {
      console.error("fetch data error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchDataOrders(statusOrder, page);
  }, [statusOrder, page]);

  const breadcrumbLink = [
    { id: 1, name: "Quản lý đơn hàng", link: `/account/orders` },
  ];

  return (
    <div>
      <>
        <Toaster />
        <MetaDecorator
          title="Thông tin tài khoản"
          description="Thông tin tài khoản"
        />
        <Container>
          <div className="container_account">
            <div className="mt-1">
              <BreadcrumbNew arrayLink={breadcrumbLink} />
            </div>
            <Row>
              <MenuAccount />
              <Col md={8} lg={9}>
                <StatusOrder
                  keyStatus={statusOrder}
                  setPage={setPage}
                  setSearchParams={setSearchParams}
                />

                <TableOrder dataOrders={dataOrders} loading={loading} />
              </Col>
            </Row>
          </div>
        </Container>
      </>
    </div>
  );
};
export default Orders;
