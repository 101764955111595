import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Link } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { clearProductViewItem } from "../../product/view/productSlice";
import { imageBaseUrl } from "../../../../config";
import "../public/viewed_product.css";

const ViewedProduct = () => {
  const product = useSelector((state) => state.product);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpenContent = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const currencyFormat = (num) => {
    return (
      Number(num)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " đ"
    );
  };

  const handleDelHistory = () => {
    dispatch(clearProductViewItem());
  };

  return (
    <div>
      <div onClick={handleOpenContent} className="product-viewed-container">
        sản phẩm đã xem
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <h4 className="product-viewed-container-modal-title">
            Sản phẩm đã xem
          </h4>
        </DialogTitle>
        <div
          onClick={handleDelHistory}
          className="product-viewed-container-modal-del-history"
        >
          Xóa lịch sử
        </div>
        <DialogContent className="product-viewed-container-content">
          <div>
            {product.viewedProductItems.map((item) => {
              return (
                <div className="viewed-product-item row" key={item.productId}>
                  <div className="viewed-product-item-left col-md-3 col-xl-2 col-4">
                    <img
                      className="viewed-product-img"
                      src={`${imageBaseUrl}/uploads/${item.picture}`}
                      alt={`${item.productName}`}
                      // loading="lazy"
                    />
                  </div>
                  <div className="col-xl-10 col-8 col-md-9">
                    <Link
                      title={item.productName}
                      to={`/${item.link}`}
                      onClick={handleClose}
                      className="viewed-product-item-content"
                    >
                      <p className="viewed-product-item-content-title">
                        {item.productName}
                      </p>
                      <div className="viewed-product-item-content-price">
                        {currencyFormat(item.price)}
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewedProduct;
