import { Col, Row } from "react-bootstrap";
import "../public/hireHome.css";
import { imageBaseUrl } from "../../../../config";

function CompanyIntroduction({ itemBanner }) {
  return (
    <Row className="container_introduction_company bg-white py-5">
      <Col md="12" className="box_introduction_company">
        <Row>
          <Col md="12" className="container_why_company col-12">
            <div className="text-center box_text_nguyen_kim">
              <h2>TẠI SAO CHỌN VI TÍNH NGUYÊN KIM</h2>
            </div>
          </Col>
          <Col md="12" className="py-5 col-12">
            <Row className="py-5">
              <Col md="6" className="col-12">
                <h3 className="text_name_company">VỀ VI TÍNH NGUYÊN KIM</h3>
                <span className="description_compay">
                  Điểm mạnh nổi bật của Nguyen Kim chúng tôi là sứ mệnh đổi mới
                  và thúc đẩy tiến bộ công nghệ. Chúng tôi luôn tạo điều kiện để
                  nhân viên có thể đóng góp ý tưởng và sáng kiến mới, từ đó tạo
                  ra những sản phẩm và dịch vụ có giá trị cao cho khách hàng.
                </span>
              </Col>
              <Col md="6" className="col-12">
                <div className="box_image">
                  <img
                    src="./image/image_nguyenkim1.jpg"
                    className="w-100 h-100"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col
        style={{
          background: `url(${
            imageBaseUrl + "/uploads/" + itemBanner?.picture
          })  no-repeat center/cover`,
        }}
        md="12"
        className="box_introduction_company_2 d-flex align-items-center"
      >
        <Row className="box_company_2">
          <Col md="6" className="col-12">
            <h3 className="text_name_company text-light">
              VI TÍNH NGUYÊN KIM CUNG CẤP
            </h3>
            <span className="description_compay text-light">
              Tận tâm với sứ mệnh cung cấp những sản phẩm công nghệ hàng đầu,
              chúng tôi tự hào là đối tác tin cậy của bạn. Chúng tôi cam kết đem
              đến những sản phẩm công nghệ hàng đầu với chất lượng và dịch vụ
              vượt trội, để mang đến trải nghiệm tuyệt vời nhất cho khách hàng
              của mình
            </span>
          </Col>
          <Col md="6"></Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CompanyIntroduction;
