import React, { useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import LogoProductHot from "../../../../component/image/pngtree-best-product-red-stamp-mark-png-image_3335005-removebg-preview.png";
import { imageBaseUrl } from "../../../../config";

import LogoNK from "../../../../component/image/logo NK.png";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const ProductImg = ({ images, image, productHot, alt }) => {
  const [selectedProduct, setSelectedProduct] = useState(image ? image : null);
  const [open, setOpen] = useState(false);

  const imageSlide = image
    ? [{ pictureProducts: image, alt: alt }, ...(images || [])]
    : images || [];

  const handleClickProduct = (selectedImage) => {
    setSelectedProduct(selectedImage);
  };

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setThumbsSwiper(null);
  };
  const allImages = [image, ...images].filter((item) => item);

  const imageSources = images.map((item) => ({
    src: `${imageBaseUrl}/uploads/${item.pictureProducts}`,
  }));
  const slideImages = [
    { src: `${imageBaseUrl}/uploads/${image}` },
    ...imageSources,
  ];

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const handleCloseModalImg = () => {
    setShowModal(false);
    setThumbsSwiper(null);
  };

  return (
    <>
      {selectedProduct ? (
        <div
          className="product-img-box border shadow mb-3 bg-body-tertiary rounded pe-auto"
          onClick={() => setOpen(true)}
        >
          <div className="product-mark">
            <img src={LogoNK} alt="logoNK" />
          </div>
          <div className="product-img-detail-wrapper">
            <img
              className="product-img-detail"
              loading="lazy"
              src={`${imageBaseUrl}/uploads/${
                selectedProduct.pictureProducts
                  ? selectedProduct.pictureProducts
                  : selectedProduct
              }`}
              alt={`${selectedProduct.alt}`}
            />
          </div>
          {productHot == 4 && (
            <div>
              <img
                src={LogoProductHot}
                className="product-hot-logo-detail"
                alt="banner hot"
                loading="lazy"
              />
            </div>
          )}
        </div>
      ) : null}

      {/* <Modal
        className="product-detail-img-desc"
        show={showModal}
        onHide={handleCloseModal}
        // centered
      >
        <IconButton
          aria-label="close"
          className="close-model-img"
          onClick={handleCloseModalImg}
          sx={{ left: "90%", top: 5, fontSize: "20px" }}
        >
          X
        </IconButton>
        <Modal.Body>
          <div>
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              // spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2"
            >
              {allImages.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="product-mark-detail">
                    <img src={LogoNK} alt="logoNK" />
                  </div>
                  <img
                    className="product-img-detail-slide"
                    src={`${imageBaseUrl}/uploads/${
                      item.pictureProducts || item
                    }`}
                    loading="lazy"
                    alt={`${item.alt}`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              // spaceBetween={2}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper"
            >
              {allImages.map((item, index) => (
                <SwiperSlide key={index}>
                  <img
                    className="product-img-detail-slide"
                    src={`${imageBaseUrl}/uploads/${
                      item.pictureProducts || item
                    }`}
                    loading="lazy"
                    alt={`${item.alt}`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Modal.Body>
      </Modal> */}

      <Lightbox
        className="container_lightbox_gallery"
        open={open}
        slides={slideImages}
        close={() => setOpen(false)}
        plugins={[Fullscreen, Thumbnails, Zoom]}
      />

      <div className="product-img-slide">
        {imageSlide
          .filter((item) => item.pictureProducts != "")
          .map((item, index) => (
            <img
              key={index}
              className={`product-img-slide-item ${
                selectedProduct === item ? "active" : ""
              }`}
              loading="lazy"
              src={`${imageBaseUrl}/uploads/${item.pictureProducts}`}
              onClick={() => handleClickProduct(item)}
              alt={`${item.alt}`}
            />
          ))}
      </div>
    </>
  );
};

export default ProductImg;
