import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { imageBaseUrl } from "../../../../config";

function PartnerPost({ partnerNews, partnerNameUrl }) {
  return (
    <div className="partner-news-grid_container">
      {partnerNews &&
        partnerNews.length > 0 &&
        partnerNews.map((item) => (
          <Card className="partner-news-grid-item">
            <Card.Img
              variant="top"
              src={`${imageBaseUrl}/uploads/${item?.picture}`}
            />
            <Card.Body className="partner-news-body">
              <Card.Title className="partner-news-body__title">
                <Link to={`/doi-tac/${partnerNameUrl}/${item.friendly_url}`}>
                  {item?.title}
                </Link>
              </Card.Title>
              <Card.Text className="partner-news-body__content">
                {item.short}
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
    </div>
  );
}

export default PartnerPost;
