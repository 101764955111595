import { Col, Row } from "react-bootstrap";
import "../public/detaiHire.css";
import { RiCoinsFill } from "react-icons/ri";
import { IoLocation } from "react-icons/io5";
import { BsHourglassSplit } from "react-icons/bs";
import { BsFillSendFill } from "react-icons/bs";
import { IoTimer } from "react-icons/io5";
import { BsCloudDownload } from "react-icons/bs";
import { FaSquareFacebook } from "react-icons/fa6";
import { memo } from "react";
import { axiosClient } from "../../../../config";
import moment from "moment/moment";

function CardSimpleParameter({ show, setShow, dataJob }) {
  const handleShow = () => setShow(true);

  const HandleDownloadCandidate = async () => {
    await axiosClient({
      url: "/member/candidate-information",
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "phieu-thong-tin-ung-vien.docx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="box_card_simple_parameter">
      <div className="box_name_job mb-2">
        <span>{dataJob.name}</span>
      </div>
      <Row>
        <Col md="4" className="item_standard col-6">
          <div className="img_standard">
            <RiCoinsFill className="icon_standard" />
          </div>
          <div className="box_text_standard">
            <span className="standard_text">Địa điểm</span>
            <span className="value_standard">{dataJob.address}</span>
          </div>
        </Col>
        <Col md="4" className="item_standard col-6">
          <div className="img_standard">
            <IoLocation className="icon_standard" />
          </div>
          <div className="box_text_standard">
            <span className="standard_text">Mức lương</span>
            <span className="value_standard">{dataJob.salary}</span>
          </div>
        </Col>

        <Col md="4" className="item_standard col-12 col-12">
          <div className="img_standard">
            <BsHourglassSplit className="icon_standard" />
          </div>
          <div className="box_text_standard">
            <span className="standard_text">Kinh nghiệm</span>
            <span className="value_standard">{dataJob.experience}</span>
          </div>
        </Col>

        <Col className="col-12  my-3 container_text_deadline_candidate ">
          <div
            className="box_candidate_information"
            onClick={() => HandleDownloadCandidate()}
          >
            <BsCloudDownload />
            <span>
              Nhấn tải và điền đẩy đủ: PHIẾU THÔNG TIN ỨNG VIÊN (Bắt buộc)
            </span>
          </div>

          <div className="deadline_apply">
            <IoTimer />{" "}
            <span>
              Hạn nộp hồ sơ: {moment(dataJob.deadline).format("DD/MM/YYYY")}
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="8" className="col-12 mb-md-0 mb-2">
          <div onClick={() => handleShow()} className="box_btn_apply ">
            <BsFillSendFill className="icon_mess_apply text-light " />
            <span className="text-light">Ứng tuyển</span>
          </div>
        </Col>
        <Col md="4" className="col-12">
          <a
            href="https://www.facebook.com/vitinhnguyenkim"
            className="box_btn_messenger"
            target="_blank"
          >
            <div className=" ">
              <FaSquareFacebook className="icon_mess_apply  " />
              Liên hệ
            </div>
          </a>
        </Col>
      </Row>
    </div>
  );
}

export default memo(CardSimpleParameter);
