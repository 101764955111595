import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import * as aboutService from "../../../services/aboutService";
import "../public/introduceMain.css";

const IntroduceDetail = () => {
  const { aboutName } = useParams();
  const [aboutDetailData, setAboutDetailData] = useState([]);
  const fetchAboutDetailData = () => {
    aboutService
      .getAboutDetail(aboutName)
      .then((data) => {
        setAboutDetailData(data.lstIntroduction);
      })
      .catch((error) => console.error("fetch data error", error));
  };
  useEffect(() => {
    fetchAboutDetailData();
  }, [aboutName]);
  return (
    <div className="introduce-company-container">
      <h2 className="news-detail-container-title">{aboutDetailData.title}</h2>
      <div className="news-detail-container-conetent">
        {aboutDetailData.description != "" ? (
          <div
            dangerouslySetInnerHTML={{ __html: aboutDetailData.description }}
          ></div>
        ) : (
          <div className="no-introduce">Không có bài viết</div>
        )}
      </div>
    </div>
  );
};

export default IntroduceDetail;
