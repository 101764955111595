import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import { imageBaseUrl } from "../../../../config";

function Partner({ partnerImages }) {
  return (
    <div className="container">
      <div className="row partner-imgs mx-0">
        <div className="col-md-5 col-12 img_main p-1">
          <Swiper
            className="h-100 img_main_item"
            autoplay={{ delay: 3000 }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
          >
            {partnerImages &&
              partnerImages.length > 0 &&
              partnerImages.map((item) => (
                <SwiperSlide>
                  <div className="h-100">
                    <img
                      alt={item?.picture}
                      className="h-100 w-100"
                      src={`${imageBaseUrl}/uploads/${item?.picture}`}
                    />
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>

        <div className="col-md-7 col-12 d-flex align-items-center secondary_main">
          <div className="row partner-imgs-right w-100 h-100 ">
            {partnerImages &&
              partnerImages.length > 0 &&
              partnerImages.slice(0, 4).map((item) => (
                <div className="col-6 partner-imgs-right-item p-1">
                  <div className="image partner-right h-100 w-100">
                    <img
                      alt={item?.picture}
                      className="w-100 h-100"
                      src={`${imageBaseUrl}/uploads/${item?.picture}`}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partner;
