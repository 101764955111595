import { axiosClient } from "../../config";

export const getCart = () => {
  const checkLogin = localStorage.getItem("user_token");
  if (checkLogin) {
    return axiosClient
      .get("/member/list-cart")
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => error.response);
  }
};

export const getCartMenu = async () => {
  return await axiosClient
    .get("/member/category-menu")
    .then((response) => {
      if (response.data.message == "Server Error") {
        getCartMenu();
      } else {
        return response.data.data;
      }
    })
    .catch((error) => error.response);
};

export const postCart = (payload) => {
  return axiosClient
    .post("/member/list-cart", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

export const addArrayCart = (arrProduct) => {
  return axiosClient
    .post("/member/add-array-cart", arrProduct)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

export const deleteCart = (payload) => {
  return axiosClient
    .delete("/member/list-cart/" + payload)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

export const updateCart = async (cartId, payload) => {
  return await axiosClient
    .put("/member/list-cart/" + cartId, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};
