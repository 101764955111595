import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import PublicRoute from "./publicRoute";
import Login from "./modules/member/view/login";
import Forgetpassword from "./modules/member/view/forget_password";
import Main from "./modules/main/view/main";
import Account from "./modules/member/view/admin/account";
import Orders from "./modules/member/view/admin/orders";
import ChangePassword from "./modules/member/view/changepassword";
import ProductComparison from "./modules/product/view/productComparison";
import WarrantyPolicyMain from "./modules/warrantyPolicy/view/warrantyPolicyMain";
import Cart from "./modules/cart/view/cart";
import Checkout from "./modules/checkout/view/checkout";
import NotFound from "../component/public/notFound/view/notFound";
import CompleteBuy from "./modules/checkout/view/completeBuy";
import Promotion from "./modules/promotion/view/promotion";
import BusinessConsultancy from "./modules/businessConsultancy/view/businessConsultancy";
import BuildConfiguration from "./modules/buildConfiguration/view/buildConfiguration";
import IntroduceMain from "./modules/introduce/view/introduceMain";
import TechnologyCorner from "./modules/technologyCorner/view/technologyCornerMain";
import PromotionDetail from "./modules/promotion/view/promotionDetail";
import ProductHotPage from "./modules/productHot/view/productHotPage";
import NewsContent from "./modules/technologyCorner/view/newsContent";
import ServicePageMain from "./modules/servicePage/view/servicePageMain";
import ServiceDetail from "./modules/servicePage/view/serviceDetail";
import IntroduceDetail from "./modules/introduce/view/introduceDetail";
import WarrantyDetail from "./modules/warrantyPolicy/view/warrantyDetail";
import ProductMain from "./modules/product/view/productMain";
import PartnerMain from "./modules/partner/views/PartnerMain";
import PartnerModal from "./modules/partner/views/PartnerModal";
import PartnerPostDetail from "./modules/partner/views/PartnerPostDetail";
import HireHome from "./modules/hire/views/hireHome";
import DetailHire from "./modules/hire-detail/view/detailHire";

import RequestQuoteForm from "./modules/quoteRequest/view/QuoteRequestForm";
import ContactForm from "./modules/contact/views/ContactForm";
import ConsultantList from "./modules/businessConsultancy/view/consultantList";
import Order from "./modules/member/view/admin/order";
import AccountStatistics from "./modules/member/view/admin/accountStatistics";
import NewRegister from "./modules/member/view/newRegister";
// import RegisterForm from "./modules/member/view/newRegister";
import ProductAll from "./modules/product/view/productAll";

import LoadingGif from "./client_component/loadingGif";
import LogoGif from "../component/image/Eclipse@1x-1.0s-200px-200px.gif";
import LogoNK from "../component/image/logo NK.png";
import DynamicPage from "./client_component/dynamicPage";

function Router() {
  return (
    <div>
      {/* homepage */}
      <Routes>
        <Route
          path="/"
          element={
            <Suspense
              fallback={<LoadingGif gifSrc={LogoGif} logoImage={LogoNK} />}
            >
              <Main />
            </Suspense>
          }
        ></Route>{" "}
        {/* end homepage */}
        {/* sign in, sign up, forget password */}
        <Route path="login" element={<PublicRoute />}>
          <Route path="/login" element={<Login />}></Route>
        </Route>
        <Route path="/register" element={<NewRegister />}></Route>
        <Route path="/forget-password" element={<Forgetpassword />} />
        {/* sign in, sign up, forget password */}
        {/* product detail, product category, product-all */}
        <Route path="/san-pham" element={<ProductAll />}></Route>
        <Route path="/:slug" element={<DynamicPage />}></Route>
        <Route
          path="/danh-muc/:categoryName/:name?"
          element={<ProductMain />}
        ></Route>
        {/* product detail, product category, product-all */}
        {/* search product */}
        <Route
          path="/danh-muc/:name?&(tim-kiem=:tim-kiem)?"
          element={<ProductMain />}
        ></Route>
        {/* search product */}
        {/* product compare */}
        <Route path="/product-comparison" element={<ProductComparison />} />
        {/* product compare */}
        {/* promotion news */}
        <Route path="/khuyen-mai" element={<Promotion />} />
        <Route
          path="/khuyen-mai/:namePromotion"
          element={<PromotionDetail />}
        />
        {/* promotion news */}
        {/* partner news */}
        <Route path="/doi-tac" element={<PartnerMain />} />
        <Route path="/doi-tac/:NamePartner" element={<PartnerModal />} />
        <Route
          path="/doi-tac/:NamePartner/:NamePost"
          element={<PartnerPostDetail />}
        />
        {/* partner news */}
        {/* hire */}
        <Route path="/tuyen-dung" element={<HireHome />} />
        <Route path="/tuyen-dung/:nameJob" element={<DetailHire />} />
        {/* hire */}
        {/* about us  */}
        <Route path="/gioi-thieu" element={<IntroduceMain />}>
          <Route path="/gioi-thieu/:aboutName" element={<IntroduceDetail />} />
        </Route>
        {/* about us  */}
        {/* consultant, ask frequently, request qouteForm, contact form */}
        <Route path="/tu-van" element={<ConsultantList />} />
        <Route path="/tu-van/:slug" element={<ConsultantList />} />
        <Route path="/dat-cau-hoi" element={<BusinessConsultancy />} />
        <Route path="/yeu-cau-bao-gia" element={<RequestQuoteForm />} />
        <Route path="/lien-he" element={<ContactForm />} />
        {/* consultant, ask frequently, request qouteForm, contact form */}
        {/* configuration builder */}
        <Route path="/xay-dung-cau-hinh" element={<BuildConfiguration />} />
        {/* configuration builder */}
        {/* best seller page */}
        <Route path="/best-sellers" element={<ProductHotPage />} />
        {/* best seller page */}
        {/* terms of warranty */}
        <Route
          path="/cac-chinh-sach-bao-hanh-va-quy-dinh"
          element={<WarrantyPolicyMain />}
        >
          <Route
            path="/cac-chinh-sach-bao-hanh-va-quy-dinh/:nameWarranty"
            element={<WarrantyDetail />}
          />
        </Route>
        {/* terms of warranty */}
        <Route path="/goc-cong-nghe" element={<TechnologyCorner />}>
          <Route path="/goc-cong-nghe/:NameNews" element={<NewsContent />} />
        </Route>
        {/* services */}
        <Route path="/dich-vu" element={<ServicePageMain />} />
        <Route path="/dich-vu/:NameService" element={<ServiceDetail />} />
        {/* services */}
        {/* cart, checkout, thankyou */}
        <Route path="/gio-hang" element={<Cart />} />
        <Route path="/check-out" element={<Checkout />} />
        <Route path="/complete" element={<CompleteBuy />} />
        {/* cart, checkout, thankyou */}
        {/* profiles user */}
        <Route path="/account/change-password" element={<ChangePassword />} />
        <Route path="/account" element={<Account />}></Route>
        <Route path="/account/orders" element={<Orders />} />
        <Route path="/account/order/:id" element={<Order />} />
        <Route path="/account/statistics" element={<AccountStatistics />} />
        {/* profiles user */}
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default Router;
