import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "../../public/menu/menu.css";
import Minibanner from "./mini_banner";
import Menumain from "./menu_main";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { axiosClient, imageBaseUrl } from "../../../../../config";
import LogoStarted from "../../public/image/bannerStart.png";

const UserSidebar = () => {
  const [listBanner, setListBanner] = useState([]);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const res = await axiosClient.get(`/member/user-advertise`);
        if (res.data.status === true) {
          setListBanner(res.data.data);
        }
      } catch (error) {
        console.error("Lỗi không thể lấy thông tin banner", error);
      }
    };

    fetchBannerData();
  }, []);

  return (
    <>
      <div className="menu-main">
        {listBanner && listBanner.length > 0 ? (
          <Carousel id="check_carousel" interval={5000}>
            {Array.isArray(listBanner) &&
              listBanner &&
              listBanner?.map((item) => (
                <Carousel.Item key={item.id}>
                  <Link title={item.title} to={`${item.link}`}>
                    <img
                      loading="preload"
                      className="slideshow__image"
                      src={`${imageBaseUrl}/uploads/${item.picture}`}
                      alt={`${item.title}`}
                    />
                  </Link>
                </Carousel.Item>
              ))}
          </Carousel>
        ) : (
          <img
            loading="eager"
            className="slideshow__image"
            src={LogoStarted}
            alt={"banner-start"}
          />
        )}

        <Container>
          <div className="container-main">
            <Menumain />
          </div>
          <Minibanner />
        </Container>
      </div>
    </>
  );
};
export default UserSidebar;
