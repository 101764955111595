import {
  Image,
  Form,
  Col,
  Row,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import "../public/hireHome.css";
import { RiMoneyDollarBoxFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { imageBaseUrl } from "../../../../config";
import { calculateRemaining } from "../../../../util/commonFunction";

function CardJob({ job }) {
  return (
    <Col md="12" sm="6" className="box_card_item_job col-12 px-4 py-2">
      <Link to={`/tuyen-dung/${job.slug}`}>
        <Row className="item_job  m-1 ">
          <Col md="8">
            <Row>
              <Col className="py-2 col-md-3-custom col-12">
                <div className="box_img_logo_company image is-5by4">
                  <img
                    className="w-100 h-100 p-2"
                    src={imageBaseUrl + "/uploads/" + job.image}
                    alt={job.name}
                  />
                </div>
              </Col>
              <Col className="d-flex flex-column justify-content-between py-2 col-md-9-custom col-12">
                <div className="info_basic_company">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 200 }}
                    overlay={<Tooltip>{job.name}</Tooltip>}
                  >
                    <span className="name_job_company">{job.name}</span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 200 }}
                    overlay={<Tooltip>{job.department}</Tooltip>}
                  >
                    <span className="name_company_hire">{job.department}</span>
                  </OverlayTrigger>
                </div>
                <div className="time_job d-flex ">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 200 }}
                    overlay={<Tooltip>{job.address}</Tooltip>}
                  >
                    <span className="text_time_job">{job.address}</span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 200 }}
                    overlay={
                      <Tooltip>
                        {" "}
                        {calculateRemaining(job.deadline) > 0
                          ? `Còn ${calculateRemaining(
                              job.deadline
                            )} ngày để ứng tuyển`
                          : "Hết hạn ứng tuyển"}
                      </Tooltip>
                    }
                  >
                    <span className="text_time_job">
                      {calculateRemaining(job.deadline) > 0
                        ? `Còn ${calculateRemaining(
                            job.deadline
                          )} ngày để ứng tuyển`
                        : "Hết hạn ứng tuyển"}
                    </span>
                  </OverlayTrigger>

                  {/* <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 200 }}
                    overlay={
                      <Tooltip> {calculateUpdate(job.updated_at)}</Tooltip>
                    }
                  >
                    <span className="text_time_job">
                      Cập nhật {calculateUpdate(job.updated_at)}
                    </span>
                  </OverlayTrigger> */}
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            md="4"
            className="box_salary_apply d-flex flex-column justify-content-between"
          >
            <div className="salary">
              <span className="text_salary">
                {job.salary} <RiMoneyDollarBoxFill />
              </span>
            </div>
            <div className="btn_apply">
              <OverlayTrigger
                placement="top"
                delay={{ show: 100, hide: 200 }}
                overlay={<Tooltip>Ứng tuyển</Tooltip>}
              >
                <div className="apply_hire_job">
                  <span>Ứng tuyển</span>
                </div>
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
      </Link>
    </Col>
  );
}

export default CardJob;
