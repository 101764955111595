import { axiosClient } from "../../config";

export const getUser = async () => {
  return await axiosClient
    .get("/member/member-information")
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

export const getOrders = async (statusOrder, page) => {
  return await axiosClient
    .get(`/member/show-order?key=${statusOrder}&page=${page}`)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

export const getOrder = async (idOrder) => {
  return await axiosClient
    .get(`/member/detail-order/${idOrder}`)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

export const postUser = async (payload) => {
  return await axiosClient
    .post("/member/member-information", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

export const changePassword = async (payload) => {
  return await axiosClient
    .post("/member/update-password-member", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

export const forgetPassword = async (payload) => {
  return await axiosClient
    .post("/member/forget-password", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

export const register = async (payload) => {
  return await axiosClient
    .post("/register", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};
