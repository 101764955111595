import { axiosClient } from "../../../../config";

import toast from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";

import { BiSolidErrorAlt } from "react-icons/bi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Button, Form, Input, Modal } from "antd";
import ComponentNumberPhoneForm from "../../../client_component/componentNumberPhoneForm";

const Consultant = ({ setIsShowConsultantForm, isShowConsultantForm }) => {
  const [form] = Form.useForm();

  const onFinish = async (value) => {
    try {
      const res = await axiosClient.post("/fill-form", {
        ...value,
      });
      if (res.status === 200) {
        form.resetFields();
        toast.custom(
          (t) => (
            <CustomToast
              icon={<IoIosCheckmarkCircle size={25} color="green" />}
              content={"Gửi yêu cầu tư vấn thành công. Xin cám ơn."}
            />
          ),
          {
            position: "top-right",
          }
        );
        setIsShowConsultantForm(false);
      } else {
        toast.custom(
          (t) => (
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={"Gửi yêu cầu tư vấn thất bại. Vui lòng gửi lại!"}
            />
          ),
          {
            position: "top-right",
          }
        );
      }
    } catch (error) {
      console.error("send data faild.");
    }
  };

  return (
    <Modal
      title="Yêu cầu tư vấn"
      open={isShowConsultantForm}
      footer={null}
      onCancel={() => setIsShowConsultantForm(false)}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Tiêu đề"
          name="title"
          rules={[
            {
              required: true,
              message: "Hãy điền tiêu đề!",
            },
          ]}
        >
          <Input placeholder={"Tiêu đề"} />
        </Form.Item>

        <Form.Item
          label="Họ và tên"
          name="name"
          rules={[
            {
              required: true,
              message: "Hãy điền họ và tên!",
            },
          ]}
        >
          <Input placeholder={"Họ và tên"} />
        </Form.Item>

        <Form.Item
          className="mb-3"
          label="Số điện thoại"
          rules={[
            {
              required: true,
              message: "Hãy nhập số điện thoại!",
            },
            {
              pattern: /^0\d{9}$/,
              message: "Số điện thoại không đúng định dạng!",
            },
          ]}
          name={"phone"}
        >
          <ComponentNumberPhoneForm placeholder={"Số điện thoại người nhận"} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "E-mail không đúng định dạng!",
            },
            {
              required: true,
              message: "Hãy nhập E-mail!",
            },
          ]}
        >
          <Input placeholder={"E-mail"} />
        </Form.Item>

        <Form.Item label="Nội dung cần tư vấn" name="content">
          <Input.TextArea placeholder={"Nội dung cần tư vấn"} />
        </Form.Item>

        <Form.Item label={null}>
          <Button type="primary" htmlType="submit">
            Gửi
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Consultant;
