import { Accordion, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { axiosClient } from "../../../../config";
import BoxProduct from "./boxProduct";
import LoadingGif from "../../../client_component/loadingGif";

import LogoGif from "../../../../component/image/Eclipse@1x-1.0s-200px-200px.gif";
import LogoNK from "../../../../component/image/logo NK.png";
import "../public/productAll.css";
import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate } from "react-router-dom";

function ProductAll() {
  const location = useLocation();
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [productAllData, setProductAllData] = useState([]);
  const [cateData, setCateData] = useState(null);
  const [statusData, setStatusData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [total, setTotal] = useState(null);
  const [sortPrice, setSortPrice] = useState("DESC");
  const [pageNumber, setPageNumber] = useState(1);

  const [isLoaded, setIsLoaded] = useState(false);

  const currentParams = new URLSearchParams(location.search);

  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const checkLogin = useAuth();

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const res = await axiosClient.get("/member/category-menu");
        if (res.data.message == "Fetched from database") {
          setCategories(res.data.data);
        }
      } catch (error) {
        console.error("Fetch category menu error", error);
      }
    };
    fetchMenuData();
  }, []);

  useEffect(() => {
    const fetchStatusData = async () => {
      try {
        const res = await axiosClient.get("/member/show-product-status");
        if (res.data.status === true) {
          setStatusData(res.data.data);
        }
      } catch (error) {
        console.error("Fetch show product status error", error);
      }
    };
    fetchStatusData();
  }, []);

  const fetchProductAllData = async () => {
    try {
      setIsLoaded(true);
      const response = await axiosClient.get(
        `/member/filter-category?keySlugCate=san-pham&page=${pageNumber}&sort=${sortPrice}&sortStatus=${selectedStatus}`
      );
      setTotal(response.data.total);
      setProductAllData(response.data.products);
    } catch (error) {
      console.error("Fetch data product all error", error);
    } finally {
      setIsLoaded(false);
    }
  };

  useEffect(() => {
    fetchProductAllData();
  }, [pageNumber, sortPrice, selectedStatus]);

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    currentParams.set("page", newPage);
    navigate(`${location.pathname}?${currentParams.toString()}`);
    setPageNumber(newPage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSortChange = (e) => {
    const value = e.target.value;
    const newSort = value === "Giá giảm dần" ? "DESC" : "ASC";
    currentParams.set("sort", newSort);
    currentParams.set("page", 1); // Reset to page 1 when sorting changes
    navigate(`${location.pathname}?${currentParams.toString()}`);
    setSortPrice(newSort);
    setPageNumber(1);
  };

  useEffect(() => {
    const pageFromUrl = parseInt(currentParams.get("page")) || 1;
    const sortFromUrl = currentParams.get("sort") || "DESC";

    setPageNumber(pageFromUrl);
    setSortPrice(sortFromUrl);
  }, [location.search]);

  return (
    <Container className="product-all__container">
      <Row className="product-all__box">
        {/* Sidebar */}
        <Col lg={2} className="product-all__left-container">
          <div className="left-container__title">Danh mục sản phẩm</div>
          <ul className="left-container__category-list list-unstyled">
            {categories && categories.length > 0
              ? categories
                  .filter((item) => item.menu_desc.menu_id != 858)
                  .map((item) => {
                    return (
                      <Link
                        to={
                          item.menu_desc.menu_id == 858
                            ? "/goc-cong-nghe/tin-noi-bo"
                            : item.menu_desc.menu_id == 848
                            ? `/${`danh-muc/${item.menu_desc.link}`}`
                            : `/${`danh-muc/${item.menu_desc.link}`}`
                        }
                      >
                        <li
                          key={item.menu_id}
                        >{`> ${item.menu_desc.title}`}</li>
                      </Link>
                    );
                  })
              : []}
          </ul>
        </Col>

        <Col lg={10} className="product-all__right-container">
          <h4 className="product-all__right-title">SẢN PHẨM</h4>

          <Accordion className="category-accordion">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Danh mục sản phẩm</Accordion.Header>
              <Accordion.Body className="category-list">
                <ul className="left-container__category-list list-unstyled">
                  {categories && categories.length > 0
                    ? categories
                        .filter((item) => item.menu_desc.menu_id != 858)
                        .map((item) => {
                          return (
                            <Link
                              to={
                                item.menu_desc.menu_id == 858
                                  ? "/goc-cong-nghe/tin-noi-bo"
                                  : item.menu_desc.menu_id == 848
                                  ? `/${`danh-muc/${item.menu_desc.link}`}`
                                  : `/${`danh-muc/${item.menu_desc.link}`}`
                              }
                            >
                              <li
                                key={item.menu_id}
                              >{`> ${item.menu_desc.title}`}</li>
                            </Link>
                          );
                        })
                    : []}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Row className="product-all__right-filter mb-3">
            <Col className="d-flex product-all__right-radio">
              <div className="me-2">Trạng thái</div>
              {Array.isArray(statusData) && statusData.length > 0 ? (
                statusData.map((status) => (
                  <Form.Check
                    key={status.status_id}
                    inline
                    label={status.product_status_desc.title}
                    name={`group_${status.status_id}`}
                    type="radio"
                    checked={selectedStatus === status.status_id}
                    onChange={() => setSelectedStatus(status.status_id)}
                  />
                ))
              ) : (
                <div>Không có trạng thái nào để hiển thị</div>
              )}
            </Col>
            <Col className="text-end">
              <Form.Select
                size="sm"
                className="d-inline w-auto"
                onChange={handleSortChange}
              >
                <option>Sắp xếp theo</option>
                <option>Giá giảm dần</option>
                <option>Giá tăng dần</option>
              </Form.Select>
            </Col>
          </Row>

          {/* Product Cards */}
          <Row>
            {isLoaded ? (
              <LoadingGif gifSrc={LogoGif} logoImage={LogoNK} />
            ) : (
              <>
                {productAllData?.length === 0 || !productAllData ? (
                  <div className="no-product">Không có sản phẩm hiển thị</div>
                ) : (
                  <div className="right-container__products-list">
                    {productAllData
                      ?.filter((item) => item.product_desc != null)
                      .map((item, index) => {
                        const filterTech =
                          item.price_list.length > 0
                            ? item.price_list
                                .filter((item) => item.main == 1)
                                .map((items) => items)
                            : [];
                        const resultFilter =
                          filterTech[0] != undefined ? filterTech[0] : {};

                        return (
                          <BoxProduct
                            key={index}
                            catNameParent={
                              item.catNameParent
                                ? item.catNameParent[0]?.friendly_url
                                : cateData?.friendly_url
                            }
                            cateParentId={
                              item.catNameParent
                                ? item.catNameParent[0]?.cat_id
                                : cateData?.cat_id
                            }
                            productId={
                              item.productId === undefined
                                ? item.id_product
                                : item.productId
                            }
                            macn={item.macn}
                            catName={
                              item.catNameParent
                                ? item.catNameParent[0]?.cat_name
                                : cateData?.cat_name
                            }
                            picture={
                              item.price_list && item.price_list.length > 0
                                ? item.price_list[0].picture !== null &&
                                  item.price_list[0].picture !== ""
                                  ? item.price_list[0].picture
                                  : "No_image.jpg"
                                : item.picture !== null && item.picture !== ""
                                ? item.picture
                                : "No_image.jpg"
                            }
                            status={item.stock}
                            productHot={item.status}
                            productName={item.product_desc.title}
                            link={item.product_desc?.friendly_url}
                            pictureStatus={item?.product_status?.picture}
                            brandName={item?.brand_desc?.title}
                            price={
                              checkLogin
                                ? resultFilter.price_old
                                  ? resultFilter.price_old
                                  : item.price_old
                                : resultFilter.price
                                ? resultFilter.price
                                : item.price
                            }
                          />
                        );
                      })}
                  </div>
                )}
              </>
            )}

            {productAllData && productAllData.length > 0 && (
              <div className="d-flex justify-content-end mt-4">
                <ReactPaginate
                  pageCount={Math.ceil(total / 20)}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  previousLabel={"<"}
                  nextLabel={">"}
                  forcePage={pageNumber - 1}
                />
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ProductAll;
