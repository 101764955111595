import React from "react";
import "../public/relatedPosts.css";
import { imageBaseUrl } from "../../../../config";
import { Link } from "react-router-dom";

const RelatedPosts = ({ relatedPosts }) => {
  return (
    <div className="related-posts-container">
      <div className="related-posts-title">Bài viết liên quan</div>
      <div className="related-posts-content">
        {relatedPosts && (
          <>
            {relatedPosts.length != 0 ? (
              relatedPosts?.map((item, index) => {
                return (
                  <Link
                    to={`/${item.friendlyUrl}`}
                    title={item.title}
                    state={{ type: "news" }}
                    key={index}
                    className="related-posts-item"
                  >
                    <div>
                      <img
                        className="related-posts-item-img"
                        src={`${imageBaseUrl}/uploads/${item.picture}`}
                        alt={item.title}
                        loading="lazy"
                      />
                    </div>
                    <div className="related-posts-item-desc">{item.title}</div>
                  </Link>
                );
              })
            ) : (
              <div className="no-related-posts-title">
                Không có bài viết nào
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RelatedPosts;
