import { Container } from "react-bootstrap";
import "../public/introduceMain.css";
import { NavLink, Outlet } from "react-router-dom";
import { Breadcrumb } from "../../../breadcrumb";
import { useEffect, useState } from "react";
import * as aboutService from "../../../services/aboutService";
import MetaDecorator from "../../../../util/metaDecorator";

const IntroduceMain = () => {
  const [introduceData, setIntroduceData] = useState([]);
  const fetchIntroduceData = () => {
    aboutService
      .getAbout()
      .then((data) => {
        setIntroduceData(data.list)
      })
      .catch((error) => console.error("fetch data error", error));
  };
  useEffect(() => {
    fetchIntroduceData();
  }, []);

  const breadcrumbLink = [
    { id: 1, name: "Giới thiệu", url: `/gioi-thieu/gioi-thieu-ve-cong-ty` },
  ];
 
  return (
    <div>
      <MetaDecorator 
        description="Giới thiệu về công ty"
        title="Giới thiệu về công ty"
      />
      <Container>
        <div className="mt-3">
          <Breadcrumb arrayLink={breadcrumbLink} />
        </div>
        <div className="introduce-main-container">
          <h2 className="warranty-policy-title">Giới thiệu</h2>
          <div className="introduce-main-box">
            <div className="introduce-main-list">
              {introduceData.map((item) => (
                <NavLink
                  to={`/gioi-thieu/${item.about_desc.friendly_url}`}
                  rel="nofollow"
                  title={item.about_desc.title}
                  className={`${({ isActive }) =>
                    isActive ? "active" : ""} Introduce-item`}
                >
                  {item.about_desc.title}
                </NavLink>
              ))}
            </div>
            <div className="introduce-main-content">
              <Outlet />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default IntroduceMain;
