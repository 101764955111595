import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { FaRegUserCircle } from "react-icons/fa";
import { GiNotebook } from "react-icons/gi";
import { IoKeyOutline, IoBarChartOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";

const MenuAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    {
      id: 1,
      name: "Tổng quan tài khoản",
      link: "/account/statistics",
      icon: <IoBarChartOutline />,
      key: "accountStatistics",
    },
    {
      id: 2,
      name: "Thông tin tài khoản",
      link: "/account",
      icon: <FaRegUserCircle />,
      key: "infoAccount",
    },
    {
      id: 3,
      name: "Quản lý đơn hàng",
      link: "/account/orders",
      icon: <GiNotebook />,
      key: "orderManagement",
    },
    {
      id: 4,
      name: "Đổi mật khẩu",
      link: "/account/change-password",
      icon: <IoKeyOutline />,
      key: "changePassword",
    },
  ];

  useEffect(() => {
    const checkLogin = localStorage.getItem("user_token");
    if (!checkLogin) {
      navigate("/login");
    }
  }, [navigate]); // Thêm `navigate` vào dependency array

  return (
    <Col md={4} lg={3} xs={12}>
      <div className="box_menu">
        {menuItems.map((item) => (
          <Link key={item.id} to={item.link}>
            <div
              className={`item_menu ${
                item.link === location.pathname ? "menu_active" : ""
              }`}
            >
              {item.icon}
              <span>{item.name}</span>
            </div>
          </Link>
        ))}
      </div>
    </Col>
  );
};

export default MenuAccount;
