import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { province } from "../public/province";
import { Eye } from "react-bootstrap-icons";
import { IconButton, InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Container, Col, Row } from "react-bootstrap";
import "../public/companyInfo.css";

const CompanyInfo = ({ onCompanyInfoChange, validationCompanyInfoErrors }) => {
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [personCities, setPersonCities] = useState([]);
  const [personDistricts, setPersonDistricts] = useState([]);
  const [personWards, setPersonWards] = useState([]);
  const [cityInteracted, setCityInteracted] = useState(false);
  const [districtInteracted, setDistrictInteracted] = useState(false);
  const [wardInteracted, setWardInteracted] = useState(false);
  const inint = sessionStorage.getItem("rsgfrom")
    ? JSON.parse(sessionStorage.getItem("rsgfrom"))
    : {
        account: "",
        username: "",
        password: "",
        re_password: "",
        email: "",
        phone: "",
        company_name: "",
        numbertax: "",
        emailcompany: "",
        preson_address: "",
        preson_city: "",
        preson_district: "",
        preson_ward: "",
        phonecompany: "",
        company_address: "",
        company_city: "",
        company_district: "",
        company_ward: "",
      };
  const [valueFormData, setValueFormData] = useState(inint);
  useEffect(() => {
    onCompanyInfoChange(valueFormData);
  }, [valueFormData, onCompanyInfoChange]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValueFormData((valueFormData) => ({
      ...valueFormData,
      [name]: value,
    }));
    sessionStorage.setItem(
      "rsgfrom",
      JSON.stringify({
        ...valueFormData,
        [name]: value,
      })
    );
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    setCities(province);
    sessionStorage.setItem(
      "rsgfrom",
      JSON.stringify({
        account: "",
        username: "",
        password: "",
        re_password: "",
        email: "",
        phone: "",
        company_name: "",
        numbertax: "",
        emailcompany: "",
        preson_address: "",
        preson_city: "",
        preson_district: "",
        preson_ward: "",
        phonecompany: "",
        company_address: "",
        company_city: "",
        company_district: "",
        company_ward: "",
      })
    );
  }, []);

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = cities.find(
      (city) => city.code === Number(selectedCityId)
    );
    setValueFormData((prevData) => ({
      ...prevData,
      company_city: selectedCity ? selectedCity.name : "",
      company_district: "",
      company_ward: "",
    }));
    setCityInteracted(true);
    setDistricts(selectedCity ? selectedCity.districts || [] : []);
    setWards([]);
  };

  const handleDistrictChange = (event) => {
    const selectedDistrictId = event.target.value;
    const selectedCityId = document.getElementById("city").value;
    const selectedCity = cities.find(
      (city) => city.code === Number(selectedCityId)
    );
    const selectedDistrict = selectedCity.districts.find(
      (district) => district.code === Number(selectedDistrictId)
    );
    setValueFormData((prevData) => ({
      ...prevData,
      company_district: selectedDistrict ? selectedDistrict.name : "",
    }));
    sessionStorage.setItem(
      "rsgfrom",
      JSON.stringify({
        ...valueFormData,
        company_district: selectedDistrict ? selectedDistrict.name : "",
      })
    );
    setDistrictInteracted(true);
    if (selectedCity) {
      setWards(selectedDistrict ? selectedDistrict.wards : []);
    }
  };

  const handleWardChange = (event) => {
    const selectedWardId = event.target.value;
    const selectedCityId = document.getElementById("city").value;
    const selectedCity = cities.find(
      (city) => city.code === Number(selectedCityId)
    );
    const selectedDistrictId = document.getElementById("district").value;
    const selectedDistrict = selectedCity.districts.find(
      (district) => district.code === Number(selectedDistrictId)
    );
    const selectedWard = selectedDistrict.wards.find(
      (ward) => ward.code == Number(selectedWardId)
    );
    setValueFormData((prevData) => ({
      ...prevData,
      company_ward: selectedWard ? selectedWard.name : "",
    }));
    sessionStorage.setItem(
      "rsgfrom",
      JSON.stringify({
        ...valueFormData,
        company_ward: selectedWard ? selectedWard.name : "",
      })
    );
    setWardInteracted(true);
  };

  const handlePersonCityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = cities.find(
      (city) => city.code === Number(selectedCityId)
    );
    setValueFormData((prevData) => ({
      ...prevData,
      preson_city: selectedCity ? selectedCity.name : "",
      preson_district: "",
      preson_ward: "",
    }));
    sessionStorage.setItem(
      "rsgfrom",
      JSON.stringify({
        ...valueFormData,
        preson_city: selectedCity ? selectedCity.name : "",
        preson_district: "",
        preson_ward: "",
      })
    );
    setCityInteracted(true);
    setPersonDistricts(selectedCity ? selectedCity.districts || [] : []);
    setPersonWards([]);
  };

  const handlePersonDistrictChange = (event) => {
    const selectedDistrictId = event.target.value;
    const selectedCityId = document.getElementById("cityPerson").value;
    const selectedCity = cities.find(
      (city) => city.code === Number(selectedCityId)
    );
    const selectedDistrict = selectedCity.districts.find(
      (district) => district.code === Number(selectedDistrictId)
    );
    setValueFormData((prevData) => ({
      ...prevData,
      preson_district: selectedDistrict ? selectedDistrict.name : "",
      preson_ward: "", // Reset ward when district changes
    }));
    sessionStorage.setItem(
      "rsgfrom",
      JSON.stringify({
        ...valueFormData,
        preson_district: selectedDistrict ? selectedDistrict.name : "",
        preson_ward: "", // Reset ward when district changes
      })
    );
    setDistrictInteracted(true);
    if (selectedCity) {
      setPersonWards(selectedDistrict ? selectedDistrict.wards : []);
    }
  };

  const handlePersonWardChange = (event) => {
    const selectedWardId = event.target.value;
    const selectedCityId = document.getElementById("cityPerson").value;
    const selectedCity = cities.find(
      (city) => city.code === Number(selectedCityId)
    );
    const selectedDistrictId = document.getElementById("districtPerson").value;
    const selectedDistrict = selectedCity.districts.find(
      (district) => district.code === Number(selectedDistrictId)
    );
    const selectedWard = selectedDistrict.wards.find(
      (ward) => ward.code === Number(selectedWardId)
    );
    setValueFormData((prevData) => ({
      ...prevData,
      preson_ward: selectedWard ? selectedWard.name : "",
    }));
    sessionStorage.setItem(
      "rsgfrom",
      JSON.stringify({
        ...valueFormData,
        preson_ward: selectedWard ? selectedWard.name : "",
      })
    );
    setWardInteracted(true);
  };
  // onSubmit={UserRegister}
  return (
    <div className="company-info-from-container">
      <form>
        <Container>
          <TextField
            autoFocus
            margin="dense"
            label="Tên đăng nhập viết liền không dấu tối thiểu 6 ký tự *"
            type="text"
            fullWidth
            value={valueFormData.account}
            name="account"
            onChange={handleInputChange}
            InputLabelProps={{
              style: { fontWeight: "bold", fontSize: "12px" }, // Đặt màu cho nhãn
            }}
          />
          {validationCompanyInfoErrors.account != "" ? (
            <p className="text-nofication">
              {validationCompanyInfoErrors.account}
            </p>
          ) : null}
          <TextField
            margin="dense"
            label="Mật khẩu *"
            type={showPassword ? "text" : "password"}
            fullWidth
            value={valueFormData.password}
            name="password"
            onChange={handleInputChange}
            InputLabelProps={{
              style: { fontWeight: "bold", fontSize: "12px" }, // Đặt màu cho nhãn
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    <Eye />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {validationCompanyInfoErrors.password != "" ? (
            <p className="text-nofication">
              {validationCompanyInfoErrors.password}
            </p>
          ) : null}
          <TextField
            margin="dense"
            label="Xác nhận mật khẩu *"
            type={showPassword ? "text" : "password"}
            fullWidth
            value={valueFormData.re_password}
            name="re_password"
            onChange={handleInputChange}
            InputLabelProps={{
              style: { fontWeight: "bold", fontSize: "12px" }, // Đặt màu cho nhãn
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    <Eye />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {validationCompanyInfoErrors.re_password != "" ? (
            <p className="text-nofication">
              {validationCompanyInfoErrors.re_password}
            </p>
          ) : null}
          <TextField
            margin="dense"
            label="Tên người đại diện *"
            type="text"
            fullWidth
            value={valueFormData.username}
            name="username"
            onChange={handleInputChange}
            InputLabelProps={{
              style: { fontWeight: "bold", fontSize: "12px" }, // Đặt màu cho nhãn
            }}
          />
          {validationCompanyInfoErrors.username != "" ? (
            <p className="text-nofication">
              {validationCompanyInfoErrors.username}
            </p>
          ) : null}

          <Row>
            <Col xs={12} sm={6} className="Personal">
              <TextField
                margin="dense"
                label="Email *"
                type="email"
                fullWidth
                value={valueFormData.email}
                name="email"
                onChange={handleInputChange}
                InputLabelProps={{
                  style: { fontWeight: "bold", fontSize: "12px" }, // Đặt màu cho nhãn
                }}
              />
              {validationCompanyInfoErrors.email != "" ? (
                <p className="text-nofication">
                  {validationCompanyInfoErrors.email}
                </p>
              ) : null}
            </Col>
            <Col xs={12} sm={6} className="Personal">
              <TextField
                margin="dense"
                label="Số điện thoại (9 - 12 số) *"
                type="text"
                fullWidth
                value={valueFormData.phone}
                name="phone"
                onChange={handleInputChange}
                InputLabelProps={{
                  style: { fontWeight: "bold", fontSize: "12px" }, // Đặt màu cho nhãn
                }}
              />
              {validationCompanyInfoErrors.phone != "" ? (
                <p className="text-nofication">
                  {validationCompanyInfoErrors.phone}
                </p>
              ) : null}
            </Col>
          </Row>

          <TextField
            margin="dense"
            label="Tên công ty *"
            type="text"
            fullWidth
            value={valueFormData.company_name}
            name="company_name"
            onChange={handleInputChange}
            InputLabelProps={{
              style: { fontWeight: "bold", fontSize: "12px" }, // Đặt màu cho nhãn
            }}
          />
          {validationCompanyInfoErrors.company_name != "" ? (
            <p className="text-nofication">
              {validationCompanyInfoErrors.company_name}
            </p>
          ) : null}
          <Row>
            <Col xs={12} sm={4} className="Personal">
              <TextField
                margin="dense"
                label="Mã số thuế *"
                type="text"
                fullWidth
                value={valueFormData.numbertax}
                name="numbertax"
                onChange={handleInputChange}
                InputLabelProps={{
                  style: { fontWeight: "bold", fontSize: "12px" }, // Đặt màu cho nhãn
                }}
              />
              {validationCompanyInfoErrors.numbertax != "" ? (
                <p className="text-nofication">
                  {validationCompanyInfoErrors.numbertax}
                </p>
              ) : null}
            </Col>
            <Col xs={12} sm={4} className="Personal">
              <TextField
                margin="dense"
                label="Email nhận hóa đơn công ty *"
                type="email"
                fullWidth
                value={valueFormData.emailcompany}
                name="emailcompany"
                onChange={handleInputChange}
                InputLabelProps={{
                  style: { fontWeight: "bold", fontSize: "12px" }, // Đặt màu cho nhãn
                }}
              />
              {validationCompanyInfoErrors.emailcompany != "" ? (
                <p className="text-nofication">
                  {validationCompanyInfoErrors.emailcompany}
                </p>
              ) : null}
            </Col>
            <Col xs={12} sm={4} className="Personal">
              <TextField
                margin="dense"
                label="Số điện thoại công ty (9 - 12 số) *"
                type="text"
                fullWidth
                value={valueFormData.phonecompany}
                name="phonecompany"
                onChange={handleInputChange}
                InputLabelProps={{
                  style: { fontWeight: "bold", fontSize: "12px" }, // Đặt màu cho nhãn
                }}
              />
              {validationCompanyInfoErrors.phonecompany != "" ? (
                <p className="text-nofication">
                  {validationCompanyInfoErrors.phonecompany}
                </p>
              ) : null}
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={6} className="Personal">
              <TextField
                margin="dense"
                label="Địa chỉ công ty *"
                type="text"
                fullWidth
                value={valueFormData.company_address}
                name="company_address"
                onChange={handleInputChange}
                InputLabelProps={{
                  style: { fontWeight: "bold", fontSize: "12px" }, // Đặt màu cho nhãn
                }}
              />
              {validationCompanyInfoErrors.company_address != "" ? (
                <p className="text-nofication">
                  {validationCompanyInfoErrors.company_address}
                </p>
              ) : null}
            </Col>
            <Col xs={12} sm={6} className="Personal">
              <select
                id="city"
                className="choose"
                onChange={handleCityChange}
                onBlur={() => setCityInteracted(true)}
              >
                <option value="">Chọn Tỉnh/Thành phố *</option>
                {province.map((city) => {
                  return (
                    <option key={city.code} value={city.code}>
                      {city.name}
                    </option>
                  );
                })}
              </select>
              {validationCompanyInfoErrors.company_city != "" ? (
                <p className="text-nofication">
                  {validationCompanyInfoErrors.company_city}
                </p>
              ) : null}
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={6} className="Personal">
              <select
                id="district"
                className="choose"
                onChange={handleDistrictChange}
                onBlur={() => setDistrictInteracted(true)}
              >
                <option value="">Chọn Quận/Huyện *</option>
                {districts.map((district) => {
                  return (
                    <option key={district.code} value={district.code}>
                      {district.name}
                    </option>
                  );
                })}
              </select>
              {validationCompanyInfoErrors.company_district != "" ? (
                <p className="text-nofication">
                  {validationCompanyInfoErrors.company_district}
                </p>
              ) : null}
            </Col>
            <Col xs={12} sm={6} className="Personal">
              <select
                id="ward"
                className="choose"
                onChange={handleWardChange}
                onBlur={() => setWardInteracted(true)}
              >
                <option value="">Chọn Phường/Xã *</option>
                {wards.map((ward) => (
                  <option key={ward.code} value={ward.code}>
                    {ward.name}
                  </option>
                ))}
              </select>
              {validationCompanyInfoErrors.company_ward != "" ? (
                <p className="text-nofication">
                  {validationCompanyInfoErrors.company_ward}
                </p>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} className="Personal">
              <TextField
                margin="dense"
                label="Địa chỉ nhận hàng *"
                type="text"
                fullWidth
                value={valueFormData.preson_address}
                name="preson_address"
                onChange={handleInputChange}
                InputLabelProps={{
                  style: { fontWeight: "bold", fontSize: "12px" }, // Đặt màu cho nhãn
                }}
              />
              {validationCompanyInfoErrors.preson_address != "" ? (
                <p className="text-nofication">
                  {validationCompanyInfoErrors.preson_address}
                </p>
              ) : null}
            </Col>
            <Col xs={12} sm={6} className="Personal">
              <select
                id="cityPerson"
                className="choose"
                onChange={handlePersonCityChange}
                onBlur={() => setCityInteracted(true)}
              >
                <option value="">Chọn Tỉnh/Thành phố *</option>
                {cities.map((city) => (
                  <option key={city.code} value={city.code}>
                    {city.name}
                  </option>
                ))}
              </select>
              {validationCompanyInfoErrors.preson_city != "" ? (
                <p className="text-nofication">
                  {validationCompanyInfoErrors.preson_city}
                </p>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} className="Personal">
              <select
                id="districtPerson"
                className="choose"
                onChange={handlePersonDistrictChange}
                onBlur={() => setDistrictInteracted(true)}
              >
                <option value="">Chọn Quận/Huyện *</option>
                {personDistricts.map((district) => (
                  <option key={district.code} value={district.code}>
                    {district.name}
                  </option>
                ))}
              </select>
              {validationCompanyInfoErrors.preson_district != "" ? (
                <p className="text-nofication">
                  {validationCompanyInfoErrors.preson_district}
                </p>
              ) : null}
            </Col>
            <Col xs={12} sm={6} className="Personal">
              <select
                id="wardPerson"
                className="choose"
                onChange={handlePersonWardChange}
                onBlur={() => setWardInteracted(true)}
              >
                <option value="">Chọn Phường/Xã *</option>
                {personWards.map((ward) => (
                  <option key={ward.code} value={ward.code}>
                    {ward.name}
                  </option>
                ))}
              </select>
              {validationCompanyInfoErrors.preson_ward != "" ? (
                <p className="text-nofication">
                  {validationCompanyInfoErrors.preson_ward}
                </p>
              ) : null}
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};

export default CompanyInfo;
