import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Col, Container, Row } from "react-bootstrap";
import MetaDecorator from "../../../../util/metaDecorator";
import { PiEnvelopeSimpleThin } from "react-icons/pi";
import {
  FaPhoneAlt,
  FaFax,
  FaEnvelope,
  FaGlobe,
  FaClock,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { axiosClient } from "../../../../config";
import "../public/contactForm.css";
import toast from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { BiSolidErrorAlt } from "react-icons/bi";

const ContactForm = () => {
  const [addressData, setAddressData] = useState({});
  const [departments, setDepartments] = useState([]);

  const fetchAddressData = async () => {
    const request = [
      axiosClient
        .get(`/member/show-contact-config`)
        .catch((error) => ({ error })),
      axiosClient.get(`/member/show-all-staff`).catch((error) => ({ error })),
    ];

    const responses = await Promise.all(request);

    responses.forEach((response, index) => {
      if (response.error) {
        if (index === 0) {
          console.error("Fetch contact info error", response.error.message);
        } else {
          console.error("Fetch department error", response.error.message);
        }
      } else {
        if (index === 0) {
          setAddressData(response.data.list);
        } else {
          setDepartments(response.data.data);
        }
      }
    });
  };
  useEffect(() => {
    fetchAddressData();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const response = await axiosClient.post(`/member/add-contact`, {
        title: values.title,
        deparment: values.contactTo,
        content: values.content,
        fullName: values.fullName,
        email: values.email,
        address: values.address,
        numberPhone: values.phoneNumber,
      });

      if (response.data.status === true) {
        toast.custom(
          (t) => (
            <CustomToast
              icon={<IoIosCheckmarkCircle size={25} color="green" />}
              content={"Thông tin liên hệ đã được gửi."}
            />
          ),
          {
            position: "top-right",
          }
        );
      }
    } catch (error) {
      console.error("Post contact form data error", error);
      toast.custom(
        (t) => (
          <CustomToast
            icon={<BiSolidErrorAlt size={25} color="red" />}
            content={"Đã xảy ra lỗi. Vui lòng thử lại!!!"}
          />
        ),
        {
          position: "top-right",
        }
      );
    }
  };

  return (
    <div>
      <MetaDecorator description="Liên hệ và góp ý" title="Liên hệ và góp ý" />

      <Container className="contact-container">
        <Row>
          <h2>LIÊN HỆ</h2>
        </Row>
        <Row>
          {addressData?.map ? (
            <div
              className="contact-map"
              dangerouslySetInnerHTML={{ __html: addressData?.map }}
            />
          ) : (
            "Không có sitemap cho địa chỉ"
          )}
        </Row>
        <Row>
          <Col lg={6} className="contact-info">
            <h4>{addressData?.company}</h4>
            <Row className="mb-3 d-flex align-items-center">
              <Col xs={2} className="text-center contact-info__icon">
                <FaMapMarkerAlt size={16} />
              </Col>
              <Col>
                <strong>Địa chỉ công ty:</strong>
                <br />
                <div
                  dangerouslySetInnerHTML={{ __html: addressData?.address }}
                />
              </Col>
            </Row>
            <Row className="mb-3 d-flex align-items-center">
              <Col xs={2} className="text-center contact-info__icon">
                <FaPhoneAlt size={16} />
              </Col>
              <Col>
                <strong>Điện thoại:</strong>
                <br />
                <div dangerouslySetInnerHTML={{ __html: addressData?.phone }} />
              </Col>
            </Row>
            <Row className="mb-3 d-flex align-items-center">
              <Col xs={2} className="text-center contact-info__icon">
                <FaFax size={16} />
              </Col>
              <Col>
                <strong>Fax: (Thông tin fax tại đây nếu có)</strong>
                <br />
              </Col>
            </Row>
            <Row className="mb-3 d-flex align-items-center">
              <Col xs={2} className="text-center contact-info__icon">
                <FaEnvelope size={16} />
              </Col>
              <Col>
                <strong>Email:</strong> {addressData?.email}
              </Col>
            </Row>
            <Row className="mb-3 d-flex align-items-center">
              <Col xs={2} className="text-center contact-info__icon">
                <FaEnvelope size={16} />
              </Col>
              <Col>
                <strong>Email đặt hàng:</strong> {addressData?.email}
              </Col>
            </Row>
            <Row className="mb-3 d-flex align-items-center">
              <Col xs={2} className="text-center contact-info__icon">
                <FaGlobe size={16} />
              </Col>
              <Col>
                <strong>Website:</strong> {addressData?.website}
              </Col>
            </Row>
            <Row className="mb-3 d-flex align-items-center">
              <Col xs={2} className="text-center contact-info__icon">
                <FaClock size={16} />
              </Col>
              <Col>
                <strong>Thời gian làm việc:</strong>
                <br />
                {addressData?.work_time}
              </Col>
            </Row>
          </Col>
          <Col lg={6} className="quote-form">
            <Formik
              initialValues={{
                fullName: "",
                email: "",
                address: "",
                phoneNumber: "",
                contactTo: "",
                title: "",
                content: "",
              }}
              validationSchema={Yup.object({
                fullName: Yup.string().required("Họ tên là bắt buộc"),
                email: Yup.string()
                  .email("Địa chỉ email không hợp lệ")
                  .required("Email là bắt buộc"),
                address: Yup.string().required("Địa chỉ là bắt buộc"),
                phoneNumber: Yup.string().required("Số điện thoại là bắt buộc"),
                title: Yup.string().required("Tiêu đề là bắt buộc"),
                content: Yup.string().required("Nội dung là bắt buộc"),
              })}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div className="contact-form__box">
                    <label>
                      Họ tên <span>(*)</span>
                    </label>
                    <div className="contact-form__input">
                      <Field name="fullName" type="text" />
                      <ErrorMessage
                        className="error_msg"
                        name="fullName"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className="contact-form__box">
                    <label>
                      Email <span>(*)</span>
                    </label>
                    <div className="contact-form__input">
                      <Field name="email" type="email" />
                      <ErrorMessage
                        className="error_msg"
                        name="email"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className="contact-form__box">
                    <label>
                      Địa chỉ <span>(*)</span>
                    </label>
                    <div className="contact-form__input">
                      <Field name="address" type="text" />
                      <ErrorMessage
                        className="error_msg"
                        name="address"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className="contact-form__box">
                    <label>
                      Điện thoại<span>(*)</span>
                    </label>
                    <div className="contact-form__input">
                      <Field name="phoneNumber" type="text" />
                      <ErrorMessage
                        className="error_msg"
                        name="phoneNumber"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className="contact-form__box">
                    <label>Liên hệ tới</label>
                    <div className="contact-form__input">
                      <Field as="select" name="contactTo" type="text">
                        <option value="">Chọn phòng ban</option>
                        {departments && departments.length > 0
                          ? departments.map((option) => (
                              <option
                                key={option.staff_id}
                                value={option.staff_id}
                              >
                                {option.title}
                              </option>
                            ))
                          : []}
                      </Field>
                    </div>
                  </div>

                  <div className="contact-form__box">
                    <label>
                      Tiêu đề <span>(*)</span>
                    </label>
                    <div className="contact-form__input">
                      <Field name="title" type="text" />
                      <ErrorMessage
                        className="error_msg"
                        name="title"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className="contact-form__box">
                    <label>
                      Nội dung <span>(*)</span>
                    </label>
                    <div className="contact-form__input">
                      <Field
                        name="content"
                        as="textarea"
                        style={{ height: "100px" }}
                      />
                      <ErrorMessage
                        className="error_msg"
                        name="content"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className="contact-form__box">
                    <label></label>
                    <div className="form-buttons">
                      <button
                        type="submit"
                        className="contact-form__submit d-flex align-items-center gap-2"
                      >
                        <PiEnvelopeSimpleThin />
                        Gửi
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactForm;
