import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const historySearchItemFromLocalStorage = localStorage.getItem('historySearh');
const initialState = {
  historySearchItems: historySearchItemFromLocalStorage ? JSON.parse(historySearchItemFromLocalStorage) : [],
};

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        addToHistorySearch: (state, action) => {
            const existingProductIndex = state.historySearchItems.findIndex(item => item.keySearch === action.payload.keySearch);
            if (existingProductIndex >= 0) {
                return;
            }
            const tempSearch = { keySearch: action.payload.keySearch, url: action.payload.url };
            state.historySearchItems.unshift(tempSearch);
            if (state.historySearchItems.length > 10) {
                state.historySearchItems.pop();
            }
            localStorage.setItem("historySearh", JSON.stringify(state.historySearchItems));
        },
        clearHistorySearchItem: (state, action) => {
            const nextCartItem = state.historySearchItems.filter((item) => item.keySearch !== action.payload.keySearch)
            state.historySearchItems = nextCartItem
            localStorage.setItem("historySearh", JSON.stringify(state.historySearchItems));
        }
        // clearProductViewItem: (state, action) => {
        //     state.viewedProductItems = []
        //     localStorage.setItem("viewedProductItems", JSON.stringify(state.viewedProductItems))
        // },
    },
});

export const {addToHistorySearch, clearHistorySearchItem} = searchSlice.actions;
export default searchSlice.reducer;