import React, { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { TagsFill, CartX, ArrowLeft, GiftFill } from "react-bootstrap-icons";
import "../public/cart.css";
import ChooseCoupon from "./chooseCoupon";
import ProductPromotion from "./productPromotion";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  removeCartItem,
  decreaseCart,
  addToCart,
  updateCartItemQuantity,
  updateTotalAmount,
  toggleSelectAll,
  toggleCartItemChecked,
  updateAppliedPromotion,
  fetchUserById,
  updateAppliedCard,
  clearAppliedPromotion,
} from "./cartSlice";
import CircularProgress from "@mui/material/CircularProgress";
import * as cartService from "../../../services/cartService";
import ChooseCard from "./chooseCard";
import ChoosePoint from "./choosePoint";
import { Breadcrumb } from "../../../breadcrumb";
import MetaDecorator from "../../../../util/metaDecorator";
import { imageBaseUrl } from "../../../../config";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IoTrash } from "react-icons/io5";

import toast, { Toaster } from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";
import { BiSolidErrorAlt } from "react-icons/bi";

import moment from "moment";

import LoadingGif from "../../../client_component/loadingGif";
import LogoGif from "../../../../component/image/Eclipse@1x-1.0s-200px-200px.gif";
import LogoNK from "../../../../component/image/logo NK.png";
import BreadcrumbNew from "../../../client_component/breadcrumbNew";

const Cart = () => {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [openProductPromoton, setOpenProductPromoton] = useState(false);

  const [selectedProductss, setSelectedProductss] = useState([]);

  const [applyCouponItem, setApplyCouponItem] = useState();

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [cartData, setCartData] = useState([]);
  const [couponValue, setCouponValue] = useState("");
  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();

  const [showDialog, setShowDialog] = useState(false);
  const [selectedDeleteProduct, setSelectedDeleteProduct] = useState(true);

  const handleClickOpen = (event) => {
    event.preventDefault();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModel = () => {
    setOpenProductPromoton(false);
  };
  const incrementCounter = (product) => {
    dispatch(addToCart(product));
  };
  const decrementCounter = (product) => {
    dispatch(decreaseCart(product));
  };

  const storeSelecetedProduct =
    JSON.parse(localStorage.getItem("selectedProducts")) || [];

  // Thay đổi số lượng bằng input
  const handleQuantityInputChange = (item, value) => {
    const newQuantity = parseInt(value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 1 && newQuantity <= 500) {
      const updatedCartData = cartData.map((cartItem) =>
        cartItem.id === item.id
          ? { ...cartItem, quality: newQuantity }
          : cartItem
      );
      setCartData(updatedCartData);

      const updateSelectedProducts = storeSelecetedProduct?.map((product) =>
        product.product_id == item.product_id
          ? {
              ...item,
              quality: newQuantity,
            }
          : product
      );

      localStorage.setItem(
        "selectedProducts",
        JSON.stringify(updateSelectedProducts)
      );

      // Thêm cả giá và title khi update bằng input
      updateItemQuantity(
        item.id,
        item.product_id,
        item.price,
        item.title,
        newQuantity
      );
    } else {
      toast.custom((t) => (
        <CustomToast
          icon={<BiSolidErrorAlt size={25} color="red" />}
          content={"Số lượng sản phẩm phải từ 1 đến 500 sản phẩm."}
        />
      ));
    }
  };

  const handleChange = (e, productId) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 1 && value <= 500) {
      dispatch(updateCartItemQuantity({ productId, quantity: value }));
    } else {
      toast.custom((t) => (
        <CustomToast
          icon={<BiSolidErrorAlt size={25} color="red" />}
          content={"Số lượng sản phẩm phải từ 1 đến 500 sản phẩm."}
        />
      ));
    }
  };

  // Gọi API để update sản phẩm
  const updateItemQuantity = (cartId, productId, price, title, newQuantity) => {
    cartService.updateCart(cartId, {
      product_id: productId,
      price: price,
      title: title,
      quality: newQuantity,
    });
  };

  const handlechooseCoupon = (coupon) => {
    if (coupon == null) {
      // localStorage.setItem("couponSelected", []);
      setApplyCouponItem(coupon);
      setCouponValue(0);
      dispatch(updateAppliedPromotion(coupon));
    } else {
      // localStorage.setItem("couponSelected", JSON.stringify(coupon));
      setApplyCouponItem(coupon);
      setCouponValue(coupon.GiaTriCoupon);
      dispatch(updateAppliedPromotion(coupon));
    }
  };

  const handlechooseCard = (card) => {
    dispatch(updateAppliedCard(card));
  };

  const fomatNumer = (number) => {
    return new Intl.NumberFormat("vi", {
      style: "currency",
      currency: "VND",
    }).format(number);
  };

  // Thay đổi checkbox
  const handleItemCheckboxDataChange = (item, isChecked) => {
    const updatedCartData = cartData.map((cartItem) =>
      cartItem.id === item.id ? { ...cartItem, isChecked } : cartItem
    );

    setCartData(updatedCartData);
    dispatch(updateAppliedCard(null));

    // localStorage.setItem("cartData", JSON.stringify(updatedCartData));
    const updatedSelectedProducts = isChecked
      ? [...selectedProductss, item]
      : selectedProductss.filter((product) => product.id !== item.id);

    setSelectedProductss(updatedSelectedProducts);
    localStorage.setItem(
      "selectedProducts",
      JSON.stringify(updatedSelectedProducts)
    );
    setSelectAllChecked(updatedSelectedProducts.length === cartData.length);
  };

  // Thay đổi checkbox
  const handleItemCheckboxChange = (productId, isChecked) => {
    dispatch(toggleCartItemChecked({ productId, isChecked }));
  };

  // Xử lý chọn tất cả checkbox
  const handleSelectAllChange = (e) => {
    if (user) {
      const { checked } = e.target;
      setSelectAllChecked(checked);
      const updatedCartData = cartData.map((item) => ({
        ...item,
        isChecked: checked,
      }));
      setCartData(updatedCartData);
      const updatedSelectedProducts = checked ? cartData : [];
      setSelectedProductss(updatedSelectedProducts);
      localStorage.setItem(
        "selectedProducts",
        JSON.stringify(updatedSelectedProducts)
      );
    } else {
      const isChecked = e.target.checked;
      dispatch(toggleSelectAll({ isChecked }));
    }
  };

  // Tính tổng tiền trong giỏ
  const subTotal = () => {
    let total = 0;
    cart.cartItem.map((item) => {
      if (item.isChecked === true) {
        total +=
          item.listPrice && item.listPrice.length > 0
            ? item.listPrice[0]?.price * item.quality
            : item.price * item.quality;
      }
    });
    return total;
  };
  const totalCart = subTotal();

  const handleDeleteProduct = async (product) => {
    if (user) {
      try {
        const res = await cartService.deleteCart(product.id);
        if (res.data.status == true) {
          setShowDialog(false);
          // setIsLoaded(false);
          fetchCartData();
          dispatch(fetchUserById());
        } else {
          toast.custom((t) => (
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={"Lỗi thực thi. Vui lòng thử lại!"}
            />
          ));
        }
      } catch (error) {
        console.error("delete data error", error);
      }
    } else {
      setShowDialog(false);
      dispatch(removeCartItem(product));
    }
  };

  // Cập nhật tổng tiền
  const updateTotalAmount = () => {
    let total = 0;
    if (cartData) {
      for (const item of cartData) {
        if (item.isChecked && item.stock === 1) {
          total += item.price * item.quality;
        }
      }
    } else {
      total = 0;
    }
    return total;
  };

  // Xử lý khi chuyển sang trang checkout
  const handlechangeCheckOutPage = () => {
    if (user) {
      const checkedProducts = cartData.filter((item) => item.isChecked);
      if (checkedProducts.length == 0) {
        toast.custom(
          (t) => (
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={"Vui lòng chọn sản phẩm để qua trang tiếp theo!"}
            />
          ),
          {
            position: "top-right",
          }
        );
      } else {
        navigate("/check-out");
      }
    } else {
      const checkedProducts = cart.cartItem.filter((item) => item.isChecked);
      if (checkedProducts.length == 0) {
        toast.custom(
          (t) => (
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={"Vui lòng chọn sản phẩm để qua trang tiếp theo!"}
            />
          ),
          {
            position: "top-right",
          }
        );
      } else {
        navigate("/check-out");
      }
    }
  };

  // Xử lý khi thay đổi số lượng bằng nút
  const handleQuantityChange = (item, newQuantity) => {
    if (newQuantity >= 1 && newQuantity <= parseInt(item.SLCoTheXuat)) {
      const updatedCartData = cartData.map((cartItem) =>
        cartItem.id === item.id
          ? { ...cartItem, quality: newQuantity }
          : { ...cartItem }
      );

      const updateSelectedProducts = storeSelecetedProduct?.map((product) =>
        product.product_id == item.product_id
          ? {
              ...item,
              quality: newQuantity,
            }
          : product
      );

      localStorage.setItem(
        "selectedProducts",
        JSON.stringify(updateSelectedProducts)
      );

      dispatch(updateAppliedCard(null));
      setCartData(updatedCartData);
      updateItemQuantity(
        item.id,
        item.product_id,
        item.price,
        item.title,
        newQuantity
      );
    } else if (newQuantity > parseInt(item.SLCoTheXuat)) {
      updateItemQuantity(
        item.id,
        item.product_id,
        item.price,
        item.title,
        newQuantity
      );
      toast.custom((t) => (
        <CustomToast
          icon={<BiSolidErrorAlt size={25} color="red" />}
          content={"Lỗi thao tác vui lòng thử lại!"}
        />
      ));
    } else {
      toast.custom((t) => (
        <CustomToast
          icon={<BiSolidErrorAlt size={25} color="red" />}
          content={"Bạn chỉ có thể đặt tối đa 500 sản phẩm cho mỗi sản phẩm."}
        />
      ));
    }
  };

  // fetch Data Cart
  const fetchCartData = () => {
    cartService
      .getCart()
      .then((data) => {
        setCartData(data);
        setIsLoaded(true);
      })
      .catch((error) => console.error("fetch data error", error));
  };

  useEffect(() => {
    if (user) {
      fetchCartData();
    }
  }, []);

  useEffect(() => {
    if (user) {
      setSelectAllChecked(cartData?.every((item) => item.isChecked));
      // localStorage.setItem("cartItems", JSON.stringify(cartData));
    }
  }, [cartData]);

  useEffect(() => {
    dispatch(clearAppliedPromotion());
    const storedCartData =
      JSON?.parse(localStorage.getItem("cartData")) != undefined
        ? JSON?.parse(localStorage.getItem("cartData"))
        : [];
    // const storedSelectedProducts =
    //   JSON.parse(localStorage.getItem("selectedProducts")) || [];
    const updatedCartData = storedCartData.map((item) => ({
      ...item,
      isChecked: false,
    }));
    // localStorage.removeItem("selectedProducts");
    setCartData(updatedCartData);
    setSelectedProductss([]);
    setSelectAllChecked(false);
  }, []);

  const breadcrumbLink = [{ id: 1, name: "Giỏ hàng", link: `/gio-hang` }];

  return (
    <div>
      <MetaDecorator description="Giỏ hàng" title="Giỏ hàng" />

      {/* Modal xóa sản phẩm */}
      <Modal
        className="cart-delete-modal"
        show={showDialog}
        onHide={() => setShowDialog(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="dialog-header">
              <IoTrash size={20} color="red" />
              <p className="dialog-header__title">Xác nhận xóa</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="dialog-body">
          Bạn có chắc muốn xóa sản phẩm :{" "}
          <strong>
            {selectedDeleteProduct.title
              ? selectedDeleteProduct.title
              : selectedDeleteProduct.productName}
          </strong>{" "}
          này khỏi giỏ hàng?
        </Modal.Body>
        <Modal.Footer className="dialog-footer">
          <Button
            className="dialog-footer__cancle"
            variant="secondary"
            onClick={() => setShowDialog(false)}
          >
            Hủy
          </Button>
          <Button
            className="dialog-footer__confirm"
            variant="danger"
            onClick={() => handleDeleteProduct(selectedDeleteProduct)}
          >
            Xác nhận
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Khi có member đăng nhập */}
      {user ? (
        <div className="cart-wrapper" style={{ marginTop: "1rem" }}>
          {!isLoaded ? (
            <LoadingGif gifSrc={LogoGif} logoImage={LogoNK} />
          ) : (
            <>
              {Array.isArray(cartData) &&
              cartData.filter((item) => item.stock === 1).length !== 0 ? (
                <>
                  <div className="">
                    <BreadcrumbNew arrayLink={breadcrumbLink} />
                  </div>
                  <div className="cart-conatiner">
                    <div className="cart-left-container">
                      <div className="cart-conatiner-header">
                        <h5 style={{ fontWeight: 700 }}>
                          Giỏ hàng (
                          {cartData.filter((item) => item.stock === 1).length})
                        </h5>
                      </div>
                      <div className="cart-left">
                        <div className="cart-desktop">
                          <div className="cart-table-header">
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={selectAllChecked}
                              onChange={handleSelectAllChange}
                            />
                            <p className="cart-name-product-header">
                              Tên sản phẩm
                            </p>
                            <p>Đơn giá</p>
                            <p>Số lượng</p>
                            <p>Thành tiền</p>
                          </div>
                          <div>
                            {cartData
                              .filter((item) => item.stock === 1)
                              .map((item) => (
                                <div className="cart-content">
                                  <div className="cart-content-item">
                                    <input
                                      className="checkbox-product"
                                      type="checkbox"
                                      name={item.title}
                                      id={item.id}
                                      checked={item.isChecked}
                                      onChange={(e) =>
                                        handleItemCheckboxDataChange(
                                          item,
                                          e.target.checked
                                        )
                                      }
                                    />
                                    <div className="d-flex cart-content-box-product">
                                      <div>
                                        <img
                                          className="cart-image"
                                          src={`${imageBaseUrl}/uploads/${item.picture}`}
                                          alt={`${item.title}`}
                                          loading="lazy"
                                        />

                                        {/*delete product desktop for login */}
                                        <div
                                          onClick={() =>
                                            // confirmDeleteProduct(item)
                                            {
                                              setShowDialog(true);
                                              setSelectedDeleteProduct(item);
                                            }
                                          }
                                          className="delete-product text-center mt-2"
                                        >
                                          Xóa
                                        </div>
                                      </div>
                                      <div className="cart-content-title">
                                        <Link
                                          to={`/${item.friendlyUrlProduct}`}
                                          title={`${item.title}`}
                                        >
                                          <p>{item.title}</p>
                                        </Link>
                                      </div>
                                    </div>
                                    <p className="cart-item-price">
                                      {fomatNumer(
                                        item.listPrice?.length > 0
                                          ? item.listPrice[0].price
                                          : item.price
                                      )}
                                    </p>

                                    {/* nút tăng, giảm số lượng */}
                                    <div className="quantity-product">
                                      <button
                                        className="decrement-btn"
                                        onClick={() =>
                                          handleQuantityChange(
                                            item,
                                            item.quality - 1
                                          )
                                        }
                                        disabled={item.quality <= 1}
                                      >
                                        -
                                      </button>
                                      <input
                                        id={item.product_id}
                                        className="input-quantity"
                                        type="number"
                                        onChange={(e) =>
                                          handleQuantityInputChange(
                                            item,
                                            e.target.value
                                          )
                                        }
                                        value={item.quality}
                                        min={1}
                                      />
                                      <button
                                        className="increment-btn"
                                        onClick={() =>
                                          handleQuantityChange(
                                            item,
                                            item.quality + 1
                                          )
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                    <p className="total-price">
                                      {fomatNumer(item.price * item.quality)}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>

                        {/* Mobile */}
                        <div className="cart-moblie">
                          <div className="cart-moblie-header">
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={selectAllChecked}
                              onChange={handleSelectAllChange}
                            />
                            <p className="cart-name-moblie-product-header">
                              Tên sản phẩm
                            </p>
                          </div>
                          <div className="cart-content">
                            {cartData
                              .filter((item) => item.stock === 1)
                              .map((item) => {
                                return (
                                  <div className="cart-content-item-box">
                                    <div className="cart-content-item">
                                      <div className="d-flex cart-content-box-product">
                                        <input
                                          className="checkbox-product"
                                          type="checkbox"
                                          name={item.title}
                                          id={item.id}
                                          checked={item.isChecked}
                                          onChange={(e) =>
                                            handleItemCheckboxDataChange(
                                              item,
                                              e.target.checked
                                            )
                                          }
                                        />

                                        <div>
                                          <img
                                            className="cart-image"
                                            src={`${imageBaseUrl}/uploads/${item.picture}`}
                                            alt={`${item.title}`}
                                            loading="lazy"
                                          />
                                          <div
                                            onClick={() =>
                                              // confirmDeleteProduct(item)
                                              {
                                                setShowDialog(true);
                                                setSelectedDeleteProduct(item);
                                              }
                                            }
                                            className="delete-product text-center mt-2"
                                          >
                                            Xóa
                                          </div>
                                        </div>
                                        <div className="cart-content-title">
                                          <Link
                                            to={`/${item.friendlyUrlProduct}`}
                                            title={`${item.title}`}
                                          >
                                            <p>{item.title}</p>
                                          </Link>
                                          <div className="d-flex justify-content-between mt-2">
                                            <p className="price-product-mobile">
                                              {fomatNumer(item.price)}
                                            </p>
                                            <div className="quantity-product">
                                              <button
                                                className="decrement-btn"
                                                onClick={() =>
                                                  handleQuantityChange(
                                                    item,
                                                    item.quality - 1
                                                  )
                                                }
                                              >
                                                -
                                              </button>
                                              <input
                                                className="input-quantity"
                                                type="number"
                                                onChange={(e) =>
                                                  handleQuantityInputChange(
                                                    item,
                                                    e.target.value
                                                  )
                                                }
                                                value={item.quality}
                                                min={1}
                                              />
                                              <button
                                                className="increment-btn"
                                                onClick={() =>
                                                  handleQuantityChange(
                                                    item,
                                                    item.quality + 1
                                                  )
                                                }
                                              >
                                                +
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="cart-right-container">
                      <div className="div-drum"></div>
                      <div>
                        {/* <marquee
                          className="slide-promotion"
                          behavior="scroll"
                          direction="left"
                          scrollamount="10"
                        >
                          Chương trình khuyến mãi mua hàng tặng thẻ cào từ ngày
                          27/09/2023 đến 31/12/2023
                        </marquee> */}
                      </div>
                      <div className="no-use-double">
                        Chương trình khuyến mãi không áp dụng đồng thời
                      </div>

                      {/* khuyến mãi và áp dụng khuyến mãi */}
                      <div className="cart-right">
                        <div className="coupon-container">
                          <div className="coupon-container-title">
                            <h6 className="mb-0">Khuyến Mãi</h6>
                            <div
                              onClick={handleClickOpen}
                              className="coupon-container-choose-coupon"
                            >
                              <TagsFill className="me-3" />
                              Chọn hoặc nhập khuyến mãi
                            </div>
                          </div>
                          {applyCouponItem != null ? (
                            <div className="choosen-coupon">
                              {/* <div className="coupon-title">
                                <p className="coupon-name">
                                  {applyCouponItem.TenCoupon}
                                </p>{" "}
                                <div className="applied-title">
                                  đã được áp dụng (bỏ chọn)
                                </div>
                              </div> */}
                              <div className="coupon-icon-gift">
                                <GiftFill />
                              </div>
                              <div className="choosen-coupon__info">
                                <div className="choosen-coupon__top">
                                  <p className="coupon-name">
                                    {applyCouponItem.TenCoupon}
                                  </p>
                                  <p className="choosen-coupon__values">
                                    {fomatNumer(applyCouponItem?.GiaTriCoupon)}
                                  </p>
                                </div>
                                <div className="choosen-coupon__bottom">
                                  <p>
                                    HSD:{" "}
                                    {moment
                                      .unix(applyCouponItem?.EndCouponDate)
                                      .format("DD/MM/YYYY")}
                                  </p>
                                  <div
                                    onClick={() => {
                                      dispatch(clearAppliedPromotion());
                                      setApplyCouponItem(null);
                                      setCouponValue(0);
                                      // localStorage.removeItem("couponSelected");
                                    }}
                                  >
                                    Bỏ chọn
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>

                        {/* <div className="coupon-container">
                          <div className="coupon-container-title">
                            <h6 className="mb-0">Sử dụng thẻ tích điểm </h6>
                            <div
                              onClick={handleClickOpenChoosePoint}
                              className="coupon-container-choose-coupon"
                            >
                              <TagsFill className="me-3" />
                              Sử dụng
                            </div>
                          </div>
                        </div> */}
                        <div className="payment-container">
                          <h6 className="mb-0">Thanh toán</h6>
                          <div className="mt-3">
                            <div className="payment-item">
                              <div>Tổng tạm tính</div>
                              <div>{fomatNumer(updateTotalAmount())}</div>
                              {/* <div>{item.isChecked ? fomatNumer(item.price * item.quality) : '0 VNĐ'}</div> */}
                            </div>
                            <div className="payment-item">
                              <div>Giảm giá</div>
                              <div>{fomatNumer(couponValue)}</div>
                            </div>
                            <div className="payment-item mt-2">
                              <div>Thành tiền</div>
                              <div className="d-flex flex-column align-items-end">
                                <div className="float-end into-money">
                                  {fomatNumer(
                                    updateTotalAmount() - couponValue
                                  )}
                                </div>
                                <div className="payment-vat">
                                  (Đã bao gồm VAT)
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            onClick={handlechangeCheckOutPage}
                            className="continue-btn"
                          >
                            <div className="continue-btn-title">Tiếp tục</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="mt-3">
                    <Breadcrumb arrayLink={breadcrumbLink} />
                  </div>
                  <div className="cart-conatiner">
                    <div className="cart-conatiner-emty">
                      <div>
                        <CartX className="cart-conatiner-emty-icon" />
                        <div className="cart-conatiner-emty-title">
                          {" "}
                          Không có sản phẩm nào
                        </div>
                        <Link className="cart-conatiner-emty-link" to="/">
                          <ArrowLeft size={20} /> Quay lại mua hàng
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        // Khi không có member đăng nhập
        <div className="cart-wrapper">
          {cart.cartItem.length !== 0 ? (
            <>
              <div className="mt-3">
                <Breadcrumb arrayLink={breadcrumbLink} />
              </div>
              <div className="cart-conatiner">
                <div className="cart-left-container">
                  <div className="cart-conatiner-header">
                    <h5>Giỏ hàng ({cart.cartItem.length})</h5>
                  </div>
                  <div className="cart-left">
                    <div className="cart-desktop">
                      <div className="cart-table-header">
                        <input
                          type="checkbox"
                          name="selectAll"
                          checked={cart?.cartItem.every(
                            (item) => item.isChecked
                          )}
                          onChange={handleSelectAllChange}
                        />
                        <p className="cart-name-product-header">Tên sản phẩm</p>
                        <p>Đơn giá</p>
                        <p>Số lượng</p>
                        <p>Thành tiền</p>
                      </div>
                      <div>
                        {cart.cartItem.map((item) => {
                          return (
                            <div className="cart-content">
                              <div className="cart-content-item">
                                <input
                                  className="checkbox-product"
                                  type="checkbox"
                                  name={item.productName}
                                  id={item.productId}
                                  checked={item.isChecked}
                                  onChange={() => {
                                    const productid = item.productId
                                      ? item.productId
                                      : item.product_id;
                                    handleItemCheckboxChange(
                                      productid,
                                      !item.isChecked
                                    );
                                  }}
                                />

                                <div className="d-flex cart-content-box-product">
                                  <div>
                                    <img
                                      className="cart-image"
                                      src={`${imageBaseUrl}/uploads/${
                                        item.listPrice?.length > 0 &&
                                        item.listPrice[0].picture !== null
                                          ? item.listPrice[0].picture
                                          : item.pictureForDetailProduct
                                          ? item.pictureForDetailProduct
                                          : item.picture
                                      }`}
                                      alt={`${item.productName}`}
                                    />
                                    <div
                                      onClick={() => {
                                        // confirmDeleteProduct(item);
                                        {
                                          setShowDialog(true);
                                          setSelectedDeleteProduct(item);
                                        }
                                      }}
                                      className="delete-product text-center mt-2"
                                    >
                                      Xóa
                                    </div>
                                  </div>
                                  <div className="cart-content-title">
                                    <Link
                                      to={`/${item.link}`}
                                      title={`${item.productName}`}
                                    >
                                      <p>{item.productName}</p>
                                    </Link>
                                  </div>
                                </div>
                                <p className="cart-item-price">
                                  {fomatNumer(
                                    item.listPrice?.length > 0
                                      ? item.listPrice[0].price
                                      : item.price
                                  )}
                                </p>
                                <div className="quantity-product">
                                  <button
                                    className="decrement-btn"
                                    onClick={() => decrementCounter(item)}
                                  >
                                    -
                                  </button>
                                  <input
                                    className="input-quantity"
                                    type="number"
                                    onChange={(e) =>
                                      handleChange(e, item.productId)
                                    }
                                    value={item.quality}
                                    min={1}
                                  />
                                  <button
                                    className="increment-btn"
                                    onClick={() => incrementCounter(item)}
                                  >
                                    +
                                  </button>
                                </div>
                                <p className="total-price">
                                  {fomatNumer(
                                    item.listPrice && item.listPrice.length > 0
                                      ? item.listPrice[0]?.price * item.quality
                                      : item.price * item.quality
                                  )}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="cart-moblie">
                      <div className="cart-moblie-header">
                        <input
                          type="checkbox"
                          name="selectAll"
                          checked={cart?.cartItem.every(
                            (item) => item.isChecked
                          )}
                          onChange={handleSelectAllChange}
                        />
                        <p className="cart-name-moblie-product-header">
                          Tên sản phẩm
                        </p>
                      </div>
                      <div className="cart-content">
                        {cart.cartItem.map((item) => {
                          return (
                            <div className="cart-content-item-box">
                              <div className="cart-content-item">
                                <div className="d-flex cart-content-box-product">
                                  <input
                                    className="checkbox-product"
                                    type="checkbox"
                                    name={item.productName}
                                    id={item.productId}
                                    checked={item.isChecked}
                                    onChange={() => {
                                      const productid = item.productId
                                        ? item.productId
                                        : item.product_id;
                                      handleItemCheckboxChange(
                                        productid,
                                        !item.isChecked
                                      );
                                    }}
                                  />
                                  <div>
                                    <img
                                      className="cart-image"
                                      src={`${imageBaseUrl}/uploads/${item.picture}`}
                                      alt={`${item.productName}`}
                                      loading="lazy"
                                    />
                                    <div
                                      onClick={() => {
                                        // confirmDeleteProduct(item)
                                        {
                                          setShowDialog(true);
                                          setSelectedDeleteProduct(item);
                                        }
                                      }}
                                      className="delete-product text-center mt-2"
                                    >
                                      Xóa
                                    </div>
                                  </div>
                                  <div className="cart-content-title">
                                    <Link
                                      to={`/${item.link}`}
                                      title={`${item.productName}`}
                                    >
                                      <p>{item.productName}</p>
                                    </Link>
                                    <div className="d-flex justify-content-between mt-2">
                                      <p className="price-product-mobile">
                                        {fomatNumer(item.price)}
                                      </p>
                                      <div className="quantity-product">
                                        <button
                                          className="decrement-btn"
                                          onClick={() => decrementCounter(item)}
                                        >
                                          -
                                        </button>
                                        <input
                                          className="input-quantity"
                                          type="number"
                                          onChange={(e) =>
                                            handleChange(e, item.productId)
                                          }
                                          value={item.quality}
                                          min={1}
                                        />
                                        <button
                                          className="increment-btn"
                                          onClick={() => incrementCounter(item)}
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cart-right-container">
                  <div className="div-drum"></div>
                  <div>
                    <marquee
                      className="slide-promotion"
                      behavior="scroll"
                      direction="left"
                      scrollamount="10"
                    >
                      Đăng nhập để nhận nhiều ưu đãi !!!
                    </marquee>
                  </div>
                  <div className="cart-right">
                    <div className="payment-container">
                      <h5>Thanh toán</h5>
                      <div className="mt-3">
                        <div className="payment-item">
                          <div>Tổng tạm tính</div>
                          <div>{fomatNumer(totalCart)}</div>
                        </div>

                        <div className="payment-item mt-2">
                          <div>Thành tiền</div>
                          <div className="d-flex flex-column align-items-end">
                            <div className="float-end into-money">
                              {fomatNumer(totalCart - couponValue)}
                            </div>
                            <div className="payment-vat">(Đã bao gồm VAT)</div>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={handlechangeCheckOutPage}
                        className="continue-btn"
                      >
                        <div className="continue-btn-title">Tiếp tục</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mt-3">
                <Breadcrumb arrayLink={breadcrumbLink} />
              </div>
              <div className="cart-conatiner">
                <div className="cart-conatiner-emty">
                  <div>
                    <CartX className="cart-conatiner-emty-icon" />
                    <div className="cart-conatiner-emty-title">
                      {" "}
                      Không có sản phẩm nào
                    </div>
                    <Link className="cart-conatiner-emty-link" to="/">
                      <ArrowLeft size={20} /> Quay lại mua hàng
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {/* Chọn KM thẻ cào */}
      {/* <ChooseCard
        open={openChooseCard}
        close={handleCloseChooseCard}
        handlechooseCard={handlechooseCard}
        handlechooseCoupon={handlechooseCoupon}
        totalCart={updateTotalAmount()}
        cartChecks={selectedProductss}
      /> */}

      {/* Chọn KM tích điểm */}
      {/* <ChoosePoint
        open={openChoosePoint}
        close={handleCloseChoosePoint}
        totalCart={updateTotalAmount()}
      /> */}

      {/* DANH CHO SP KM */}
      <ChooseCoupon
        open={open}
        close={handleClose}
        fomatNumer={fomatNumer}
        handlechooseCard={handlechooseCard}
        handlechooseCoupon={handlechooseCoupon}
        cartChecks={selectedProductss}
        applyCouponItem={applyCouponItem}
        setApplyCouponItem={setApplyCouponItem}
        setCouponValue={setCouponValue}
      />
      <ProductPromotion open={openProductPromoton} close={handleCloseModel} />
      {/* <div>
        <Dialog open={confirmNofication} onClose={CloseDuplicateMST}>
          <DialogContent>
            <h3 className="text-center text-danger">
              Thông báo
            </h3>
            <br />
            <h3 className="text-center">
              Bạn có muốn xóa sản phẩm này ra khỏi giỏ hàng không !!!
            </h3>
            <br />
            <div
              onClick={() => {
                setDuplicateMST(false)
              }}
              className="send-and-login-btn float-end"
            >
              đóng
            </div>
          </DialogContent>
        </Dialog>
      </div> */}
      <h1 style={{ color: "transparent" }}>giỏ hàng</h1>
    </div>
  );
};

export default Cart;
