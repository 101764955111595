import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const viewedProductItemsFromLocalStorage =
  sessionStorage.getItem("viewedProductItems");
const initialState = {
  viewedProductItems: viewedProductItemsFromLocalStorage
    ? JSON.parse(viewedProductItemsFromLocalStorage)
    : [],
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    addToProductView: (state, action) => {
      const existingProductIndex = state.viewedProductItems.findIndex(
        (item) => item.productName === action.payload.productName
      );
      if (existingProductIndex >= 0) {
        return;
      }
      const tempProduct = { ...action.payload, quality: 1 };
      state.viewedProductItems.push(tempProduct);
      if (state.viewedProductItems.length > 10) {
        state.viewedProductItems.shift();
      }
      sessionStorage.setItem(
        "viewedProductItems",
        JSON.stringify(state.viewedProductItems)
      );
    },
    clearProductViewItem: (state, action) => {
      state.viewedProductItems = [];
      sessionStorage.setItem(
        "viewedProductItems",
        JSON.stringify(state.viewedProductItems)
      );
    },
  },
});

export const { addToProductView, clearProductViewItem } = productSlice.actions;
export default productSlice.reducer;
