import React, { useState, useEffect } from "react";
import "../public/footer.css";
import "../../../../component/public/footer/footer_media.css";
import {
  House,
  JournalText,
  CardChecklist,
  GearWide,
  Megaphone,
  Whatsapp,
  BoxArrowInRight,
  BoxArrowInLeft,
  Cart4,
  PcDisplay,
  TagsFill,
  PersonCircle,
  GeoAltFill,
  SendFill,
  BellFill,
  PersonPlusFill,
  EnvelopeFill,
} from "react-bootstrap-icons";
import Badge from "@mui/material/Badge";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { SiZalo } from "react-icons/si";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Logo from "../../../../component/image/logo NK.png";
import Register from "../../../modules/member/view/register";
import Notification from "../../../modules/nofication/view/nofication";
import { axiosClient, imageBaseUrl } from "../../../../config";
import toast from "react-hot-toast";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { BiSolidErrorAlt } from "react-icons/bi";

import * as AdvertiseService from "../../../services/advertiseService";
import CustomToast from "../../alertCustom";

const Footer = ({
  userName,
  supportMember,
  noficationData,
  handleNotificationClick,
}) => {
  const count = useSelector((state) => state.cart.total);
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");

  const [open, setOpen] = useState(false);
  const cart = useSelector((state) => state.cart);
  const [state, setState] = useState({
    right: false,
  });
  const [dataSocial, setDataSocial] = useState([]);

  const handleDropupClick = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOpen = (event) => {
    event.preventDefault();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();
  const handleLogout = () => {
    localStorage.removeItem("user_token");
    sessionStorage.removeItem("vtnkus");
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list_item_footer = [
    {
      id: 1,
      icon: <House size={30} className="mb-2" />,
      title: "Trang chủ",
      link: "/",
    },

    {
      id: 2,
      icon: (
        <Badge
          badgeContent={user ? count : cart.cartItem.length}
          color="primary"
        >
          <Cart4 size={30} className="mb-2" />
        </Badge>
      ),
      title: "Giỏ hàng",
      link: "/gio-hang",
    },
    {
      id: 3,
      icon: <PersonCircle size={30} className="mb-2" />,
      title: userName,
      link: "/account",
    },
  ];
  const list_footer = [
    {
      id: 2,
      icon: <PcDisplay size={30} className="mb-2" />,
      title: "Xây dựng cấu hình",
      link: "/xay-dung-cau-hinh",
    },
    {
      id: 3,
      icon: <TagsFill size={30} className="mb-2" />,
      title: "Tổng hợp khuyến mãi",
      link: "/khuyen-mai",
    },
    {
      id: 5,
      icon: <Megaphone size={30} className="mb-2" />,
      title: "Tin công nghệ",
      link: "/goc-cong-nghe/tin-cong-nghe",
    },

    // {
    //   id: 7,
    //   icon: <JournalText size={30} className="mb-2" />,
    //   title: "Tra cứu đơn hàng",
    //   link: "http://user.vitinhnguyenkim.vn/companies",
    // },
    {
      id: 8,
      icon: (
        <BoxArrowInLeft onClick={handleClickOpen} size={30} className="mb-2" />
      ),
      title: "Đăng ký",
      link: "",
    },
    {
      id: 9,
      icon: <BoxArrowInRight size={30} className="mb-2" />,
      title: "Đăng nhập",
      link: "/login",
    },
    {
      id: 10,
      icon: <Whatsapp size={30} className="mb-2" />,
      title: "Gọi hỗ trợ",
      link: `tel:${
        user && supportMember ? supportMember?.phone : "0912246137"
      }`,
    },
    {
      id: 11,
      icon: <SiZalo size={30} className="mb-2" />,
      title: "Liên hệ hỗ trợ",
      link: `https://zalo.me/${
        user && supportMember ? supportMember?.phone : "0912246137"
      }`,
    },
    {
      id: 12,
      icon: (
        <>
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              {noficationData.notificationList.filter(
                (item) => item.viewed == false
              ).length > 0 && user ? (
                <Badge
                  badgeContent={
                    noficationData.notificationList.filter(
                      (item) => !item.viewed
                    ).length
                  }
                  color="error"
                >
                  <BellFill
                    onClick={toggleDrawer("right", true)}
                    size={30}
                    className="mb-2 "
                  />
                </Badge>
              ) : (
                <BellFill size={30} className="mb-2 " />
              )}
              <SwipeableDrawer
                className="header-sidebar-swipe"
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                <h2 className="notification-title">Thông báo</h2>
                <Notification
                  noficationData={noficationData}
                  handleNotificationClick={handleNotificationClick}
                />
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </>
      ),
      title: "Thông báo",
      link: "",
    },
    {
      id: 13,
      icon: <PersonPlusFill size={30} className="mb-2" />,
      title: "Tuyển dụng",
      link: "/tuyen-dung",
      target: "_blank",
    },
  ];

  // https://zalo.me/${user && supportMember ? supportMember?.phone : '0912246137'}

  const list_footer_user = [
    {
      id: 2,
      icon: <PcDisplay size={30} className="mb-2" />,
      title: "Xây dựng cấu hình",
      link: "/xay-dung-cau-hinh",
    },
    {
      id: 3,
      icon: <TagsFill size={30} className="mb-2" />,
      title: "Tổng hợp khuyến mãi",
      link: "/khuyen-mai",
    },

    {
      id: 5,
      icon: <Megaphone size={30} className="mb-2" />,
      title: "Tin công nghệ",
      link: "/goc-cong-nghe/tin-cong-nghe",
    },

    // {
    //   id: 7,
    //   icon: <JournalText size={30} className="mb-2" />,
    //   title: "Tra cứu đơn hàng",
    //   link: "http://uservtnk.ddns.net/companies",
    // },
    {
      id: 8,
      icon: (
        <BoxArrowInLeft onClick={handleLogout} size={30} className="mb-2" />
      ),
      title: "Đăng xuẩt",
      link: "/login",
    },
    {
      id: 9,
      icon: <CardChecklist size={30} className="mb-2" />,
      title: "Quản lý đơn hàng",
      link: "/account/orders",
    },
    {
      id: 10,
      icon: <Whatsapp size={30} className="mb-2" />,
      title: "Gọi hỗ trợ",
      link: `tel:${
        user && supportMember ? supportMember?.phone : "0912246137"
      }`,
    },
    {
      id: 11,
      icon: <SiZalo size={30} className="mb-2" />,
      title: "Liên hệ hỗ trợ",
      link: `https://zalo.me/${
        user && supportMember ? supportMember?.phone : "0912246137"
      }`,
    },
    {
      id: 12,
      icon: (
        <>
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              {noficationData.notificationList.filter(
                (item) => item.viewed == false
              ).length > 0 && user ? (
                <Badge
                  badgeContent={
                    noficationData.notificationList.filter(
                      (item) => !item.viewed
                    ).length
                  }
                  color="error"
                >
                  <BellFill
                    onClick={toggleDrawer("right", true)}
                    size={30}
                    className="mb-2 "
                  />
                </Badge>
              ) : (
                <BellFill size={30} className="mb-2 " />
              )}

              <SwipeableDrawer
                className="header-sidebar-swipe"
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                <h2 className="notification-title">Thông báo</h2>
                <Notification
                  noficationData={noficationData}
                  handleNotificationClick={handleNotificationClick}
                />
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </>
      ),
      title: "Thông báo",
      link: "",
    },

    {
      id: 13,
      icon: <PersonPlusFill size={30} className="mb-2" />,
      title: "Tuyển dụng",
      link: "/tuyen-dung",
      target: "_blank",
    },
  ];

  const listSlogan = [
    {
      id: 1,
      image: `${imageBaseUrl}/uploads/weblink/ho-tro.svg`,
      placeholder: "hỗ trợ",
      title: "Hỗ trợ",
      desc: "24h/7 ngày",
    },
    {
      id: 2,
      image: `${imageBaseUrl}/uploads/weblink/thanh-toan.svg`,
      placeholder: "Thanh toán",
      title: "Thanh toán",
      desc: "tiện lợi, an toàn",
    },
    {
      id: 3,
      image: `${imageBaseUrl}/uploads/weblink/doi-tra.svg`,
      placeholder: "Đổi trả",
      title: "Đổi trả",
      desc: "miễn phí",
    },
    {
      id: 4,
      image: `${imageBaseUrl}/uploads/weblink/gia-ca.svg`,
      placeholder: "Giá cả",
      title: "Giá cả",
      desc: "cạnh tranh",
    },
    {
      id: 5,
      image: `${imageBaseUrl}/uploads/weblink/cam-ket.svg`,
      placeholder: "Cam kết",
      title: "Cam kết",
      desc: "hàng chính hãng",
    },
  ];
  const listParner = [
    {
      id: 1,
      title: "Nhà phân phối uy tín chât lượng hàng đầu",
      child: [
        {
          id: 1,
          image: `${imageBaseUrl}/uploads/weblink/_80x80___nhabanle-4.png`,
          link: "#",
        },
        {
          id: 2,
          image: `${imageBaseUrl}/uploads/weblink/_80x80___nhabanle-1.png`,
          link: "#",
        },
        {
          id: 3,
          image: `${imageBaseUrl}/uploads/weblink/_80x80___nhabanle-2.png`,
          link: "#",
        },
        {
          id: 4,
          image: `${imageBaseUrl}/uploads/weblink/_80x80___nhabanle-3.png`,
          link: "#",
        },
      ],
    },
    {
      id: 2,
      title: "Chứng nhận đối tác",
      child: [
        {
          id: 1,
          image: `/image/logo-company/asus.png`,
          link: "/gioi-thieu/chung-nhan-doi-tac",
        },
        {
          id: 2,
          image: `/image/logo-company/dell.png`,
          link: "/gioi-thieu/chung-nhan-doi-tac",
        },
        {
          id: 3,
          image: `/image/logo-company/lenovo.png`,
          link: "/gioi-thieu/chung-nhan-doi-tac",
        },
        {
          id: 4,
          image: `/image/logo-company/samsung.png`,
          link: "/gioi-thieu/chung-nhan-doi-tac",
        },
        {
          id: 5,
          image: `/image/logo-company/hp.png`,
          link: "/gioi-thieu/chung-nhan-doi-tac",
        },
      ],
    },
  ];

  const linksGroup1 = [
    { title: "Giới thiệu công ty", to: "/gioi-thieu/gioi-thieu-ve-cong-ty" },
    { title: "Dịch vụ", to: "/dich-vu" },
    { title: "Tư vấn hỏi đáp", to: "/tu-van" },
    { title: "Liên hệ và góp ý", to: "/lien-he" },
    { title: "Yêu cầu báo giá", to: "/yeu-cau-bao-gia" },
    { title: "Tuyển dụng", to: "/tuyen-dung", target: "_blank" },
  ];

  const linksGroup2 = [
    {
      title: "Quy định thanh toán",
      to: "/cac-chinh-sach-bao-hanh-va-quy-dinh/quy-dinh-thanh-toan",
    },
    {
      title: "Chính sách bảo hành",
      to: "/cac-chinh-sach-bao-hanh-va-quy-dinh/chinh-sach-bao-hanh",
    },
    {
      title: "Chính sách giao hàng",
      to: "/cac-chinh-sach-bao-hanh-va-quy-dinh/giao-hang-va-lap-dat",
    },
    {
      title: "Chính sách bảo mật TT cá nhân",
      to: "/cac-chinh-sach-bao-hanh-va-quy-dinh/chinh-sach-bao-mat-tt-ca-nhan",
    },
    {
      title: "Hướng dẫn gửi bảo hành",
      to: "/cac-chinh-sach-bao-hanh-va-quy-dinh/huong-dan-gui-bao-hanh",
    },
    {
      title: "Chính sách kiểm hàng",
      to: "/cac-chinh-sach-bao-hanh-va-quy-dinh/chinh-sach-kiem-hang",
    },
    {
      title: "Chính sách đổi sản phẩm",
      to: "/cac-chinh-sach-bao-hanh-va-quy-dinh/chinh-sach-doi-san-pham",
    },
    {
      title: "Chính sách hoàn tiền",
      to: "/cac-chinh-sach-bao-hanh-va-quy-dinh/chinh-sach-hoan-tien",
    },
  ];

  const FooterLinks = ({ links }) => (
    <div className="footer-container-list-list">
      {links.map((link, index) => (
        <div key={index}>
          <Link
            to={link.to}
            rel="nofollow"
            title={link.title}
            className="footer-container-link"
            target={link.target || "_self"}
          >
            {link.title}
          </Link>
        </div>
      ))}
    </div>
  );

  const [footerBannerData, setFooterBannerData] = useState([]);
  const fetchFooterBannerData = async () => {
    try {
      const data = await AdvertiseService.getFooterBanner();
      setFooterBannerData(data);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };

  const fetchDataSocial = async () => {
    try {
      const res = await axiosClient.get("/member/show-icon");
      if (res.data.status) {
        setDataSocial(res.data.data);
      }
    } catch (error) {
      console.error("fetch data error", error);
    }
  };
  useEffect(() => {
    fetchFooterBannerData();
    fetchDataSocial();
  }, []);

  const sendMail = async () => {
    try {
      const res = await axiosClient.post("/member/post-mail", {
        email: email,
      });
      if (res.data.status == true) {
        toast.custom(
          (t) => (
            <CustomToast
              icon={<IoIosCheckmarkCircle size={25} color="green" />}
              content={"Đã gửi thành công! "}
            />
          ),
          {
            position: "top-right",
            duration: 1000,
          }
        );
      } else {
        toast.custom((t) => (
          <CustomToast
            icon={<BiSolidErrorAlt size={25} color="red" />}
            content={"Đã xảy ra lỗi. Xin vui lòng thử lại!"}
          />
        ));
      }
    } catch (error) {
      console.error("post data error", error);
    }
  };

  return (
    <div className="footer">
      <Container>
        <div className="footer-container">
          <div className="footer-container-banner">
            <img
              className="footer-container-banner-img"
              src={`${imageBaseUrl}/uploads/${footerBannerData?.[0]?.picture}`}
              placeholder="20 năm"
              loading="lazy"
              alt="footer-banner"
            />
          </div>
        </div>
      </Container>
      <div className="footer-container-logan">
        <Container>
          <div className="footer-container-logan-list">
            {listSlogan.map((item, index) => (
              <div key={index} className="footer-container-logan-item">
                <div>
                  <img
                    className="footer-container-logan-icon"
                    src={`${item.image}`}
                    alt={item.placeholder}
                    placeholder={item.placeholder}
                    loading="lazy"
                  />
                </div>
                <div className="footer-container-logan-text">
                  <div className="footer-container-logan-title">
                    {item.title}
                  </div>
                  <div className="footer-container-logan-desc">{item.desc}</div>
                  {/* <label className='footer-container-logan-title'>{item.title}</label> */}
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>
      <Container>
        <div className="footer-container-list-link">
          <FooterLinks links={linksGroup1} />
          <FooterLinks links={linksGroup2} />
          <div className="footer-container-list-list">
            <label className="support-title">Tổng đài hỗ trợ</label>
            <ul className="support-list">
              <li className="support-list-item">CSKH: 1900 6739</li>
              {/* <li className="support-list-item">Bảo hành: 1900 6739</li> */}
            </ul>
            <div className="footer-container-list-social-network">
              {dataSocial &&
                dataSocial.length > 0 &&
                dataSocial.map((item) => (
                  <a
                    key={item.icon_id}
                    rel="nofollow"
                    title={item.title}
                    href={item.link}
                    alt={item.title}
                    target={item.target}
                  >
                    <img
                      className="item_social"
                      alt={item.title}
                      src={`${imageBaseUrl}/uploads/${item.picture}`}
                    />
                  </a>
                ))}
            </div>
          </div>
          <div className="footer-container-fb">
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fvitinhnguyenkim%3Fref%3Dembed_page&tabs=timeline&width=340&height=70&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId"
              width="340"
              height="150"
              style={{ border: "none", overflow: "hidden" }}
              loading="lazy"
              allowFullScreen
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
        <div className="footer-container-address">
          <div className="footer-container-address-item">
            <div>
              <GeoAltFill className="loaction-icon" />
            </div>
            <div className="footer-container-address-item-text">
              {/* <div>
                Địa chỉ : 245B Trần Quang Khải, Phường Tân Định, Quận 1, TP. Hồ
                Chí Minh
              </div>
              <div>Email : nhannth@nguyenkimvn.vn</div> */}
              <div className="footer-container-address-item-title">
                Công ty TNHH Vi Tính Nguyên Kim
              </div>
              <div>
                Địa chỉ : 245B Trần Quang Khải, p Tân Định, Quận 1, Tp. HCM
              </div>
              <div>
                Giấy chứng nhận đăng ký kinh doanh số 0303753468 do Sở kế hoạch
                và đầu tư Tp. HCM cấp ngày 14/4/2005
              </div>
            </div>
          </div>

          <div className="newsletter-frm">
            <div className="newsletter-icon-text">
              <div className="icon-wrapper">
                <EnvelopeFill className="newsletter-icon" />
              </div>
              <div>
                <div className="newsletter-title">Đăng ký mail</div>
                <div className="newsletter-description">
                  Nhận tin khuyến mãi, hot deal, giảm giá...
                </div>
              </div>
            </div>
            <div className="newsletter-input-btn">
              <input
                type="text"
                name="femail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                placeholder="Địa chỉ email của bạn"
              />
              <div onClick={() => sendMail()} className="sendmail-btn">
                <SendFill />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="key-Search-container">
        <Container>
          <div className="key-Search-container-list">
            <label className="key-Search-container-title">
              Tìm kiếm nhiều:{" "}
            </label>
            <ul className="key-Search-container-items">
              <Link
                title="Laptop Dell"
                to={"/laptop-dell"}
                className="key-Search-container-item"
              >
                Laptop Dell
              </Link>
              <Link
                title=" HP"
                to={"/laptop-hp"}
                className="key-Search-container-item"
              >
                Laptop HP
              </Link>
              <Link
                title="Macbook Pro"
                to={"/laptop-macbook"}
                className="key-Search-container-item"
              >
                Macbook Pro
              </Link>
              <Link
                title="Laptop giá rẻ"
                to={"/laptop"}
                className="key-Search-container-item"
              >
                Laptop giá rẻ
              </Link>
              <Link
                title="Máy chủ server"
                to={"/may-chu"}
                className="key-Search-container-item"
              >
                Máy chủ server
              </Link>
              <Link
                title="Máy tính văn phòng"
                to={"/may-tinh-van-phong"}
                className="key-Search-container-item"
              >
                Máy tính văn phòng
              </Link>
            </ul>
          </div>
        </Container>
      </div>
      <div className="footer-container-logo">
        <Container>
          <div className="footer-container-logo-partner-container">
            <div>
              <div>
                <img className="logo-img" src={Logo} alt="logo nguyên kim" />
              </div>
              <div className="logo-desc">
                Phân phối các sản phẩm công nghệ thông tin toàn diện.
              </div>
              <a href="http://online.gov.vn/Website/chi-tiet-129562">
                <div className="box-image-certificate">
                  <img
                    className="w-100 "
                    alt="certificate"
                    src={"/image/certificate-logo.jpg"}
                  />
                </div>
              </a>
            </div>
            {listParner.map((item, index) => (
              <div key={index} className="footer-container-list-partner">
                <label className="footer-container-list-partner-title">
                  {item.title}
                </label>
                <div className="footer-container-list-partner-icon">
                  {item.child.map((items, indexs) => (
                    <Link key={indexs} rel="nofollow" to={`${items.link}`}>
                      <img
                        className="footer-container-list-partner-img"
                        key={items.id}
                        alt={item.title}
                        src={items.image}
                        loading="lazy"
                      />
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>

      {/* footer di động */}
      <div className="footer-phone ">
        <div className="footer-container-fixed">
          <Container>
            {isOpen && (
              <>
                {user ? (
                  <Row className="footer-main">
                    {list_footer_user.map((item, index) => (
                      <Col key={index} xs={3} sm={3} md={3}>
                        <div className="icon-footer">
                          <Link
                            rel="nofollow"
                            title={item.title}
                            className="footer-icon-item"
                            to={item.link}
                          >
                            {item.icon}
                            <p>{item.title}</p>
                          </Link>
                        </div>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <Row className="footer-main">
                    {list_footer.map((item, index) => (
                      <Col key={index} xs={3} sm={3} md={3}>
                        <div className="icon-footer">
                          <Link
                            rel="nofollow"
                            title={item.title}
                            className="footer-icon-item"
                            to={item.link}
                          >
                            {item.icon}
                            <p>{item.title}</p>
                          </Link>
                        </div>
                      </Col>
                    ))}
                  </Row>
                )}
              </>
            )}
            <Row className="footer-main">
              {user ? (
                list_item_footer.map((item, index) => (
                  <Col key={index}>
                    <div className="icon-footer">
                      <Link
                        rel="nofollow"
                        title={item.title}
                        className="footer-icon-item"
                        to={item.link}
                      >
                        {item.icon}
                        <p>{item.title}</p>
                      </Link>
                    </div>
                  </Col>
                ))
              ) : (
                <>
                  {list_item_footer.map((item, index) => {
                    return (
                      index < 2 && (
                        <Col key={index}>
                          <div className="icon-footer">
                            <Link
                              rel="nofollow"
                              title={item.title}
                              className="footer-icon-item"
                              to={item.link}
                            >
                              {item.icon}
                              <p>{item.title}</p>
                            </Link>
                          </div>
                        </Col>
                      )
                    );
                  })}
                </>
              )}
              <Col>
                <div className="icon-footer">
                  <span
                    className="footer-icon-item"
                    title="arrowup"
                    onClick={handleDropupClick}
                  >
                    <GearWide size={40} className="arrowup" />
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Register open={open} close={handleClose} />
      </div>
    </div>
  );
};

export default Footer;
