import { axiosClient } from "../../config";

export const getNewsCategory = async () => {
  return await axiosClient
    .get("/member/news-category")
    .then((response) => {
      if (response.data.status == true) {
        return response.data.data;
      } else {
        getNewsCategory();
      }
    })
    .catch((error) => error.response);
};
