import React, { useState, useEffect } from "react";
import "../public/headerSidebar.css";
import * as cartService from "../../../services/cartService";
import { Link } from "react-router-dom";
const HeaderSidebar = () => {
  const [menu, setMenu] = useState([]);
  useEffect(() => {
    cartService
      .getCartMenu()
      .then((data) => {
        setMenu(data);
      })
      .catch((error) => console.error("fetch data error", error));
  }, []);
  return (
    <div className="header-sidebar-container">
      <div className="header-sidebar-title">Danh mục sản phẩm</div>
      {Array.isArray(menu) &&
        menu?.map((item, index) => (
          <div key={index}>
            {/* {item.parentid === 0 && item.subcategories && item.category_name !== 'Khuyến mãi vàng' ? ( */}
            <div>
              <Link
                title={item.menu_desc.title}
                to={`/danh-muc/${item.menu_desc.link}`}
                className="d-flex align-items-center header-cate-items"
              >
                {/* {item.icon} */}
                <p className="header-cate-item-title">{item.menu_desc.title}</p>
              </Link>
            </div>
            {/* ) : null} */}
          </div>
        ))}
    </div>
  );
};
export default HeaderSidebar;
