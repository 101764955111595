import React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import * as authService from "../../../services/authService";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../public/member.css";
import "../public/forget_password.css";
import MetaDecorator from "../../../../util/metaDecorator";

import toast, { Toaster } from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";

import { BiSolidErrorAlt } from "react-icons/bi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Forgotpassword = () => {
  const [email, setEmail] = useState("");
  const [showErrorUserEmail, setShowErrorUserEmail] = useState("");
  const [open, setOpen] = useState(false);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      setEmail(value);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      UserForget();
    }
  };
  const [openProdcess, setOpenProdcess] = useState(false);
  const UserForget = async () => {
    const validateEmail = /^\S+@\S+\.\S+$/.test(email);
    if (validateEmail) {
      setOpenProdcess(true);
      try {
        const res = await authService.forgetPassword({ email });
        if (res.data.status == true) {
          setShowErrorUserEmail("");
          setOpen(true);
        } else {
          toast.custom((t) => (
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={"Email không tồn tại!"}
            />
          ));
        }
      } catch (error) {
        console.error("post data error", error);
      } finally {
        setOpenProdcess(false);
      }
    } else {
      setShowErrorUserEmail("Sai định dạng Email!");
    }
  };
  return (
    <div className="forget-pass">
      <Toaster />
      <MetaDecorator title="Quên mật khẩu" description="Quên mật khẩu" />
      <Container>
        <Row>
          <Col xs={0} md={3}></Col>
          <Col xs={12} md={6}>
            <div className="columnform-forget">
              <h4 className="form-forget">Lấy lại mật khẩu</h4>
              <form onKeyDown={handleKeyDown}>
                <h6>Nhập email đăng ký </h6>
                <TextField
                  margin="dense"
                  label="Email *"
                  type="email"
                  name="email"
                  value={email}
                  fullWidth
                  onChange={handleInputChange}
                />
                <p className="error-login">{showErrorUserEmail}</p>
                <div className="form-forget-action">
                  <Link
                    rel="nofollow"
                    title="send"
                    to="/login"
                    className="send-and-login-btn"
                  >
                    Đăng nhập
                  </Link>
                  <div className="send-and-login-btn" onClick={UserForget}>
                    Gửi
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <h3>Thông tin tài khoản sẽ được gửi đến email của bạn!</h3>
            <br />
            <Link
              rel="nofollow"
              title="đăng nhập"
              autoFocus
              className="send-and-login-btn float-end"
              to="/login"
            >
              Đăng nhập
            </Link>
          </DialogContent>
        </Dialog>
      </div>
      {openProdcess && (
        <Modal
          open={openProdcess}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="paypalProcessing">
              <div className="animation">
                <div className="container">
                  <div className="balls">
                    <div className="ball ball-one" />
                    <div className="ball ball-two" />
                    <div className="ball ball-three" />
                    <div className="ball ball-four" />
                    <div className="shadow shadow-one" />
                    <div className="shadow shadow-two" />
                    <div className="shadow shadow-three" />
                    <div className="shadow shadow-four" />
                  </div>
                </div>
              </div>
              <div className="text">
                <h1>Đang xử lý</h1>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default Forgotpassword;
