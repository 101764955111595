import { axiosClient } from "../../config";

export const getAbout = async () => {
  return await axiosClient
    .get("/member/about")
    .then((res) => {
      return res.data;
    })
    .catch((error) => error.response);
};

export const getAboutDetail = async (payload) => {
  return await axiosClient
    .get("/member/about/" + payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => error.response);
};
