import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import NewsDetail from "../modules/technologyCorner/view/newsDetail";
import ProductDetail from "../modules/product-detail/view/productDetail";
import LoadingGif from "./loadingGif";
import LogoGif from "../../../src/component/image/Eclipse@1x-1.0s-200px-200px.gif";
import LogoNK from "../../../src/component/image/logo NK.png";
import { axiosClient } from "../../config";

function DynamicPage() {
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [typePage, setTypePage] = useState(null);
  const { state } = useLocation();

  useEffect(() => {
    setLoading(true);
    // Nếu `typePage` đã có giá trị từ `state`, không cần gọi API
    if (state?.type) {
      setTypePage(state.type);
    } else {
      checkTypePage();
    }
  }, [slug]);

  const checkTypePage = async () => {
    try {
      const response = await axiosClient.get(`/member/check-type/${slug}`);
      const type = response?.data?.type;

      if (type === "news" || type === "product") {
        setTypePage(type);
      } else {
        console.error("Unknown page type:", type);
      }
    } catch (error) {
      console.error("Fetch category menu error", error);
    }
  };

  if (typePage === "news") {
    return <NewsDetail loading={loading} setLoading={setLoading} news={slug} />;
  }

  if (typePage === "product") {
    return (
      <ProductDetail loading={loading} setLoading={setLoading} slug={slug} />
    );
  }

  // Nếu không xác định được loại trang, hiển thị thông báo lỗi hoặc trang 404
  return <LoadingGif gifSrc={LogoGif} logoImage={LogoNK} />;
}

export default DynamicPage;
