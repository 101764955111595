import { Clock } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import "../public/boxNews.css";
import { imageBaseUrl } from "../../../../config";
import { Card, Row, Col } from "react-bootstrap";

const BoxNews = ({ image, title, desc, link, datePost }) => {
  const momentObj = moment.unix(datePost);
  const formattedTime = momentObj.format("DD/MM/YYYY");
  return (
    <Card className="mb-3">
      <Link
        rel="nofollow"
        title={title}
        to={`/${link}`}
        state={{ type: "news" }}
        className="text-decoration-none text-dark"
      >
        <Row className="g-0">
          {/* Image Section */}
          <Col md={3} className=" ">
            <div className="p-2">
              <Card.Img
                className="w-100  "
                src={`${imageBaseUrl}/uploads/${
                  image !== "" ? image : "No_image.jpg"
                }`}
                alt={title !== "" ? title : "không có"}
                loading="lazy"
                style={{ objectFit: "cover", width: "100%", height: "130px" }}
              />
            </div>
          </Col>
          {/* Content Section */}
          <Col md={9}>
            <Card.Body>
              {/* Title */}
              <Card.Title className="mb-2 text_genaral_two_line_2">
                {title}
              </Card.Title>

              {/* Time */}
              <div className="d-flex align-items-center mb-2 box_time_news">
                <Clock className="me-1" />
                <span>{formattedTime}</span>
              </div>

              {/* Description */}
              <Card.Text className="text_genaral_two_line_2">
                {desc && desc}
              </Card.Text>
            </Card.Body>
          </Col>
        </Row>
      </Link>
    </Card>
  );
};
export default BoxNews;
