import { Link, useNavigate } from "react-router-dom";
import "../../public/menu/subMenu.css";
const SubMenu = ({ subItem, categoryParentLink, service }) => {
  return (
    <div className="sub-menu-container">
      {subItem.map((item) => {
        return (
          <div key={item.menu_id} className="sub-menu-container-item p-3">
            <Link title={item.menu_desc.title} to="" className="sub-menu-title">
              {item.menu_desc.title}
            </Link>

            <div className="mt-3">
              {item.parentx
                .filter((item) => item.menu_desc.link != "#")
                .map((items, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex flex-column text-black sub-menu-item"
                    >
                      <Link
                        title={items.menu_desc.title}
                        to={
                          service != 848
                            ? `/${`danh-muc/${items.menu_desc.link}`}`
                            : `/dich-vu/${items.menu_desc.link}`
                        }
                        className="sub-menu-item-title"
                      >
                        {items.menu_desc.title}
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default SubMenu;
