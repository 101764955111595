import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import moment from "moment";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { GiftFill } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAppliedPromotion,
  updateAppliedCard,
  clearAppliedPromotion,
} from "./cartSlice";
import { axiosClient } from "../../../../config";
import "../public/chooseCoupon.css";

import toast, { Toaster } from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";

import { BiSolidErrorAlt } from "react-icons/bi";

const ChooseCoupon = ({
  open,
  close,
  fomatNumer,
  handlechooseCoupon,
  cartChecks,
  handlechooseCard,
  applyCouponItem,
  setApplyCouponItem,
  setCouponValue,
}) => {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState([]);
  const [couponTemp, setCouponTemp] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    try {
      const res = await axiosClient.get("/member/coupon");

      if (res.data.status) {
        setCoupon(res.data.data);
        setCouponTemp(res.data.data);
      }
    } catch (error) {
      console.error("fetch data error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (applyCouponItem) {
      const applyProductCode = [applyCouponItem?.MaKhoSPApdung]; // mã hàng áp dụng
      const applyCategory = applyCouponItem?.categoryName; // tên nghành hàng
      const applyCategoryParent = applyCouponItem?.friendlyUrlCat; // url nghành hàng
      const applyBrand = applyCouponItem?.brandName; // tên thương hiệu

      // check mã hàng áp dụng
      const checkProductCode = isSubset(
        cartChecks.map((item) => item.macn),
        applyProductCode
      );

      // check nghành hàng
      const checkCategory = isSubset(
        cartChecks.map((item) => item.cateChild),
        applyCategory
      );

      // check theo url nhóm nghành
      const checkCategoryParent = isSubset(
        cartChecks.map((item) => item.cat_name_parent),
        applyCategoryParent
      );

      // check theo
      const checkBrand = isSubset(
        cartChecks.map((item) => item.brand_name),
        applyBrand
      );

      if (
        checkProductCode ||
        (applyCategory?.length === 0 &&
          applyBrand?.length === 0 &&
          applyCategoryParent?.length === 0)
      ) {
        return;
      } else {
        if (checkCategory || checkBrand) {
          return;
        } else {
          dispatch(clearAppliedPromotion());
          setApplyCouponItem(null);
          setCouponValue(0);
        }
      }
    }
  }, [cartChecks, applyCouponItem]);

  // kiểm tra thương hiệu của sản phẩm trong giỏ
  const isSubset = (cart, brand) => {
    const isSubset = cart.some((cartBrand) => {
      return brand.some((brandItem) => cartBrand === brandItem);
    });
    return isSubset;
  };

  // tính tổng tiền (km -> giảm)
  const subTotal = () => {
    let total = 0;
    cartChecks.map((item) => {
      total += item.price * item.quality;
    });
    return total;
  };

  const totalCart = subTotal();
  // Hàm call khi nhấn áp dụng
  const handleApllyCoupon = (coupon) => {
    const checkCouponAndProceed = () => {
      if (cartChecks.length === 0) {
        toast.custom((t) => (
          <CustomToast
            icon={<BiSolidErrorAlt size={25} color="red" />}
            content={"Không có sản phẩm nào chọn để áp dụng mã giảm!"}
          />
        ));
        return;
      }

      const applyProductCode = [coupon.MaKhoSPApdung]; // mã hàng áp dụng
      const applyCategory = coupon.categoryName; // tên nghành hàng
      const applyCategoryParent = coupon.friendlyUrlCat; // url nghành hàng
      const applyBrand = coupon.brandName; // tên thương hiệu

      // check mã hàng áp dụng
      const checkProductCode = isSubset(
        cartChecks.map((item) => item.macn),
        applyProductCode
      );

      // check nghành hàng
      const checkCategory = isSubset(
        cartChecks.map((item) => item.cateChild),
        applyCategory
      );

      // check theo url nhóm nghành
      // const checkCategoryParent = isSubset(
      //   cartChecks.map((item) => item.cat_name_parent),
      //   applyCategoryParent
      // );

      // check theo
      const checkBrand = isSubset(
        cartChecks.map((item) => item.brand_name),
        applyBrand
      );

      if (coupon.dataCouponDesc[0].SoLanConLaiDes > 0) {
        if (totalCart >= coupon.DonHangChapNhanTu) {
          if (
            checkProductCode ||
            (applyCategory.length === 0 &&
              applyBrand.length === 0 &&
              applyCategoryParent.length === 0)
          ) {
            dispatch(updateAppliedPromotion(coupon));
            handlechooseCoupon(coupon);
            close();
          } else {
            if (checkCategory || checkBrand) {
              dispatch(updateAppliedPromotion(coupon));
              handlechooseCoupon(coupon);
              close();
            } else {
              toast.custom((t) => (
                <CustomToast
                  icon={<BiSolidErrorAlt size={25} color="red" />}
                  content={
                    "Khuyến mãi không phù hợp với đơn hàng này vui lòng chọn khuyến mãi khác!"
                  }
                />
              ));
            }
          }
        } else {
          toast.custom((t) => (
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={"Giá trị đơn hàng không đủ để áp dụng!"}
            />
          ));
        }
      } else {
        toast.custom((t) => (
          <CustomToast
            icon={<BiSolidErrorAlt size={25} color="red" />}
            content={
              "Mã khuyến mãi đã hết số lần sử dụng. Vui lòng chọn mã khuyến mãi khác!"
            }
          />
        ));
      }
    };

    // không áp dụng km đồng thời
    if (cart.appliedCard !== null) {
      const result = window.confirm(
        "Khuyến mãi này không áp dụng chung với những mã khuyến mãi khác. Bạn có muốn thay đổi sang mã khuyến mãi khác không?"
      );

      if (result) {
        dispatch(updateAppliedCard(null));
        handlechooseCard(null);
        checkCouponAndProceed();
      }
    } else {
      checkCouponAndProceed();
    }
  };

  // search theo mã đợt phát hành

  const handleSearchInputChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (!value || value.trim() === "") {
      setCoupon(couponTemp.flat());
      return;
    }

    const updateDataSearch = couponTemp.flat().filter((item) => {
      const title = item.TenCoupon && item.TenCoupon.toLowerCase();
      const searchValue = value.toLowerCase();
      return title && title.includes(searchValue);
    });

    setCoupon(updateDataSearch);
  };

  return (
    <>
      <Dialog fullWidth={"md"} open={open} onClose={close}>
        <div className="choose-coupon-model">
          <DialogTitle className="choose-coupon-title" id="alert-dialog-title">
            Khuyến mãi và mã giảm giá
          </DialogTitle>
          <DialogContent>
            <div>
              <div className="choose-coupon-box-search">
                <div className="box-search">
                  <div className="search-input">
                    <input
                      type="text"
                      name="discount-code"
                      placeholder="Mã giảm giá/phiếu mua hàng"
                      value={searchTerm}
                      onChange={handleSearchInputChange}
                    />
                  </div>
                </div>
                <div className="apply-btn">Áp dụng</div>
              </div>
              <div className="choose-coupon-content">
                {coupon?.filter(
                  (item) =>
                    item != "" &&
                    item.couponType == 0 &&
                    item.dataCouponDesc[0].SoLanConLaiDes !== 0
                ).length != 0 ? (
                  <p className="choose-coupon-content-title">
                    Chọn 1 khuyến mãi
                  </p>
                ) : (
                  <p className="choose-coupon-content-title">
                    Không có mã khuyến mãi nào
                  </p>
                )}
                <div className="coupon-item-container">
                  {coupon
                    ?.filter(
                      (item) =>
                        item != "" &&
                        item.couponType == 0 &&
                        item.dataCouponDesc[0].SoLanConLaiDes !== 0
                    )
                    .map((item) => {
                      // const momentObj = moment.unix(item?.EndCouponDate);
                      const momentObj = moment.unix(item?.EndCouponDate);
                      const formattedTime = momentObj.format("DD-MM-YYYY");
                      return (
                        <div key={item?.id} className="coupon-item">
                          <div>
                            <div className="coupon-icon-gift">
                              <GiftFill />
                            </div>
                          </div>
                          <div className="coupon-item-name">
                            <div>
                              <p className="coupon-item__name">
                                {item?.TenCoupon}
                              </p>
                              <p className="coupon-item__desc">
                                Điều kiện: {item?.DesCoupon}
                              </p>
                              <p className="coupon-item__minimum-order">
                                Đơn tối thiểu{" "}
                                {fomatNumer(item?.DonHangChapNhanTu)}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="coupon-item__expire">
                                HSD: {formattedTime}
                              </p>
                              {cart.appliedPromotion != null ? (
                                <>
                                  {cart.appliedPromotion?.id == item?.id ? (
                                    // <p className="apply-coupon">
                                    //   Đã áp dụng mã khuyến mãi này
                                    // </p>
                                    <div
                                      style={{
                                        fontSize: "13px",
                                        color: "rgb(25, 144, 255)",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        dispatch(clearAppliedPromotion());
                                        setApplyCouponItem(null);
                                        setCouponValue(0);
                                        // localStorage.removeItem(
                                        //   "couponSelected"
                                        // );
                                      }}
                                    >
                                      Bỏ chọn
                                    </div>
                                  ) : (
                                    <p
                                      onClick={() => handleApllyCoupon(item)}
                                      className="apply-coupon"
                                    >
                                      Áp dụng
                                    </p>
                                  )}
                                </>
                              ) : (
                                <p
                                  onClick={() => handleApllyCoupon(item)}
                                  className="apply-coupon"
                                >
                                  Áp dụng
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div>
              <div onClick={close} className="close-choose-coupon-model">
                Đóng
              </div>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default ChooseCoupon;
