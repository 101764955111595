import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import NotFound404 from "../../../image/not-found.jpg";
import "../public/notFound.css";

const NotFound = () => {
  return (
    <div>
      <Container>
        <div className="not-found-container">
          <div>
            <div>
              <img src={NotFound404} />
            </div>
            <Link className="back-btn" to="/">
              Quay lại trang chủ
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NotFound;
