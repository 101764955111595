import { Container, Image, Form, Col, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import "../public/hireHome.css";
import SearchJobs from "../views/searchJobs";
import HotJobs from "../views/hotJobs";
import CompanyIntroduction from "../views/companyIntroduction";
import AlbumImageList from "../views/albumImageList";
import RewardsBenefit from "./rewardsBenefit";
import WeHire from "./weHire";
import TextAmination from "./textAmination";
import { axiosClient } from "../../../../config";
import { useEffect, useState } from "react";

import { getImageBannerHire } from "../../../services/advertiseService";

const bannerData = [
  {
    id: 1,
    url: "https://image.talentnetwork.vn/vitinhnguyenkim///news/2023/07/06/1688640792_1920x750.jpg",
  },
  {
    id: 2,
    url: "https://image.talentnetwork.vn/vitinhnguyenkim///news/2023/07/06/1688640798_1920x750-2.jpg",
  },
  {
    id: 3,
    url: "https://image.talentnetwork.vn/vitinhnguyenkim///news/2023/07/06/1688640810_1920x750-4.jpg",
  },
  {
    id: 4,
    url: "https://image.talentnetwork.vn/vitinhnguyenkim///news/2023/07/06/1688640804_1920x750-3-.jpg",
  },
];

function HireHome() {
  const [listJobs, setListJobs] = useState([]);
  const [nameJob, setNameJob] = useState("");
  const [vacancies, setVacancies] = useState("");
  const [checkSearch, setCheckSearch] = useState(true);
  const [page, setPage] = useState(1);
  const [listBanner, setListBanner] = useState([]);
  const [loadding, setLoadding] = useState(false);
  const fetchDataJobHire = async () => {
    try {
      const res = await axiosClient.get(
        `/member/show-hire-post?hire_cate_id=${vacancies}&name=${nameJob}&page=${page}`
      );
      setListJobs(res.data.data);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };

  useEffect(() => {
    setLoadding(true);

    getImageBannerHire()
      .then((data) => {
        setLoadding(false);
        setListBanner(data);
      })
      .catch((error) => console.error("fetch data error", error));
  }, []);

  useEffect(() => {
    fetchDataJobHire();
  }, [checkSearch, page]);

  return (
    <div className="hire-wrapper">
      {loadding ? (
        <></>
      ) : (
        <Container fluid>
          {/* banner */}
          <Row className="banner_hire">
            <Carousel fade={true}>
              {bannerData &&
                bannerData.length > 0 &&
                bannerData.map((item) => (
                  <Carousel.Item key={item.id}>
                    <div className="box_img_banner_hire">
                      <TextAmination />
                      <Image className="w-100 h-100" src={item.url} fluid />
                    </div>
                  </Carousel.Item>
                ))}
            </Carousel>
          </Row>

          <SearchJobs
            checkSearch={checkSearch}
            setCheckSearch={setCheckSearch}
            setVacancies={setVacancies}
            setNameJob={setNameJob}
            setListJobs={setListJobs}
          />

          <HotJobs
            itemBanner={listBanner[0]}
            setPage={setPage}
            page={page}
            listJobs={listJobs}
            setListJobs={setListJobs}
          />

          <CompanyIntroduction itemBanner={listBanner[3]} />
          <AlbumImageList />
          <RewardsBenefit itemBanner={listBanner[1]} />
          <WeHire />
        </Container>
      )}
    </div>
  );
}

export default HireHome;
