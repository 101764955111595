import { memo } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap/esm";
import { IoMailOpenOutline } from "react-icons/io5";
import { PiFolderUserDuotone } from "react-icons/pi";
import { MdOutlineContactPhone } from "react-icons/md";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { FaCloudUploadAlt } from "react-icons/fa";
import { CgFileDocument } from "react-icons/cg";
import { TiDocumentText } from "react-icons/ti";
import { FaRegCheckCircle } from "react-icons/fa";

import { useState } from "react";
import { useFormik } from "formik";

import axios from "axios";
import { imageBaseUrl } from "../../../../config";

function ModalApplyJob({ setShow, show, idJob, setShowSnackBar }) {
  const allowedExtensions = /(\.pdf|\.docx|\.doc)$/i;

  const [nameFile, setNameFile] = useState("");
  const [nameCandidateInformation, setNameCandidateInformation] = useState("");
  const [valueErr, setValueErr] = useState({
    email: "",
    fileCV: "",
    fileCandidate: "",
    fullName: "",
    letterIntroduce: "",
    numberPhone: "",
  });

  const handleCloseModal = () => setShow(false);

  const openFileCV = () => {
    const fileInput = document.getElementById("formFileSm");
    if (fileInput) {
      fileInput.click();
    }
  };

  const checkIsEmptyInValueErr = (value) => {
    // Lấy tất cả các giá trị của object valueErr
    const values = Object.values(value);

    // Kiểm tra xem mọi giá trị trong mảng values có đều là chuỗi rỗng hay không
    return values.every((val) => val === "" || val === false);
  };

  const openFileCandidate = () => {
    const fileInput = document.getElementById("formFileSmCandidate");
    if (fileInput) {
      fileInput.click();
    }
  };

  function handleChangeCV(event) {
    if (!allowedExtensions.exec(event?.target?.files[0]?.name)) {
      formik.setFieldValue("fileCV", false);
    } else {
      setValueErr((prev) => ({
        ...prev,
        fileCV: "",
      }));
      formik.setFieldValue("fileCV", event?.target?.files[0]);
      setNameFile(event?.target?.files[0]?.name);
    }
  }

  function handleChangeCandidate(event) {
    if (!allowedExtensions.exec(event?.target?.files[0]?.name)) {
      formik.setFieldValue("fileCandidate", false);
    } else {
      setValueErr((prev) => ({
        ...prev,
        fileCandidate: "",
      }));
      formik.setFieldValue("fileCandidate", event.target.files[0]);
      setNameCandidateInformation(event?.target?.files[0]?.name);
    }
  }

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      numberPhone: "",
      fileCV: "",
      fileCandidate: "",
      letterIntroduce: "",
      idJob: idJob,
    },
    onSubmit: async (values) => {
      if (checkIsEmptyInValueErr(valueErr)) {
        try {
          const res = await axios.post(
            imageBaseUrl + "/api/create-candidate",
            values,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              responseType: "blob",
            }
          );

          if (res.data.status) {
            setShowSnackBar({
              open: true,
              status: res.data.status,
            });
          }
        } catch (error) {
          console.error("post data error", error);
        } finally {
          setShow(false);
        }
      }
    },

    validate: (values) => {
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      const regexNumber = /^\d+$/;

      if (values.fullName.length <= 0) {
        setValueErr((prev) => ({
          ...prev,
          fullName: "Họ và tên không được bỏ trống!",
        }));
      } else {
        setValueErr((prev) => ({
          ...prev,
          fullName: "",
        }));
      }
      if (values.email.length <= 0) {
        setValueErr((prev) => ({
          ...prev,
          email: "Email không được bỏ trống!",
        }));
      } else if (!regexEmail.test(values.email)) {
        setValueErr((prev) => ({
          ...prev,
          email: "Email chưa đúng định dạng!",
        }));
      } else {
        setValueErr((prev) => ({
          ...prev,
          email: "",
        }));
      }

      if (values.numberPhone.length <= 0) {
        setValueErr((prev) => ({
          ...prev,
          numberPhone: "Số điện thoại không được bỏ trống!",
        }));
      } else if (!regexNumber.test(values.numberPhone)) {
        setValueErr((prev) => ({
          ...prev,
          numberPhone: "Số điện thoại chỉ bao gồm kí tự số!",
        }));
      } else {
        setValueErr((prev) => ({
          ...prev,
          numberPhone: "",
        }));
      }
      if (values.fileCV <= 0 && values.fileCV !== false) {
        setValueErr((prev) => ({
          ...prev,
          fileCV: "CV ứng viên không được bỏ trống!",
        }));
      } else if (values.fileCV == false) {
        setValueErr((prev) => ({
          ...prev,
          fileCV: "CV ứng viên chỉ nhận file PDF, DOC và DOCX!",
        }));
      } else {
        setValueErr((prev) => ({
          ...prev,
          fileCV: "",
        }));
      }

      if (values.fileCandidate <= 0 && values.fileCandidate !== false) {
        setValueErr((prev) => ({
          ...prev,
          fileCandidate: "Phiếu thông tin ứng viên không được bỏ trống !",
        }));
      } else if (values.fileCandidate == false) {
        setValueErr((prev) => ({
          ...prev,
          fileCandidate:
            "Phiếu thông tin ứng viên chỉ nhận file PDF, DOC và DOCX!",
        }));
      } else {
        setValueErr((prev) => ({
          ...prev,
          fileCandidate: "",
        }));
      }
    },
  });

  return (
    <>
      <Modal
        animation={true}
        show={show}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Ứng tuyển
            <span className="name_job_modal">
              Nhân Viên Kinh Doanh - Tư Vấn Tuyển Sinh
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-2">
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group
              as={Row}
              className="mb-1"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="12" className="py-1">
                <div className="box_title_modal_aplly">
                  <MdOutlineDriveFileRenameOutline className="me-2" />
                  <span> Họ và tên (*) </span>
                </div>
              </Form.Label>

              <Col sm="12">
                <Form.Control
                  name="fullName"
                  type="text"
                  size="sm"
                  onChange={formik.handleChange}
                  value={formik.values.fullName}
                  className="w-100"
                  placeholder=" Họ và tên đệm"
                />
              </Col>
              {valueErr.fullName ? (
                <span className="text_err">{valueErr.fullName}</span>
              ) : (
                <span className="text_err"></span>
              )}
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-1"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="12" className="py-1">
                <div className="box_title_modal_aplly">
                  <MdOutlineAlternateEmail className="me-2" />
                  <span> Email (*) </span>
                </div>
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  size="sm"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className="w-100"
                  type="email"
                  name="email"
                  placeholder="Email "
                />
              </Col>
              {valueErr.email ? (
                <span className="text_err">{valueErr.email}</span>
              ) : (
                <span className="text_err"></span>
              )}
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-1"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="12" className="py-1">
                <div className="box_title_modal_aplly">
                  <MdOutlineContactPhone className="me-2" />
                  <span> Số điện thoại (*) </span>
                </div>
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  pattern="^-?[0-9]\d*\.?\d*$"
                  name="numberPhone"
                  required
                  size="sm"
                  onChange={formik.handleChange}
                  value={formik.values.numberPhone}
                  className="w-100"
                  type="text"
                  placeholder="Số điện thoại"
                />
              </Col>
              {valueErr.numberPhone ? (
                <span className="text_err">{valueErr.numberPhone}</span>
              ) : (
                <span className="text_err"></span>
              )}
            </Form.Group>

            <Form.Group controlId="formFileSm" className="mb-1">
              <Form.Label className="file_cv py-1" column sm="12">
                <div className="box_title_modal_aplly">
                  <PiFolderUserDuotone className="me-1" />
                  <span>CV ứng tuyển (*) </span>
                </div>
              </Form.Label>

              <Form.Control
                name="fileCV"
                required
                onChange={handleChangeCV}
                accept=".doc, .docx, .pdf"
                className="w-100 input_file_cv"
                type="file"
                size="sm"
              />

              <div className="box_btn_click_file">
                <Row>
                  <Col className=" col-12  text-center d-flex align-items-center justify-content-center">
                    <FaCloudUploadAlt className={`icon_upload `} />
                    <span className={`text_use_upload `}>
                      Tải CV lên từ máy tính
                    </span>
                  </Col>
                  <Col className=" col-12 ">
                    <div className="text_box_upload">
                      <span>
                        Hỗ trợ định dạng .doc, .docx, pdf có kích thước dưới 5MB
                      </span>
                    </div>
                  </Col>
                  <Col className=" col-12 mt-3">
                    <div className="box_btn_click_choose text-center p-1 d-flex justify-content-center d-flex align-items-center">
                      {nameFile && (
                        <div className="name_file_selected">
                          <div className="d-flex align-items-center">
                            <CgFileDocument
                              className={`${nameFile ? "color_success" : ""}`}
                            />

                            <span
                              className={`name_file ${
                                nameFile ? "color_success" : ""
                              }`}
                            >
                              {nameFile}{" "}
                            </span>
                          </div>
                        </div>
                      )}

                      <span className="btn_click_choose" onClick={openFileCV}>
                        Chọn CV
                      </span>
                    </div>
                  </Col>

                  {valueErr.fileCV && (
                    <Col className="col-12 text-center">
                      <span className="text_err">{valueErr.fileCV}</span>
                    </Col>
                  )}
                </Row>
              </div>
            </Form.Group>

            <Form.Group controlId="formFileSmCandidate" className="mb-1">
              <Form.Label className="file_cv py-1" column sm="12">
                <div className="box_title_modal_aplly">
                  <PiFolderUserDuotone className={`icon_swoosh `} />
                  <span>Phiếu thông tin ứng viên (*) </span>
                </div>
              </Form.Label>

              <Form.Control
                name="fileCandidate"
                required
                onChange={handleChangeCandidate}
                accept=".doc, .docx, .pdf"
                className="w-100 input_file_cv"
                type="file"
                size="sm"
              />

              <div className="box_btn_click_file" onClick={openFileCandidate}>
                <Row>
                  <Col className=" col-12  text-center d-flex align-items-center justify-content-center">
                    <TiDocumentText
                      className={`icon_upload ${
                        nameCandidateInformation ? "color_success" : ""
                      }`}
                    />
                    {nameCandidateInformation ? (
                      <>
                        <span className="text_use_upload_2">
                          {nameCandidateInformation}
                          <FaRegCheckCircle className={`ms-2 icon_swoosh `} />
                        </span>
                      </>
                    ) : (
                      <>
                        {valueErr.fileCandidate ? (
                          <span className="text_err">
                            {valueErr.fileCandidate}
                          </span>
                        ) : (
                          <span className="text_use_upload">
                            Tải phiếu thông tin ứng viên
                          </span>
                        )}
                      </>
                    )}
                  </Col>
                  <Col className=" col-12 ">
                    <div className="text_box_upload">
                      <span>
                        Hỗ trợ định dạng .doc, .docx, pdf có kích thước dưới 5MB
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form.Group>

            <Form.Group as={Row} className="mb-1">
              <Form.Label column sm="12" className="py-1">
                <div className="box_title_modal_aplly">
                  <IoMailOpenOutline className="me-1" />
                  <span>Thư giới thiệu</span>
                </div>
              </Form.Label>

              <Col sm="12">
                <Form.Control
                  onChange={formik.handleChange}
                  value={formik.values.letterIntroduce}
                  name="letterIntroduce"
                  as="textarea"
                  className="w-100 letters_introduce"
                  rows={5}
                  placeholder="Viết giới thiệu ngắn gọn về bản thân (điểm mạnh, điểm yếu) 
    và nêu rõ mong muốn, lý do bạn ứng tuyển vào vị trí này"
                />
              </Col>
            </Form.Group>

            <Button type="submit" className="btn_submit_apply_job">
              Nộp hồ sơ ứng tuyển
            </Button>

            <Button onClick={handleCloseModal} className="btn_cancel_apply_job">
              Huỷ
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default memo(ModalApplyJob);
