import React, { useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Select from "react-select";
import "../public/register.css";
import { Col, Row } from "react-bootstrap";
import { Eye, EyeSlash } from "react-bootstrap-icons";

import { province, province as provinces } from "../public/province";
import { axiosClient } from "../../../../config";
import CustomToast from "../../../client_component/alertCustom";
import { BiSolidErrorAlt } from "react-icons/bi";
import toast from "react-hot-toast";
import LoadingGif from "../../../client_component/loadingGif";

import LogoGif from "../../../../component/image/Eclipse@1x-1.0s-200px-200px.gif";
import LogoNK from "../../../../component/image/logo NK.png";

import LogoThankyou from "../../../../../src/component/image/nk viền.png";

const RegisterForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isNotify, setIsNotify] = useState(false);

  const [companyAddressDetail, setCompanyAddressDetail] = useState({
    province: "",
    district: "",
    ward: "",
  });

  const [deliveryAddressDetail, setDeliveryAddressDetail] = useState({
    province: "",
    district: "",
    ward: "",
  });

  const initialValues = {
    username: "",
    password: "",
    confirmPassword: "",
    representativeName: "",
    email: "",
    phone: "",
    companyName: "",
    taxCode: "",
    invoiceEmail: "",
    companyPhone: "",
    companyAddress: "",
    deliveryAddress: "",
    agreePolicy: false,
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .min(6, "Tên đăng nhập phải có ít nhất 6 ký tự")
      .required("Tên đăng nhập là bắt buộc"),
    password: Yup.string()
      .min(6, "Mật khẩu phải có ít nhất 6 ký tự")
      .required("Mật khẩu là bắt buộc"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Mật khẩu xác nhận không khớp")
      .required("Xác nhận mật khẩu là bắt buộc"),
    representativeName: Yup.string().required("Tên người đại diện là bắt buộc"),
    email: Yup.string()
      .email("Email không hợp lệ")
      .required("Email là bắt buộc"),
    phone: Yup.string()
      .matches(/^\d{9,12}$/, "Số điện thoại không hợp lệ")
      .required("Số điện thoại là bắt buộc"),
    companyName: Yup.string().required("Tên công ty là bắt buộc"),
    taxCode: Yup.string().required("Mã số thuế là bắt buộc"),
    invoiceEmail: Yup.string()
      .email("Email không hợp lệ")
      .required("Email nhận hóa đơn là bắt buộc"),
    companyPhone: Yup.string()
      .matches(/^\d{9,12}$/, "Số điện thoại công ty không hợp lệ")
      .required("Số điện thoại công ty là bắt buộc"),
    agreePolicy: Yup.boolean()
      .oneOf([true], "Bạn phải đồng ý với chính sách của công ty")
      .required("Bạn phải đồng ý với chính sách của công ty"),
    companyAddress: Yup.string().required("Địa chỉ công ty là bắt buộc"),
    deliveryAddress: Yup.string().required("Địa chỉ nhận hàng là bắt buộc"),
    nameReceiver: Yup.string().required("Họ và tên người nhận là bắt buộc"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setIsLoading(true);
      const dataSubmit = {
        username: values.username,
        password: values.password,
        full_name: values.representativeName,
        email: values.email,
        phone: values.phone,
        address: values.deliveryAddress,
        ward: deliveryAddressDetail.ward.label,
        district: deliveryAddressDetail.district.label,
        city_province: deliveryAddressDetail.province.label,
        tencongty: values.companyName,
        masothue: values.taxCode,
        emailcty: values.invoiceEmail,
        diachicongty: `${values.companyAddress}, ${companyAddressDetail.ward.label}, ${companyAddressDetail.district.label}, ${companyAddressDetail.province.label}`,
        sdtcongty: values.phone,
        nameReceiver: values.nameReceiver,
        status: 2,
      };

      const response = await axiosClient.post("/register", dataSubmit);
      if (response.data.status === true) {
        resetForm();
        setCompanyAddressDetail({
          province: "",
          district: "",
          ward: "",
        });
        setDeliveryAddressDetail({
          province: "",
          district: "",
          ward: "",
        });
        setIsNotify(true);
      } else {
        if (response.data.message == "error") {
          toast.custom((t) => (
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={"Email đăng ký đã tồn tại. Vui lòng thử email khác!"}
            />
          ));
        } else if (response.data.message == "existMST") {
          toast.custom((t) => (
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={"Mã số thuế đã tồn tại!"}
            />
          ));
        } else if (response.data.message == "existUserName") {
          toast.custom((t) => (
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={"Tên đăng nhập đã tồn tại!"}
            />
          ));
        } else {
          toast.custom((t) => (
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={"Đã xảy ra lỗi. Xin vui lòng thử lại!"}
            />
          ));
        }
      }
    } catch (error) {
      console.error("Post register form error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddressChange = (type, level, selectedValue) => {
    if (type === "company") {
      setCompanyAddressDetail((prev) => ({
        ...prev,
        [level]: selectedValue,
        ...(level === "province" && { district: "", ward: "" }),
        ...(level === "district" && { ward: "" }),
      }));
    } else if (type === "delivery") {
      setDeliveryAddressDetail((prev) => ({
        ...prev,
        [level]: selectedValue,
        ...(level === "province" && { district: "", ward: "" }),
        ...(level === "district" && { ward: "" }),
      }));
    }
  };

  const handleClose = () => {
    setIsNotify(false);
  };

  return (
    <div className="register-form__wrapper">
      <div className="register-form__heading">Đăng ký</div>
      <div className="register-form__subheading">
        Đăng ký để trở thành đối tác của Nguyên Kim
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, resetForm }) => (
          <Form className="register-form__container">
            <Row>
              <Col md={6} className="user-form">
                <h5>Thông tin tài khoản / Người đại diện</h5>

                <div className="register-form__input">
                  <label>Tên đăng nhập</label>
                  <div>
                    <Field name="username" type="text" />
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="register-form__input">
                  <label>Mật khẩu</label>
                  <div>
                    <div className="d-flex">
                      <Field
                        name="password"
                        type={showPassword ? "text" : "password"}
                      />
                      <Button
                        style={{ fontSize: 20 }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <EyeSlash /> : <Eye />}
                      </Button>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="register-form__input">
                  <label>Xác nhận mật khẩu</label>
                  <div>
                    <div className="d-flex">
                      <Field
                        name="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                      />
                      <Button
                        style={{
                          fontSize: 20,
                        }}
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? <EyeSlash /> : <Eye />}
                      </Button>
                    </div>
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="register-form__input">
                  <label>Họ tên người đại diện</label>
                  <div>
                    <Field name="representativeName" type="text" />
                    <ErrorMessage
                      name="representativeName"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="register-form__input">
                  <label>Email</label>
                  <div>
                    <Field name="email" type="email" />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="register-form__input">
                  <label>Số điện thoại</label>
                  <div>
                    <Field name="phone" type="text" />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <Row item xs={12}>
                  <Typography variant="h6">Thông tin nhận hàng</Typography>
                  <div className="register-form__input">
                    <label>Họ và tên người nhận</label>
                    <div>
                      <Field name="nameReceiver" type="text" />
                      <ErrorMessage
                        name="nameReceiver"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>

                  <div className="register-form__input">
                    <label>Địa chỉ nhận hàng</label>
                    <div>
                      <Field name="deliveryAddress" type="text" />
                      <ErrorMessage
                        name="deliveryAddress"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>

                  <div className="register-form__input">
                    <label>Tỉnh thành</label>
                    <Select
                      options={provinces.map((province) => ({
                        label: province.name,
                        value: province.code,
                      }))}
                      onChange={(selectedOption) =>
                        handleAddressChange(
                          "delivery",
                          "province",
                          selectedOption
                        )
                      }
                      value={deliveryAddressDetail.province}
                      placeholder="Chọn tỉnh/thành phố"
                    />
                  </div>
                  <div className="register-form__input">
                    <label>Quận huyện</label>
                    <Select
                      options={
                        deliveryAddressDetail.province
                          ? provinces
                              .find(
                                (province) =>
                                  province.code ===
                                  deliveryAddressDetail.province.value
                              )
                              .districts.map((district) => ({
                                label: district.name,
                                value: district.code,
                              }))
                          : []
                      }
                      onChange={(selectedOption) =>
                        handleAddressChange(
                          "delivery",
                          "district",
                          selectedOption
                        )
                      }
                      value={deliveryAddressDetail.district}
                      placeholder="Chọn tỉnh/thành phố"
                    />
                  </div>
                  <div className="register-form__input">
                    <label>Phường xã</label>
                    <Select
                      options={
                        deliveryAddressDetail.district
                          ? provinces
                              .find(
                                (province) =>
                                  province.code ===
                                  deliveryAddressDetail.province.value
                              )
                              .districts.find(
                                (district) =>
                                  district.code ===
                                  deliveryAddressDetail.district.value
                              )
                              .wards.map((ward) => ({
                                label: ward.name,
                                value: ward.code,
                              }))
                          : []
                      }
                      onChange={(selectedOption) =>
                        handleAddressChange("delivery", "ward", selectedOption)
                      }
                      value={values.companyAddress.province}
                      placeholder="Chọn tỉnh/thành phố"
                    />
                  </div>
                </Row>
              </Col>

              <Col md={6} className="company-form">
                <Typography variant="h6">Thông tin công ty</Typography>

                <div className="register-form__input">
                  <label>Tên công ty</label>
                  <div>
                    <Field name="companyName" type="text" />
                    <ErrorMessage
                      name="companyName"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="register-form__input">
                  <label>Mã số thuế</label>
                  <div>
                    <Field name="taxCode" type="text" />
                    <ErrorMessage
                      name="taxCode"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="register-form__input">
                  <label>Email nhận hóa đơn</label>
                  <div>
                    <Field name="invoiceEmail" type="email" />
                    <ErrorMessage
                      name="invoiceEmail"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="register-form__input">
                  <label>Số điện thoại công ty</label>
                  <div>
                    <Field name="companyPhone" type="text" />
                    <ErrorMessage
                      name="companyPhone"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="register-form__input">
                  <label>Địa chỉ công ty</label>
                  <div>
                    <Field name="companyAddress" type="text" />
                    <ErrorMessage
                      name="companyAddress"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="register-form__input">
                  <label>Tỉnh thành</label>
                  <Select
                    options={provinces.map((province) => ({
                      label: province.name,
                      value: province.code,
                    }))}
                    onChange={(selectedOption) =>
                      handleAddressChange("company", "province", selectedOption)
                    }
                    value={companyAddressDetail.province}
                    placeholder="Chọn tỉnh/thành phố"
                  />
                </div>

                <div className="register-form__input">
                  <label>Quận huyện</label>
                  <Select
                    options={
                      companyAddressDetail.province
                        ? provinces
                            .find(
                              (province) =>
                                province.code ===
                                companyAddressDetail.province.value
                            )
                            .districts.map((district) => ({
                              label: district.name,
                              value: district.code,
                            }))
                        : []
                    }
                    onChange={(selectedOption) =>
                      handleAddressChange("company", "district", selectedOption)
                    }
                    value={companyAddressDetail.district}
                    placeholder="Chọn quận/ huyện"
                  />
                </div>
                <div className="register-form__input">
                  <label>Phường xã</label>
                  <Select
                    options={
                      companyAddressDetail.district
                        ? provinces
                            .find(
                              (province) =>
                                province.code ===
                                companyAddressDetail.province.value
                            )
                            .districts.find(
                              (district) =>
                                district.code ===
                                companyAddressDetail.district.value
                            )
                            .wards.map((ward) => ({
                              label: ward.name,
                              value: ward.code,
                            }))
                        : []
                    }
                    onChange={(selectedOption) =>
                      handleAddressChange("company", "ward", selectedOption)
                    }
                    value={companyAddressDetail.ward}
                    placeholder="Chọn phường/ xã"
                  />
                </div>
              </Col>
            </Row>

            <div className="register-form__submit-box">
              <FormControlLabel
                control={
                  <Checkbox
                    name="agreePolicy"
                    onChange={(e) =>
                      setFieldValue("agreePolicy", e.target.checked)
                    }
                    checked={values.agreePolicy}
                  />
                }
                label="Tôi đã đọc và đồng ý với Nội quy hoặc chính sách bảo mật của công ty"
                className="register-form__policy"
              />
              {/* <ErrorMessage
                name="agreePolicy"
                component="div"
                className="error"
              /> */}

              <Button
                disabled={values.agreePolicy === false ? true : false}
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                Đăng ký
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      {isLoading && <LoadingGif gifSrc={LogoGif} logoImage={LogoNK} />}

      <Dialog
        open={isNotify}
        onClose={handleClose}
        aria-labelledby="thank-you-dialog"
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          {/* Logo công ty */}
          <Box display="flex" justifyContent="center" my={2}>
            <img
              src={LogoThankyou}
              alt="Nguyên Kim Logo"
              style={{
                width: "100px",
                height: "100px",
                objectFit: "contain",
              }}
            />
          </Box>

          {/* Heading cảm ơn */}
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            Cảm ơn Quý khách đã đăng ký tài khoản tại Nguyên Kim!
          </Typography>

          {/* Câu subtext */}
          <Typography
            variant="body1"
            align="center"
            color="textSecondary"
            paragraph
          >
            Bộ phận quản trị đang phê duyệt tài khoản, sẽ có một email xác nhận
            tài khoản được gửi đến bạn.
          </Typography>
          <Typography variant="body1" align="center" color="textSecondary">
            Trong thời gian này, bạn có thể tiếp tục mua hàng.
          </Typography>

          <Box display="flex" justifyContent="center" mt={3}>
            <Button
              variant="contained"
              color="error"
              onClick={handleClose}
              sx={{
                borderRadius: "20px",
                textTransform: "none",
                fontWeight: "bold",
                px: 4,
                py: 1,
              }}
            >
              Tiếp tục mua hàng
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RegisterForm;
