import { Pagination, Spin, Tooltip } from "antd";
import moment from "moment";
import { statusColors } from "../../config";
import { useNavigate } from "react-router-dom";

moment.locale("vi");

const TableOrder = ({ dataOrders, loading, page, setPage }) => {
  const navigate = useNavigate();

  return (
    <div className="box_list_order custom_scroll mt-1 table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Mã đơn hàng</th>
            <th scope="col">Ngày mua</th>
            <th className="col_name_product" scope="col">
              Sản phẩm{" "}
            </th>
            <th scope="col">Tổng tiền </th>

            <th scope="col">Trạng thái</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={5}>
                <div className="box_loading_orders">
                  <Spin />
                </div>
              </td>
            </tr>
          ) : dataOrders && dataOrders?.data && dataOrders?.data?.length > 0 ? (
            dataOrders?.data?.map((item, index) => (
              <tr
                onClick={() => {
                  navigate(`/account/order/${item.order_id}`);
                }}
                className="item_row_product"
                key={item?.order_id}
              >
                <td>
                  <span className="code_item_order">{item?.order_code} </span>
                </td>
                <td className="value_item_order">
                  {" "}
                  {moment
                    .unix(item.date_order)
                    .format("dddd, HH:mm DD-MM-YYYY")
                    .replace(/^\w/, (c) => c.toUpperCase())}
                </td>
                <td>
                  {item.order_detail && item.order_detail.length > 1 ? (
                    <ol className="ps-2 value_item_order ">
                      {item.order_detail.map((item, index) => (
                        <Tooltip
                          color="#2db7f5"
                          placement="top"
                          key={index}
                          title={item.item_title}
                        >
                          <li className="name_product_manager my-1">
                            {item.item_title}
                          </li>
                        </Tooltip>
                      ))}
                    </ol>
                  ) : (
                    <span className="value_item_order ">
                      {item.order_detail.map((item, index) => (
                        <Tooltip
                          color="#2db7f5"
                          placement="top"
                          key={index}
                          title={item.item_title}
                        >
                          <span className="name_product_manager my-1">
                            {item.item_title}
                          </span>
                        </Tooltip>
                      ))}
                    </span>
                  )}
                </td>
                <td className="value_item_order">
                  <span className="total_order_manager">
                    {Number(item.total_cart).toLocaleString("vi", {
                      style: "currency",
                      currency: "VND",
                    })}
                  </span>
                </td>

                <td>
                  <span
                    style={{
                      color: statusColors[item?.order_status?.keyStatus],
                    }}
                    className={`value_item_status `}
                  >
                    {item.order_status.title}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={5}>
              <div className="box_empty_product_manage_order">
                <img alt="Not Found Product" src={"/image/img_not_found.png"} />
                <span>Bạn không có đơn hàng nào</span>
              </div>
            </td>
          )}
        </tbody>
      </table>

      {dataOrders && dataOrders?.total && dataOrders?.total > 10 ? (
        <div className="d-flex justify-content-end">
          <Pagination
            showQuickJumper
            defaultPageSize={10}
            current={page ? page : dataOrders?.current_page}
            total={dataOrders?.total}
            showSizeChanger={false}
            defaultCurrent={1}
            onChange={(e) => {
              setPage(e);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
export default TableOrder;
