import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { imageBaseUrl } from "../config";

const LazyImage = ({ alt, src, width, height, effect, classCss }) => (
  <LazyLoadImage
    className={classCss}
    effect={effect}
    alt={`${alt}`}
    height={height}
    src={`${imageBaseUrl}/uploads/${src}`}
    width={width}
  />
);
export default LazyImage;
