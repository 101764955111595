import React, { useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Card from "react-bootstrap/Card";
import { CartFill } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import ReviewProduct from "./reviewProduct";
import ProductDrawer from "./productDrawer";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import "../public/boxProduct.css";
import { addToCart, fetchUserById } from "../../cart/view/cartSlice";
import { addToProductView } from "./productSlice";
import * as cartService from "../../../services/cartService";
import { useDispatch } from "react-redux";
import { imageBaseUrl } from "../../../../config";

import toast, { Toaster } from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";

import { BiSolidErrorAlt } from "react-icons/bi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import LazyImage from "../../../../util/lazyImage";
import { Popover } from "antd";
import PopupCardParameters from "../../../../util/popupCardParameters";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BoxProduct = (props) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);

  const [isShowSnackbar, setIsShowSnackbar] = useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [openproductComparison, setOpenproductComparison] = useState(false);
  const [addProduct, setAddProduct] = useState(null);
  const handleClickOpenModel = (product) => {
    const temp = [];
    temp.push(product);
    setAddProduct(temp);
    setOpenproductComparison(true);
  };
  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();
  const handleCloseModel = () => {
    setOpenproductComparison(false);
  };
  const currencyFormat = (num) => {
    return (
      Number(num)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " đ"
    );
  };

  const handleBuy = async (product) => {
    if (user) {
      cartService
        .postCart({
          product_id: props.productId,
          quality: 1,
          price: props.discount_percent
            ? props.price - props.discount_price
            : props.price,
          title: props.productName,
          picture: product.picture,
          MaKhoSPApdung: props.macn,
          catName: props.catName,
          catNameParent: props.catNameParent,
          brandName: props.brandName,
          stock: props.status,
          cateChild: props.catChild,
        })
        .then((res) => {
          if (res.data.status == true) {
            dispatch(fetchUserById());
            navigate("/gio-hang");
          } else {
            toast.custom((t) => (
              <CustomToast
                icon={<BiSolidErrorAlt size={25} color="red" />}
                content={"Đã xảy ra lỗi. Xin vui lòng thử lại!"}
              />
            ));
          }
        })
        .catch((error) => console.error("post data error", error));
    } else {
      dispatch(addToCart(product));
      navigate("/gio-hang");
    }
  };
  const handleAddToCart = async (product) => {
    setIsShowSnackbar(true);
    if (user) {
      try {
        const res = await cartService.postCart({
          product_id: props.productId,
          quality: 1,
          price: props.discount_percent
            ? props.price - props.discount_price
            : props.price,
          title: props.productName,
          picture:
            typeof props.picture !== "string"
              ? props.picture[0]
              : props.picture,
          MaKhoSPApdung: props.macn,
          catName: props.catName,
          cateChild: props.catChild,
          catNameParent: props.catNameParent,
          brandName: props.brandName,
          stock: props.status,
        });
        if (res.data.status == true) {
          dispatch(fetchUserById());
          // setOpenAlert(true);
          toast.custom(
            (t) => (
              <CustomToast
                icon={<IoIosCheckmarkCircle size={25} color="green" />}
                content={"Đã thêm sản phẩm vào giỏ hàng"}
                id={t.id}
              />
            ),
            {
              position: "top-right",
              duration: 1000,
            }
          );
        } else {
          toast.custom(
            (t) => (
              <CustomToast
                icon={<BiSolidErrorAlt size={25} color="red" />}
                content={"Đã xảy ra lỗi. Xin vui lòng thử lại!"}
              />
            ),
            {
              position: "top-right",
            }
          );
        }
      } catch (error) {
        console.error("post data error", error);
      }
    } else {
      dispatch(addToCart(product));
      // setOpenAlert(true);
      toast.custom(
        (t) => (
          <CustomToast
            icon={<IoIosCheckmarkCircle size={25} color="green" />}
            content={"Đã thêm sản phẩm vào giỏ hàng"}
            id={t.id}
          />
        ),
        {
          position: "top-right",
          duration: 1000,
        }
      );
    }
  };

  const handleAddToViewed = (product) => {
    dispatch(addToProductView(product));
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <>
      {isShowSnackbar && (
        <Snackbar
          open={openAlert}
          autoHideDuration={1000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right", // Adjust this as needed
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity="success"
            sx={{ width: "100%" }}
          >
            Thêm vào giỏ hàng thành công
          </Alert>
        </Snackbar>
      )}
      <div>
        <div className="container_card_product">
          <Card className="card-producthot-product">
            <Card.Title className="title-product mt-1 mb-0">
              {props.title}
              <p className="mb-0" style={{ textAlign: "right" }}>
                {props.is_hot == "true" ?? "new"}
              </p>
            </Card.Title>
            <Card.Header className="sale">
              <Link
                title={props.productName}
                to={`/${props.link}`}
                state={{ type: "product" }}
                onClick={() => handleAddToViewed(props)}
                className="img-product-box"
              >
                <LazyImage
                  classCss={"img-product"}
                  alt={props.productName}
                  src={props?.picture}
                  width={null}
                  height={null}
                  effect={"blur"}
                />
              </Link>

              {props.productHot == 4 && (
                <div>
                  <img
                    src={`${imageBaseUrl}/uploads/${props.pictureStatus}`}
                    className="product-hot-banner"
                    alt="banner product Hot"
                    loading="lazy"
                  />
                </div>
              )}
              <div className="topsale">
                <div
                  title="So sánh sản phẩm"
                  className="icon-inside-product "
                  onClick={() => handleClickOpenModel(props)}
                >
                  <CompareArrowsIcon className="icon-compare-product" />
                </div>

                <div
                  title="Xem chi tiết"
                  onClick={handleClickOpen}
                  className="icon-inside-product  icon-inside-product-midle"
                >
                  <BsFillEyeFill size={14} />
                </div>
                {(props.status === 1 || props.status === 4) &&
                  props.price != 0 && (
                    <>
                      {props.price !== null ? (
                        <div title="Thêm vào giỏ hàng">
                          <div
                            onClick={() => handleAddToCart(props)}
                            size={14}
                            className="icon-inside-product "
                          >
                            <CartFill />
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
              </div>
            </Card.Header>
            {/* Link  */}
            <Card.Body>
              <Link
                to={`/${props.link}`}
                state={{ type: "product" }}
                rel="nofollow"
                title={props.productName}
                onClick={() => handleAddToViewed(props)}
              >
                <Card.Text className="txt-brif-productHot">
                  {props.productName}
                </Card.Text>
                {!props.discount_percent && (
                  <div className="d-flex justify-content-between align-items-center">
                    {props.status === 0 ? (
                      <div className="txt-price-productHot">
                        Liên hệ Kinh doanh
                      </div>
                    ) : props.status === 1 && props.price <= 0 ? (
                      <div className="txt-price-productHot">
                        Liên hệ Kinh doanh
                      </div>
                    ) : props.status === 1 ||
                      (props.status === 4 && props.price !== 0) ||
                      (props.status === 5 && props.price !== 0) ? (
                      <div className="txt-price-productHot">
                        {props.price != null
                          ? currencyFormat(props.price)
                          : "Liên hệ Kinh doanh"}
                      </div>
                    ) : props.status === 2 ? (
                      <div className="txt-price-productHot">
                        Ngừng kinh doanh
                      </div>
                    ) : null}
                  </div>
                )}
                {props.discount_percent && props.discount_percent > 0 ? (
                  <>
                    {props.discount_percent !== 0 ? (
                      <>
                        <div className="txt-priceOld-productHot">
                          {currencyFormat(props.price)}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="txt-price-productHot">
                            {currencyFormat(props.price - props.discount_price)}
                          </div>
                          <div className="discount">
                            <p>{props.discount_percent} %</p>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
              </Link>
            </Card.Body>
            <Card.Body className="box-btn-producthot">
              {props.status === 0 ? (
                <div className="text-center btn-buy">
                  <Link
                    rel="nofollow"
                    title="liên hệ"
                    to="tel:19006739"
                    className="btn-buy-title"
                  >
                    <p className="btn-text pt-1">LIÊN HỆ</p>
                  </Link>
                </div>
              ) : props.status === 1 && props.price === 0 ? (
                <div className="text-center btn-buy">
                  <Link
                    rel="nofollow"
                    title="liên hệ"
                    to="tel:19006739"
                    className="btn-buy-title"
                  >
                    <p className="btn-text pt-1">LIÊN HỆ</p>
                  </Link>
                </div>
              ) : props.status === 1 ||
                props.status === 4 ||
                props.status === 5 ? (
                <>
                  {props.price != null ? (
                    <div className="text-center btn-buy">
                      <div
                        onClick={() => handleBuy(props)}
                        className="btn-buy-title"
                        rel="nofollow"
                      >
                        <p className="btn-text pt-1">MUA NGAY</p>
                      </div>
                    </div>
                  ) : (
                    <div className="text-center btn-buy">
                      <Link
                        rel="nofollow"
                        title="liên hệ"
                        to="tel:19006739"
                        className="btn-buy-title"
                      >
                        <p className="btn-text pt-1">LIÊN HỆ</p>
                      </Link>
                    </div>
                  )}
                </>
              ) : props.status === 2 ? (
                <div className="text-center btn-buy">
                  <div className="btn-buy-title">
                    <p className="btn-text pt-1">NGỪNG KINH DOANH</p>
                  </div>
                </div>
              ) : null}
            </Card.Body>
          </Card>
        </div>

        {open && (
          <ReviewProduct
            open={open}
            close={handleClose}
            friendlyUrl={props.link}
            productData={props}
          />
        )}

        {addProduct != null && openproductComparison == true ? (
          <ProductDrawer
            openComparison={openproductComparison}
            closeComparison={handleCloseModel}
            category={props.catNameParent}
            list={addProduct}
            cateParentId={props.cateParentId}
          />
        ) : null}
      </div>
    </>
  );
};
export default BoxProduct;
