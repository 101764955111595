const PopupCardParameters = ({ productName }) => {
  console.log("coi log bao nhiêu lần");

  return (
    <div className="box-popup-parameter-card">
      <div className="name-product-popup">
        <span>{productName}</span>
      </div>
    </div>
  );
};
export default PopupCardParameters;
