// Định nghĩa action types
const LOGIN = 'LOGIN';
const REGISTER = 'REGISTER';

// Action creators
const login = () => ({
  type: LOGIN
});

const register = () => ({
  type: REGISTER
});

export { LOGIN, REGISTER, login, register };
