import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "../public/productComparison.css";
import CircularProgress from "@mui/material/CircularProgress";
import { axiosClient } from "../../../../config";
import { useEffect } from "react";
import MetaDecorator from "../../../../util/metaDecorator";

const ProductComparison = () => {
  const compareProducts = localStorage.getItem("compareItems");
  const compareProductsArray = JSON.parse(compareProducts);
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const fetchData = async () => {
    try {
      const res = await axiosClient.get(
        "/member/compare-products?key1=" +
          compareProductsArray.at(0).productId +
          "&key2=" +
          compareProductsArray.at(1).productId
      );
      const dataProduct = res.data.data;
      setData(dataProduct);
      setIsLoaded(true);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <MetaDecorator title="So sánh sản phẩm" description="So sánh sản phẩm" />
      <Container>
        {!isLoaded ? (
          <div className="d-flex justify-content-center align-items-center">
            <div>
              <CircularProgress />
            </div>
          </div>
        ) : (
          <div className="product-comparison-container">
            <table className="products-4">
              <thead>
                <tr>
                  <th className="product-comparison-th label">&nbsp;</th>
                  {data.map((item) => (
                    <th className="product-comparison-th" key={item.productId}>
                      <h5>{item.productName}</h5>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data[0]?.dataTechnology.map((compareProductItem, index) => (
                  <>
                    <tr className="product-comparison-tr">
                      <td class="heading" colspan="3">
                        <span>{compareProductItem.catOption}</span>
                      </td>
                    </tr>
                    <tr key={index}>
                      <td className="product-comparison-td label">
                        {compareProductItem.catOption}
                      </td>
                      {data.map((product, idx) => (
                        <td className="product-comparison-td" key={idx}>
                          <div
                            className="spec"
                            spec-title="Label"
                            dangerouslySetInnerHTML={{
                              __html:
                                product.dataTechnology[index]?.nameCatOption ||
                                "Không có",
                            }}
                          ></div>
                        </td>
                      ))}
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Container>
    </>
  );
};

export default ProductComparison;
