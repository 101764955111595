import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import WarrantyPolicy from "./warrantyPolicy";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import RelatedProducts from "./relatedProducts";
import DetailedSpecificate from "./detailedSpecificate";
import ProductDescription from "./productDescription";
import ProductDrawer from "../../product/view/productDrawer";
import RelatedPosts from "./relatedPosts";

import Comment from "./comment";
import "../public/productDetail.css";
import { Link, useNavigate } from "react-router-dom";
import { addToCart, fetchUserById } from "../../cart/view/cartSlice";
import * as productService from "../../../services/productService";
import * as cartService from "../../../services/cartService";

import ComboProduct from "./comboProduct";

import ProductImg from "./productImg";

import MetaDecorator from "../../../../util/metaDecorator";
import { axiosClient } from "../../../../config";
import { LuCopy } from "react-icons/lu";
import { LuCopyCheck } from "react-icons/lu";
import NotFound from "../../../../component/public/notFound/view/notFound";
import hotlineIcon from "../public/images/hotline-icon.png";

import toast, { Toaster } from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";
import { BiSolidErrorAlt } from "react-icons/bi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import LoadingGif from "../../../client_component/loadingGif";

import BreadcrumbNew from "../../../client_component/breadcrumbNew";
import useAuth from "../../../../util/useAuth";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProductDetail = ({ slug, loading, setLoading }) => {
  const navigate = useNavigate();
  const [copyed, setCopyed] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [checkProduct, setCheckProduct] = useState(true);
  const [data, setData] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const dispatch = useDispatch();
  const [dataComponent, setDataComponent] = useState({
    specifications: null,
    post: null,
    relatedPosts: null,
  });

  const [phone, setPhone] = useState("");
  const isAuth = useAuth();

  const fetchSpecifications = async () => {
    try {
      const response = await axiosClient.get(
        `/member/get-product-technology/${slug}`
      );
      const data = response?.data?.technology;

      if (data) {
        setDataComponent((prev) => ({
          ...prev,
          specifications: data,
        }));
      }
    } catch (error) {
      console.error("Fetch specifications error", error);
    }
  };

  const fetchRelatedPosts = async () => {
    try {
      const response = await axiosClient.get(`/member/post-relate?key=${slug}`);
      const data = response?.data?.data;

      if (data) {
        setDataComponent((prev) => ({
          ...prev,
          relatedPosts: data,
        }));
      }
    } catch (error) {
      console.error("Fetch specifications error", error);
    }
  };

  const fetchProductDescription = async () => {
    try {
      const response = await axiosClient.get(
        `/member/get-description-product/${slug}`
      );
      const data = response?.data?.productDescription;

      if (data) {
        setDataComponent((prev) => ({
          ...prev,
          post: data, // `data` là giá trị mới bạn muốn cập nhật cho `post`
        }));
      }
    } catch (error) {
      console.error("Fetch category menu error", error);
    }
  };

  const fetchProductDetail = async () => {
    try {
      if (!slug) return;
      setCheckProduct(false);

      const data = await productService.getProductDetail(slug);

      if (data.status === true) {
        setData(data.productDetail || []);
        setLoading(false);
        setCheckProduct(true);

        Promise.all([
          fetchSpecifications(),
          fetchProductDescription(),
          fetchRelatedPosts(),
        ]).catch((error) => {
          console.error("Error fetching specifications or description:", error);
        });
      } else if (data?.data.status === "false") {
        setNotFound(true);
        if (data.data.error === "null Product") {
          setCheckProduct(false);
        } else {
          console.error("Unknown error:", data.data.error);
        }
      } else {
        setNotFound(true);
      }
    } catch (error) {
      console.error("Fetch data error", error);
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductDetail();
  }, [slug]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const currencyFormat = (num) => {
    return (
      Number(num)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " đ"
    );
  };

  const handleAddToCart = async (product) => {
    if (isAuth) {
      try {
        const res = await cartService.postCart({
          product_id: product.productId,
          quality: 1,
          price:
            product.listPrice.length != 0
              ? product.listPrice
                  .filter((item) => item.main == 1)
                  .map((item) => item.price_old)
                  .join("")
              : product.priceOld,
          title: product.productName,
          picture:
            product.listPrice.length > 0
              ? product.listPrice
                  .filter((item) => item.main == 1)
                  .map((item) => item.picture)
                  .join("")
              : product.pictureForDetailProduct,
          MaKhoSPApdung: product.maso,
          catName: product.catName,
          catNameParent: product.catNameParent,
          brandName: product.brandName,
          stock: product.stock,
          cateChild: product.catName,
        });
        if (res.data.status == true) {
          dispatch(fetchUserById());
          // setOpenAlert(true);
          toast.custom(
            (t) => (
              <CustomToast
                icon={<IoIosCheckmarkCircle size={25} color="green" />}
                content={"Đã thêm sản phẩm vào giỏ hàng"}
                id={t.id}
              />
            ),
            {
              position: "top-right",
              duration: 1000,
            }
          );
        } else {
          toast.custom(
            (t) => (
              <CustomToast
                icon={<BiSolidErrorAlt size={25} color="red" />}
                content={"Đã xảy ra lỗi. Xin vui lòng thử lại!"}
              />
            ),
            {
              position: "top-right",
            }
          );
        }
      } catch (error) {
        console.error("post data error", error);
      }
    } else {
      dispatch(addToCart(product));
      // setOpenAlert(true);
      toast.custom(
        (t) => (
          <CustomToast
            icon={<IoIosCheckmarkCircle size={25} color="green" />}
            content={"Đã thêm sản phẩm vào giỏ hàng"}
            id={t.id}
          />
        ),
        {
          position: "top-right",
          duration: 1000,
        }
      );
    }
  };

  const handleBuy = async (product) => {
    if (isAuth) {
      cartService
        .postCart({
          product_id: product.productId,
          quality: 1,
          price:
            data.listPrice && data.listPrice.length != 0
              ? data.listPrice
                  .filter((item) => item.main == 1)
                  .map((item) => item.price_old)
                  .join("")
              : data.priceOld,
          title: product.productName,
          picture:
            product.listPrice.length > 0
              ? product.listPrice
                  .filter((item) => item.main == 1)
                  .map((item) => item.picture)
                  .join("")
              : product.pictureForDetailProduct,

          MaKhoSPApdung: product.maso,
          catName: product.catName,
          catNameParent: product.catName,
          brandName: product.brandName,
          stock: product.stock,
          cateChild: product.catName,
        })
        .then((res) => {
          if (res.data.status == true) {
            dispatch(fetchUserById());
            navigate("/gio-hang");
          } else {
            toast.custom(
              (t) => (
                <CustomToast
                  icon={<BiSolidErrorAlt size={25} color="red" />}
                  content={"Đã xảy ra lỗi. Xin vui lòng thử lại!"}
                />
              ),
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => console.error("post data error", error));
    } else {
      dispatch(addToCart(product));
      navigate("/gio-hang");
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const breadcrumbLink = [
    {
      id: 1,
      name: data.catNameSuperParent,
      link: `/${data.catNameSuperParentUrl}`,
    },

    {
      id: 2,
      name: data.catNameParent,
      link: `/danh-muc/${data.catNameParentUrl}`,
    },

    { id: 3, name: data.catName, link: `/danh-muc/${data.urlName}` },

    {
      id: 4,
      name: data.catChildName,
      link: `/danh-muc/${data.catChildNameUrl}`,
    },
  ];

  const combinePitureList = (images) => {
    const list = [];
    for (const item of images) {
      if (item.display === 1) {
        list.push({
          alt: item.pic_name,
          pictureProducts: item.picture,
        });
      }
    }
    return list;
  };

  const handleCopy = (name, price_old, price) => {
    const textarea = document.createElement("textarea");
    textarea.value = `${name} ${
      isAuth ? currencyFormat(price_old) : currencyFormat(price)
    }`;
    document.body.appendChild(textarea);
    textarea.select();

    try {
      document.execCommand("copy");
      setCopyed(true);
    } catch (error) {
      console.error("Unable to copy to clipboard", error);
    } finally {
      document.body.removeChild(textarea);
    }
  };

  const loginPrice = () => {
    if (data.stock === 2) {
      return "Ngừng kinh doanh";
    }

    if ((data.price || data.listPrice.price) && data.stock === 0) {
      return "Liên hệ kinh doanh";
    }

    if (data.listPrice && data.listPrice.length > 0) {
      return currencyFormat(data.listPrice[0].price_old);
    }

    if (data.priceOld !== 0) {
      return currencyFormat(data.priceOld);
    }
  };

  const notLoginPrice = () => {
    if (data.stock === 2) {
      return "Ngừng kinh doanh";
    }

    if ((data.price || data.listPrice.price) && data.stock === 0) {
      return "Liên hệ kinh doanh";
    }

    if (data.listPrice && data.listPrice.length > 0) {
      return currencyFormat(data.listPrice[0].price);
    }

    if (data.price !== 0) {
      return currencyFormat(data.price);
    }
  };

  const retailPrice = () => {
    if (
      data.stock === 2 ||
      data.stock === 0 ||
      ((data.price || data.listPrice.price) && data.stock === 0)
    ) {
      return false;
    }

    if (data.listPrice && data.listPrice.length > 0) {
      return true;
    }

    if (data.price !== 0) {
      return true;
    }
    return false;
  };

  const notHaveListPrice = () => {
    const compareButton = (
      <div
        style={{ width: "100%" }}
        rel="nofollow"
        className="btn-compare"
        onClick={handleClickOpen}
      >
        So sánh
      </div>
    );

    if (data.stock === 2) {
      return (
        <div className="btn-box">
          <div
            style={{ width: "100%" }}
            rel="nofollow"
            className="btn-box-buy-now"
          >
            Ngừng kinh doanh
          </div>
          {compareButton}
        </div>
      );
    }

    if (data.stock === 0 || (data.price === 0 && data.stock === 1)) {
      return (
        <div className="btn-box">
          <Link
            rel="nofollow"
            title="liên hệ"
            to="tel:19006739"
            className="btn-box-buy-now"
            style={{ width: "100%" }}
          >
            Liên hệ
          </Link>
          {compareButton}
        </div>
      );
    }

    if (data.price !== 0 && data.stock === 1) {
      return (
        <div className="btn-box">
          <div
            onClick={() => handleAddToCart(data)}
            rel="nofollow"
            className="btn-box-add-cart col-md-4 col-12 px-0"
          >
            Thêm vào giỏ hàng
          </div>
          <Snackbar
            open={openAlert}
            autoHideDuration={1000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="success"
              sx={{ width: "100%" }}
            >
              Thêm vào giỏ hàng thành công
            </Alert>
          </Snackbar>
          <div
            onClick={() => handleBuy(data)}
            rel="nofollow"
            className="btn-box-buy-now col-md-4 col-12"
          >
            Mua ngay
          </div>
          {compareButton}
        </div>
      );
    }
  };

  const haveListPrice = () => {
    const compareButton = (
      <div
        style={{ width: "100%" }}
        rel="nofollow"
        className="btn-compare"
        onClick={handleClickOpen}
      >
        So sánh
      </div>
    );

    if (data.stock === 2) {
      return (
        <div className="btn-box">
          <div
            style={{ width: "100%" }}
            rel="nofollow"
            className="btn-box-buy-now"
          >
            Ngừng kinh doanh
          </div>
          {compareButton}
        </div>
      );
    }

    if (data.stock === 0 || data.listPrice[0].price === 0) {
      return (
        <div className="btn-box">
          <Link
            rel="nofollow"
            title="liên hệ"
            to="tel:19006739"
            className="btn-box-buy-now"
            style={{ width: "100%" }}
          >
            Liên hệ
          </Link>
          {compareButton}
        </div>
      );
    }

    if (data.listPrice[0].price !== 0 && data.stock === 1) {
      return (
        <>
          <div>
            <div
              onClick={() => handleBuy(data)}
              rel="nofollow"
              className="btn-box-buy-now col-12"
            >
              Mua ngay
            </div>
          </div>
          <div className="btn-box">
            <div
              onClick={() => handleAddToCart(data)}
              rel="nofollow"
              className="btn-box-add-cart col-md-6 col-12 px-0"
            >
              Thêm vào giỏ hàng
            </div>

            {compareButton}
          </div>
        </>
      );
    }
  };

  const handleSubmitConsultantForm = async (e) => {
    e.preventDefault();
    if (!phone.trim()) {
      toast.custom(
        (t) => (
          <CustomToast
            icon={<BiSolidErrorAlt size={25} color="red" />}
            content={"Vui lòng nhập thông tin số điện thoại!"}
          />
        ),
        {
          position: "top-right",
        }
      );
      return;
    }

    if (phone.trim().length > 10 || phone.trim().length < 10) {
      toast.custom(
        (t) => (
          <CustomToast
            icon={<BiSolidErrorAlt size={25} color="red" />}
            content={"Vui lòng kiểm tra lại thông tin số điện thoại!"}
          />
        ),
        {
          position: "top-right",
        }
      );
      return;
    }

    try {
      const res = await axiosClient.post(`/fill-form`, {
        phone: phone,
      });

      if (res.data.status === true) {
        setPhone("");
        toast.custom(
          (t) => (
            <CustomToast
              icon={<IoIosCheckmarkCircle size={25} color="green" />}
              content={"Gửi yêu cầu tư vấn thành công. Xin cám ơn."}
            />
          ),
          {
            position: "top-right",
          }
        );
      } else {
        toast.custom(
          (t) => (
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={"Gửi yêu cầu tư vấn thất bại. Vui lòng gửi lại!"}
            />
          ),
          {
            position: "top-right",
          }
        );
      }
    } catch (error) {
      console.error("Lỗi không thể gửi yêu cầu tư vấn", error);
    }
  };

  return (
    <div className="product-detail-model">
      <MetaDecorator title={data.friendlyTitle} description={data.metadesc} />
      <Container>
        {loading && <LoadingGif />}

        <>
          {checkProduct == true && data.display == 1 && (
            <>
              <BreadcrumbNew arrayLink={breadcrumbLink} />

              <div className="product-detail-container">
                <div className="product-detail-right">
                  <div className="product-detail-header">
                    <div className="product-image-slide">
                      <ProductImg
                        images={
                          combinePitureList(data?.pictures) !== null
                            ? combinePitureList(data?.pictures)
                            : "No_image.jpg"
                        }
                        image={
                          data.listPrice && data.listPrice.length > 0
                            ? data.listPrice[0].picture !== null &&
                              data.listPrice[0].picture !== ""
                              ? data.listPrice[0].picture
                              : "No_image.jpg"
                            : data.pictureForDetailProduct !== null
                            ? data.pictureForDetailProduct
                            : "No_image.jpg"
                        }
                        alt={data?.productName}
                        productHot={data.status}
                      />
                    </div>
                    <div className="product-detail-center">
                      <h1 className="product-detail-name-product">
                        {data.productName}
                      </h1>
                      <div className="product-code">
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="label-title">Mã sản phẩm :</p>
                            <p className="label-content code">{data?.MaHH}</p>
                            {/* <p className="label-content code">{data.MaHH}</p> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex   align-items-center">
                          <div className="label-title me-3">
                            {" "}
                            {isAuth ? "Giá đại lý:" : "Gía bán:"}{" "}
                          </div>
                          <div className="price-product me-2">
                            {isAuth ? loginPrice() : notLoginPrice()}
                          </div>
                          <div className="price-retail">
                            <p className="mb-0">(Đã bao gồm VAT)</p>{" "}
                          </div>
                        </div>

                        {isAuth && (
                          <div className="d-flex   align-items-center">
                            <div className="label-title me-3">
                              Giá thị trường:
                            </div>
                            <div className="price-market me-2">
                              <span>{notLoginPrice()}</span>
                            </div>
                          </div>
                        )}

                        <div className="product-detail__status d-flex align-items-end">
                          <div className="label-title">Trạng thái: </div>
                          <div className="ms-2">
                            {data && data.stock == 2
                              ? "Ngừng kinh doanh"
                              : null}
                            {data && data.stock == 1 ? "Còn hàng" : null}
                            {data && data.stock == 0
                              ? "Liên hệ Kinh doanh"
                              : null}
                          </div>
                        </div>

                        <div
                          style={{ marginTop: "8px" }}
                          onClick={() =>
                            handleCopy(
                              data?.productName,
                              data.priceOld,
                              data.price
                            )
                          }
                          className="copy-btn"
                        >
                          {copyed ? (
                            <LuCopyCheck style={{ fontSize: "20px" }} />
                          ) : (
                            <LuCopy style={{ fontSize: "20px" }} />
                          )}{" "}
                          Copy thông tin sản phẩm
                        </div>

                        <div>{data.giftDesc}</div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="product_desc">
                            <hr></hr>
                            {data.short != null && data.short !== "" ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data.short,
                                }}
                              ></div>
                            ) : (
                              <div>Không có mô tả</div>
                            )}
                          </div>
                        </div>
                      </div>

                      {data.listPrice && data.listPrice.length > 0
                        ? haveListPrice()
                        : notHaveListPrice()}

                      <div className="product-detail-hotline">
                        <div className="product-detail-hotline__message">
                          <img src={hotlineIcon} alt="hotline-icon" />
                          <p>
                            Hãy để lại số điện thoại để được hỗ trợ giá sỉ,
                            chúng tôi sẽ gọi lại cho bạn ngay!
                          </p>
                        </div>
                        <div className="product-detail-hotline__form">
                          <form onSubmit={handleSubmitConsultantForm}>
                            <input
                              type="number"
                              name="your-tel"
                              id="your-tel"
                              placeholder="Nhập số điện thoại của bạn"
                              autoComplete="off"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                            <button type="submit">Gửi</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="detailed-specificate-turn-on">
                    <DetailedSpecificate list={dataComponent.specifications} />
                  </div>

                  {data.data_group && data.data_group.length != 0 && (
                    <ComboProduct
                      productDetail={data}
                      groupProduct={data.data_group}
                      stock={data.stock}
                    />
                  )}

                  <ProductDescription desc={dataComponent.post} />
                  <RelatedProducts
                    friendUrl={data.friendlyUrl}
                    category={data.catNameParent}
                    cateParentId={data.cat_id}
                  />

                  <Comment
                    comments={data.commentProductId}
                    moduleComment="product"
                    macn={data?.MaHH}
                    productId={data.productId}
                  />
                </div>
                <div className="product-detail-left">
                  <DetailedSpecificate list={dataComponent.specifications} />
                  <RelatedPosts relatedPosts={dataComponent.relatedPosts} />
                  <WarrantyPolicy />
                </div>
              </div>
              <ProductDrawer
                openComparison={open}
                category={data.catNameParent}
                closeComparison={handleClose}
                cateParentId={data.cat_id}
                list={[data]}
              />
            </>
          )}

          {notFound && <NotFound />}
        </>
      </Container>
    </div>
  );
};

export default ProductDetail;
