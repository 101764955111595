import React, { useState } from "react";

import { login } from "../../../redux/action";
import "../public/member.css";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import MetaDecorator from "../../../../util/metaDecorator";
import { Button, Flex, Form, Input, Space, Spin } from "antd";
import { axiosClient } from "../../../../config";
import { Link, useNavigate } from "react-router-dom";

const mapDispatchToProps = (dispatch) => ({
  login: () => dispatch(login()),
});

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const response = await axiosClient.post("/login", {
        username: values.username,
        password: values.password,
      });

      if (response.data.token) {
        localStorage.setItem("user_token", response.data.token);
        localStorage.setItem("m_status", response.data.admin?.m_status);
        navigate("/");
      }

      if (!response.data.status) {
        form.setFields([
          {
            name: "password",
            errors: ["Mật khẩu hoặc tài khoản chưa chính xác!"],
          },
        ]);
      }
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      setLoading(false);
    }
  };

  const benefits = [
    "Được hưởng đầy đủ các ưu đãi mua hàng",
    "Cập nhật thông tin sản phẩm mới, tin tức công nghệ, tin khuyến mại",
    "Cập nhật giá sản phẩm, báo giá hàng ngày",
    "Theo dõi quản lý đơn hàng, điểm thưởng mua hàng",
    "Ưu đãi bảo hành, giảm giá mua hàng và phí dịch vụ",
  ];

  return (
    <div className="container-login">
      <MetaDecorator title="Đăng nhập" description="Đăng nhập" />

      {loading && <Spin spinning={loading} fullscreen />}

      <Flex justify={"center"} align={"center"}>
        <div className="box_login">
          <div className="title_login ">
            <span>ĐĂNG NHẬP</span>
          </div>
          <Form
            form={form}
            requiredMark="optional"
            name="basic"
            layout="vertical"
            style={{
              minWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Tài khoản"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Hãy nhập tài khoản!",
                },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Tài khoản" />
            </Form.Item>

            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Hãy nhập mật khẩu!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Mật khẩu"
              />
            </Form.Item>

            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Đăng nhập
                </Button>
                <Button
                  onClick={() => {
                    navigate("/register");
                  }}
                  htmlType="button"
                >
                  Đăng ký
                </Button>
                <Link to="/forget-password" title={"Quên mật khẩu"}>
                  <Button color="danger" variant="link">
                    Quên mật khẩu
                  </Button>
                </Link>
              </Space>
            </Form.Item>
          </Form>

          <div className="membership_benefits">
            <div className="title_benefits">
              <span> Nếu bạn là thành viên?</span>
            </div>
            <div className="value_benefits">
              {benefits &&
                benefits.length > 0 &&
                benefits.map((item, index) => (
                  <span key={index}>- {item}</span>
                ))}
            </div>
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(Login);
