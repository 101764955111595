export const calculateRemaining = (deadline) => {
  const today = new Date();
  const targetDate = new Date(deadline);
  const days = Math.ceil((targetDate - today) / (1000 * 3600 * 24));
  return days;
};

export const calculateUpdate = (startDateString) => {
  const startDate = new Date(startDateString);
  const currentDate = new Date();

  const elapsedMilliseconds = currentDate - startDate;
  const elapsedDays = Math.floor(elapsedMilliseconds / (1000 * 3600 * 24));
  const elapsedHours = Math.floor(
    (elapsedMilliseconds % (1000 * 3600 * 24)) / (1000 * 3600)
  );
  const elapsedMinutes = Math.floor(
    (elapsedMilliseconds % (1000 * 3600)) / (1000 * 60)
  );

  let elapsedString = "";

  if (elapsedDays > 0) {
    elapsedString += `${elapsedDays} ngày`;
  }

  if (elapsedHours > 0) {
    elapsedString += ` ${elapsedHours} giờ`;
  }

  if (elapsedMinutes > 0) {
    elapsedString += ` ${elapsedMinutes} phút`;
  }

  return elapsedString;
};
