import * as React from 'react';
import Box from '@mui/material/Box';
 
import { Container, Image, Form, Col, Row } from "react-bootstrap";

import { ImageList, ImageListItem, useMediaQuery } from '@mui/material';


export default function AlbumImageList() {
  const matches = useMediaQuery('(max-width:600px)');

 
  return (
    <Row className='bg-white'>
      <Col md='12' className='container_list_image'>
        <div className='pb-4'>
          <h3 className='text_name_company text-center'>VĂN HÓA VI TÍNH NGUYÊN KIM</h3>
          <span className='description_compay text-center text_culture '>
            VI TÍNH NGUYÊN KIM coi trọng mỗi cá nhân và khuyến khích sự đóng góp của mọi người. Tại VI TÍNH NGUYÊN KIM, không có ý kiến nào là nhỏ, mà mỗi ý kiến đều được đón nhận và tôn trọng. Chúng tôi khuyến khích sự sáng tạo và thúc đẩy mọi người đạt được tiềm năng tối đa của mình.
          </span>
        </div>
        <Box>
          <ImageList variant={  'masonry'}
     
     

     cols={matches ? 2 : 4}
            gap={12}>
            {itemData.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Col>
    </Row>
  );
}

const itemData = [
  {
    img: './image/img1.jpg',
    title: 'Bed',
  },
  {
    img: './image/img2.jpg',
    title: 'Books',
  },
  {
    img: './image/img3.jpg',
    title: 'Sink',
  },
  {
    img: './image/img4.jpg',
    title: 'Kitchen',
  },
  {
    img: './image/img5.jpg',
    title: 'Blinds',
  },
  {
    img: './image/img6.jpg',
    title: 'Chairs',
  },
  {
    img: './image/img7.jpg',
    title: 'Laptop',
  },
  {
    img: './image/img8.jpg',
    title: 'Doors',
  },
  {
    img: './image/img9.jpg',
    title: 'Coffee',
  },
  {
    img: './image/img10.jpg',
    title: 'Storage',
  },
  {
    img: './image/img11.jpg',
    title: 'Candle',
  },
  {
    img: './image/img12.jpg',
    title: 'Coffee table',
  },
  //   {
  //     img: './image/img13.jpg',
  //     title: 'Coffee table',
  //   },
  //   {
  //     img: './image/img14.jpg',
  //     title: 'Coffee table',
  //   },
  //   {
  //     img: './image/img15.jpg',
  //     title: 'Coffee table',
  //   },
];
