import React from "react";
import { Link } from "react-router-dom";
import { GiftFill } from "react-bootstrap-icons";
import "../public/nofication.css";
const Nofication = ({ noficationData, handleNotificationClick }) => {
  return (
    <div className="nofication-container">
      {noficationData?.notificationList.map((item, index) => {
        return (
          <Link
            to={`${item.link == "" ? "/gio-hang" : "/khuyen-mai"}`}
            onClick={() => handleNotificationClick(index)}
            key={index}
            className={`nofication-item ${item.viewed ? "" : "active"}`}
          >
            <div className="nofication-right">
              <div className="nofication-icon-gift">
                <GiftFill />
              </div>
            </div>
            <div className="coupon-item-name">
              <div>
                <p className="coupon-item-title">{item.name}</p>
                <p className="coupon-item-desc">Tri gia {item.price}</p>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default Nofication;
