import { axiosClient } from "../../config";

export const getBanner = async () => {
  return await axiosClient
    .get("/member/advertise")
    .then((res) => {
      const combineBannerList = [];
      for (const item of res.data.data) {
        if (item.pos == "banner" && item.module_show == "main") {
          combineBannerList.push(item);
        }
      }
      return combineBannerList;
    })
    .catch((error) => error.response);
};

export const getMiniBanner = async () => {
  return await axiosClient
    .get("/member/advertise")
    .then((res) => {
      const combineBannerList = [];
      for (const item of res.data.data) {
        if (item.pos == "mini baner" && item.module_show == "main") {
          combineBannerList.push(item);
        }
      }
      return combineBannerList;
    })
    .catch((error) => error.response);
};

export const getPopup = async () => {
  return await axiosClient
    .get("/member/advertise")
    .then((res) => {
      const combineBannerList = [];
      for (const item of res.data.data) {
        if (item.pos == "popup" && item.module_show == "main") {
          combineBannerList.push(item);
        }
      }
      return combineBannerList;
    })
    .catch((error) => error.response);
};

export const getProductAdvertise = async () => {
  return await axiosClient
    .get("/product-advertise")
    .then((res) => {
      const combineBannerList = [];
      for (const item of res.data.data) {
        if (item.type == "Home" && item.display == 1) {
          combineBannerList.push(item);
        }
      }
      return combineBannerList;
    })
    .catch((error) => error.response);
};

export const getProductCateAdvertise = async () => {
  return await axiosClient
    .get("/product-advertise")
    .then((res) => {
      const combineBannerList = [];
      for (const item of res.data.data) {
        if (item.type == "Product" && item.display == 1) {
          combineBannerList.push(item);
        }
      }
      return combineBannerList;
    })
    .catch((error) => error.response);
};

export const getBannerProductHot = async () => {
  return await axiosClient
    .get("/member/advertise")
    .then((res) => {
      const combineBannerList = [];
      for (const item of res.data.data) {
        if (item.pos == "productHot" && item.module_show == "product") {
          combineBannerList.push(item);
        }
      }
      return combineBannerList;
    })
    .catch((error) => error.response);
};

export const getFooterBanner = async () => {
  return await axiosClient
    .get("/member/advertise")
    .then((res) => {
      const combineBannerList = [];
      for (const item of res.data.data) {
        if (item.pos == "banner-footer" && item.display == 1) {
          combineBannerList.push(item);
        }
      }
      return combineBannerList;
    })
    .catch((error) => error.response);
};

export const getImageBannerHire = () => {
  return axiosClient
    .get("/member/advertise")
    .then((res) => {
      const combineBannerHireList = [];
      for (const item of res.data.data) {
        if (item.pos == "BannerHire" && item.display == 1) {
          combineBannerHireList.push(item);
        }
      }
      return combineBannerHireList;
    })
    .catch((error) => error.response);
};

export const getBannerLeftRight = async () => {
  return await axiosClient
    .get("/member/advertise")
    .then((res) => {
      const bannerLeftRight = [];
      for (const item of res.data.data) {
        if (item.pos == "BannerLeftRight" && item.module_show == "main") {
          bannerLeftRight.push(item);
        }
      }
      return bannerLeftRight;
    })
    .catch((error) => error.response);
};

export const getBannerPopup = async () => {
  return await axiosClient
    .get("/member/advertise")
    .then((res) => {
      const bannerPopup = [];
      for (const item of res.data.data) {
        if (item.pos == "popup" && item.module_show == "main") {
          bannerPopup.push(item);
        }
      }
      return bannerPopup;
    })
    .catch((error) => error.response);
};
