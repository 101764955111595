import { Col, Container, Row } from "react-bootstrap";
import BreadcrumbNew from "../../../../client_component/breadcrumbNew";
import MetaDecorator from "../../../../../util/metaDecorator";
import MenuAccount from "../../../../client_component/menuAccount";
import { ConfigProvider, DatePicker, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { axiosClient } from "../../../../../config";
import TableOrder from "../../../../client_component/tableOrder";
import locale from "antd/locale/vi_VN";
import dayjs from "dayjs";
import "dayjs/locale/vi";
dayjs.locale("vi");

const breadcrumbLink = [
  { id: 1, name: "Tổng quan tài khoản", link: `/account/statistics` },
];

const AccountStatistics = () => {
  const [page, setPage] = useState(1);
  const [statusOrder, setStatusOrder] = useState([]);
  const [dataOrders, setDataOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    time: "",
    status: "",
  });

  const onChangeTime = (date, dateString) => {
    setFilter((prev) => {
      return {
        ...prev,
        time: date ? dayjs(date).format("MM/YYYY") : "",
      };
    });
    setPage(1);
  };

  const fetchStatusOrder = async () => {
    try {
      const res = await axiosClient.get("/member/show-order-status");
      setStatusOrder(res?.data?.status ? res?.data?.data : []);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };

  useEffect(() => {
    fetchStatusOrder();
  }, []);

  const fetchDataOrders = async () => {
    try {
      const res = await axiosClient.get(
        `/member/order?status=${filter.status}&month=${filter.time}&page=${page}`
      );

      setDataOrders(res?.data ? res?.data : []);
      setLoading(false);
    } catch (error) {
      console.error("fetch data error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchDataOrders();
  }, [filter, page]);

  const BoxData = ({ title, value, image }) => (
    <div className="box_data h-100">
      <div className="box_item_data">
        <div className="box_title_data">
          <div className="box_title">
            <span>{title}</span>
          </div>
          <div className="value_data text-center">
            <span>{value}</span>
          </div>
        </div>
        <div className="box_image_data">
          <img src={image} alt={title} />
        </div>
      </div>
    </div>
  );

  const handleChange = (value) => {
    setFilter((prev) => {
      return { ...prev, status: value ?? "" };
    });
    setPage(1);
  };

  return (
    <div>
      <>
        <MetaDecorator
          title="Tổng quan tài khoản"
          description="Tổng quan tài khoản"
        />
        <Container>
          <div className="container_account">
            <div className="mt-1">
              <BreadcrumbNew arrayLink={breadcrumbLink} />
            </div>

            <Row>
              <MenuAccount />
              <Col md={8} lg={9} xs={12}>
                <Row>
                  <Col md={12} className="mt-2 mt-md-0">
                    <div className="container_data ">
                      <BoxData
                        title="Tất cả  đơn hàng"
                        value={dataOrders?.count ?? 0}
                        image="/image/boxes.png"
                      />

                      <BoxData
                        title="Số đơn hàng thành công"
                        value={dataOrders?.countOrderSuccess ?? 0}
                        image="/image/package_done.png"
                      />
                      <BoxData
                        title="Tổng tiền đơn hàng thành công"
                        value={
                          dataOrders?.totalSumMonthSuccess &&
                          dataOrders.totalSumMonthSuccess > 0
                            ? dataOrders.totalSumMonthSuccess.toLocaleString(
                                "vi",
                                {
                                  style: "currency",
                                  currency: "VND",
                                }
                              )
                            : (0).toLocaleString("vi", {
                                style: "currency",
                                currency: "VND",
                              })
                        }
                        image="/image/shipping-cost.png"
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md={12} lg={8}>
                    <Space className="box_filter_chart" align="center">
                      <ConfigProvider locale={locale}>
                        <DatePicker
                          allowClear
                          onChange={onChangeTime}
                          picker="month"
                          className="w-100"
                        />
                      </ConfigProvider>

                      <Select
                        onChange={handleChange}
                        placeholder="Trạng thái đơn hàng"
                        className="w-100"
                        allowClear
                        options={
                          statusOrder && statusOrder.length > 0
                            ? statusOrder.map((item) => ({
                                value: item.status_id,
                                label: item.title,
                              }))
                            : []
                        }
                      />
                    </Space>
                  </Col>
                </Row>

                <TableOrder
                  page={page}
                  setPage={setPage}
                  dataOrders={dataOrders?.listOrder ?? []}
                  loading={loading}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </>
    </div>
  );
};
export default AccountStatistics;
