import React, { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import { Col, Row } from "react-bootstrap";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { PlusLg, XLg } from "react-bootstrap-icons";
import { axiosClient, imageBaseUrl } from "../../../../config";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import "../public/productDrawer.css";

import toast, { Toaster } from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";

import { BiSolidErrorAlt } from "react-icons/bi";
import Slide from "@mui/material/Slide";
import { Card, Tooltip } from "antd";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const priceList = [
  {
    id: 1,
    title: "dưới 1 triệu",
    min_price: "10",
    max_price: "999999",
  },
  {
    id: 2,
    title: "từ 1 triệu đến 5 triệu",
    min_price: "1000000",
    max_price: "5000000",
  },
  {
    id: 3,
    title: "từ 5 triệu đến 7 triệu",
    min_price: "5000000",
    max_price: "7000000",
  },
  {
    id: 4,
    title: "từ 7 triệu đến 10 triệu",
    min_price: "7000000",
    max_price: "10000000",
  },
  {
    id: 5,
    title: "từ 10 triệu đến 15 triệu",
    min_price: "10000000",
    max_price: "15000000",
  },
  {
    id: 6,
    title: "từ 15 triệu đến 20 triệu",
    min_price: "15000000",
    max_price: "20000000",
  },
  {
    id: 7,
    title: "từ 20 triệu đến 50 triệu",
    min_price: "20000000",
    max_price: "50000000",
  },
  {
    id: 8,
    title: "từ 50 triệu trở lên",
    min_price: "50000000",
    max_price: "9999999999",
  },
];
const ProductDrawer = ({
  openComparison,
  closeComparison,
  list,
  category,
  cateParentId,
}) => {
  const { Meta } = Card;

  const [options, setOptions] = useState([]);
  const [addCompareItem, setAddCompareItem] = useState(list);
  const [dataSearch, setDataSearch] = useState([]);
  const [chooseBrand, setChooseBrand] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const navigate = useNavigate();
  const [maxPrice, setMaxPrice] = useState("");

  const searchInputRef = useRef(null);
  const listAdd = Array.isArray(list) ? list : [list];

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    axiosClient
      .get("/member/category-list/" + cateParentId)
      .then((response) => {
        setOptions(response.data);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [cateParentId]);

  useEffect(() => {
    if (chooseBrand && minPrice && maxPrice) {
      // setBothOptionsSelected(true);

      axiosClient
        .get(
          "/member/compare-product-search?key=" +
            cateParentId +
            "&brand=" +
            chooseBrand +
            "&price=" +
            minPrice +
            "&price_old=" +
            maxPrice
        )
        .then((res) => {
          if (!Array.isArray(res.data)) {
            setDataSearch([]);
          } else {
            setDataSearch(res.data);
          }
        })
        .catch((error) => console.error("Error:", error));
    } else {
      setDataSearch([]);
      // setBothOptionsSelected(false);
    }
  }, [chooseBrand, minPrice, maxPrice, cateParentId]);
  const handleAddCompare = (product) => {
    if (addCompareItem.length === 2) {
      toast.custom((t) => (
        <CustomToast
          icon={<BiSolidErrorAlt size={25} color="red" />}
          content={"Không thể thêm nữa. Đã đạt số lượng tối đa!"}
        />
      ));
      return;
    }
    const updatedAddCompareItem = [...addCompareItem, product];

    setAddCompareItem(updatedAddCompareItem);
    localStorage.setItem("compareItems", JSON.stringify(updatedAddCompareItem));
    setChooseBrand("");
    setMinPrice("");
    setMaxPrice("");
    setDataSearch([]);
  };
  const handleRemoveCompare = (productId) => {
    const updatedAddCompareItem = addCompareItem.filter(
      (item) => item.productId !== productId
    );
    setAddCompareItem(updatedAddCompareItem);
    localStorage.setItem("compareItems", JSON.stringify(updatedAddCompareItem));
  };

  const handleChangeOption = (selectedOption) => {
    setChooseBrand(selectedOption);
  };
  const handleChangePrice = (selectedPriceId) => {
    const selectedPriceOption = priceList.find(
      (priceOption) => priceOption.id === parseInt(selectedPriceId)
    );
    if (selectedPriceOption) {
      const min_Price = selectedPriceOption.min_price;
      const max_Price = selectedPriceOption.max_price;
      setMinPrice(min_Price);
      setMaxPrice(max_Price);
    }
  };

  const currencyFormat = (num) => {
    return (
      Number(num)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " đ"
    );
  };

  const handleComparion = () => {
    if (addCompareItem.length === 1) {
      toast.custom((t) => (
        <CustomToast
          icon={<BiSolidErrorAlt size={25} color="red" />}
          content={"Vui lòng chọn sản phẩm để so sánh!"}
        />
      ));
      return;
    } else {
      navigate("/product-comparison");
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        TransitionComponent={Transition}
        open={openComparison}
        onClose={closeComparison}
        maxWidth={"sm"}
      >
        <div className="productComparison">
          <DialogTitle
            className="productComparisonTitle "
            id="alert-dialog-title"
          >
            So sánh sản phẩm
          </DialogTitle>
          <DialogContent>
            <div className="productComparisonContent">
              <div>
                <div>
                  {/* <h4 className="model-comparison-title" onClick={handleSearch}>
                    Nhập tên sản phẩm cần so sánh
                  </h4> */}
                  <div className="productComparison-select-box ">
                    <Row className="mx-0">
                      <Col xs={12} sm={6} className="Personal px-1">
                        <select
                          id="option"
                          className="choose"
                          onChange={(e) => handleChangeOption(e.target.value)}
                        >
                          <option className="brand-option" value="">
                            chọn thương hiệu so sánh
                          </option>
                          {isLoaded ? (
                            <>
                              {options.map((option) =>
                                option.listBrand.map((categoryItem) => (
                                  <option
                                    className="brand-option"
                                    key={categoryItem.friendly_url}
                                    value={categoryItem.id}
                                  >
                                    {categoryItem.title}
                                  </option>
                                ))
                              )}
                            </>
                          ) : (
                            <option className="fs-5 fw-bolder text-muted">
                              Loading....
                            </option>
                          )}
                        </select>
                      </Col>
                      <Col xs={12} sm={6} className="Personal px-1">
                        <select
                          id="price"
                          className="choose"
                          onChange={(e) => handleChangePrice(e.target.value)}
                        >
                          <option className="brand-option" value="">
                            Chọn giá để so sánh
                          </option>
                          {priceList.map((priceOption) => (
                            <option
                              className="brand-option"
                              key={priceOption.id}
                              value={priceOption.id}
                            >
                              {priceOption.title}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                  </div>
                  <div className="find-sp">
                    {/* {hasChoose && (
                    <>
                      <input
                        type="text"
                        name="search"
                        className="search-product-by-brand"
                        value={searchTemp}
                        onChange={handleSearch}
                        // onClick={handleSearch}
                        ref={searchInputRef}
                        placeholder="chọn tên sản phẩm cần so sánh"
                      ></input>
                      {searchTemp == "" ? (
                        <Search className="icon-search" size={15} />
                      ) : (
                        <XLg
                          className="icon-search"
                          size={15}
                          onClick={handleClear}
                        />
                      )}
                     
                     
                    </>
                  )} */}
                    {dataSearch.length > 0 && (
                      <div className="search-brand-result">
                        {dataSearch
                          ?.filter(
                            (item) =>
                              item.picture != "uploads/" &&
                              item.productName != null
                          )
                          .map((item) => {
                            return (
                              <div
                                key={item.productId}
                                className="search-brand-result-item"
                              >
                                <div className="search-brand-result-item-left">
                                  <div className="search-brand-img-box">
                                    <img
                                      className="search-brand-img"
                                      src={`${imageBaseUrl}/uploads/${
                                        item.pictureForDetailProduct
                                          ? item.pictureForDetailProduct
                                          : "No_image.jpg"
                                      }`}
                                      alt={`${item.productName}`}
                                      // loading="lazy"
                                    />
                                  </div>
                                  <div className="search-brand-product-content">
                                    <div className="search-brand-product-content-title">
                                      {item.productName}
                                    </div>
                                    <div className="search-brand-product-content-price">
                                      {currencyFormat(item.price)}
                                    </div>
                                  </div>
                                </div>
                                <div className="search-brand-result-item-right">
                                  <div className="search-brand-result-icon-search">
                                    <PlusLg
                                      onClick={() => {
                                        handleAddCompare(item);
                                        if (searchInputRef.current) {
                                          searchInputRef.current.focus();
                                        }
                                      }}
                                      className="result-icon-search"
                                      size={20}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                    {chooseBrand &&
                      minPrice &&
                      maxPrice &&
                      dataSearch.length <= 0 && (
                        <div className="no-search-results">
                          Không tìm thấy sản phẩm
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="productComparisonItem mt-2">
                <div className="box-item-choose">
                  {addCompareItem.length == 0
                    ? listAdd.map((item) => (
                        <Card
                          hoverable
                          style={{
                            width: 240,
                          }}
                          cover={
                            <img
                              alt="example"
                              src={`${imageBaseUrl}/uploads/${
                                Array.isArray(item?.picture)
                                  ? item?.picture.join("")
                                  : item.picture
                              }`}
                            />
                          }
                        >
                          <Meta title={item?.name} />
                        </Card>
                      ))
                    : addCompareItem.map((item, index) => (
                        <Card
                          hoverable
                          extra={
                            index > 0 ? (
                              <XLg
                                size={15}
                                onClick={() =>
                                  handleRemoveCompare(item.productId)
                                }
                              />
                            ) : (
                              <span></span>
                            )
                          }
                          style={{
                            width: 240,
                          }}
                          cover={
                            <img
                              src={`${imageBaseUrl}/uploads/${
                                Array.isArray(item.picture)
                                  ? item?.picture.join("")
                                  : item.pictureForDetailProduct
                                  ? item.pictureForDetailProduct
                                  : item.picture
                              }`}
                              alt={`${item.productName}`}
                            />
                          }
                        >
                          <div className="box_body_compare">
                            <Tooltip title={item.productName} color={"#87d068"}>
                              <span>{item.productName} </span>
                            </Tooltip>
                          </div>
                        </Card>
                      ))}
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div
              rel="nofollow"
              className="btn-comparison"
              autoFocus
              onClick={handleComparion}
            >
              <div>
                <p className="text-white text-submit">So sánh ngay</p>
              </div>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default ProductDrawer;
