import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
    const checkLogin = localStorage.getItem('user_token');
    if (checkLogin) {
        return true;
    } else {
        return false;
    }
};

const PublicRoute = () => {
    const checkLogin = useAuth();
    return checkLogin ? <Navigate to="/" /> : <Outlet />;
}
export default PublicRoute