import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import "../public/technologyCornerMain.css";
import * as productService from "../../../services/productService";
import * as newsService from "../../../services/newsService";
import MetaDecorator from "../../../../util/metaDecorator";
import { imageBaseUrl } from "../../../../config";
import BreadcrumbNew from "../../../client_component/breadcrumbNew";

const TechnologyCorner = () => {
  const location = useLocation();
  const [productHotData, setProductHotData] = useState([]);
  const [newsCategory, setNewsCategory] = useState([]);

  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };

  const fetchNewsCategory = () => {
    newsService
      .getNewsCategory()
      .then((data) => {
        setNewsCategory(data);
      })
      .catch((error) => console.error("fetch data error", error));
  };
  useEffect(() => {
    fetchNewsCategory();
  }, []);

  const user = useAuth();
  const fetchProductHotData = async () => {
    try {
      const res = await productService.getProductHot(99999);

      if (res.status === true) {
        setProductHotData(res.productHot);
      }
    } catch (error) {
      console.error("fetch data error", error);
    }
  };
  useEffect(() => {
    fetchProductHotData();
  }, []);

  const currencyFormat = (num) => {
    return (
      Number(num)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " đ"
    );
  };
  const breadcrumbLink = [
    { id: 1, name: "Góc công nghệ", link: `/goc-cong-nghe/tin-cong-nghe` },
  ];

  return (
    <div>
      <MetaDecorator
        title="Góc công nghệ"
        description="Góc công nghệ tại Công ty Vi tính Nguyên Kim"
      />
      <Container>
        <div className="technology-corner-container">
          <div className="mb-2">
            <BreadcrumbNew arrayLink={breadcrumbLink} />
          </div>
          <h4 className="technology-corner-title">Góc công nghệ</h4>
          <div className="technology-corner-container-box">
            <div className="technology-corner-container-left">
              <div className="technology-corner-list">
                {newsCategory
                  ?.filter((item) => item.news_category_desc != null)
                  .map((list) => (
                    <Link
                      key={list.cat_id}
                      rel="nofollow"
                      title={list.news_category_desc.cat_name}
                      className={`${
                        location.pathname ==
                        `/goc-cong-nghe/${list.news_category_desc.friendly_url}`
                          ? "text-active"
                          : "text-black"
                      } technology-corner-item`}
                      to={`/goc-cong-nghe/${list.news_category_desc.friendly_url}`}
                    >
                      {list.news_category_desc.cat_name}
                    </Link>
                  ))}
              </div>
              <div className="technology-corner-product-promotion-box-desktop">
                <div className="technology-corner-product-promotion">
                  <h3 className="technology-corner-product-promotion-title">
                    sản phẩm hot
                  </h3>
                  <div className="box_auto_amination ">
                    <div className="product-promotion-list">
                      {Array.isArray(productHotData) &&
                        productHotData.length > 0 &&
                        productHotData
                          .filter(
                            (item) =>
                              item.price_list &&
                              item.price_list.length > 0 &&
                              item.price_list[0].price > 0 &&
                              item.price_list[0].price_old > 0
                          )
                          .map((item) => {
                            return (
                              <div className="product-promotion-items">
                                <div>
                                  <img
                                    src={
                                      item.price_list[0].picture
                                        ? `${imageBaseUrl}/uploads/${item.price_list[0].picture}`
                                        : `${imageBaseUrl}/uploads/No_image.jpg`
                                    }
                                    className="product-promotion-img"
                                    alt={`${item.product_desc.title}`}
                                    loading="lazy"
                                  />
                                </div>
                                <div className="product-promotion-desc">
                                  <Link
                                    to={`/${item.product_desc.friendly_url}`}
                                    rel="nofollow"
                                    title={item.product_desc.title}
                                    className="product-promotion-desc-title"
                                  >
                                    {item.product_desc.title}
                                  </Link>
                                  <div className="product-promotion-desc-price">
                                    {user
                                      ? currencyFormat(
                                          item.price_list[0].price_old
                                        )
                                      : currencyFormat(
                                          item.price_list[0].price
                                        )}
                                  </div>
                                  <div className="product-promotion-desc-status">
                                    Tình trạng:{" "}
                                    <b className="status-product">còn hàng</b>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="technology-corner-container-content">
              <Outlet />
            </div>
            <div className="technology-corner-product-promotion-box-moblie">
              <div className="technology-corner-product-promotion">
                <h2 className="technology-corner-product-promotion-title">
                  sản phẩm hot
                </h2>
                <div className="box_auto_amination ">
                  <div className="product-promotion-list">
                    {productHotData.map((row) => {
                      if (Array.isArray(row.product)) {
                        return row.product
                          .filter(
                            (item) =>
                              item.price_list &&
                              item.price_list.length > 0 &&
                              item.price_list[0].price > 0 &&
                              item.price_list[0].price_old > 0
                          )
                          .map((item) => (
                            <div
                              className="product-promotion-items"
                              key={item.id}
                            >
                              <div>
                                <img
                                  src={
                                    item.picture
                                      ? `${imageBaseUrl}/uploads/${item.picture}`
                                      : `${imageBaseUrl}/uploads/No_image.jpg`
                                  }
                                  className="product-promotion-img"
                                  alt={item.productName}
                                  loading="lazy"
                                />
                              </div>
                              <div className="product-promotion-desc">
                                <div className="product-promotion-desc-title">
                                  {item.productName}
                                </div>
                                <div className="product-promotion-desc-price">
                                  {user
                                    ? item.price_list[0].price_old
                                    : item.price_list[0].price}
                                </div>
                                <div className="product-promotion-desc-status">
                                  Tình trạng:{" "}
                                  <b className="status-product">còn hàng</b>
                                </div>
                              </div>
                            </div>
                          ));
                      }
                      return null; // Return null if `row.product` is not an array
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TechnologyCorner;
