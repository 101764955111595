import { useEffect } from "react";
import { axiosClient, imageBaseUrl } from "../../../../config";
import { useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import "../public/partnerModal.css";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PartnerPost from "./PartnerPost";
import PartnerImages from "./PartnerImages";
import { Fragment } from "react";

function PartnerModal(props) {
  const [partnerData, setPartnerData] = useState();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("profile");

  useEffect(() => {
    fetchPartnerData();
  }, []);

  const fetchPartnerData = async () => {
    setLoading(true);
    try {
      const res = await axiosClient.get(
        `/member/partner-detail/${props.namePartner}`
      );
      if (res.data.status == "success") {
        setPartnerData(res.data.partner);
      }
    } catch (error) {
      console.error("Lỗi khi fetch partner data", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        className=""
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div
          className={`partner-container ${loading ? "text-center p-5" : ""}`}
        >
          {loading ? (
            <>
              <Spinner animation="border" variant="dark" />
            </>
          ) : (
            <Fragment>
              <Modal.Header className="border-bottom-0" closeButton>
                <Modal.Title>Đối tác: {partnerData?.namePartner}</Modal.Title>
              </Modal.Header>
              <div className="partner-detail__logo">
                <img src={`${imageBaseUrl}/uploads/${partnerData?.logo}`} />
              </div>

              <div className="tabs">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3 "
                >
                  <Tab
                    className=""
                    eventKey="profile"
                    title="Bài viết của hãng"
                  >
                    <PartnerPost
                      partnerNews={partnerData?.partner_news}
                      partnerNameUrl={partnerData?.url}
                    />
                  </Tab>
                  <Tab className="" eventKey="home" title="Nguyên Kim và hãng">
                    <Link
                      to={partnerData?.link}
                      style={{
                        fontSize: "16px",
                      }}
                      target="_blank"
                    >
                      Đường dẫn tới trang đối tác của chúng tôi tại đây.
                    </Link>
                    <PartnerImages partnerImages={partnerData?.partner_image} />
                  </Tab>
                </Tabs>
              </div>
            </Fragment>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default PartnerModal;
