import React, { useRef, useState, useEffect, memo, lazy } from "react";
import { Cart3 } from "react-bootstrap-icons";
import { Container } from "react-bootstrap";
import Badge from "@mui/material/Badge";
import { BiSupport } from "react-icons/bi";
import { MdSupportAgent, MdBuildCircle } from "react-icons/md";
// import Register from "../../../modules/member/view/register.js";
import Logo from "../../../../component/image/nk_logo_NK.png";
import {
  Search,
  PersonFill,
  PersonAdd,
  TagsFill,
  PeopleFill,
  List,
  Newspaper,
  XLg,
  BellFill,
  Trash,
  InfoSquare,
  PersonFillAdd,
} from "react-bootstrap-icons";
import { PiExamFill } from "react-icons/pi";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import "../public/header.css";
import HeaderSidebar from "./headerSidebar.js";
import MenuMain from "../../../modules/main/view/menu/menu_main.js";
import "../../../../component/public/header/header_media.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { addToHistorySearch, clearHistorySearchItem } from "./searchSlice.js";
import { axiosClient, imageBaseUrl } from "../../../../config.js";
import Consultant from "../../../modules/support/view/consultant.js";

const Support = React.lazy(() =>
  import("../../../modules/support/view/support.js")
);

const Nofication = React.lazy(() =>
  import("../../../modules/nofication/view/nofication.js")
);

const Header = ({ member, noficationData, handleNotificationClick }) => {
  const count = useSelector((state) => state.cart.total);
  const historySearch = useSelector((state) => state.historySearch);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [change, setChange] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchList, setSearchList] = useState(null);
  const typingTimeOut = useRef(null);
  const location = useLocation();
  const cart = useSelector((state) => state.cart);
  const [state, setState] = useState({
    left: false,
  });

  const [isShowConsultantForm, setIsShowConsultantForm] = useState(false);

  useEffect(() => {
    const changeHeader = () => {
      if (window.scrollY >= 100) {
        setChange(true);
      }
      if (window.scrollY <= 10) {
        setChange(false);
      }
    };
    window.addEventListener("scroll", changeHeader);

    return () => window.removeEventListener("scroll", changeHeader);
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");

    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();

  const handleLogout = async () => {
    localStorage.removeItem("user_token");
    localStorage.removeItem("m_status");
    sessionStorage.removeItem("vtnkus");
  };
  const handleSearch = (event) => {
    setOpenHistorySearch(false);
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
    if (searchValue == "") {
      if (typingTimeOut.current) {
        clearTimeout(typingTimeOut.current);
      }
      setSearchList(null);
      return;
    } else {
      if (event.key === "Enter") {
        setSearchList(null);
        if (typingTimeOut.current) {
          clearTimeout(typingTimeOut.current);
        }
        handleEnterKey(searchValue);
        event.preventDefault();
        return;
      } else {
        if (typingTimeOut.current) {
          clearTimeout(typingTimeOut.current);
        }

        typingTimeOut.current = setTimeout(
          () => {
            setIsLoading(true);
            fetchData(searchValue);
          },
          event.key !== "Enter" ? 1000 : 0
        );
      }
    }
  };

  const handleSearchByIcon = () => {
    if (typingTimeOut.current) {
      clearTimeout(typingTimeOut.current);
    }

    setSearchList(null);
    handleEnterKey(searchTerm);
    window.location.reload();
    return;
  };

  const handleEnterKey = (searchValue) => {
    dispatch(
      addToHistorySearch({
        keySearch: searchValue,
        url: `/danh-muc?tim-kiem=${searchValue}`,
      })
    );
    setSearchList(null);
    navigate(`/danh-muc?tim-kiem=${searchValue}`);
    setSearchList(null);
    setSearchTerm("");
  };
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async (searchValue) => {
    try {
      const value = searchValue.toLowerCase().split(" ").join("%");
      const response = await axiosClient.get(`/member/search-product`, {
        params: { key: value },
      });
      const data = response.data.product;
      setSearchList(data);
      setOpenHistorySearch(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Fetch API for search data error", error);
    }
  };
  useEffect(() => {
    return () => {
      if (typingTimeOut.current) {
        clearTimeout(typingTimeOut.current);
      }
    };
  }, []);
  const currencyFormat = (num) => {
    return (
      Number(num)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " đ"
    );
  };
  const handleClearInput = () => {
    setSearchList(null);
    setSearchTerm("");
  };
  const [openHistorySearch, setOpenHistorySearch] = useState(false);
  const hanleOpenHistorySearch = () => {
    if (searchList === null) {
      setOpenHistorySearch(true);
    } else {
      setSearchList(null);
      setOpenHistorySearch(false);
    }
  };

  const hanleDeleteItem = (keySearch) => {
    dispatch(clearHistorySearchItem(keySearch));
  };

  const handleSaveHistory = (product) => {
    dispatch(
      addToHistorySearch({
        keySearch: product.productName,
        url: `/${product.friendLyUrl}`,
      })
    );
  };

  return (
    <div
      id="header"
      className={`${change ? "changeHeader" : ""} header-container`}
    >
      {/* desktop */}

      <div className="header_main">
        <Container>
          <div className="header-main-container">
            <div className={`${change ? "disable-block" : ""}`}>
              <Link
                rel="nofollow"
                title="Công ty vi tính Nguyên Kim"
                to="/"
                className="nk__icon"
              >
                <img
                  className="nk_image"
                  src={Logo}
                  alt="Công ty vi tính Nguyên Kim"
                />
              </Link>
            </div>
            <div className="">
              <div className="search">
                <div className="header-list-icon">
                  <div className={`${change ? "d-block" : "d-none"}`}>
                    {["left"].map((anchor) => (
                      <React.Fragment key={anchor}>
                        <Button onClick={toggleDrawer("left", true)}>
                          <List className="menu-icon me-2" size={35} />
                        </Button>
                        <SwipeableDrawer
                          className="header-sidebar-swipe"
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                          onOpen={toggleDrawer(anchor, true)}
                        >
                          <HeaderSidebar />
                        </SwipeableDrawer>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                <div className="search__item">
                  <div className="search__bar">
                    <div className="search__bar-input">
                      <input
                        placeholder="Tìm kiếm sản phẩm"
                        className="search__input"
                        onKeyDown={handleSearch}
                        onClick={hanleOpenHistorySearch}
                        value={searchTerm}
                        // value={123}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="search__button">
                    <div className="search__button-mobile">
                      {searchList === null || searchList?.length === 0 ? (
                        <Search className="search-icon" size={15} />
                      ) : (
                        <XLg
                          className="search-icon"
                          size={15}
                          onClick={handleClearInput}
                        />
                      )}
                    </div>
                    <div className="search__button-desktop">
                      <Search
                        onClick={handleSearchByIcon}
                        className="search-icon"
                        size={15}
                      />
                    </div>
                  </div>
                  {isLoading ? (
                    <div className="search__item-result">
                      <div className="d-flex justify-content-center">
                        <div className="custom-loader"></div>
                      </div>
                    </div>
                  ) : searchList != null && searchList?.length !== 0 ? (
                    <div className="search__item-result">
                      {searchList != null && searchList?.length != 0
                        ? searchList.map((item, index) => {
                            return (
                              <div
                                key={item.uid}
                                className="search__item-result-item"
                              >
                                <div className="search__item-result-img-box">
                                  <img
                                    className="search__item-result-img"
                                    src={`${imageBaseUrl}/uploads/${
                                      item.picture !== "" &&
                                      item.picture !== null
                                        ? item.picture
                                        : "No_image.jpg"
                                    }`}
                                    alt={item.productName}
                                    loading="lazy"
                                  />
                                </div>
                                <Link
                                  to={
                                    item.priceOld == "service" ||
                                    item.price == "service"
                                      ? `/dich-vu/${item.friendLyUrl}`
                                      : `/${item.friendLyUrl}`
                                  }
                                  title={item.productName}
                                  onClick={() => handleSaveHistory(item)}
                                  className="search__item-result-item-right"
                                >
                                  <div className="search__item-result-item-right-title">
                                    {item.productName}
                                  </div>
                                  {item.priceOld !== "service" &&
                                    item.price !== "service" && (
                                      <div className="search__item-result-item-right-price">
                                        {user
                                          ? currencyFormat(item.priceOld)
                                          : currencyFormat(item.price)}
                                      </div>
                                    )}
                                </Link>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  ) : (
                    searchList != null &&
                    searchList?.length == 0 && (
                      <div className="search__item-result">
                        <div className="not-found-product">
                          Không có sản phẩm
                        </div>
                      </div>
                    )
                  )}

                  {openHistorySearch &&
                    historySearch.historySearchItems.length !== 0 && (
                      <div className="search__item-result">
                        <div className="p-3">
                          <div className="history-search-header">
                            <h3 className="history-search-title">
                              Lịch sử tìm kiếm
                            </h3>
                            <XLg
                              className="history-search-icon"
                              size={18}
                              onClick={() => setOpenHistorySearch(false)}
                            />
                          </div>
                          {historySearch.historySearchItems.map((item) => {
                            return (
                              <div className="history-search-item">
                                <Link
                                  to={`${item.url}`}
                                  className="history-search-key"
                                  onClick={() => setOpenHistorySearch(false)}
                                  title={item.keySearch}
                                >
                                  {item.keySearch}
                                </Link>
                                <Trash
                                  size={18}
                                  onClick={() => hanleDeleteItem(item)}
                                  className="history-search-icon"
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="align-items-center header-main-item-container justify-content-between w-100 flex-wrap">
              <a
                rel="nofollow"
                title="hotline: 1900 6739"
                href="tel:19006739"
                className="header-main-item"
              >
                <BiSupport className="header-main-item-icon" />
                <div>
                  <div className="no-wrap-text">Hotline</div>
                  <div className="no-wrap-text">1900 6739</div>
                </div>
              </a>

              <div className="header-main-item-sup">
                <MdSupportAgent className="header-main-item-icon" />
                <div>
                  <div className="no-wrap-text">Liên hệ</div>
                  <div className="no-wrap-text">Tư vấn</div>
                </div>
                <div className="header-main-sup-item-dropdown">
                  <Support />
                </div>
              </div>

              {user ? (
                <Link
                  rel="nofollow"
                  title="giỏ hàng"
                  to="/gio-hang"
                  className="header-main-item"
                >
                  <Badge badgeContent={count} color="primary">
                    <Cart3 className="header-main-item-icon" />
                  </Badge>
                  <div className="no-wrap-text">Giỏ hàng</div>
                </Link>
              ) : (
                <Link
                  rel="nofollow"
                  title="giỏ hàng"
                  to="/gio-hang"
                  className="header-main-item"
                >
                  <Badge badgeContent={cart?.cartItem?.length} color="primary">
                    <Cart3 className="header-main-item-icon" />
                  </Badge>
                  <div>
                    <div className="no-wrap-text">Giỏ</div>
                    <div className="no-wrap-text">hàng</div>
                  </div>
                </Link>
              )}

              <div className="header-main-item header-main-nofication">
                {noficationData.notificationList.filter(
                  (item) => item.viewed == false
                ).length > 0 && user ? (
                  <Badge
                    badgeContent={
                      noficationData.notificationList.filter(
                        (item) => !item.viewed
                      ).length
                    }
                    color="error"
                  >
                    <BellFill className="header-main-item-icon" />
                  </Badge>
                ) : (
                  <BellFill className="header-main-item-icon" />
                )}

                <div>
                  <div className="no-wrap-text">Thông</div>
                  <div className="no-wrap-text">báo</div>
                </div>

                {noficationData.notificationList.length > 0 && user && (
                  <div className="header-main-nofication-dropdown">
                    <Nofication
                      noficationData={noficationData}
                      handleNotificationClick={handleNotificationClick}
                      userName={member?.full_name}
                    />
                  </div>
                )}
              </div>

              {!user ? (
                <div className="d-flex   ">
                  <div className="header-main-btn me-4">
                    <Link
                      rel="nofollow"
                      to="/login"
                      title="đăng nhập"
                      className="header-main-item-link no-wrap-text"
                    >
                      <PersonFill className="header-main-item-icon" />
                      Đăng Nhập
                    </Link>
                  </div>

                  <Link
                    rel="nofollow"
                    to={"/register"}
                    title="đăng ký"
                    className="header-main-btn no-wrap-text"
                  >
                    <PersonAdd className="header-main-item-icon" />
                    Đăng ký
                  </Link>
                </div>
              ) : (
                <div className="d-flex  ">
                  <div className="header-main-btn me-4">
                    <div className="header-main-item-link user-name-btn">
                      <PersonFill className="header-main-item-icon" />
                      {member?.full_name}
                      <div
                        style={{ zIndex: 10 }}
                        className="user-name-dropdown"
                      >
                        <div className="user-name-dropdown-item">
                          <Link
                            rel="nofollow"
                            title="Tổng quan tài khoản"
                            to="/account/statistics"
                          >
                            Tổng quan tài khoản
                          </Link>
                        </div>

                        <div className="user-name-dropdown-item">
                          <Link
                            rel="nofollow"
                            title="thông tin tài khoản"
                            to="/account"
                          >
                            Thông tin tài khoản
                          </Link>
                        </div>

                        <div className="user-name-dropdown-item">
                          <Link
                            rel="nofollow"
                            title="Quản lý đơn hàng"
                            to="/account/orders"
                          >
                            Quản lý đơn hàng
                          </Link>
                        </div>
                        <div className="user-name-dropdown-item">
                          <Link
                            rel="nofollow"
                            title="Đổi mật khẩu"
                            to="/account"
                          >
                            Đổi mật khẩu
                          </Link>
                        </div>

                        <div className="user-name-dropdown-item">
                          <Link
                            rel="nofollow"
                            title="đăng xuẩt"
                            to="/login"
                            onClick={handleLogout}
                          >
                            Đăng xuất
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
      <div className="top_header">
        <Container>
          <div className="top-header-container">
            {location.pathname != "/" ? (
              <div className="top-header-container-item header-main-category-list">
                <List className=" me-2" size={22} />
                <div className="no-wrap-text">Danh mục sản phẩm</div>
                <div className="header-main-category-list-menu">
                  <MenuMain />
                </div>
              </div>
            ) : (
              <>
                <div className={`${change ? "d-block" : "d-none"}`}>
                  <div className="top-header-container-item header-main-category-list">
                    <List className=" me-2" size={22} />
                    <div className="no-wrap-text">Danh mục sản phẩm</div>
                    <div className="header-main-category-list-menu">
                      <MenuMain />
                    </div>
                  </div>
                </div>
              </>
            )}

            <Link
              rel="nofollow"
              title="tổng hợp khuyến mãi"
              to="/khuyen-mai"
              className="top-header-container-item"
            >
              <TagsFill className="top-header-container-item-icon" />
              <div className="no-wrap-text">Tổng hợp khuyến mãi</div>
            </Link>

            <Link
              rel="nofollow"
              title="xây dựng cấu hình"
              to="/xay-dung-cau-hinh"
              className="top-header-container-item"
            >
              <MdBuildCircle className="top-header-container-item-icon" />
              <div className="no-wrap-text">Xây dựng cấu hình</div>
            </Link>
            <Link
              to="/tuyen-dung"
              rel="nofollow"
              title="tuyển dụng"
              className="top-header-container-item"
            >
              <PeopleFill className="top-header-container-item-icon" />
              <div className="no-wrap-text">Tuyển dụng</div>
            </Link>
            <Link
              to="/goc-cong-nghe/tin-noi-bo"
              rel="nofollow"
              title="tin tức"
              className="top-header-container-item"
            >
              <Newspaper className="top-header-container-item-icon" />
              <div className="no-wrap-text">Tin tức</div>
            </Link>

            <div className="header-main-consultant">
              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#0d6efe",
                }}
                onClick={() => setIsShowConsultantForm(true)}
                className="top-header-container-item header-button"
              >
                <InfoSquare className="top-header-container-item-icon" />
                <div className="no-wrap-text">Yêu cầu tư vấn</div>
              </button>

              <Consultant
                isShowConsultantForm={isShowConsultantForm}
                setIsShowConsultantForm={setIsShowConsultantForm}
              />
              <div
                onClick={() => setIsShowConsultantForm(false)}
                style={{
                  display: `${isShowConsultantForm ? "block" : "none"} `,
                }}
                id="overlay"
              ></div>
            </div>

            <Link
              rel="nofollow"
              title="Đối tác của Nguyên Kim"
              to="/doi-tac"
              className="top-header-container-item"
            >
              <PersonFillAdd className="top-header-container-item-icon" />
              <div className="no-wrap-text">Đối tác của Nguyên Kim</div>
            </Link>

            <Link
              target="_blank"
              rel="nofollow"
              title="Đào tạo"
              to="https://vitinhnguyenkim.vn/edu"
              className="top-header-container-item"
            >
              <PiExamFill className="top-header-container-item-icon" />
              <div className="no-wrap-text">Trung tâm đào tạo</div>
            </Link>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default memo(Header);
