import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "../../partner/public/partnerPostDetail.css";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { axiosClient } from "../../../../config";
import MetaDecorator from "../../../../util/metaDecorator";
import BreadcrumbNew from "../../../client_component/breadcrumbNew";

const PartnerPostDetail = () => {
  const { NamePost } = useParams();
  const [newsData, setNewsData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchNewsData = async () => {
    try {
      const res = await axiosClient.get(
        "/member/partnerNews-detail/" + NamePost
      );
      setNewsData(res.data.partner);
      setIsLoaded(true);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };

  useEffect(() => {
    fetchNewsData();
  }, []);

  const breadcrumbLink = [
    { id: 1, name: "Đối tác của chúng tôi", link: `/doi-tac`, type: null },
    {
      id: 2,
      name: newsData?.title,
      link: `/${newsData.friendly_url}`,
      type: "news",
    },
  ];
  return (
    <>
      <MetaDecorator
        title={newsData.friendly_title}
        description={newsData.metadesc}
      />
      <Container>
        <div className="news-detail-container">
          <div className="mb-4">
            {/* <BreadCrumbNews arrayLink={breadcrumbLink} /> */}

            <BreadcrumbNew arrayLink={breadcrumbLink} />
          </div>
          <div>
            {!isLoaded ? (
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  <CircularProgress />
                </div>
              </div>
            ) : (
              <div>
                <h2 className="news-detail-container-title">
                  {newsData.title}
                </h2>
                <div className="news-detail-container-conetent">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: newsData.description.replace(
                        /<p>(?=.*<img)/g,
                        '<p class="text-center">'
                      ),
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default PartnerPostDetail;
