import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "../public/promotonDetail.css";
import { axiosClient } from "../../../../config";
import { Breadcrumb } from "../../../breadcrumb";
import MetaDecorator from "../../../../util/metaDecorator";

const PromotionDetail = () => {
  const { namePromotion } = useParams();
  const [promotionDetailData, setPromotionData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await axiosClient.get(`/member/promotion/` + namePromotion);
      setPromotionData(res.data.data);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const breadcrumbLink = [
    { id: 1, name: "Tổng hợp khuyến mãi", url: `/khuyen-mai` },
    {
      id: 2,
      name: promotionDetailData.title,
      url: `/khuyen-mai/${namePromotion}`,
    },
  ];

  return (
    <>
      <MetaDecorator
        title={promotionDetailData.friendly_title}
        description={promotionDetailData.metadesc}
      />
      <Container>
        <div className="promtion-detail-container">
          <div className="mb-4">
            <Breadcrumb arrayLink={breadcrumbLink} />
          </div>
          <h2 className="news-detail-container-title">
            {promotionDetailData.title}
          </h2>
          <div className="news-detail-container-conetent">
            <div
              dangerouslySetInnerHTML={{
                __html: promotionDetailData.description,
              }}
            ></div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PromotionDetail;
