import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { CheckLg } from "react-bootstrap-icons";
import "../public/checkout.css";
import PickUP from "./pickUp";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  removeCheckedCartItems,
  clearAppliedPromotion,
  fetchUserById,
} from "../../cart/view/cartSlice";
import FixQuantity from "./fixQuantity";
import { axiosClient, imageBaseUrl } from "../../../../config";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MetaDecorator from "../../../../util/metaDecorator";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";
import { BiSolidErrorAlt } from "react-icons/bi";
import BreadcrumbNew from "../../../client_component/breadcrumbNew";
import CustomInfoDelivery from "./customInfoDelivery";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const Checkout = () => {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [openProdcess, setOpenProdcess] = useState(false);
  const navigate = useNavigate();
  const [receive, setReceive] = useState("home");
  const [paymentMethod, setPaymentmethod] = useState("bank_transfer");
  const [note, setNote] = useState("");
  const [openFixModel, setOpenFixModel] = useState(false);
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const [pickUpInfo, setPickUpInfo] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const CheckProductData = localStorage.getItem("selectedProducts");
  const combineCheckProductData = JSON.parse(CheckProductData);

  // const updateMemberCartData = JSON.parse

  const handleDeliveryInfoChange = (info) => {
    setDeliveryInfo(info);
  };
  const handlePickUpInfoChange = (info) => {
    setPickUpInfo(info);
  };

  // Đóng mở Fixquantity
  const handleClickOpen = (event) => {
    event.preventDefault();
    setOpenFixModel(true);
  };
  const handleClose = () => {
    setOpenFixModel(false);
  };

  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();

  // Tính tiền khi cập nhật giá || 0

  const subTotal = () => {
    let total = 0;
    if (user) {
      combineCheckProductData?.map((item) => {
        total += item.price * item.quality;
      });
      return total;
    }
    cart.cartItem
      .filter((item) => item.isChecked)
      .map((item) => {
        if (item.isChecked === true) {
          total +=
            item.listPrice && item.listPrice.length > 0
              ? item.listPrice[0].price * item.quality
              : item.price * item.quality;
        }
      });
    return total;
  };

  const totalCart = subTotal();

  function validateName(name) {
    var pattern = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\/-]/;
    if (pattern.test(name)) {
      return true;
    } else {
      return false;
    }
  }
  const handleCheckout = async () => {
    if (receive == "home") {
      if (!user) {
        if (deliveryInfo == null) {
          toast.custom(
            (t) => (
              <CustomToast
                icon={<BiSolidErrorAlt size={25} color="red" />}
                content={"Vui lòng nhập thông tin nhận hàng!"}
              />
            ),
            {
              position: "top-right",
            }
          );
        } else {
          setOpenProdcess(true);
          try {
            const res = await axiosClient.post("/member/checkout", {
              d_name: deliveryInfo.name,
              d_email: deliveryInfo.email,
              d_phone: deliveryInfo.phone,
              d_address: `${deliveryInfo.address} ${deliveryInfo.ward.label} ${deliveryInfo.district.label} ${deliveryInfo.city.label}`,
              orders: cart.cartItem.filter((item) => item.isChecked),
              shipping_method: receive,
              payment_method: paymentMethod,
              comment: "",
              note: note,
              total_cart: totalCart,
              total_price:
                cart.appliedPromotion != null
                  ? totalCart - cart.appliedPromotion.GiaTriCoupon
                  : totalCart,
              CouponDiscout:
                cart.appliedPromotion != null
                  ? cart.appliedPromotion.GiaTriCoupon
                  : "0",
              maCoupon:
                cart.appliedPromotion != null
                  ? cart.appliedPromotion.dataCouponDesc[0].MaCouponDes
                  : "",
              CouponId:
                cart.appliedPromotion != null
                  ? cart.appliedPromotion.dataCouponDesc[0].idCouponDes
                  : "",
              card: 0,
            });
            const orderId = res.data.orderId;
            if (res.data.status == true) {
              dispatch(removeCheckedCartItems());
              dispatch(clearAppliedPromotion());
              dispatch(fetchUserById());
              Cookies.set("orderId", orderId);
              navigate(`/complete?order_id=${orderId}`);
            } else if (
              res.data.status === false &&
              res.data.message == "The quantity does not exceed 1000"
            ) {
              toast.custom(
                (t) => (
                  <CustomToast
                    icon={<BiSolidErrorAlt size={25} color="red" />}
                    content={
                      "Số lượng sản phẩm trong giỏ vượt quá quy định. Vui lòng đặt lại!"
                    }
                  />
                ),
                {
                  position: "top-right",
                }
              );
            } else {
              toast.custom(
                (t) => (
                  <CustomToast
                    icon={<BiSolidErrorAlt size={25} color="red" />}
                    content={"Đã có lỗi xảy ra. Xin vui lòng thử lại!"}
                  />
                ),
                {
                  position: "top-right",
                }
              );
            }
          } catch (error) {
            console.error("fetch data error", error);
          } finally {
            // Tắt trạng thái xử lý
            setOpenProdcess(false);
          }
        }
      } else {
        setOpenProdcess(true);
        try {
          const res = await axiosClient.post("/member/checkout", {
            // d_name: userInfo.full_name,
            // d_email: userInfo.email,
            // d_phone: userInfo.phone,
            // d_address: `${userInfo.address} ${userInfo.district} ${userInfo.city_province}`,
            orders: combineCheckProductData.filter((item) => item.stock === 1),
            shipping_method: receive,
            payment_method: paymentMethod,
            comment: "",
            note: note,
            total_cart: totalCart,
            total_price:
              cart.appliedPromotion != null
                ? totalCart - cart.appliedPromotion.GiaTriCoupon
                : totalCart,
            CouponDiscout:
              cart.appliedPromotion != null
                ? cart.appliedPromotion.GiaTriCoupon
                : 0,
            maCoupon:
              cart.appliedPromotion != null
                ? cart.appliedPromotion.dataCouponDesc[0].MaCouponDes
                : "",
            CouponId:
              cart.appliedPromotion != null
                ? cart.appliedPromotion.dataCouponDesc[0].idCouponDes
                : "",
            card: cart.appliedCard != null ? cart.appliedCard.GiaTriCard : 0,
          });
          const orderId = res.data.orderId;

          if (res.data.status == true) {
            dispatch(removeCheckedCartItems());
            dispatch(clearAppliedPromotion());
            dispatch(fetchUserById());
            localStorage.removeItem("selectedProducts");
            Cookies.set("orderId", orderId);
            navigate(`/complete?order_id=${orderId}`);
          } else if (
            res.data.status === false &&
            res.data.message == "The quantity does not exceed 1000"
          ) {
            toast.custom(
              (t) => (
                <CustomToast
                  icon={<BiSolidErrorAlt size={25} color="red" />}
                  content={
                    "Số lượng sản phẩm trong giỏ vượt quá quy định. Vui lòng đặt lại!"
                  }
                />
              ),
              {
                position: "top-right",
              }
            );
          } else {
            toast.custom(
              (t) => (
                <CustomToast
                  icon={<BiSolidErrorAlt size={25} color="red" />}
                  content={"Đã có lỗi xảy ra. Xin vui lòng thử lại!"}
                />
              ),
              {
                position: "top-right",
              }
            );
          }
        } catch (error) {
          console.error("fetch data error", error);
        } finally {
          // Tắt trạng thái xử lý
          setOpenProdcess(false);
        }
      }
    } else {
      if (!user) {
        if (
          pickUpInfo.name == "" &&
          pickUpInfo.phone == "" &&
          pickUpInfo.email == ""
        ) {
          toast.custom(
            (t) => (
              <CustomToast
                icon={<BiSolidErrorAlt size={25} color="red" />}
                content={"Vui lòng nhập thông tin nhận hàng!"}
              />
            ),
            {
              position: "top-right",
            }
          );
        } else {
          const updatedValidationErrors = {
            ...validationErrors,
            name:
              pickUpInfo.name.trim() === ""
                ? "Vui lòng nhập tên."
                : validateName(pickUpInfo.name)
                ? "Tên không được có ký tự đặc biệt"
                : "",
            phone:
              pickUpInfo.phone.trim() === ""
                ? "Vui lòng nhập số điện thoại."
                : !/(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/.test(
                    pickUpInfo.phone
                  )
                ? "vui lòng nhập đúng số điện thoại"
                : "",
            email:
              pickUpInfo.email.trim() === ""
                ? "Vui lòng nhập email."
                : !/^\S+@\S+\.\S+$/.test(pickUpInfo.email)
                ? "Vui lòng nhập đúng email"
                : "",
          };
          setValidationErrors(updatedValidationErrors);
          if (
            updatedValidationErrors.name == "" &&
            updatedValidationErrors.phone == "" &&
            updatedValidationErrors.email == ""
          ) {
            setOpenProdcess(true);
            try {
              const res = await axiosClient.post("/member/checkout", {
                d_name: pickUpInfo.name,
                d_email: pickUpInfo.email,
                d_phone: pickUpInfo.phone,
                d_address:
                  "245B Trần Quang Khải, Phường Tân Định, Quận 1, Hồ Chí Minh",
                orders: cart.cartItem.filter((item) => item.isChecked),
                shipping_method: receive,
                payment_method: paymentMethod,
                comment: "",
                note: note,
                total_cart: totalCart,
                total_price:
                  cart.appliedPromotion != null
                    ? totalCart - cart.appliedPromotion.GiaTriCoupon
                    : totalCart,
                CouponDiscout:
                  cart.appliedPromotion != null
                    ? cart.appliedPromotion.GiaTriCoupon
                    : 0,
                maCoupon:
                  cart.appliedPromotion != null
                    ? cart.appliedPromotion.dataCouponDesc[0].MaCouponDes
                    : "",
                CouponId:
                  cart.appliedPromotion != null
                    ? cart.appliedPromotion.dataCouponDesc[0].idCouponDes
                    : "",
                card: 0,
              });
              const orderId = res.data.orderId;

              if (res.data.status == true) {
                dispatch(removeCheckedCartItems());
                dispatch(clearAppliedPromotion());
                dispatch(fetchUserById());
                Cookies.set("orderId", orderId);

                navigate(`/complete?order_id=${orderId}`);
              } else if (
                res.data.status === false &&
                res.data.message == "The quantity does not exceed 1000"
              ) {
                toast.custom(
                  (t) => (
                    <CustomToast
                      icon={<BiSolidErrorAlt size={25} color="red" />}
                      content={
                        "Số lượng sản phẩm trong giỏ vượt quá quy định. Vui lòng đặt lại!"
                      }
                    />
                  ),
                  {
                    position: "top-right",
                  }
                );
              } else {
                toast.custom(
                  (t) => (
                    <CustomToast
                      icon={<BiSolidErrorAlt size={25} color="red" />}
                      content={"Đã có lỗi xảy ra. Xin vui lòng thử lại!"}
                    />
                  ),
                  {
                    position: "top-right",
                  }
                );
              }
            } catch (error) {
              console.error("fetch data error", error);
            } finally {
              // Tắt trạng thái xử lý
              setOpenProdcess(false);
            }
          }
        }
      } else {
        setOpenProdcess(true);
        try {
          const res = await axiosClient.post("/member/checkout", {
            // d_name: userInfo.full_name,
            // d_email: userInfo.email,
            // d_phone: userInfo.phone,
            d_address:
              "245B Trần Quang Khải, Phường Tân Định, Quận 1, Hồ Chí Minh",
            orders: combineCheckProductData,
            shipping_method: receive,
            payment_method: paymentMethod,
            comment: "",
            note: note,
            total_cart: totalCart,
            total_price:
              cart.appliedPromotion != null
                ? totalCart - cart.appliedPromotion.GiaTriCoupon
                : totalCart,
            CouponDiscout:
              cart.appliedPromotion != null
                ? cart.appliedPromotion.GiaTriCoupon
                : 0,
            maCoupon:
              cart.appliedPromotion != null
                ? cart.appliedPromotion.dataCouponDesc[0].MaCouponDes
                : "",
            CouponId:
              cart.appliedPromotion != null
                ? cart.appliedPromotion.dataCouponDesc[0].idCouponDes
                : "",
            card: cart.appliedCard != null ? cart.appliedCard.GiaTriCard : 0,
          });

          if (res.data.status == true) {
            dispatch(removeCheckedCartItems());
            dispatch(clearAppliedPromotion());
            dispatch(fetchUserById());
            localStorage.removeItem("selectedProducts");
            navigate("/complete");
          } else if (
            res.data.status === false &&
            res.data.message == "The quantity does not exceed 1000"
          ) {
            toast.custom(
              (t) => (
                <CustomToast
                  icon={<BiSolidErrorAlt size={25} color="red" />}
                  content={
                    "Số lượng sản phẩm trong giỏ vượt quá quy định. Vui lòng đặt lại!"
                  }
                />
              ),
              {
                position: "top-right",
              }
            );
          } else {
            toast.custom(
              (t) => (
                <CustomToast
                  icon={<BiSolidErrorAlt size={25} color="red" />}
                  content={"Đã có lỗi xảy ra. Xin vui lòng thử lại!"}
                />
              ),
              {
                position: "top-right",
              }
            );
          }
        } catch (error) {
          console.error("fetch data error", error);
        } finally {
          setOpenProdcess(false);
        }
      }
    }
  };

  const formatNumer = (number) => {
    return new Intl.NumberFormat("vi", {
      style: "currency",
      currency: "VND",
    }).format(number);
  };
  const breadcrumbLink = [
    { id: 1, name: "Giỏ hàng", link: `/gio-hang` },
    { id: 2, name: "Thanh toán", link: `/check-out` },
  ];
  return (
    <div>
      <MetaDecorator description="Check out" title="Check out" />
      <Container className="checkout-container-model">
        <div className="mt-3">
          <BreadcrumbNew arrayLink={breadcrumbLink} />
        </div>
        <div className="checkout-container">
          <div className="checkout-container-left">
            <div className="choose-delivery-method-container">
              <div className="choose-delivery-method-list">
                <div
                  className={`${
                    receive == "home" ? "active-choose-delivery" : ""
                  } choose-delivery-item`}
                  onClick={() => setReceive("home")}
                >
                  Giao hàng tận nơi
                </div>
                <div
                  className={`${
                    receive == "free" ? "active-choose-delivery" : ""
                  } choose-delivery-item`}
                  onClick={() => setReceive("free")}
                >
                  Nhận hàng tại cửa hàng
                </div>
              </div>
              <div>
                {receive == "home" ? (
                  // <HomeDelivery
                  //   onDeliveryInfoChange={handleDeliveryInfoChange}
                  // />

                  <CustomInfoDelivery
                    onDeliveryInfoChange={handleDeliveryInfoChange}
                  />
                ) : (
                  <PickUP
                    onPickUpInfoChange={handlePickUpInfoChange}
                    validationErrors={validationErrors}
                  />
                )}
              </div>
            </div>
            <div className="note-container">
              <p>Ghi chú cho đơn hàng</p>
              <input
                className="note-input"
                type="text"
                name="note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Nhập thông tin ghi chú cho đơn hàng"
              />
            </div>
            <div className="choose-payment-method-container">
              <p className="choose-payment-method-title">
                Phương thức thanh toán
              </p>
              <p className="choose-payment-method-desc">
                Thông tin thanh toán của bạn sẽ luôn được bảo mật
              </p>
              <div className="choose-payment-method-list">
                {/* <div
                  onClick={() => setPaymentmethod("cash")}
                  className={`${
                    paymentMethod == "cash" ? "active-choose" : ""
                  } choose-payment-method-item`}
                >
                  Thanh toán khi nhận hàng
                  <CheckLg
                    className={`${
                      paymentMethod == "cash" ? "icon-check" : "d-none"
                    }`}
                  />
                </div> */}
                <div
                  onClick={() => setPaymentmethod("bank_transfer")}
                  className={`${
                    paymentMethod == "bank_transfer" ? "active-choose" : ""
                  } choose-payment-method-item ms-3`}
                >
                  Thanh toán chuyển khoản trực tiếp
                  <CheckLg
                    className={`${
                      paymentMethod == "bank_transfer" ? "icon-check" : "d-none"
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="checkout-container-right">
            <div className="Information-line-container">
              <div className="Information-line">
                <div className="Information-line-title">Thông tin đơn hàng</div>

                {user ? (
                  <Link className="fix-btn" to={"/gio-hang"}>
                    Chỉnh sửa
                  </Link>
                ) : (
                  <div onClick={handleClickOpen} className="fix-btn">
                    Chỉnh sửa
                  </div>
                )}
              </div>

              {/* khi có member */}
              {user ? (
                <>
                  {combineCheckProductData
                    ?.filter((item) => item.stock === 1)
                    .map((item) => {
                      return (
                        <div className="Information-line-content">
                          <div className="d-flex align-items-center">
                            <div>
                              <img
                                className="Information-line-image"
                                src={`${imageBaseUrl}/uploads/${item.picture}`}
                                alt={`${item.title}`}
                                loading="lazy"
                              />
                            </div>
                            <div className="">
                              <div className="Information-line-content-title-product-name">
                                {item.title}
                              </div>
                              <div className="Information-line-content__quantity">
                                Số lượng: {item.quality}
                              </div>
                              <div className="Information-line-content__price">
                                {formatNumer(item.price * item.quality)}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                // không có member
                <>
                  {cart.cartItem
                    .filter((item) => item.isChecked)
                    .map((item) => {
                      return (
                        <div className="Information-line-content">
                          <div className="d-flex align-items-center">
                            <div>
                              <img
                                className="Information-line-image"
                                src={`${imageBaseUrl}/uploads/${
                                  item.listPrice && item.listPrice.length > 0
                                    ? item.listPrice[0].picture
                                    : item.picture
                                }`}
                                alt={`${item.productName}`}
                                loading="lazy"
                              />
                            </div>
                            <div className="Information-line-content-title">
                              <div className="Information-line-content-title-product-name">
                                {item.productName}
                              </div>
                              <div className="Information-line-content__quantity">
                                Số lượng: {item.quality}
                              </div>
                              <div className="Information-line-content__price">
                                {formatNumer(
                                  item.listPrice && item.listPrice?.length > 0
                                    ? item.listPrice[0].price * item.quality
                                    : item.price * item.quality
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
            {cart.appliedPromotion != null && user ? (
              <div className="technical-support-container">
                <div className="technical-support-container__applied">
                  Khuyến mãi áp dụng
                </div>
                <div className="technical-support-container__name">
                  {cart.appliedPromotion.TenCoupon}
                </div>
              </div>
            ) : null}
            {cart.appliedCard != null && user ? (
              <div className="technical-support-container">
                <div className="technical-support-container__applied">
                  Tặng:{" "}
                </div>
                <div>{cart.appliedCard.title}</div>
              </div>
            ) : null}
            {/* <div className="technical-support-container">
              <h5>Hỗ trợ kỹ thuật</h5>

              <a href="tel:19006739">1900 6739</a>
            </div> */}
            {/* {user && CardValue() > 0 &&
                            <div className="promotions mb-3">
                                <div>Chúc mừng bạn được nhận thẻ cào trị giá: {formatNumer(CardValue())}</div>
                            </div>
                        } */}

            <div className="payment-container">
              <div className="payment-item">
                <div>Tổng tạm tính</div>
                <div>{formatNumer(totalCart)}</div>
              </div>
              <div className="payment-item">
                <div>Giảm giá</div>
                <div>
                  {cart.appliedPromotion != null
                    ? formatNumer(cart.appliedPromotion.GiaTriCoupon)
                    : formatNumer(0)}
                </div>
              </div>
              <div className="payment-item my-2">
                <div>Phí vận chuyển</div>
                <div>Miễn phí</div>
              </div>
              <div className="payment-item">
                <div>Thành tiền</div>
                <div className="d-flex flex-column align-items-end">
                  <div className="float-end into-money">
                    {cart.appliedPromotion
                      ? formatNumer(
                          totalCart - cart.appliedPromotion.GiaTriCoupon
                        )
                      : formatNumer(totalCart)}
                  </div>
                  <div className="checkout-vat">(Đã bao gồm VAT)</div>
                </div>
              </div>
              <div onClick={handleCheckout} className="continue-btn">
                <div className="continue-btn-title">Thanh toán</div>
              </div>
              <div className="note-payment">
                <p>
                  Nhấn "Thanh toán" đồng nghĩa với việc bạn đọc và đồng ý tuân
                  theo
                  <Link
                    className="ms-2"
                    rel="nofollow"
                    title="điều khoản và điều kiện"
                    to="/cac-chinh-sach-bao-hanh-va-quy-dinh/quy-dinh-thanh-toan"
                  >
                    Điều khoản và Điều kiện
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>

      {/* Chỉnh sửa cập nhật giá cả sản phẩm */}
      <FixQuantity open={openFixModel} close={handleClose} />
      {openProdcess && (
        <Modal
          open={openProdcess}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="paypalProcessing">
              <div className="animation">
                <div className="container">
                  <div className="balls">
                    <div className="ball ball-one" />
                    <div className="ball ball-two" />
                    <div className="ball ball-three" />
                    <div className="ball ball-four" />
                    <div className="shadow shadow-one" />
                    <div className="shadow shadow-two" />
                    <div className="shadow shadow-three" />
                    <div className="shadow shadow-four" />
                  </div>
                </div>
              </div>
              <div className="text">
                <h1>Đang xử lý</h1>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default Checkout;
