import { useState } from "react";
import { Spin } from "antd";
import { addArrayCart } from "../services/cartService";
import { useNavigate } from "react-router-dom";

const ButtonRepurchase = ({ arrProduct }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleRepurchase = async () => {
    try {
      setIsLoading(true); // Bật trạng thái loading
      const res = await addArrayCart(arrProduct);

      if (res?.data?.status) {
        navigate("/gio-hang"); // Điều hướng nếu thành công
      } else {
        console.error("Repurchase failed: Invalid response status");
      }
    } catch (error) {
      console.error("Repurchase error:", error);
    } finally {
      setIsLoading(false); // Đảm bảo tắt trạng thái loading dù thành công hay thất bại
    }
  };

  return (
    <>
      <div onClick={handleRepurchase} className="btn_repurchase">
        <span>Mua lại sản phẩm</span>
      </div>
      <Spin spinning={isLoading} fullscreen />
    </>
  );
};

export default ButtonRepurchase;
