import "./CustomToast.css";
import toast from "react-hot-toast";
import { FiX } from "react-icons/fi";
const CustomToast = ({ icon, content, id }) => {
  const handleClose = () => {
    toast.dismiss(id);
  };

  return (
    <div className="custom-toast">
      {icon && <span className="toast-icon">{icon}</span>}
      <span className="toast-content">{content}</span>
      <button className="toast-close" onClick={handleClose}>
        <FiX size={25} />
      </button>
    </div>
  );
};

export default CustomToast;
