import { useParams } from "react-router-dom";
import * as guideService from "../../../services/guideService";
import CircularProgress from "@mui/material/CircularProgress";
import "../public/warrantyPolicyMain.css";
import React, { useState, useEffect } from "react";
const WarrantyDetail = () => {
  const { nameWarranty } = useParams();
  const [guideDetailData, setGuideDetailData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const fetchGuideDetailData = () => {
    guideService
      .getGuideDetail(nameWarranty)
      .then((data) => {
        setGuideDetailData(data.list);
        setIsLoaded(true);
      })
      .catch((error) => console.error("fetch data error", error));
  };
  useEffect(() => {
    fetchGuideDetailData();
  }, [nameWarranty]);
  return (
    <>
      {isLoaded ? (
        <div>
          <h2 className="text-center text-uppercase fw-bolder text-title">
            {guideDetailData.title}
          </h2>
          <div className="news-detail-container-conetent">
            {guideDetailData.description != "" ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: guideDetailData.description,
                }}
              ></div>
            ) : (
              <div className="no-introduce">Không có bài viết</div>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <CircularProgress />
          </div>
        </div>
      )}
    </>
  );
};

export default WarrantyDetail;
