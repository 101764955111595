import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../public/detailedSpecificateModel.css";

const DetailedSpecificateModel = ({ open, close, subList }) => {
  return (
    <Dialog open={open} onClose={close}>
      <div className="detailed-specificate-model-container detailed-specificate-model-container-detail">
        <DialogTitle className="productComparisonTitle" id="alert-dialog-title">
          Thông số chi tiết
        </DialogTitle>
        <DialogContent className="detailed-specificate-model-content detailed-specificate-model-content-detail">
          <TableContainer
            className="detailed-specificate-model-table detailed-specificate-model-table-detail"
            component={Paper}
          >
            <Table sx={{ width: "100%" }} aria-label="spanning table">
              <TableBody>
                {subList?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="detailed-specificate-col-title-main-model">
                        {item.catOption}
                      </TableCell>
                      <TableCell
                        className="detailed-specificate-col-title-model"
                        align="left"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              item.nameCatOption !== null
                                ? item.nameCatOption
                                : "",
                          }}
                        ></div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogContent className="detailed-specificate-model-content-moblie">
          <TableContainer
            className="detailed-specificate-model-table"
            component={Paper}
          >
            <Table sx={{ width: "100%" }} aria-label="spanning table">
              <TableBody>
                {subList?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="detailed-specificate-col-title-main-model">
                        {item.catOption}
                      </TableCell>
                      <TableCell
                        className="detailed-specificate-col-title-model"
                        align="left"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              item.nameCatOption !== null
                                ? item.nameCatOption
                                : "",
                          }}
                        ></div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default DetailedSpecificateModel;
