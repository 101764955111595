import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../../breadcrumb";
import { axiosClient } from "../../../../config";
import MetaDecorator from "../../../../util/metaDecorator";

const ServiceDetail = () => {
  const { NameService } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [serviceDetail, setServiceDetail] = useState([]);
  const fetchServiceDetail = async () => {
    try {
      const res = await axiosClient.get(
        "/member/service-detail/" + NameService
      );
      setServiceDetail(res.data.service);
      setIsLoaded(true);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };

  useEffect(() => {
    fetchServiceDetail();
  }, []);

  const breadcrumbLink = [
    { id: 1, name: "Dịch vụ", url: `/dich-vu` },
    {
      id: 2,
      name: serviceDetail.title,
      url: `/dich-vu/${serviceDetail.friendly_url}`,
    },
  ];

  return (
    <>
      <MetaDecorator
        title={serviceDetail.friendly_title}
        description={
          serviceDetail.metadesc != null ? serviceDetail.metadesc : ""
        }
      />
      <Container>
        <div className="news-detail-container">
          <div>
            {!isLoaded ? (
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  <CircularProgress />
                </div>
              </div>
            ) : (
              <div>
                <div className="mb-4">
                  <Breadcrumb arrayLink={breadcrumbLink} />
                </div>
                <h2 className="news-detail-container-title">
                  {serviceDetail.title}
                </h2>
                <div className="news-detail-container-conetent">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: serviceDetail.description,
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
    // <div dangerouslySetInnerHTML={{ __html: serviceDetail.description }}></div>
  );
};

export default ServiceDetail;
