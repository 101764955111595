import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Container, Row } from "react-bootstrap";
import { PiEnvelopeSimpleThin } from "react-icons/pi";
import { axiosClient } from "../../../../config";
import MetaDecorator from "../../../../util/metaDecorator";
import "../public/businessConsultancy.css";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { BiSolidErrorAlt } from "react-icons/bi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import CustomToast from "../../../client_component/alertCustom";

const ConsultantList = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [categories, setCategories] = useState([]);

  const fetchConsultantCategory = async () => {
    try {
      const response = await axiosClient.get(`/member/show-all-faqsCate`);
      if (response.data.status === true) {
        setCategories(response.data.data.reverse());
      }
    } catch (error) {
      console.error("Fetch consultant category error", error);
    }
  };

  useEffect(() => {
    fetchConsultantCategory();
  }, []);

  const handleCategoryClick = (slug) => {
    navigate(`/tu-van/${slug}`);
  };

  const handleSubmit = async (values) => {
    try {
      const responses = await axiosClient.post("/member/add-faqs", {
        fullName: values.fullName,
        email: values.email,
        category: values.contactTo,
        contentQuestion: values.content,
      });

      if (responses.data.status === true) {
        toast.custom(
          (t) => (
            <CustomToast
              icon={<IoIosCheckmarkCircle size={25} color="green" />}
              content={"Yêu cầu tư vấn đã được gửi."}
            />
          ),
          {
            position: "top-right",
          }
        );
      }
    } catch (error) {
      console.error("Post questions for consultant error", error);
      toast.custom(
        (t) => (
          <CustomToast
            icon={<BiSolidErrorAlt size={25} color="red" />}
            content={"Đã xảy ra lỗi. Vui lòng thử lại!!!"}
          />
        ),
        {
          position: "top-right",
        }
      );
    }
  };

  return (
    <div>
      <MetaDecorator description="Đặt câu hỏi" title="Đặt câu hỏi" />
      <Container className="questions-container">
        <h2>ĐẶT CÂU HỎI</h2>
        <div className="questions-category">
          <ul>
            {categories &&
              categories.length > 0 &&
              categories.map((item) => (
                <li
                  style={{
                    backgroundColor: item?.friendly_url === slug && "#0459aa",
                    color: item?.friendly_url === slug && "white",
                  }}
                  onClick={() => handleCategoryClick(item?.friendly_url)}
                  key={item?.cat_id}
                >
                  {item?.cat_name}
                </li>
              ))}
          </ul>
        </div>
        <div className="questions-form">
          <Formik
            initialValues={{
              fullName: "",
              email: "",
              contactTo: "",
              content: "",
            }}
            validationSchema={Yup.object({
              fullName: Yup.string().required("Họ tên là bắt buộc"),
              email: Yup.string()
                .email("Địa chỉ email không hợp lệ")
                .required("Địa chỉ email là bắt buộc"),
              contactTo: Yup.string().required("Chuyên mục hỏ là bắt buộc"),
              content: Yup.string().required("Nội dung là bắt buộc"),
            })}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="questions-form__box">
                  <label>
                    Họ tên <span>(*)</span>
                  </label>
                  <div className="questions-form__input">
                    <Field name="fullName" type="text" />
                    <ErrorMessage
                      className="error_msg"
                      name="fullName"
                      component="div"
                    />
                  </div>
                </div>

                <div className="questions-form__box">
                  <label>
                    Email <span>(*)</span>
                  </label>
                  <div className="questions-form__input">
                    <Field name="email" type="email" />
                    <ErrorMessage
                      className="error_msg"
                      name="email"
                      component="div"
                    />
                  </div>
                </div>

                <div className="questions-form__box">
                  <label>Chuyên mục</label>
                  <div className="questions-form__input">
                    <Field as="select" name="contactTo" type="text">
                      <option value="">Chọn mục tư vấn</option>
                      {categories && categories.length > 0
                        ? categories.map((option) => (
                            <option key={option.cat_id} value={option.cat_id}>
                              {option.cat_name}
                            </option>
                          ))
                        : []}
                    </Field>
                  </div>
                </div>

                <div className="questions-form__box">
                  <label>
                    Nội dung câu hỏi <span>(*)</span>
                  </label>
                  <div className="questions-form__input">
                    <Field
                      name="content"
                      as="textarea"
                      style={{ height: "100px" }}
                    />
                    <ErrorMessage
                      className="error_msg"
                      name="content"
                      component="div"
                    />
                  </div>
                </div>

                <div className="questions-form__box">
                  <label></label>
                  <div className="form-buttons">
                    <button
                      type="submit"
                      className="questions-form__submit d-flex align-items-center gap-2"
                    >
                      <PiEnvelopeSimpleThin />
                      Gửi
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export default ConsultantList;
