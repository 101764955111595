import { useEffect, useState } from "react";
import { getBannerLeftRight } from "../../../../services/advertiseService";
import { imageBaseUrl } from "../../../../../config";
import { Link } from "react-router-dom";
import LazyImage from "../../../../../util/lazyImage";
const AdvertisingBanner = ({ classCss }) => {
  const [isVisible, setIsVisible] = useState(false); // Trạng thái hiển thị banner
  const [dataBanner, setDataBanner] = useState([]);
  useEffect(() => {
    const handleChangeBanner = () => {
      const carousel = document.getElementById("check_carousel");
      const header = document.getElementById("header");

      if (carousel && header) {
        const threshold = carousel.offsetHeight + header.offsetHeight;
        if (window.scrollY >= threshold && window.scrollY < 12000) {
          setIsVisible(true); // Hiện banner
        } else {
          setIsVisible(false); // Ẩn banner
        }
      }
    };

    window.addEventListener("scroll", handleChangeBanner);

    // Cleanup sự kiện khi component unmount
    return () => {
      window.removeEventListener("scroll", handleChangeBanner);
    };
  }, []);

  const fetchFooterBannerData = async () => {
    try {
      const data = await getBannerLeftRight();
      setDataBanner(data);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };
  useEffect(() => {
    fetchFooterBannerData();
  }, []);

  return isVisible && dataBanner?.[0]?.link && dataBanner?.[1]?.link ? (
    <>
      <div className={`container_banner_advertising_right d-none d-xxl-block`}>
        <Link to={dataBanner?.[0]?.link}>
          <div className="box_container_banner_right">
            <LazyImage
              classCss={"img_banner_right"}
              alt={"banner_ticky_right"}
              src={dataBanner?.[0]?.picture}
              width={null}
              height={null}
              effect={"blur"}
            />
          </div>
        </Link>
      </div>

      <div className={`container_banner_advertising_left d-none d-xxl-block`}>
        <Link to={dataBanner?.[1]?.link}>
          <div className="box_container_banner_right">
            <LazyImage
              classCss={"img_banner_right"}
              alt={"banner_ticky_right"}
              src={dataBanner?.[1]?.picture}
              width={null}
              height={null}
              effect={"blur"}
            />
          </div>
        </Link>
      </div>
    </>
  ) : null;
};

export default AdvertisingBanner;
