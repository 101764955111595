import React, { useEffect, useState } from "react";
import SubMenu from "./subMenu";
import { Link } from "react-router-dom";
import { axiosClient } from "../../../../../config";
import "../../public/menu/menu_main.css";

const Menumain = () => {
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const res = await axiosClient.get("/member/category-menu");
        if (res.data.message == "Fetched from database") {
          setMenu(res.data.data);
        }
      } catch (error) {
        console.error("Lỗi không lấy được thông tin danh mục menu.", error);
      }
    };
    fetchMenuData();
  }, []);

  return (
    <div className="menu-block">
      <div className="cate-item">
        {Array.isArray(menu) &&
          menu
            ?.filter((item) => item.menu_desc.menu_id != 858)
            .map((item, index) => {
              return (
                <div key={item.menu_id}>
                  <Link
                    title={item.menu_desc.title}
                    to={
                      item.menu_desc.menu_id == 858
                        ? "/goc-cong-nghe/tin-noi-bo"
                        : item.menu_desc.menu_id == 848
                        ? `/${`danh-muc/${item.menu_desc.link}`}`
                        : `/${`danh-muc/${item.menu_desc.link}`}`
                    }
                    className="d-flex align-items-center cate-items"
                  >
                    <p className="cate-item-title">{item.menu_desc.title}</p>
                    <div className="sub-menu">
                      {item.parenty ? (
                        <SubMenu
                          service={item.menu_desc.menu_id}
                          subItem={item.parenty}
                          categoryParentLink={item.menu_desc.link}
                        />
                      ) : null}
                    </div>
                  </Link>
                </div>
              );
            })}
      </div>
    </div>
  );
};
export default Menumain;
