import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";
import { axiosClient, imageBaseUrl } from "../../../../config";
import { useEffect } from "react";
import {
  calculateRemaining,
  calculateUpdate,
} from "../../../../util/commonFunction";
import { Link } from "react-router-dom";

function BoxJobRelated({ dataJob }) {
  const [listJobs, setListJobs] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchDataJobRelated = async () => {
    setLoading(true);
    try {
      const res = await axiosClient.get(
        `/member/show-hire-related?hireCateId=${dataJob.hire_cate_id}&postId=${dataJob.id}`
      );
      setListJobs(res.data.data);
      setLoading(false);
    } catch (error) {
      console.error("fetch data error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataJobRelated();
  }, []);

  return (
    <div className="box_container_job_related">
      <Row>
        <Col md={12} className="box_infor_job_common">
          <div className="box_text">
            <span>Việc làm liên quan</span>
          </div>
        </Col>
      </Row>

      {!loading && (
        <Row>
          {listJobs &&
            listJobs.length > 0 &&
            listJobs?.map((item, index) => (
              <Col md={12} key={item.id} className="box_item_job_related p-3">
                <Link to={`/tuyen-dung/${item.slug}`}>
                  <Row className="mx-0">
                    <Col md="3" className="p-3 col_img_job_related">
                      <div className="box_img_job_related is-5by4 image">
                        <img
                          className="w-100 h-100"
                          src={imageBaseUrl + "/uploads/" + item.image}
                        />
                      </div>
                    </Col>
                    <Col md="9" className="">
                      <div className="box_name_job_related">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 100, hide: 200 }}
                          overlay={<Tooltip> {item.name} </Tooltip>}
                        >
                          <span>{item.name}</span>
                        </OverlayTrigger>
                      </div>
                      <div className="box_company_job_related">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 100, hide: 200 }}
                          overlay={<Tooltip>{item.department} </Tooltip>}
                        >
                          <span>{item.department}</span>
                        </OverlayTrigger>
                      </div>
                    </Col>
                    <Col md="12" className="mt-3 px-0">
                      <div className="time_job_2 d-flex ">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 100, hide: 200 }}
                          overlay={<Tooltip>{item.address}</Tooltip>}
                        >
                          <span className="text_time_job">{item.address}</span>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 100, hide: 200 }}
                          overlay={
                            <Tooltip>
                              {" "}
                              Còn {calculateRemaining(item.deadline)} ngày để
                              ứng tuyển
                            </Tooltip>
                          }
                        >
                          <span className="text_time_job">
                            Còn {calculateRemaining(item.deadline)} ngày để ứng
                            tuyển
                          </span>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 100, hide: 200 }}
                          overlay={
                            <Tooltip>
                              {" "}
                              Cập nhật {calculateUpdate(item.updated_at)}{" "}
                            </Tooltip>
                          }
                        >
                          <span className="text_time_job">
                            Cập nhật {calculateUpdate(item.updated_at)}
                          </span>
                        </OverlayTrigger>
                      </div>
                    </Col>
                  </Row>
                </Link>
              </Col>
            ))}

          <Col md="12" className="col-12"></Col>
        </Row>
      )}
    </div>
  );
}

export default BoxJobRelated;
