// Import các dependencies và action creators

import { Toaster } from "react-hot-toast";
import MenuAccount from "../../../client_component/menuAccount";
import MetaDecorator from "../../../../util/metaDecorator";
import { Col, Container, Row } from "react-bootstrap";
import { Form, Input, Space, Button } from "antd";
import { changePassword } from "../../../services/authService";
import { useNavigate } from "react-router-dom";
import BreadcrumbNew from "../../../client_component/breadcrumbNew";

// Component Login
const ChangePassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleChangePassword = async (values) => {
    try {
      const res = await changePassword(values);

      if (res.data.status === true) {
        localStorage.removeItem("user_token");
        sessionStorage.removeItem("vtnkus");

        navigate("/login");
      } else {
        form.setFields([
          {
            name: "currentPassword",
            errors: ["Mật khẩu hiện tại không đúng!"],
          },
        ]);
      }
    } catch (error) {
      console.error("post data error");
    }
  };

  const breadcrumbLink = [
    { id: 1, name: "Đổi mật khẩu", link: `/account/change-password` },
  ];

  return (
    <>
      <Toaster />
      <MetaDecorator title="Đổi mật khẩu" description="Đổi mật khẩu" />
      <Container>
        <div className="container_account">
          <div className="mt-1">
            <BreadcrumbNew arrayLink={breadcrumbLink} />
          </div>
          <Row>
            <MenuAccount />
            <Col md={8} lg={9} className="mt-2 mt-md-0">
              <div className="box_form_info_user">
                <div className="box_change_password d-flex justify-content-center d-flex align-items-center">
                  <div className="box_form_change_pass  ">
                    <Form
                      requiredMark="optional"
                      onFinish={handleChangePassword}
                      form={form}
                      layout="vertical"
                      autoComplete="off"
                    >
                      <Form.Item
                        hasFeedback
                        label="Mật khẩu hiện tại"
                        name="currentPassword"
                        validateTrigger="onBlur"
                        rules={[
                          {
                            required: true,
                            message: "Nhập mật khẩu hiện tại",
                          },
                        ]}
                      >
                        <Input.Password placeholder="Nhập mật khẩu hiện tại" />
                      </Form.Item>

                      <Form.Item
                        hasFeedback
                        label="Mật khẩu mới"
                        name="newPassword"
                        validateDebounce={1000}
                        rules={[
                          {
                            required: true,
                            message: "Hãy nhập mật khẩu!",
                          },
                          {
                            pattern: /^(?=.*[A-Z])(?=.*[!@#$&*])/,
                            message:
                              "Mật khẩu phải chứa ít nhất một chữ cái viết hoa và một ký tự đặc biệt!",
                          },
                          {
                            min: 8,
                            message: "Mật khẩu phải dài hơn 8 ký tự!",
                          },
                        ]}
                      >
                        <Input.Password placeholder="Nhập mật khẩu mới" />
                      </Form.Item>

                      <Form.Item
                        dependencies={["passNew"]}
                        hasFeedback
                        label="Nhập lại mật khẩu mới"
                        name="enterNewPassword"
                        validateFirst
                        rules={[
                          {
                            required: true,
                            message: "Nhập lại mật khẩu mới",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("newPassword") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Mật khẩu chưa trùng khớp")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="Nhập lại mật khẩu mới" />
                      </Form.Item>

                      <Form.Item className="mb-0">
                        <Space className="d-flex justify-content-center">
                          <Button htmlType="submit">Lưu thay đổi</Button>
                        </Space>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default ChangePassword;
