import { axiosClient } from "../../config";

export const getProduct = async () => {
  try {
    const response = await axiosClient.get("/member/category");
    const data = response.data.data;
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
    throw error;
  }
};

export const getAllProductHot = async () => {
  return await axiosClient
    .get("/member/show-all-product-hot")
    .then((response) => {
      if (response.data.status == true) {
        return response.data;
      }
    })
    .catch((error) => error.response);
};

export const getProductHot = async () => {
  return await axiosClient
    .get("/member/product-hot")
    .then((response) => {
      if (response.data.status == true) {
        return response.data;
      }
    })
    .catch((error) => error.response);
};

export const getProductDetail = async (payload) => {
  return await axiosClient
    .get("/member/product-detail/" + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};
