import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { GiftFill } from 'react-bootstrap-icons';
import '../public/productPromotion.css';

const ProductPromotion = ({ open, close }) => {
    return (
        <Dialog open={open} onClose={close}>
            <div className="product-promotion-model">
                <DialogTitle className="product-promotion-title" id="alert-dialog-title">
                    Khuyến mãi sản phẩm
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className="product-promotion-content">
                            <div className='product-promotion-content-container'>
                                <p className="product-promotion-content-title">Chọn 1 trong các khuyến mãi sau</p>
                                <div className="product-promotion-item-container">
                                    <div className="product-promotion-item">
                                        <div>
                                            <div className="product-promotion-icon-gift">
                                                <GiftFill />
                                            </div>
                                        </div>
                                        <div className="product-promotion-item-name">
                                            <div>
                                                <p>1x Bộ voucher Sinh nhật 20 năm Nguyên Kim</p>
                                                <p>Đơn tối thiểu 10.000.000đ</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <p>HSD: 31/08/2023</p>
                                                <p className="cancel-btn">Bỏ chọn</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div>
                        <div onClick={close} className="close-product-promotion-model">
                            Đóng
                        </div>
                    </div>
                </DialogActions>
            </div>
        </Dialog>
    );
};

export default ProductPromotion;
