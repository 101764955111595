import { Image, Form, Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import '../public/hireHome.css'
import { useState } from "react";


function WeHire() {
    const processHire = [
        {
          id: 1,
          urlHire: "./image/we_hire1.png",
          urlPoint: "./image/point_we_hire.png",
          urlLine: "./image/line_we_hire.png",
          step: 'Nộp đơn',
          title:'Đừng ngần ngại nộp đơn để có thêm nhiều cơ hội việc làm'

        },
        {
          id: 2,
          urlHire: "./image/we_hire2.png",
          urlPoint: "./image/point_we_hire.png",
          urlLine: "./image/line_we_hire.png",
          step: 'Phỏng vấn',
          title:'Chúng tôi muốn cung cấp cho bạn cơ hội không chỉ để chúng tôi hiểu bạn hơn, mà còn giúp bạn khám phá thêm về cách làm việc tại VI TÍNH NGUYÊN KIM! Quá trình phỏng vấn bắt đầu bằng một cuộc gọi điện thoại'

        },
        {
          id: 3,
          urlHire: "./image/we_hire3.png",
          urlPoint: "./image/point_we_hire.png",
          urlLine: "./image/line_we_hire.png",
          step: 'Thông báo',
          title:'Công ty sẽ liên hệ với bạn nếu chúng tôi chọn bạn cho vị trí này. Nếu bạn không phù hợp với vai trò hiện tại, đừng lo lắng. Chúng tôi luôn có nhiều cơ hội việc làm mới mỗi ngày, và chúng tôi mong được hợp tác với bạn trong tương lai'

        },
        {
          id: 4,
          urlHire: "./image/we_hire4.png",
          urlPoint: "./image/point_we_hire.png",
          urlLine: "./image/line_we_hire.png",
          step: 'Hợp đồng',
          title:'Chào mừng bạn đến với VI TÍNH NGUYÊN KIM'

        },
      ];

    return (
        <Row className="container_introduction_company bg-white">
            <Col md='12' className="box_introduction_company">
                <Row>
                    <Col md='12' className="we_hire">
                        <div className="text-center box_text_nguyen_kim">
                            <h2>CHÚNG TÔI TUYỂN DỤNG NHƯ THẾ NÀO</h2>
                        </div>
                    </Col>
                
                </Row>
              
              

              <Row className="box_process_hire">

      
               {
                processHire && processHire?.map((item,index )=> (
                  <Col md='3' className="item_process_hire" key={index}>
                     <div className="icon-hire">
                        <img src={item.urlHire} />
                     </div>
                     <div className={index === 3 ? 'last_iconpoint' : 'iconpoint'}>
                        <img src={item.urlPoint} />
                     </div>
                     <div className="box_text_process">
                           <span className="text_process_step">
                            {item.step}
                           </span>
                           <span className="text_process_title">
                            {item.title}
                           </span>
                     </div>
                  </Col>

                ))
               }
          


              </Row>

            </Col>

        </Row>
    );
}

export default WeHire;
