import { Container } from "react-bootstrap";
import { GoCheckCircle } from "react-icons/go";
import "../public/completeBuy.css";
import { axiosClient, imageBaseUrl } from "../../../../config";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import LoadingGif from "../../../client_component/loadingGif";
import LogoGif from "../../../../component/image/Eclipse@1x-1.0s-200px-200px.gif";
import LogoNK from "../../../../component/image/logo NK.png";

import Cookies from "js-cookie";
import { Tooltip, notification } from "antd";

const CompleteBuy = () => {
  const [api, contextHolder] = notification.useNotification();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const orderId = searchParams.get("order_id");

  const [infoCompletedOrder, setInfoCompletedOrder] = useState({});
  const [userInfo, setUserInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();
  const openNotification = (message, time) => {
    api["success"]({
      description: message,

      duration: time,
    });
  };
  const fetchCompletedOrder = async () => {
    if (user) {
      try {
        setIsLoading(true);
        const response = await axiosClient.get(`/member/information-order`);
        if (response.data.status === true) {
          setInfoCompletedOrder(response.data.data);
        }
      } catch (error) {
        console.error("Get completed order error", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        setIsLoading(true);
        const storedOrderId = Cookies.get("orderId");
        if (storedOrderId !== orderId) {
          throw new Error("Access to get order information denied!");
        }

        const response = await axiosClient.get(
          `/member/information-order/${orderId}`
        );
        if (response.data.status === true) {
          setInfoCompletedOrder(response.data.data);
        }
      } catch (error) {
        console.error("Get completed order error", error);
        navigate("/");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleConfirmOrder = async () => {
    try {
      const res = await axiosClient.get(
        `/member/update-order-success/${infoCompletedOrder?.orderId}`
      );

      if (res?.data?.status) {
        navigate("/");
      }
    } catch (error) {
      console.error("fetch data error", error);
    }
  };

  useEffect(() => {
    fetchCompletedOrder();
  }, []);

  const getUserName = async () => {
    try {
      const res = await axiosClient.get("/member/member-information");
      setUserInfo(res.data.member);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };

  useEffect(() => {
    getUserName();
  }, []);

  return isLoading ? (
    <LoadingGif gifSrc={LogoGif} logoImage={LogoNK} />
  ) : (
    <Container>
      {contextHolder}

      {infoCompletedOrder && Object.entries(infoCompletedOrder).length > 0 ? (
        <>
          <div className="order-confirmation">
            <div className="order-confirmation__header">
              <GoCheckCircle className="order-confirmation__icon" />
              <div className="order-confirmation__message">
                <h5>Cảm ơn quý khách đã đặt hàng tại Nguyên Kim</h5>
                <p>
                  Một email xác nhận đã được gửi tới{" "}
                  {infoCompletedOrder.d_gmail}
                </p>
                <p>Xin vui lòng kiểm tra email của bạn.</p>
              </div>
            </div>

            <div className="order-confirmation__details">
              <div className="order-confirmation__info">
                <h3>Thông tin mua hàng</h3>
                <p>
                  <strong>Họ và tên:</strong> {infoCompletedOrder.d_name}
                </p>
                <p>
                  <strong>Tên công ty:</strong> {userInfo?.Tencongty}
                </p>

                <p>
                  <strong>Email:</strong> {infoCompletedOrder.d_gmail}
                </p>
                <p>
                  <strong>Số điện thoại:</strong> {infoCompletedOrder.d_phone}
                </p>
              </div>

              <div className="order-confirmation__shipping-address">
                <h3>Thông tin nhận hàng</h3>
                <p>
                  <strong>Họ và tên:</strong> {infoCompletedOrder.d_name}
                </p>
                <p>
                  <strong>Địa chỉ:</strong> {infoCompletedOrder.d_adress}
                </p>
                <p>
                  <strong>Số điện thoại:</strong> {infoCompletedOrder.d_phone}
                </p>
              </div>

              <div className="order-confirmation__payment-method">
                <h3>Phương thức thanh toán</h3>
                <p>
                  {infoCompletedOrder.payment_method === "cash"
                    ? "Thanh toán khi nhận hàng"
                    : "Thanh toán chuyển khoản trực tiếp"}
                </p>
              </div>

              <div className="order-confirmation__delivery-method">
                <h3>Phương thức vận chuyển</h3>
                <p>
                  {infoCompletedOrder.shipping_method === "home"
                    ? "Giao hàng tận nơi, miễn phí vận chuyển khu vực nội thành"
                    : "Nhận tại cửa hàng"}
                </p>
              </div>
            </div>

            <div className="box_qr_bank my-2">
              <img
                src="/image/thanh-toan.jpg"
                alt="Mã QR chuyển khoản"
                className="w-100 h-100"
              />

              <Tooltip placement="top" title={"NHẤN ĐỂ SAO CHÉP!"}>
                <div
                  onClick={() => {
                    openNotification("Đã COPY nội dung chuyển khoản!", 1);
                    navigator.clipboard.writeText(
                      `${infoCompletedOrder?.d_code} - ${userInfo?.Masothue}`
                    );
                  }}
                  className="box_content_bank text-center"
                >
                  <span className="title_content_bank">
                    {" "}
                    Nội dung chuyển khoản:
                  </span>
                  <span className="value_content_bank">
                    {infoCompletedOrder?.d_code} - {userInfo?.Masothue}{" "}
                  </span>
                </div>
              </Tooltip>
            </div>

            <div className="order-confirmation__summary">
              <h3>
                Đơn hàng #{infoCompletedOrder.d_code} (
                {infoCompletedOrder.listProduct.length})
              </h3>
              <div className="order-confirmation__list">
                {infoCompletedOrder.listProduct &&
                infoCompletedOrder.listProduct.length > 0
                  ? infoCompletedOrder.listProduct.map((order) => (
                      <>
                        <div className="order-confirmation__item">
                          <img
                            src={`${imageBaseUrl}/uploads/${order.picture}`}
                            alt={order.order_id}
                          />
                          <div className="order-confirmation__item-info">
                            <p className="order-confirmation__item-title">
                              {order.item_title}
                            </p>
                            <p className="order-confirmation__item-quantity">
                              <span>Số lượng: </span>
                              {order.quantity}
                            </p>
                          </div>
                          <p className="order-confirmation__item-total">
                            {Number(order.subtotal).toLocaleString("vi-VN", {
                              style: "currency",
                              currency: "VND",
                            })}
                          </p>
                        </div>
                      </>
                    ))
                  : "Không có thông tin sản phẩm đã đặt hàng"}
              </div>
              <div className="order-confirmation__pricing">
                <p>
                  Tạm tính:{" "}
                  {Number(infoCompletedOrder.total_cart).toLocaleString(
                    "vi-VN",
                    {
                      style: "currency",
                      currency: "VND",
                    }
                  )}
                </p>
                <p>Phí vận chuyển: Miễn phí</p>
                <p>
                  Giảm giá:
                  {Number(infoCompletedOrder.CouponDiscout).toLocaleString(
                    "vi-VN",
                    {
                      style: "currency",
                      currency: "VND",
                    }
                  )}
                </p>
                <h3>
                  Tổng cộng:{" "}
                  {Number(infoCompletedOrder.total_price).toLocaleString(
                    "vi-VN",
                    {
                      style: "currency",
                      currency: "VND",
                    }
                  )}
                </h3>
              </div>

              <div
                className="btn_completed text-center"
                onClick={handleConfirmOrder}
              >
                <span>Xác nhận</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="no-order">
          <h5>Chưa có đơn hàng nào được xác nhận.</h5>
          <p>Vui lòng kiểm tra lại hoặc thử đặt hàng mới.</p>
        </div>
      )}
    </Container>
  );
};

export default CompleteBuy;
