import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { axiosClient } from "../../../../config";
import MetaDecorator from "../../../../util/metaDecorator";
import "../public/consultantList.css";
import { FaQuestionCircle } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";

const ConsultantList = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [categories, setCategories] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);

  const fetchConsultantCategory = async () => {
    try {
      const response = await axiosClient.get(`/member/show-all-faqsCate`);
      if (response.data.status === true) {
        setCategories(response.data.data);
      }
    } catch (error) {
      console.error("Fetch consultant category error", error);
    }
  };

  useEffect(() => {
    fetchConsultantCategory();
  }, []);

  const fetchQuestionsData = async (slug) => {
    try {
      const response = await axiosClient.get(
        `/member/show-detail-faqs/${slug}`
      );

      if (response.data.status === true) {
        setQuestionsData(response.data.data);
      }
    } catch (error) {
      console.error("Fetch questions data error", error);
    }
  };

  useEffect(() => {
    if (!slug) {
      fetchQuestionsData("cau-hoi-thuong-gap");
    } else {
      fetchQuestionsData(slug);
    }
  }, [slug]);

  const handleCategoryClick = (slug) => {
    navigate(`/tu-van/${slug}`);
  };

  return (
    <div>
      <MetaDecorator description="Đặt câu hỏi" title="Đặt câu hỏi" />
      <Container className="consultant-container">
        <h2>TƯ VẤN HỎI ĐÁP</h2>
        <div className="consultant-category">
          <ul>
            {categories &&
              categories.length > 0 &&
              categories.map((item) => (
                <li
                  style={{
                    backgroundColor: item?.friendly_url === slug && "#0459aa",
                    color: item?.friendly_url === slug && "white",
                  }}
                  onClick={() => handleCategoryClick(item?.friendly_url)}
                  key={item?.cat_id}
                >
                  {item?.cat_name}
                </li>
              ))}
          </ul>
        </div>

        <div className="consultant-box">
          <p>
            Dưới đây là một số câu hỏi thường gặp và giải đáp. Nếu bạn còn có
            những câu hỏi nào khác liên quan đến dịch vụ của chúng tôi vui lòng
            gửi câu hỏi và nhớ ghi rõ tiêu đề câu hỏi cũng như nội dung để nhận
            được giải đáp nhanh nhất. Xin chân thành cảm ơn quý khách !
          </p>
          <Link to={"/dat-cau-hoi"}>
            <button>Đặt câu hỏi </button>
          </Link>
        </div>

        <div className="questions-list">
          {questionsData && questionsData?.length > 0 ? (
            questionsData.map((question, index) => (
              <div key={index} className="question-item">
                <FaQuestionCircle className="question-icon" />
                <p>{question?.title}</p>
              </div>
            ))
          ) : (
            <h5>"Không có tư vấn nào cho danh mục này"</h5>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ConsultantList;
