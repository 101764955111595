import React, { useEffect, useState, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import BoxProduct from "./boxProduct";
import SideBarProduct from "./sidsebarProduct";
import { axiosClient } from "../../../../config";
import "../public/product.css";
import MetaDecorator from "../../../../util/metaDecorator";
import Dropdown from "react-bootstrap/Dropdown";
import LoadingGif from "../../../client_component/loadingGif";
import LogoGif from "../../../../component/image/Eclipse@1x-1.0s-200px-200px.gif";
import LogoNK from "../../../../component/image/logo NK.png";
import BreadcrumbNew from "../../../client_component/breadcrumbNew";

const ProductMain = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { categoryName } = useParams();
  const [data, setData] = useState([]);
  const [cateData, setCateData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sortPrice, setSortPrice] = useState("desc");
  const [dataListParents, setDataListParents] = useState(null);
  const [dataListChild, setDataListChild] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalProduct, setTotalProduct] = useState(0);
  const [cateOptions, setCateOptions] = useState();

  const queryParams = new URLSearchParams(location.search);

  const search = queryParams.get("tim-kiem") ? queryParams.get("tim-kiem") : "";

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;

    if (newPage < 2) {
      setPageNumber(newPage);
      window.scrollTo(0, 0);
      return;
    }

    setPageNumber(newPage);
    const searchWithoutPage = location.search.replace(/(\?|&)page=\d+/g, "");
    const pagePart = `page=${newPage}`;
    const updatedURL = searchWithoutPage
      ? `${searchWithoutPage}&${pagePart}`
      : `?${pagePart}`;
    window.scrollTo(0, 0);
    navigate(`${currentLocation}${updatedURL}`);
  };

  const currentLocation = `${location.pathname}`;
  const [checkCategory, setCheckCategory] = useState(true);
  const [isLoadedCate, setIsLoadedCate] = useState(false);
  const [chooseOptionValue, setChooseOptionValue] = useState([]);

  const handleChooseOption = useCallback((options) => {
    setChooseOptionValue(options);
  }, []);

  const [categoryOption, setCategoryOption] = useState({});

  let extractedValue;

  let getCurrentParams = window.location.search;

  var regex = /cpu=([^\s]+)/;
  var match = getCurrentParams.match(regex);

  if (match && match[1]) {
    extractedValue = match[1];
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axiosClient.get("/member/category-option?key=1");
        const optionsData = res.data.options;
        const cateOptions = optionsData.filter(
          (option) => option.slug == "cong-nghe-cpu"
        );
        const result = cateOptions?.[0].propertiesValue.filter(
          (item) => item.slug == extractedValue
        );

        setCategoryOption(result);
      } catch (error) {
        console.error("fetch data error", error);
      }
    };

    fetchData();
  }, [extractedValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axiosClient.get(
          `/member/select-category/${categoryName}`
        );
        const data = res.data;
        setDataListChild(data);
      } catch (error) {
        console.error("fetch category child data error", error);
      }
    };

    fetchData();
  }, [categoryName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          data[0]?.category?.parentid == 11 ||
          data[0]?.category?.parentid == 12
        ) {
          const res = await axiosClient.get(
            `/member/category-option?key=${data[0]?.cat_id}`
          );
          setCateOptions(res.data.options);
        }
      } catch (error) {
        console.error("fetch category option key data error", error);
      }
    };
    fetchData();
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      if (search === "") {
        setIsLoaded(false);
        try {
          const res = await axiosClient.get(
            `/member/filter-category?keySlugCate=${categoryName}&params=${
              chooseOptionValue.length > 0
                ? JSON.stringify(chooseOptionValue)
                : JSON.stringify(categoryOption)
            }&page=${pageNumber}&sort=${sortPrice}`
          );

          const dataCategory = res.data.products;
          const cateList = res.data.dataListParent;
          const categoryData = res.data.catname;
          const totalProduct = res.data.totalProductForFilter;

          setDataListParents(cateList);
          setData(dataCategory);
          setCateData(categoryData);
          setTotalProduct(totalProduct);
          // setTotalPage(lastPage);
          setCheckCategory(true);
          setIsLoadedCate(true);
          setIsLoaded(true);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        setIsLoaded(false);
        try {
          const res = await axiosClient.get(
            `/member/filter-category?search=${search}&page=${pageNumber}&sort=${sortPrice}`
          );
          if (res.data.status == false) {
            setCheckCategory(false);
            setIsLoadedCate(true);
            setIsLoaded(true);
          } else {
            const dataCategory = res.data.products.data;
            const cateList = res.data.dataListParent;
            const lastPage = res.data.products.last_page;
            const categoryData = res.data.catNameParent;
            const totalProduct = res.data.totalProductForFilter;
            setIsLoaded(true); // Đánh dấu dữ liệu đã được tải
            setDataListParents(cateList);
            setData(dataCategory); // Cập nhật data dựa trên kết quả yêu cầu
            setCateData(categoryData);
            setTotalProduct(totalProduct);
            // setTotalPage(lastPage);
            setCheckCategory(true);
            setIsLoadedCate(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
  }, [
    pageNumber,
    // location.pathname,
    // sortStatus,
    chooseOptionValue,
    sortPrice,
    search,
    categoryOption,
    categoryName,
  ]);

  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const checkLogin = useAuth();

  const initialPage =
    parseInt(new URLSearchParams(location.search).get("page")) || 1;

  useEffect(() => {
    setPageNumber(1);
  }, [location.pathname]);

  const combineLink = () => {
    const breadcrumbLink = [];
    if (dataListParents != null) {
      for (const item of dataListParents) {
        if (item != null) {
          breadcrumbLink.push({
            id: item.id,
            name: item?.cat_name,
            link: `/danh-muc/${item?.friendly_url}`,
          });
        }
      }
    }
    return breadcrumbLink;
  };

  const breadcrumbLinks = combineLink();
  const breadcrumbLink = [
    {
      id: 1,
      name: cateData != null ? cateData?.category_desc?.cat_name : search,
      link: `/${
        cateData != null ? location.pathname.slice(10) : `?tim-kiem=${search}`
      }`,
    },
    {
      id: 2,
      name: search
        ? ""
        : data?.[0]?.category_des?.cat_name != null
        ? data?.[0].category_des?.cat_name
        : "",
      link: `${
        search
          ? ""
          : data?.[0]?.category_des?.friendly_url != null
          ? `/${data?.[0].category_des?.friendly_url}`
          : ""
      }`,
    },
  ];

  const handlePropertiesFilter = (catId, propertyId) => {
    const filterProperty = cateOptions
      .filter((item) => item.id === propertyId)[0]
      .propertiesValue.filter((item) => item.id === catId);
    setChooseOptionValue(filterProperty);
  };

  return (
    <>
      <div>
        <MetaDecorator
          description={
            cateData != null ? cateData?.category_desc?.metadesc : ``
          }
          title={
            // laptop?cpu=core-i5
            cateData != null
              ? cateData?.category_desc?.friendly_title
              : `Từ khóa tìm kiếm: ${search}`
          }
        />

        {isLoadedCate && checkCategory && (
          <div className="product-show">
            <Container>
              <BreadcrumbNew
                arrayLink={
                  search
                    ? breadcrumbLink
                    : breadcrumbLinks.filter(
                        (item, index) => breadcrumbLinks.indexOf(item) === index
                      )
                }
              />
              <Row>
                <Col
                  className="container-product-right"
                  xs={12}
                  sm={10}
                  md={12}
                >
                  <Container>
                    <div>
                      <div className="container-product-right-title">
                        {cateData != null
                          ? cateData?.category_desc?.cat_name
                          : `Từ khóa tìm kiếm: ${search}`}
                        <p className="container-product-right-title-quantity-product">
                          ({totalProduct}+ sản phẩm)
                        </p>
                      </div>
                      <div>
                        {dataListParents != undefined &&
                          dataListParents != null &&
                          dataListChild != undefined &&
                          dataListChild != null && (
                            <SideBarProduct
                              catId={dataListParents[1]?.cat_id}
                              category={`danh-muc/${dataListParents[1]?.friendly_url}`}
                              cateChild={dataListChild}
                              onChooseOption={handleChooseOption}
                              locationParams={location}
                            />
                          )}
                      </div>

                      {/* Dùng cho linh kiện PC và Phụ kiện  */}
                      {dataListChild?.status && (
                        <div style={{ display: "flex", gap: "10px" }}>
                          {cateOptions &&
                            cateOptions.length > 0 &&
                            cateOptions.map((item, index) => (
                              <Dropdown>
                                <Dropdown.Toggle
                                  id="dropdown-basic"
                                  style={{
                                    padding: "8px 10px",
                                    fontSize: "14px",
                                    backgroundColor: "white",
                                    border: "1px solid #e0e0e0",
                                    color: "#212529",
                                  }}
                                >
                                  {`Lọc theo ${item.title}`}
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ fontSize: "14px" }}>
                                  {item.propertiesValue.map((itemx) => (
                                    <Dropdown.Item
                                      key={itemx.id}
                                      onClick={() =>
                                        handlePropertiesFilter(
                                          itemx.id,
                                          itemx.properties_id
                                        )
                                      }
                                    >
                                      {itemx.name}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            ))}
                        </div>
                      )}

                      {data && data.length > 0 && (
                        <div className="container-product-right-filter">
                          <div className="container-product-right-filter-title">
                            Sắp xếp theo
                          </div>
                          <div className="container-product-right-filter-list">
                            <div
                              onClick={() => setSortPrice("asc")}
                              className="container-product-right-filter-list-item"
                            >
                              Giá tăng dần
                            </div>
                            <div
                              onClick={() => setSortPrice("desc")}
                              className="container-product-right-filter-list-item"
                            >
                              Giá giảm dần
                            </div>
                          </div>
                        </div>
                      )}
                      {data && data.length > 0 && (
                        <div className="container-product-right-filter-mobile">
                          <div className="container-product-right-filter-title me-2">
                            Sắp xếp theo
                          </div>
                          <div className="container-product-right-filter-list">
                            <div
                              onClick={() => setSortPrice("asc")}
                              className="container-product-right-filter-list-item"
                            >
                              Giá tăng dần
                            </div>
                            <div
                              onClick={() => setSortPrice("desc")}
                              className="container-product-right-filter-list-item"
                            >
                              Giá giảm dần
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {!isLoaded ? (
                      <LoadingGif gifSrc={LogoGif} logoImage={LogoNK} />
                    ) : (
                      <>
                        {data?.length === 0 || !data ? (
                          <div className="no-product">
                            không có sản phẩm nào
                          </div>
                        ) : (
                          <div className="test">
                            {data
                              ?.filter(
                                (item) => item.product_desc != null
                                // && item.Hienthi == "Y"
                              )
                              .map((item, index) => {
                                const filterTech =
                                  item.price_list.length > 0
                                    ? item.price_list
                                        .filter((item) => item.main == 1)
                                        .map((items) => items)
                                    : [];
                                const resultFilter =
                                  filterTech[0] != undefined
                                    ? filterTech[0]
                                    : {};

                                return (
                                  <BoxProduct
                                    key={index}
                                    catNameParent={item.catParent}
                                    // cateParentId={item.catParentId}
                                    catChild={item.catName}
                                    catName={item.catName}
                                    productId={
                                      item.productId === undefined
                                        ? item.id_product
                                        : item.productId
                                    }
                                    macn={item.macn}
                                    picture={
                                      item.price_list &&
                                      item.price_list.length > 0
                                        ? item.price_list[0].picture !== null &&
                                          item.price_list[0].picture !== ""
                                          ? item.price_list[0].picture
                                          : "No_image.jpg"
                                        : item.picture !== null &&
                                          item.picture !== ""
                                        ? item.picture
                                        : "No_image.jpg"
                                    }
                                    status={item.stock}
                                    productHot={item.status}
                                    pictureStatus={
                                      item?.product_status?.picture
                                    }
                                    productName={item.product_desc.title}
                                    link={item.product_desc?.friendly_url}
                                    brandName={item?.brand_desc?.title}
                                    price={
                                      checkLogin
                                        ? resultFilter.price_old
                                          ? resultFilter.price_old
                                          : item.price_old
                                        : resultFilter.price
                                        ? resultFilter.price
                                        : item.price
                                    }
                                    percent={(() => {
                                      const priceOld =
                                        resultFilter.price_old ||
                                        item.price_old ||
                                        0;
                                      const currentPrice =
                                        resultFilter.price || item.price || 0;

                                      if (priceOld > 0 && currentPrice > 0) {
                                        return (
                                          ((currentPrice - priceOld) /
                                            priceOld) *
                                          100
                                        );
                                      }

                                      return 0; // Trả về 0 nếu không thể tính toán
                                    })()}
                                  />
                                );
                              })}
                          </div>
                        )}
                      </>
                    )}
                  </Container>
                </Col>
                {data && data.length > 0 && (
                  <div className="d-flex justify-content-end mt-4">
                    <ReactPaginate
                      pageCount={Math.ceil(totalProduct / 20)}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      previousLabel={"<"}
                      nextLabel={">"}
                      initialPage={initialPage - 1}
                    />
                  </div>
                )}

                {data && data.length > 0 && (
                  <div className="category-desc">
                    {cateData != undefined && cateData != null && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: cateData?.category_desc.description,
                        }}
                      />
                    )}
                  </div>
                )}
              </Row>
            </Container>
          </div>
        )}

        {!isLoadedCate && checkCategory && (
          <LoadingGif gifSrc={LogoGif} logoImage={LogoNK} />
        )}
      </div>
    </>
  );
};

export default ProductMain;
