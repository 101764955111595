import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as cartService from "../../../services/cartService";
import { toast } from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";
import { BiSolidErrorAlt } from "react-icons/bi";

const calculateTotalAmount = (cartItems, selectedProducts) => {
  return cartItems.reduce((total, item) => {
    if (selectedProducts.includes(item.productId)) {
      total += item.price * item.cartQuantity;
    }
    return total;
  }, 0);
};

export const fetchUserById = createAsyncThunk(
  "users/fetchByIdStatus",
  async (thunkAPI) => {
    const response = await cartService.getCart();
    return response;
  }
);

const initialState = {
  cartItem: localStorage.getItem("cartItem")
    ? JSON.parse(localStorage.getItem("cartItem"))
    : [],
  totalAmount: 0,
  cartDataItemsss: [],
  total: 0,
  selectedProducts: [],
  appliedPromotion: null,
  appliedCard: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const itemIndex = state.cartItem.findIndex(
        (item) => item.productName === action.payload.productName
      );

      if (itemIndex >= 0) {
        // Kiểm tra nếu số lượng hiện tại không vượt quá 500
        if (state.cartItem[itemIndex].quality < 50) {
          state.cartItem[itemIndex].quality += 1;
          localStorage.setItem("cartItem", JSON.stringify(state.cartItem));
        } else {
          toast.custom((t) => (
            <CustomToast
              icon={<BiSolidErrorAlt size={25} color="red" />}
              content={
                "Bạn chỉ có thể đặt tối đa 500 sản phẩm cho mỗi sản phẩm."
              }
            />
          ));
        }
      } else {
        const tempProduct = { ...action.payload, quality: 1 };
        state.cartItem.push(tempProduct);
        localStorage.setItem("cartItem", JSON.stringify(state.cartItem));
      }
    },

    removeCartItem: (state, action) => {
      const nextCartItem = state.cartItem.filter(
        (item) => item.productName !== action.payload.productName
      );
      state.cartItem = nextCartItem;
      localStorage.setItem("cartItem", JSON.stringify(state.cartItem));
    },

    decreaseCart: (state, action) => {
      const itemIndex = state.cartItem.findIndex(
        (item) => item.productName === action.payload.productName
      );
      if (state.cartItem[itemIndex].quality > 1) {
        state.cartItem[itemIndex].quality -= 1;
      } else if (state.cartItem[itemIndex] === 1) {
        const nextCartItem = state.cartItem.filter(
          (item) => item.productName !== action.payload.productName
        );
        state.cartItem = nextCartItem;
      }
      localStorage.setItem("cartItem", JSON.stringify(state.cartItem));
    },

    clearCart: (state, action) => {
      state.cartItem = [];
      localStorage.setItem("cartItem", JSON.stringify(state.cartItem));
    },

    updateCartItemQuantity: (state, action) => {
      const { productId, quantity } = action.payload;
      state.cartItem = state.cartItem.map((item) =>
        item.productId === productId ? { ...item, quality: quantity } : item
      );
      localStorage.setItem("cartItem", JSON.stringify(state.cartItem));
    },

    // updateMemberCartItemQuantity: (state, action) => {
    //   const { productId, quantity } = action.payload;
    //   state.selectedProducts = state.selectedProducts.map((item) =>
    //     item.product_id === productId ? { ...item, quality: quantity } : item
    //   );
    //   localStorage.setItem(
    //     "selectedProducts",
    //     JSON.stringify(state.selectedProducts)
    //   );
    // },

    updateTotalAmount: (state) => {
      state.totalAmount = calculateTotalAmount(
        state.cartItem,
        state.selectedProducts
      );
    },

    updateSelectedProducts: (state, action) => {
      state.selectedProducts = action.payload;
    },

    toggleCartItemChecked: (state, action) => {
      const { productId, isChecked } = action.payload;
      const updatedCartItem = state.cartItem.map((item) =>
        item.productId === productId || item.product_id === productId
          ? { ...item, isChecked }
          : item
      );
      state.cartItem = updatedCartItem;
      localStorage.setItem("cartItem", JSON.stringify(updatedCartItem));
    },

    toggleSelectAll: (state, action) => {
      const { isChecked } = action.payload;
      state.cartItem.forEach((item) => {
        item.isChecked = isChecked;
      });
      if (isChecked) {
        state.selectedProducts = state.cartItem.map((item) =>
          item.productId ? item.productId : item.product_id
        );
      } else {
        state.selectedProducts = [];
      }
      localStorage.setItem("cartItem", JSON.stringify(state.cartItem));
      localStorage.setItem(
        "selectedProducts",
        JSON.stringify(state.selectedProducts)
      );
    },
    removeCheckedCartItems: (state) => {
      state.cartItem = state.cartItem.filter((item) => !item.isChecked);
      localStorage.setItem("cartItem", JSON.stringify(state.cartItem));
    },

    // km
    updateAppliedPromotion: (state, action) => {
      state.appliedPromotion = action.payload;
    },
    clearAppliedPromotion: (state) => {
      state.appliedPromotion = null;
    },
    updateAppliedCard: (state, action) => {
      state.appliedCard = action.payload;
    },
    clearAppliedCard: (state) => {
      state.appliedCard = null;
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchUserById.fulfilled, (state, action) => {
      state.cartDataItemsss = action && action?.payload;
      state.total =
        action &&
        Array.isArray(action.payload) &&
        action.payload?.filter((item) => item.stock === 1).length;
    });
  },
});

export const {
  addToCart,
  removeCartItem,
  decreaseCart,
  clearCart,
  updateCartItemQuantity,
  updateMemberCartItemQuantity,
  updateTotalAmount,
  updateSelectedProducts,
  toggleCartItemChecked,
  toggleSelectAll,
  removeCheckedCartItems,
  updateAppliedPromotion,
  clearAppliedPromotion,
  updateAppliedCard,
  clearAppliedCard,
} = cartSlice.actions;
export default cartSlice.reducer;
