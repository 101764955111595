import { Link } from "react-router-dom";
import { imageBaseUrl } from "../../config";
import React from "react"; // Import React để sử dụng JSX và React component

const ComponentCardItemOrder = ({ item }) => {
  return (
    <div className="row item_card_order mx-0 my-2">
      <div className="col-lg-2 col-4">
        <div className="box_image_card_item_order">
          <Link to={`/${item?.friendlyUrl}`} state={{ type: "product" }}>
            <img
              src={imageBaseUrl + "/uploads/" + item.Picture}
              alt={item.ProductName}
            />
          </Link>
        </div>
      </div>
      <div className="col-lg-10 col-8 d-flex justify-content-center  flex-column">
        <div className="item_infor_card_item_order item_name_price">
          <Link to={`/${item?.friendlyUrl}`} state={{ type: "product" }}>
            <span className="name_card_item_order text_genaral_two_line_2">
              {item.ProductName}
            </span>
          </Link>

          <span className="price_card_item_order">
            {item.subtotal.toLocaleString("vi", {
              style: "currency",
              currency: "VND",
            })}
          </span>
        </div>
        <div className="item_infor_card_item_order my-1">
          <span className="text_info_basic_card_item_order">
            Thương hiệu: {item.Category}
          </span>
          <span className="text_info_basic_card_item_order">
            X{item.quantity}
          </span>
        </div>

        <div className="item_infor_card_item_order d-none d-lg-block">
          <div>
            <span className="text_info_basic_card_item_order">
              Cung cấp bởi:
            </span>{" "}
            <span className="name_web">Vi Tính Nguyên Kim</span>
          </div>
        </div>

        {item?.present && item?.present?.content && (
          <div className="">
            <div className="box_promotion_product p-0">
              <div
                dangerouslySetInnerHTML={{ __html: item?.present?.content }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ComponentCardItemOrder;
