import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import "../public/member.css";
import "bootstrap/dist/css/bootstrap.css";
import * as authService from "../../../services/authService";
import PersonalInfo from "./personalInfo";
import CompanyInfo from "./companyInfo";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Modal from "@mui/material/Modal";

import toast, { Toaster } from "react-hot-toast";
import CustomToast from "../../../client_component/alertCustom";

import { BiSolidErrorAlt } from "react-icons/bi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const Register = ({ open, close }) => {
  const [notify, setNotify] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [duplicateMST, setDuplicateMST] = useState(false);
  const [duplicateUserName, setDuplicateUserName] = useState(false);
  const [value, setValue] = React.useState("2");
  const [openProdcess, setOpenProdcess] = useState(false);
  const [PersonInfo, setPersonInfo] = useState({
    account: "",
    username: "",
    password: "",
    re_password: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    districtss: "",
    ward: "",
  });
  const [companyInfo, setCompanyInfo] = useState({
    account: "",
    username: "",
    password: "",
    re_password: "",
    email: "",
    phone: "",
    company_name: "",
    numbertax: "",
    emailcompany: "",
    preson_address: "",
    preson_city: "",
    preson_district: "",
    preson_ward: "",
    phonecompany: "",
    company_address: "",
    company_city: "",
    company_district: "",
    company_ward: "",
  });
  const [validationCompanyInfoErrors, setValidationCompanyInfoErrors] =
    useState({
      account: "",
      username: "",
      password: "",
      re_password: "",
      email: "",
      phone: "",
      company_name: "",
      numbertax: "",
      emailcompany: "",
      preson_address: "",
      preson_city: "",
      preson_district: "",
      preson_ward: "",
      phonecompany: "",
      company_address: "",
      company_city: "",
      company_district: "",
      company_ward: "",
    });
  const [validationPersonInfoErrors, setValidationPersonInfoErrors] = useState({
    account: "",
    username: "",
    password: "",
    re_password: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    districtss: "",
    ward: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Close = () => {
    setNotify(false);
  };
  const CloseDuplicateEmail = () => {
    setDuplicateEmail(false);
  };
  const CloseDuplicateMST = () => {
    setDuplicateMST(false);
  };

  const CloseDuplicateUserName = () => {
    setDuplicateUserName(false);
  };

  const handlePersonInfoChange = (info) => {
    setPersonInfo(info);
  };

  const handleCompanyInfoChange = (info) => {
    setCompanyInfo(info);
  };
  const validatePhoneNumber = (phone) => {
    var validatePhone = /(03|05|07|08|09|01[2|6|8|9]|028)+([0-9]{7,8})\b/;
    return validatePhone.test(phone);
  };

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const validateUserName = (usename) => {
    const regex = /^[a-z0-9\+]*$/;
    return regex.test(usename);
  };

  const handleRegister = async () => {
    // if (value == 1) {
    //   const errorsPresonInfo = {};
    //   if (!PersonInfo.account) {
    //     errorsPresonInfo.account = "Tên đăng nhập không được để trống";
    //   } else if (PersonInfo.account.length < 6) {
    //     errorsPresonInfo.account = "Tên đăng nhập tối thiểu 6 ký tự";
    //   } else if (!validateUserName(PersonInfo.account)) {
    //     errorsPresonInfo.account = "Tên đăng nhập không được có dấu hoặc ký tự đặc biệt";
    //   }
    //   if (!PersonInfo.username) {
    //     errorsPresonInfo.username = "Tên không được để trống";
    //   }
    //   // else if (validateName(PersonInfo.username)) {
    //   //   errorsPresonInfo.username = "Tên không được có ký tự đặc biệt hoặc số";
    //   // }
    //   if (!PersonInfo.password) {
    //     errorsPresonInfo.password = "Mật khẩu không được để trống";
    //   } else if (PersonInfo.password.length < 6) {
    //     errorsPresonInfo.password = "Mật khẩu nhập tối thiểu 6 ký tự";
    //   }
    //   if (!PersonInfo.re_password) {
    //     errorsPresonInfo.re_password = "Mật khẩu không được để trống";
    //   }
    //   if (PersonInfo.password != PersonInfo.re_password) {
    //     errorsPresonInfo.re_password = "Mật khẩu không trùng khớp";
    //   }
    //   if (!PersonInfo.email) {
    //     errorsPresonInfo.email = "Email không được để trống";
    //   } else if (!validateEmail(PersonInfo.email)) {
    //     errorsPresonInfo.email = "Vui lòng nhập đúng email";
    //   }
    //   if (!PersonInfo.phone) {
    //     errorsPresonInfo.phone = "Số điện thoại không được để trống";
    //   } else if (!validatePhoneNumber(PersonInfo.phone)) {
    //     errorsPresonInfo.phone = "Vui lòng nhập đúng số điện thoại";
    //   }
    //   if (!PersonInfo.address) {
    //     errorsPresonInfo.address = "Địa chỉ không được để trống";
    //   }
    //   if (PersonInfo.city == "") {
    //     errorsPresonInfo.city = "Chọn Tỉnh/Thành phố";
    //   }
    //   if (PersonInfo.districtss == "") {
    //     errorsPresonInfo.districtss = "Chọn Quận/Huyện";
    //   }
    //   if (PersonInfo.ward == "") {
    //     errorsPresonInfo.ward = "Chọn Phường/Xã";
    //   }
    //   setValidationPersonInfoErrors(errorsPresonInfo);
    //   if (Object.keys(errorsPresonInfo).length === 0) {
    //     setOpenProdcess(true);
    //     authService.register({
    //         username: PersonInfo.account,
    //         password: PersonInfo.password,
    //         full_name: PersonInfo.username,
    //         email: PersonInfo.email,
    //         phone: PersonInfo.phone,
    //         address: PersonInfo.address,
    //         ward: PersonInfo.ward,
    //         district: PersonInfo.districtss,
    //         city_province: PersonInfo.city,
    //         status: 1,
    //       }).then((res) => {
    //         if (res.data.status == true) {
    //           setNotify(true);
    //         } else {
    //           if (res.data.message == "error") {
    //             setDuplicateEmail(true)
    //           } else if (res.data.message == "existUserName") {
    //             setDuplicateUserName(true)
    //           }else {
    //             alert("lỗi thực thi");
    //           }
    //         }
    //       }).catch((error) =>  console.error("post data error", error)).finally(() => setOpenProdcess(false))
    //   } else {
    //     alert("Vui lòng kiểm tra lại thông tin !!!")
    //   }
    // } else {
    const errorsCompanyInfo = {};
    if (!companyInfo.account) {
      errorsCompanyInfo.account = "Tên đăng nhập không được để trống";
    } else if (companyInfo.account.length < 6) {
      errorsCompanyInfo.account = "Tên đăng nhập tối thiểu 6 ký tự";
    } else if (!validateUserName(companyInfo.account)) {
      errorsCompanyInfo.account =
        "Tên đăng nhập không được có dấu hoặc ký tự đặc biệt";
    }
    if (!companyInfo.username) {
      errorsCompanyInfo.username = "Tên không được để trống";
    }
    // else if (validateName(companyInfo.username)) {
    //   errorsCompanyInfo.username = "Tên không được có ký tự đặc biệt hoặc số";
    // }
    if (!companyInfo.password) {
      errorsCompanyInfo.password = "Mật khẩu không được để trống";
    } else if (companyInfo.password.length < 6) {
      errorsCompanyInfo.password = "Mật khẩu nhập tối thiểu 6 ký tự";
    }
    if (!companyInfo.re_password) {
      errorsCompanyInfo.re_password = "Mật khẩu không được để trống";
    }
    if (companyInfo.password != companyInfo.re_password) {
      errorsCompanyInfo.re_password = "Mật khẩu không trùng khớp";
    }
    if (!companyInfo.email) {
      errorsCompanyInfo.email = "Email không được để trống";
    } else if (!validateEmail(companyInfo.email)) {
      errorsCompanyInfo.email = "Vui lòng nhập đúng email";
    }
    if (!companyInfo.numbertax) {
      errorsCompanyInfo.numbertax = "Mã số thuế không được để trống";
    }
    if (!companyInfo.emailcompany) {
      errorsCompanyInfo.emailcompany = "Email không được để trống";
    } else if (!validateEmail(companyInfo.emailcompany)) {
      errorsCompanyInfo.emailcompany = "Vui lòng nhập đúng email";
    }
    if (!companyInfo.phone) {
      errorsCompanyInfo.phone = "Số điện thoại không được để trống";
    } else if (!validatePhoneNumber(companyInfo.phone)) {
      errorsCompanyInfo.phone = "Vui lòng nhập đúng số điện thoại";
    }
    if (!companyInfo.phonecompany) {
      errorsCompanyInfo.phonecompany =
        "Số điện thoại không được không được để trống";
    } else if (!validatePhoneNumber(companyInfo.phonecompany)) {
      errorsCompanyInfo.phonecompany = "Vui lòng nhập đúng số điện thoại";
    }
    if (!companyInfo.company_name) {
      errorsCompanyInfo.company_name = "Tên công ty không được để trống";
    }
    if (!companyInfo.company_address) {
      errorsCompanyInfo.company_address = "Địa chỉ công ty không được để trống";
    }
    if (companyInfo.company_city == "") {
      errorsCompanyInfo.company_city = "Chọn Tỉnh/Thành phố";
    }
    if (companyInfo.company_district == "") {
      errorsCompanyInfo.company_district = "Chọn Quận/Huyện";
    }
    if (companyInfo.company_ward == "") {
      errorsCompanyInfo.company_ward = "Chọn Phường/Xã";
    }
    if (!companyInfo.preson_address) {
      errorsCompanyInfo.preson_address = "Địa chỉ không được để trống";
    }
    if (companyInfo.preson_city == "") {
      errorsCompanyInfo.preson_city = "Chọn Tỉnh/Thành phố";
    }
    if (companyInfo.preson_district == "") {
      errorsCompanyInfo.preson_district = "Chọn Quận/Huyện";
    }
    if (companyInfo.preson_ward == "") {
      errorsCompanyInfo.preson_ward = "Chọn Phường/Xã";
    }
    setValidationCompanyInfoErrors(errorsCompanyInfo);
    if (Object.keys(errorsCompanyInfo).length === 0) {
      setOpenProdcess(true);
      authService
        .register({
          username: companyInfo.account,
          password: companyInfo.password,
          full_name: companyInfo.username,
          email: companyInfo.email,
          phone: companyInfo.phone,
          address: companyInfo.preson_address,
          ward: companyInfo.preson_ward,
          district: companyInfo.preson_district,
          city_province: companyInfo.preson_city,
          tencongty: companyInfo.company_name,
          masothue: companyInfo.numbertax,
          emailcty: companyInfo.emailcompany,
          diachicongty: `${companyInfo.company_address}, ${companyInfo.company_ward}, ${companyInfo.company_district}, ${companyInfo.company_city}`,
          sdtcongty: companyInfo.phonecompany,
          status: 2,
        })
        .then((res) => {
          if (res.data?.status == true) {
            sessionStorage.setItem(
              "rsgfrom",
              JSON.stringify({
                account: "",
                username: "",
                password: "",
                re_password: "",
                email: "",
                phone: "",
                company_name: "",
                numbertax: "",
                emailcompany: "",
                preson_address: "",
                preson_city: "",
                preson_district: "",
                preson_ward: "",
                phonecompany: "",
                company_address: "",
                company_city: "",
                company_district: "",
                company_ward: "",
              })
            );
            setNotify(true);
          } else {
            if (res.data.message == "error") {
              setDuplicateEmail(true);
            } else if (res.data.message == "existMST") {
              setDuplicateMST(true);
            } else if (res.data.message == "existUserName") {
              setDuplicateUserName(true);
            } else {
              toast.custom((t) => (
                <CustomToast
                  icon={<BiSolidErrorAlt size={25} color="red" />}
                  content={"Đã xảy ra lỗi. Xin vui lòng thử lại!"}
                />
              ));
            }
          }
        })
        .catch((error) => console.error("post data error", error))
        .finally(() => setOpenProdcess(false));
    } else {
      toast.custom((t) => (
        <CustomToast
          icon={<BiSolidErrorAlt size={25} color="red" />}
          content={"Vui lòng kiểm tra lại thông tin!"}
        />
      ));
    }
  };

  return (
    <div className="z-3">
      <Toaster />
      <div>
        <Dialog open={open} onClose={close}>
          <div className="register-from">
            <DialogTitle className="text-center">
              <p className="fs-2 text-center fw-semibold mb-0">Đăng ký</p>
              <span className="title_cooperate_register text-center">
                Đăng ký hợp tác cùng Nguyên Kim
              </span>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={close}
              sx={{ position: "absolute", right: 10, top: 10 }}
            >
              X
            </IconButton>
            <DialogContent>
              <div>
                <div className="">
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          {/* <Tab label="Thông tin Cá nhân" value="1" /> */}
                          <Tab label="Thông tin Công ty" value="2" />
                        </TabList>
                      </Box>
                      {/* <TabPanel className="tab" value="1">
                        <PersonalInfo
                          onPersonInfoChange={handlePersonInfoChange}
                          validationPersonInfoErrors={
                            validationPersonInfoErrors
                          }
                        />
                      </TabPanel> */}
                      <TabPanel className="tab" value="2">
                        <CompanyInfo
                          validationCompanyInfoErrors={
                            validationCompanyInfoErrors
                          }
                          onCompanyInfoChange={handleCompanyInfoChange}
                        />
                      </TabPanel>
                    </TabContext>
                  </Box>
                </div>
                <div className="text-center">
                  <Button
                    variant="contained"
                    onClick={handleRegister}
                    className="btn_register"
                  >
                    <span className="">Đăng ký</span>
                  </Button>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
      </div>
      <div>
        <Dialog open={notify} onClose={Close}>
          <DialogContent>
            <h3 className="text-center text-danger">
              Cám ơn bạn đã đăng ký, bộ phận quản trị đang phê duyệt!
            </h3>
            <br />
            <h3 className="text-center">
              Trong thời gian này bạn có thể mua hàng tiếp tục
            </h3>
            <br />
            <div
              onClick={() => {
                setNotify(false);
                close();
              }}
              className="send-and-login-btn float-end"
            >
              Đăng nhập ngay
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog open={duplicateEmail} onClose={CloseDuplicateEmail}>
          <DialogContent>
            <h3 className="text-center text-danger">Thông báo</h3>
            <br />
            <h3 className="text-center">
              Email đã tồn tài vui lòng thử email khác !!!
            </h3>
            <br />
            <div
              onClick={() => {
                setDuplicateEmail(false);
              }}
              className="send-and-login-btn float-end"
            >
              đóng
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog open={duplicateMST} onClose={CloseDuplicateMST}>
          <DialogContent>
            <h3 className="text-center text-danger">Thông báo</h3>
            <br />
            <h3 className="text-center">Mã số thuế đã tồn tại !!!</h3>
            <br />
            <div
              onClick={() => {
                setDuplicateMST(false);
              }}
              className="send-and-login-btn float-end"
            >
              đóng
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog open={duplicateUserName} onClose={CloseDuplicateUserName}>
          <DialogContent>
            <h3 className="text-center text-danger">Thông báo</h3>
            <br />
            <h3 className="text-center">Tên đăng nhập đã tồn tại !!!</h3>
            <br />
            <div
              onClick={() => {
                setDuplicateUserName(false);
              }}
              className="send-and-login-btn float-end"
            >
              đóng
            </div>
          </DialogContent>
        </Dialog>
      </div>
      {openProdcess && (
        <Modal
          open={openProdcess}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="paypalProcessing">
              <div className="animation">
                <div className="container">
                  <div className="balls">
                    <div className="ball ball-one" />
                    <div className="ball ball-two" />
                    <div className="ball ball-three" />
                    <div className="ball ball-four" />
                    <div className="shadow shadow-one" />
                    <div className="shadow shadow-two" />
                    <div className="shadow shadow-three" />
                    <div className="shadow shadow-four" />
                  </div>
                </div>
              </div>
              <div className="text">
                <h1>Đang xử lý</h1>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};
export default Register;
