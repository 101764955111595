import { HandIndex } from "react-bootstrap-icons";
import "../public/warrantyPolicy.css";
import { Link } from "react-router-dom";
const WarrantyPolicy = () => {
  return (
    <div>
      <div className="warranty-policy">
        <h6 className="warranty-policy-title mb-0 p-1">THÔNG TIN LIÊN QUAN </h6>
        <div className="warranty-policy-content">
          <Link
            className="warranty-policy-content-item"
            to="/cac-chinh-sach-bao-hanh-va-quy-dinh/quy-dinh-thanh-toan"
            rel="nofollow"
            title="Quy định thanh toán"
          >
            <HandIndex className="point" />
            Quy định thanh toán
          </Link>
          <Link
            className="warranty-policy-content-item"
            to="/cac-chinh-sach-bao-hanh-va-quy-dinh/chinh-sach-bao-hanh"
            rel="nofollow"
            title="Chính sách bảo hành"
          >
            <HandIndex className="point" />
            Chính sách bảo hành
          </Link>
          <Link
            className="warranty-policy-content-item"
            to="/cac-chinh-sach-bao-hanh-va-quy-dinh/giao-hang-va-lap-dat"
            rel="nofollow"
            title="Chính sách giao hàng"
          >
            <HandIndex className="point" />
            Chính sách giao hàng
          </Link>
          <Link
            className="warranty-policy-content-item"
            to="/cac-chinh-sach-bao-hanh-va-quy-dinh/chinh-sach-bao-mat-tt-ca-nhan"
            rel="nofollow"
            title="Chính sách bảo mật thông tin khách hàng"
          >
            <HandIndex className="point" />
            Chính sách bảo mật thông tin
          </Link>
          <Link
            className="warranty-policy-content-item"
            to="/cac-chinh-sach-bao-hanh-va-quy-dinh/huong-dan-gui-bao-hanh"
            rel="nofollow"
            title="Hướng dẫn bảo hành"
          >
            <HandIndex className="point" />
            Hướng dẫn bảo hành
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WarrantyPolicy;
