import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import "../public/quoteRequestForm.css";
import { Container } from "react-bootstrap";
import MetaDecorator from "../../../../util/metaDecorator";
import { PiEnvelopeSimpleThin } from "react-icons/pi";
import { axiosClient } from "../../../../config";

const RequestQuoteForm = () => {
  const [fileError, setFileError] = useState(null);

  const handleSubmit = async (values) => {
    if (fileError) {
      alert("File không hợp lệ, không thể submit form.");
      return;
    }

    try {
      const response = await axiosClient.post(`/add-contact-qoute`, {
        fullName: values.fullName,
        numberPhone: values.phoneNumber,
        email: values.email,
        typeCustomer: values.companyName,
        address: values.address,
        content: values.content,
        file: values.file,
      });
    } catch (error) {
      console.error("Post quote request form error", error.message);
    }
  };

  const validFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/zip",
    "application/vnd.ms-excel", // Excel cũ (xls)
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  return (
    <div>
      <MetaDecorator description="Yêu cầu báo giá" title="Yêu cầu báo giá" />

      <Container className="quote-container">
        <div className="quote-form">
          <h2>YÊU CẦU BÁO GIÁ</h2>
          <p className="quote-form__content">
            Hãy để lại thông tin và yêu cầu, chúng tôi sẽ liên lạc lại để tư vấn
            cho quý khách.
          </p>
          <Formik
            initialValues={{
              fullName: "",
              phoneNumber: "",
              email: "",
              companyName: "",
              address: "",
              content: "",
              file: null,
            }}
            validationSchema={Yup.object({
              fullName: Yup.string().required("Họ tên là bắt buộc"),
              phoneNumber: Yup.string().required("Số điện thoại là bắt buộc"),
              email: Yup.string().email("Địa chỉ email không hợp lệ"),
              content: Yup.string().required("Nội dung là bắt buộc"),
            })}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="quote-form__box">
                  <label>
                    Họ tên <span>(*)</span>
                  </label>
                  <div className="quote-form__input">
                    <Field name="fullName" type="text" />
                    <ErrorMessage
                      className="error_msg"
                      name="fullName"
                      component="div"
                    />
                  </div>
                </div>

                <div className="quote-form__box">
                  <label>
                    Số điện thoại <span>(*)</span>
                  </label>
                  <div className="quote-form__input">
                    <Field name="phoneNumber" type="text" />
                    <ErrorMessage
                      className="error_msg"
                      name="phoneNumber"
                      component="div"
                    />
                  </div>
                </div>

                <div className="quote-form__box">
                  <label>Email</label>
                  <div className="quote-form__input">
                    <Field name="email" type="email" />
                    <ErrorMessage
                      className="error_msg"
                      name="email"
                      component="div"
                    />
                  </div>
                </div>

                <div className="quote-form__box">
                  <label>Tên công ty / Khách lẻ</label>
                  <div className="quote-form__input">
                    <Field name="companyName" type="text" />
                  </div>
                </div>

                <div className="quote-form__box">
                  <label>Địa chỉ</label>
                  <div className="quote-form__input">
                    <Field name="address" type="text" />
                  </div>
                </div>

                <div className="quote-form__box">
                  <label>
                    Nội dung <span>(*)</span>
                  </label>
                  <div className="quote-form__input">
                    <Field
                      name="content"
                      as="textarea"
                      style={{ height: "100px" }}
                    />
                    <ErrorMessage
                      className="error_msg"
                      name="content"
                      component="div"
                    />
                  </div>
                </div>

                <div className="quote-form__box">
                  <label>Đính kèm tệp</label>
                  <div className="quote-form__input">
                    <input
                      style={{ fontSize: 14 }}
                      name="file"
                      type="file"
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        if (file && !validFileTypes.includes(file.type)) {
                          setFileError(
                            "Chỉ chấp nhận file .doc, .docx, .pdf, .zip, .xls"
                          );
                          setFieldValue("file", null);
                        } else {
                          setFileError(null);
                          setFieldValue("file", file);
                        }
                      }}
                    />
                  </div>
                  <div className="quote-form__box">
                    <label></label>
                    {fileError && <div className="error">{fileError}</div>}
                  </div>
                </div>

                <div className="quote-form__box">
                  <label></label>
                  <div className="form-buttons">
                    <button
                      type="submit"
                      className="quote-form__submit d-flex align-items-center gap-2"
                    >
                      <PiEnvelopeSimpleThin />
                      Gửi
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export default RequestQuoteForm;
