import React, { useState, useEffect } from "react";
import "../public/productHotPage.css";
import { Col, Container, Row } from "react-bootstrap";
import * as productService from "../../../services/productService";
import * as advertiseService from "../../../services/advertiseService";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import BoxProduct from "../../product/view/boxProduct";
import BannerProductHot from "../../../../component/image/bannerProdutHot.jpg";
import { imageBaseUrl } from "../../../../config";
import MetaDecorator from "../../../../util/metaDecorator";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 768 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 768, min: 450 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 450, min: 0 },
    items: 2,
  },
};

const ProductHotPage = () => {
  const [productHotData, setProductHotData] = useState([]);
  const [productHotCategory, setProductHotCategory] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [allProductHot, productHot] = await Promise.all([
          productService.getAllProductHot(),
          productService.getProductHot(),
        ]);

        setProductHotCategory(allProductHot?.catProductHot);
        setProductHotData(productHot?.productHot);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchData();
  }, []);

  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();
  const [bannerProductHot, setBannerProductHot] = useState([]);
  const fetchBannerProductHot = () => {
    advertiseService
      .getBannerProductHot()
      .then((data) => {
        setBannerProductHot(data);
      })
      .catch((error) => console.error("fetch data error", error));
  };
  useEffect(() => {
    fetchBannerProductHot();
  }, []);

  return (
    <>
      <MetaDecorator
        title="Sản phẩm nổi bật"
        description="Sản phẩm nổi bật tại công ty Vi tính nguyên kim"
      />
      <div className="product-hot-page-container">
        <Link title="banner product hot" to="/san-pham-noi-bat">
          <img
            src={BannerProductHot}
            className="product-hot-page-container-img"
            alt="banner product hot"
            loading="lazy"
          />
        </Link>

        <Container>
          <div className="product-hot-page-top-flash-sale">
            <div>
              {bannerProductHot
                .filter((item) => item.title == "top deal sốc")
                .map((banner) => (
                  <img
                    src={`${imageBaseUrl}/uploads/${banner.picture}`}
                    className="product-hot-page-container-img"
                    alt={`${banner.title}`}
                    loading="lazy"
                  />
                ))}
            </div>
            <div className="product-hot-page-top-flash-sale-content">
              <Container className="product-hot-container p-3">
                <Row>
                  <Carousel
                    responsive={responsive}
                    containerClass="carousel-container"
                  >
                    {productHotData?.map((item) => {
                      const picture =
                        item.price_list?.[0]?.picture ||
                        item.picture ||
                        "No_image.jpg";
                      const price = user
                        ? item.price_list?.[0]?.price_old
                        : item.price_list?.[0]?.price;

                      return (
                        <Col
                          key={item.IdProduct} // Đảm bảo key là duy nhất
                          className="brand-product w-100"
                        >
                          <div className="me-2">
                            <BoxProduct
                              productId={item.IdProduct}
                              catNameParent={item.catNameParent}
                              picture={picture}
                              pictureStatus={item.product_status?.picture}
                              productName={item.product_desc?.title}
                              link={item.product_desc?.friendly_url}
                              price={price}
                              catName={item.category_des?.cat_name}
                              status={item.stock}
                              productHot={item.status}
                              macn={item.macn}
                              brandName={item.brandName}
                              cateParentId={parseInt(item?.cat_parent_id)}
                            />
                          </div>
                        </Col>
                      );
                    })}
                  </Carousel>
                </Row>
              </Container>
            </div>

            <Container className="mt-4">
              {productHotCategory.map((itemCategory) => (
                <div className="mt-4" key={itemCategory.product_id}>
                  {/* Hiển thị banner */}
                  <div>
                    {bannerProductHot
                      .filter(
                        (banner) =>
                          banner.title.normalize("NFC").toLowerCase() ===
                          itemCategory.cat_name.normalize("NFC").toLowerCase()
                      )
                      .map((banner) => (
                        <img
                          key={banner.title}
                          src={`${imageBaseUrl}/uploads/${banner.picture}`}
                          className="product-hot-page-container-img"
                          alt={banner.title}
                          loading="lazy"
                        />
                      ))}
                  </div>

                  {/* Hiển thị danh sách sản phẩm */}
                  <div className="product product-hot-item">
                    <Row>
                      <Carousel
                        responsive={responsive}
                        containerClass="carousel-container"
                      >
                        {itemCategory.product.map((product) => {
                          const picture =
                            product.price_list?.length > 0 &&
                            product.price_list[0]?.picture
                              ? product.price_list[0].picture
                              : product.picture || "No_image.jpg";

                          const discountPercent =
                            product.price_old > 0
                              ? ((product.price - product.price_old) /
                                  product.price_old) *
                                100
                              : 0;

                          const price = user
                            ? product.price_list?.[0]?.price_old ||
                              product.price_old
                            : product.price_list?.[0]?.price || product.price;

                          return (
                            <Col
                              key={product.product_id}
                              className="brand-product w-100"
                            >
                              <BoxProduct
                                productId={product.IdProduct}
                                catNameParent={product.catNameParent}
                                picture={picture}
                                percent={discountPercent}
                                id={product.IdProduct}
                                macn={product.macn}
                                catName={product.category_des?.cat_name}
                                productName={product?.product_desc?.title}
                                link={product.product_desc?.friendly_url}
                                status={product.stock}
                                productHot={product.status}
                                pictureStatus={product.product_status?.picture}
                                brandName={product.brandName}
                                price={price}
                                cateParentId={parseInt(
                                  itemCategory?.cat_id,
                                  10
                                )}
                              />
                            </Col>
                          );
                        })}
                      </Carousel>
                    </Row>
                  </div>
                </div>
              ))}
            </Container>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ProductHotPage;
