import { axiosClient } from "../../config";

export const getGuide = async () => {
  return await axiosClient
    .get("/member/guide")
    .then((res) => {
      return res.data;
    })
    .catch((error) => error.response);
};

export const getGuideDetail = async (payload) => {
  return await axiosClient
    .get("/member/guide/" + payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => error.response);
};
