import { Container, Image, Form, Col, Row } from "react-bootstrap";
import { FaRankingStar } from "react-icons/fa6";
import { BsHourglassSplit } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { GiPaperBagFolded } from "react-icons/gi";
import { FaMedal } from "react-icons/fa";

function CardInfoCompany({ dataJob }) {
  const natureWork = [
    {
      icon: <FaRankingStar />,
      title: "Cấp bậc",
      value: dataJob.rank,
    },
    {
      icon: <BsHourglassSplit />,
      title: "Kinh nghiệm",
      value: dataJob.experience,
    },
    {
      icon: <FaUsers />,
      title: "Số lượng tuyển ",
      value: `${dataJob.number} người`,
    },
    {
      icon: <GiPaperBagFolded />,
      title: "Hình thức làm việc",
      value: dataJob.form,
    },
    {
      icon: <FaMedal />,
      title: "Bằng cấp ",
      value: dataJob.degree,
    },
  ];

  return (
    <div className="box_card_info_company">
      <Row>
        <Col md={12} className="box_infor_job_common">
          <div className="box_text">
            <span>Thông tin chung</span>
          </div>
        </Col>
      </Row>
      <Row>
        {natureWork &&
          natureWork?.map((item, index) => (
            <Col key={index} md="12" className="d-flex align-items-center my-3">
              <div className="box_icon_nature">{item.icon}</div>
              <Row>
                <Col md="12" className="text_rank">
                  {item.title}
                </Col>
                <Col md="12" className="text_value">
                  {item.value}
                </Col>
              </Row>
            </Col>
          ))}
      </Row>
    </div>
  );
}

export default CardInfoCompany;
