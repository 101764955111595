import React, {useState,useEffect} from 'react'
import '../public/pickUp.css';

const PickUP = ({onPickUpInfoChange, validationErrors}) => {
    const [valueFormData, setValueFormData] = useState({
        name: '',
        phone: '',
        email: '',
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setValueFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };
    useEffect(() => {
        onPickUpInfoChange(valueFormData)
    }, [valueFormData, onPickUpInfoChange])
    const useAuth = () => {
        const checkLogin = localStorage.getItem("user_token");
        if (checkLogin) {
          return true;
        } else {
          return false;
        }
      };
      const user = useAuth();
    return (
        <div className="pickup-container">
            <div className="pickup-address">
                <p>245B Trần Quang Khải, Phường Tân Định, Quận 1, Hồ Chí Minh</p>
            </div>
            {!user && (
                <div className="mt-4">
                    <p className="pickup-info">Thông tin nhận hàng</p>
                    <div className="pickup-input-container">
                        <div className="pickup-input-item">
                            <p className="pickup-input-lable">
                                Tên khách hàng <strong className="attention">*</strong>
                            </p>
                            <input
                                className="pickup-input"
                                type="text"
                                name="name"
                                placeholder="Nhập tên khách hàng"
                                value={valueFormData.name}
                                onChange={handleInputChange}
                            />
                            {validationErrors.name ? <span className="error-message">{validationErrors.name}</span> : null}
                        </div>
                        <div className="pickup-input-item">
                            <p className="pickup-input-lable">
                                Số điện thoại <strong className="attention">*</strong>
                            </p>
                            <input
                                className="pickup-input"
                                type="text"
                                name="phone"
                                placeholder="Nhập số điện thoại"
                                value={valueFormData.phone}
                                onChange={handleInputChange}
                            />
                            {validationErrors.phone ? <span className="error-message">{validationErrors.phone}</span> : null}
                        </div>
                        <div className="pickup-input-item">
                            <p className="pickup-input-lable">
                                Email nhận hàng <strong className="attention">*</strong>
                            </p>
                            <input className="pickup-input" type="email" name="email" placeholder="Nhập Email"  value={valueFormData.email} onChange={handleInputChange}/>
                            {validationErrors.email ? <span className="error-message">{validationErrors.email}</span> : null}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PickUP;
