import React, { useState, useEffect } from "react";
import BoxProduct from "../../product/view/boxProduct";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../public/relatedProducts.css";
import { axiosClient } from "../../../../config";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 768 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 450 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 450, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const RelatedProducts = ({ category, friendUrl, cateParentId }) => {
  const useAuth = () => {
    const checkLogin = localStorage.getItem("user_token");
    if (checkLogin) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();
  const [relatedProducts, setRelatedProducts] = useState([]);
  useEffect(() => {
    axiosClient
      .get("/member/relate-product?key=" + friendUrl)
      .then((response) => setRelatedProducts(response.data.relatedProduct))
      .catch((error) => console.error(error));
  }, []);
  return (
    <>
      {Array.isArray(relatedProducts) && relatedProducts.length > 0 ? (
        <div className="related-product-container">
          <h3 className="related-product-title">Sản phẩm liên quan</h3>
          <div className="realated-product-content">
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              // draggable
              focusOnSelect={false}
              // infinite
              itemClass="carousel-item-padding-40-px"
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={responsive}
            >
              {relatedProducts
                .filter(
                  (item) =>
                    item.productName !== null &&
                    item.friendlyUrl !== null &&
                    item.picture !== "uploads/"
                )
                .map((item, index) => {
                  return (
                    <BoxProduct
                      percent={
                        item.priceOld > 0
                          ? ((item.price - item.priceOld) / item.priceOld) * 100
                          : 0
                      }
                      key={index}
                      id={item.productId}
                      productId={item.productId}
                      link={item.friendlyUrl}
                      category={category}
                      picture={item?.picture}
                      productName={item.productName}
                      price={user ? item.priceOld : item.price}
                      macn={item.maCn}
                      status={item.Status}
                      catNameParent={category}
                      brandName={item.brandName}
                      catName={item.catName}
                      cateParentId={cateParentId}
                    />
                  );
                })}
            </Carousel>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RelatedProducts;
