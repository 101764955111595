import React, { useEffect, useState } from "react";
import { axiosClient } from "../../../../../config";
import MetaDecorator from "../../../../../util/metaDecorator";
import { Select } from "antd";

const StatusOrder = ({ setSearchParams, setPage, keyStatus }) => {
  const [statusOrder, setStatusOrder] = useState([]);

  const fetchStatusOrder = async () => {
    try {
      const res = await axiosClient.get("/member/show-order-status");
      setStatusOrder(res?.data?.status ? res?.data?.data : []);
    } catch (error) {
      console.error("fetch data error", error);
    }
  };

  useEffect(() => {
    fetchStatusOrder();
  }, []);

  const handleClick = (keyStatus) => {
    setSearchParams({ statusOrder: keyStatus }); // Gắn search param vào URL
    setPage(1);
  };

  const handleChange = (value) => {
    setSearchParams({ statusOrder: value }); // Gắn search param vào URL
    setPage(1);
  };
  return (
    <>
      <MetaDecorator title="Quản lý đơn hàng" description="Quản lý đơn hàng" />
      <div className="col-12 box_title_status_order mb-1    ">
        <span className="text_title_common ">Quản lý đơn hàng</span>
      </div>

      <div className="d-flex justify-content-end mb-1 box_status_order_container">
        <div className="box_status_order custom_scroll d-none d-lg-flex">
          {statusOrder &&
            statusOrder.length > 0 &&
            statusOrder.map((item, index) => (
              <span
                key={item.status_id}
                className={`item_status   ${
                  keyStatus === item.keyStatus ? "item_status_active" : ""
                }   `}
                onClick={() => handleClick(item.keyStatus)} // Gắn sự kiện click
              >
                {item.title}
              </span>
            ))}
        </div>
      </div>

      <Select
        size="large"
        className="d-lg-none w-50"
        defaultValue="Trạng thái đơn hàng"
        style={{
          width: 120,
        }}
        onChange={handleChange}
        options={
          statusOrder && statusOrder.length > 0
            ? statusOrder.map((item, index) => {
                return {
                  value: item.keyStatus,
                  label: item.title,
                };
              })
            : []
        }
      />
    </>
  );
};
export default StatusOrder;
